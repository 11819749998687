import sortalphadown from "../../../../src/assets/sortalphadown.png";
import onenine from "../../../../src/assets//sortnumericdown.png";

export const userTableConfig = [
  {
    id: "1",
    col1: "⋮⋮⋮",
    col2: false,
    col3: "User ID",
    column: "USERID",
    col4: sortalphadown,
    iconIndex: 0,
  },
  {
    id: "2",
    col1: "⋮⋮⋮",
    col2: false,
    col3: "Name",
    column: "USERNAME",
    col4: sortalphadown,
    iconIndex: 0,
  },
  {
    id: "3",
    col1: "⋮⋮⋮",
    col2: false,
    col3: "Email",
    column: "USER_EMAIL",
    col4: sortalphadown,
    iconIndex: 0,
  },
  {
    id: "4",
    col1: "⋮⋮⋮",
    col2: false,
    col3: "Equiv. User ID",
    column: "EQUV_USER",
    col4: sortalphadown,
    iconIndex: 0,
  },
  {
    id: "5",
    col1: "⋮⋮⋮",
    col2: false,
    col3: "Start Date",
    column: "START_DATE",
    col4: onenine,
    iconIndex: 2,
  },
  {
    id: "6",
    col1: "⋮⋮⋮",
    col2: false,
    col3: "End Date",
    column: "END_DATE",
    col4: onenine,
    iconIndex: 2,
  },
];

export const columnOptionsConfig = [
  { type: "text", label: "Company Code", value: "t.COMP_CODE" },
  { type: "text", label: "Company Name", value: "c.NAME" },
  { type: "text", label: "Module Name", value: "mo.NAME" },
  { type: "text", label: "Menu Name", value: "t.menuItem" },
  { type: "text", label: "Menu Group", value: "t.menuGroup" },
  { type: "text", label: "Access", value: "t.access" },
];
