import { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";

const ItemTypes = {
  ROW: "row",
};

const EditColumnDev = (props) => {
  const [data, setData] = useState(props.columnHeaders);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const currentPath = window.location.pathname;
  const localStorageKey = `sorting${currentPath}`;
  const selectedKey = `sorting-index${currentPath}`;
  const company = useSelector((state: any) => state?.userConfig?.company);
  useEffect(() => {
    const storedDataString: any = localStorage.getItem(localStorageKey);
    const indexes = localStorage.getItem(selectedKey);
    const filterData = JSON.parse(storedDataString);

    const parseData = () => {
      if (props.columnHeaders && filterData) {
        let newArr = [...props.columnHeaders];

        newArr.forEach((item) => {
          filterData.forEach((res) => {
            if (item.defaultColumnIndex === res.defaultColumnIndex) {
              item.caption = res.caption;
            }
          });
        });

        return newArr;
      }
    };

    let temp = parseData();

    if (temp) {
      setData(temp);

      const updatedSelectedRows = temp
        .filter((row) => selectedRows.includes(row.defaultColumnIndex))
        .map((row) => row.defaultColumnIndex);
      setSelectedRows(updatedSelectedRows);
    } else {
      setData(props.columnHeaders);
    }

    setSelectedRows(indexes ? JSON.parse(indexes) : []);
  }, []);

  const Row = ({ row, index, moveRow, selectedRows }) => {
    const [, ref, preview] = useDrag({
      type: ItemTypes.ROW,
      item: { index },
    });

    const [, drop] = useDrop({
      accept: ItemTypes.ROW,
      hover: (draggedItem: any) => {
        if (draggedItem.index !== index) {
          moveRow(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });

    const handleEditClick = (defaultColumnIndex) => {
      setData((prevData) =>
        prevData.map((row) => ({
          ...row,
          isEditing:
            row?.defaultColumnIndex === defaultColumnIndex
              ? !row.isEditing
              : false,
        }))
      );
    };

    const handleInputChange = (defaultColumnIndex, value) => {
      var result = [...data];
      result = result.map((x) => {
        if (x?.defaultColumnIndex === defaultColumnIndex) {
          x.caption = value;
          return x;
        } else return x;
      });
      setData(result);
    };

    const handleSelectChange = (defaultColumnIndex, event) => {
      const selectedValue = event.target.value;
      var result = [...data];
      result = result.map((x) => {
        if (x?.defaultColumnIndex === defaultColumnIndex) {
          x.alignment = selectedValue;
          return x;
        } else return x;
      });
      setData(result);
    };
    const handleFilterSelectChange = (defaultColumnIndex, event) => {
      const selectedValue = event.target.value;
      var result = [...data];
      result = result.map((x) => {
        if (x?.defaultColumnIndex === defaultColumnIndex) {
          x.aggFunc = selectedValue;
          return x;
        } else return x;
      });
      setData(result);
    };

    const handleToggleVisibility = (defaultColumnIndex) => {
      setData((prevColumns) => {
        return prevColumns.map((column, i) => {
          if (column?.defaultColumnIndex === defaultColumnIndex) {
            return { ...column, columnVisible: !column.columnVisible };
          } else {
            return column;
          }
        });
      });
    };

    return (
      <tr
        key={row.defaultColumnIndex}
        ref={(node) => drop(ref(node))}
      >
        <td
          style={{
            border: "0",
            padding: "8px",
            color: "black",
          }}
          className="fs-5"
        >
          ⋮⋮⋮
        </td>
        <td
          style={{
            border: "0",
            padding: "8px",
            color: "black",
            width:"300px",
            paddingLeft:row.isEditing?"8px":"23px"
          }}
          className="fs-5"
          onClick={() => handleEditClick(row.defaultColumnIndex)}
        >
          {row.isEditing ? (
            <input
              type="text"
              value={row.caption}
              onChange={(e) =>
                handleInputChange(row.defaultColumnIndex, e.target.value)
              }
              disabled={!row?.columnVisible}
              autoFocus
            />
          ) : (
            row.caption
          )}
        </td>
        <td
          style={{
            border: "0",
            padding: "8px",
            color: "black",
            width: "30%",
          }}
          className="fs-5"
        >
          <select
            style={{ width: "100%" }}
            onChange={(event) =>
              handleFilterSelectChange(row.defaultColumnIndex, event)
            }
            value={row?.aggFunc}
            disabled={!row?.columnVisible}
          >
            <option value="none">None</option>
            <option value="count">count</option>
            {row.columnType==="Numeric" && <option value="sum">sum</option>}
            {row.columnType==="Numeric" && <option value="min">min</option>}
            {row.columnType==="Numeric" && <option value="max">max</option>}
            {row.columnType==="Numeric" && <option value="avg">average</option>}
          </select>
        </td>
        <td
          style={{
            border: "0",
            padding: "8px",
            color: "black",
            width: "30%",
          }}
          className="fs-5"
        >
          <select
            style={{ width: "100%" }}
            onChange={(event) =>
              handleSelectChange(row.defaultColumnIndex, event)
            }
            value={row?.alignment}
            disabled={!row?.columnVisible}
          >
            <option value="left">Left</option>
            <option value="right">Right</option>
            <option value="center">Center</option>
          </select>
        </td>
        <td style={{ border: "0", padding: "8px",opacity: !row?.columnVisible ? 0.2 : 1, }}>
          <div onClick={() => handleToggleVisibility(row.defaultColumnIndex)}>
            <div>
              {row?.columnVisible ? (
                <FaEye
                  style={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                    color: "grey",
                  }}
                />
              ) : (
                <FaEyeSlash
                  style={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                    color: "grey",
                  }}
                />
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    const localStorageData = localStorage.getItem(localStorageKey);
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);

      setData((prevData) => {
        const mergedData = [...prevData];

        parsedData.forEach((parsedItem) => {
          const index = mergedData.findIndex(
            (item) => item.defaultColumnIndex === parsedItem.defaultColumnIndex
          );
          if (index !== -1) {
            mergedData[index] = { ...mergedData[index], ...parsedItem };
          } else {
            mergedData.push(parsedItem);
          }
        });

        return mergedData;
      });
    }
  }, []);

  const moveRow = (dragIndex, hoverIndex) => {
    const draggedRow = data[dragIndex];
    const newData = [...data];
    newData.splice(dragIndex, 1);
    newData.splice(hoverIndex, 0, draggedRow);
    setData(newData);
  };

  const handleApply = () => {
    const storedDataString: any = localStorage.getItem(localStorageKey);
    const filterData = JSON.parse(storedDataString);

    const parseData = () => {
      if (data && filterData) {
        return data.map((item, index) => {
          const matchedFilter = filterData.find(
            (res) => res.defaultColumnIndex === item.defaultColumnIndex
          );
          return matchedFilter
            ? { ...item, caption: matchedFilter.caption }
            : item;
        });
      }
      return data;
    };

    const updatedData = parseData();

    const updatedDataWithIndex = updatedData.map((item, index) => ({
      ...item,
      columnIndex: index+1,
    }));

    const payload = {
      tabStorage: props.tabStorage,
      tabSqlName: props.tabSqlName,
      modifiedColumnNames: updatedDataWithIndex,
      companyCode: company,
    };

    props.onApply(payload);
    props.onCancel();
  };

  const handleMouseEnter = () => {
    const accordionHeader = document.querySelector(".accordion-header");
    if (accordionHeader) {
      accordionHeader.setAttribute("data-bs-toggle", "collapse");
    }
  };

  const handleMouseLeave = () => {
    const accordionHeader = document.querySelector(".accordion-header");
    if (accordionHeader) {
      accordionHeader.setAttribute("data-bs-toggle", "");
    }
  };

  const handleCancel = () => {
    // props.onApply([]);
    props.onCancel();
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <Formik
        initialValues={{ /* your initial form values */ }}
        onSubmit={handleApply}
        onReset={handleCancel}
      >
        {({ isSubmitting }) => (
          <Form>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "-28px",
              }}
            >
              <thead style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  marginTop: "-28px",
                }}>
                <th></th>
                <th style={{paddingLeft:"20px"}}>Column Name</th>
                <th style={{paddingLeft:"10px"}}>Aggregate Function</th>
                <th style={{paddingLeft:"10px"}}>Alignment</th>
                <th style={{paddingLeft:"10px"}}>Show/Hide</th>
                </thead>
              <tbody>
                {data.map((row, index) => row && row.defaultCaption !== 'Actions' && (
                  <Row
                    key={row.defaultColumnIndex}
                    row={row}
                    index={index}
                    moveRow={moveRow}
                    selectedRows={selectedRows}
                  />
                ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-center gap-15 mt-5">
              <button
                type="reset"
                className="btn btn-secondary rounded-pill mx-2"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                title="Cancel"

              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn text-white rounded-pill mx-2"
                title="Apply"
                style={{ backgroundColor: "#0a7eac" }}
                data-kt-users-modal-action="submit"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                disabled={isSubmitting}
              >
                Apply
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </DndProvider>
  );
};

export default EditColumnDev;
