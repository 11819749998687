/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  login,
  loginViaGoogle,
  loginViaMicrosoft,
} from "../core/_requests";
import { useAuth } from "../core/Auth";
import google from "../../../../assets/google.png";
import microsoft from "../../../../assets/micro3.png";
import AlertModel from "../../widgets/components/AlertModel";
import ExpireModel from "../../widgets/components/Expire";
import { UserEditModal } from "../../apps/user-management/users-list/user-edit-modal/UserEditModal";
import jwt_decode from "jwt-decode";
import "react-toastify/dist/ReactToastify.css";
import { toastMessage } from "../functions/toastMessage";
import UserBlockModal from "../../widgets/components/UserblockModel";
import { Language } from "../../../utils/Config";
import { languageChangeHandler } from "../../../../theme/assets/ts/_utils/LanguageUtils";
import AlertModelMoreAttempt from "../../widgets/components/AlertModatMoreAttempt";
import ClearStorage from "../functions/ClearStorage";
import { saveIsMultipleCompanyAssigned } from "../../../../reducers/LayoutReducer";
import { useDispatch } from "react-redux";
import { saveCompanyAndName, saveLanguage } from "../../../../reducers/UserConfigReducer";
import { Alert } from 'react-bootstrap';


const loginSchema = Yup.object().shape({
  userId: Yup.string()
    .label("")
    .trim()
    // .email("Invalid Email")
    // .min(3, "Minimum 3 symbols")
    // .max(50, "Maximum 50 symbols")
    .required("User Id is required"),
  userpwd: Yup.string()
    //.min(3, 'Minimum 3 symbols')
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  userId: "",
  userpwd: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/


export function Login() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [call, setCall] = useState(false);
  const [pref, setPref] = useState(false);
  const [authToken, setAuthToken] = useState<any>("");
  const [userId, setUserId] = useState("");
  const [days, setDays] = useState("");
  const [attemptMessage, setAttemptMessage] = useState("");
  const [time, setTime] = useState("");
  const [platform, setPlatform] = useState(false);
  const [userBlockedModel, setUserBlockedModel] = useState(false);
  const languages=Language.LANGUAGE;
  

  const {
    saveAuth,
    setCurrentUser,
    currentUser,
    setCurrentUsers,
    setCurrent,
  } = useAuth();
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [itemIdForReset, setItemIdForReset] = useState(false);
  const [moreAttemptModal, setMoreAttemptModal] = useState(false);
  const [isPrefrenceModalOpen, setIsPrefrenceModalOPen] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
  const [expiredModel, setExpiredModel] = useState(false);
  const [priority, setPriority] = useState({
    warning: false,
    expired: false,
    platform: false,
    tfa: false,
  });
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const inactivity = localStorage.getItem('inactivity');
    if (inactivity === 'true') {
      setShowAlert(true);
      localStorage.removeItem('inactivity');
    }
  }, []);

  
  const handleCloseAlert = () => {
    setShowAlert(false);
    localStorage.setItem('inactivity', 'false');
  };

  const navigate = useNavigate();
  const closePrefrencedModal = (event) => {
    setPriority((prevPriority) => ({
      ...prevPriority,
      warning: false,
      expired: false,
      platform: false,
    }));
    setIsPrefrenceModalOPen(false);
    setPref(!pref);
    setCall(true);
  };
  const closeForgotPasswordModal = (event) => {
    setIsForgotPasswordModalOpen(false);
  };
  const closeChangePasswordModal = (event) => {
    // event.preventDefault(); // Prevent the default link behavior
    setChangePasswordModalOpen(false);
    // setCall(true);
    // setCall(true);
  };
  let auth;

  const handleClick = async (platform) => {
    try {
      setPlatform(true);
      let data: any;
      switch (platform) {
        case "microsoft":
          data = await loginViaMicrosoft(platform);
          break;
        case "google":
          data = await loginViaGoogle(platform);
          break;

        default:
          break;
      }
      if (data) {
        let decryptData: any = jwt_decode(data?.accessToken);

        let token = data?.accessToken;
        let refreshToken = data?.refreshToken;
        let subParts = decryptData?.sub?.split("#");
        if (!subParts[3]) {
          // If there's no language after the third hash, add "English"
          subParts[3] = "English";
        }
        let currentUser = {
          sub: subParts.join("#"),
          roles: decryptData?.roles,
          token,
          refreshToken,
        };
        setCurrentUser(currentUser);
        saveAuth(currentUser);
        toastMessage("Logged in successfully", "success");
        navigate("/selectcompany");
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        return toastMessage(
          `Your ${platform.toLocaleUpperCase()} account is not linked. Please login using Classic Id.`,
          "error"
        );
      } else if (
        error.code === "auth/account-exists-with-different-credential"
      ) {
        return toastMessage(
          `Your account exist with different credential`,
          "error"
        );
      } else if (error?.code === "auth/popup-closed-by-user" || error?.code === "auth/cancelled-popup-request") {
        return;
      } else {
        toastMessage(`Something went wrong!`, "error");
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      ClearStorage({ saveAuth, setCurrentUser, setCurrentUsers, setCurrent });
        try {
          const res = await login(values?.userId, values?.userpwd);
          localStorage.setItem("refreshToken", res?.data?.data?.refreshToken);
          auth = res?.data?.data;
          setAuthToken(auth);
          setUserId(values.userId);
          await setPriority((prevPriority) => ({
            ...prevPriority,
            platform: auth.platformBlank || prevPriority.platform,
            warning: auth.passExpiryWarning || prevPriority.warning,
            expired: auth.passwordExpired || prevPriority.expired,
            tfa: auth.tfa || prevPriority.tfa,
          }));
          console.debug("Setting call to true");
          await setCall(true);
          console.debug("Setting call to true : AFTER");
          await setPref(!pref);
          console.debug("Logged in successfully : :::::" )
          toastMessage("Logged in successfully", "success");

          if (!platform || auth.accessToken) {
            let temp = {
              userId: values?.userId,
              data: [],
              favoriteNames: [],
            };
            dispatch(saveIsMultipleCompanyAssigned(auth.isMultipleCompanyAssigned || true))
            let pinnedData: any = localStorage.getItem("pinnedData");
            pinnedData = JSON.parse(pinnedData);

            let finArr: any = [];
            if (!pinnedData || (pinnedData && pinnedData.length === 0)) {
              finArr.push(temp);
            } else {
              pinnedData.map((item) => {
                if (item.userId !== values?.userId) {
                  finArr.push(temp);
                } else {
                  finArr = pinnedData;
                }
              });
            }
            localStorage.setItem("pinnedData", JSON.stringify(finArr));

            if (auth?.accessToken) {
              let token = auth.accessToken;
              let refreshToken = auth.refreshToken;
              let decryptData: any = jwt_decode(auth?.accessToken);
              let subParts = decryptData?.sub?.split("#");
              if (!subParts[3]) {
                // If there's no language after the third hash, add "English"
                subParts[3] = "English";
              }
              let currentUser = {
                sub: subParts.join("#"),
                roles: decryptData?.roles,
                token,
                refreshToken,
              };
              console.debug("decryptData", currentUser);

              let language = currentUser!.sub.split("#")[3];
              let obj: any = languages.find((lang) => lang?.label === language);
              localStorage.setItem("language", obj?.value);
              dispatch(saveLanguage(obj?.value));
              languageChangeHandler(obj.value);
              setCurrentUser(currentUser);
              saveAuth(currentUser);
              
            }
          }
        } catch (error: any) {
          saveAuth(undefined);
          let statusFunc = () => {
            if (error?.response?.status === 403) {
              return toastMessage(`${error.response.data.data}`, "error");
            } else if (error.response?.status === 400) {
              return toastMessage(
                `${error.response.data.data}` || "UserId should be either uppercase characters or should be a combination of uppercase characters and numbers",
                "error"
              );
            } else if (error.response?.status === 404) {
              return toastMessage(
                `${error.response.data.data}` || "User does not exists !",
                "error"
              );
            } else if (error.response?.status === 401) {
              const errorMessageArray = error?.response?.data?.data;
              if (errorMessageArray && errorMessageArray.includes("#")) {
                const splitMessages = errorMessageArray
                  .split("#")
                  .map((message) => message.trim());
                if (splitMessages[1]) {
                  setAttemptMessage(splitMessages[1]);
                  setMoreAttemptModal(true);
                }
                return toastMessage(splitMessages[0], "error");
              } else {
                return toastMessage(errorMessageArray, "error");
              }
            } else if (error.response?.status === 423) {
              setTime(error.response.data.data);
              return setUserBlockedModel(true);
            }
          };
          setStatus(statusFunc());
          setSubmitting(false);
          setLoading(false);
        }
      }
      // , 1000);
    // },
  });
  const handleReset = () => {
    setChangePasswordModalOpen(true);
    setItemIdForReset(false);
  };

  const handleResetBlockModal = () => {
    setUserBlockedModel(false);
  };

  useEffect(() => {
    console.debug("calling !!!",call,priority);
    if (call) {
      checkNavigation();
    }
  }, [call, pref]);

  useEffect(() => {
    console.debug("calling !!!",call,priority);
    if (call) {
      checkNavigation();
    }
  }, [call]);

  const checkNavigation =async ()=>{
    if (priority.warning) {
      setItemIdForReset(true);
      setDays(authToken.daysLeft);
    } else if (priority.expired) {
      setExpiredModel(true);
    } else if (priority.platform) {
      setIsPrefrenceModalOPen(true);
      setPriority({ ...priority, platform: false });
    } else if (priority.tfa) {
      navigate("/twoFactorAuth", {
        state: { userId, email: authToken.email },
      });
      setPriority({ ...priority, tfa: false });
    } else {
        navigate("/selectcompany");
    }
  }

  const [passwordType, setPasswordType] = useState("password");

  const togglePasswordVisibility = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const handleUserIdChange = (e) => {
    const { name, value } = e.target;
    const upperCaseValue = value?.toUpperCase().replace(/[^A-Z0-9]/g, "");
    formik.setFieldValue(name, upperCaseValue);
  };

  return (
    <>
      <>
      {showAlert && (
      <Alert variant="danger" onClose={handleCloseAlert} dismissible>
        Logged out due to user inactivity
      </Alert>
    )}
        <div className="d-flex">
          <div className="p-5 cardSize" style={{ borderRadius: 0 }}>
            <form
              className="form w-100"
              onSubmit={formik.handleSubmit}
              noValidate
              id="kt_login_signin_form"
            >
              <div className="text-center mb-5"></div>

              <div className="fv-row mb-3">
                <div className="input-group">
                  <span className="input-group-text bg-white  h-40px">
                    <i className="bi bi-person-fill fs-3"></i>
                  </span>
                  <input
                    className="form-control  border-secondary fs-5"
                    placeholder="User Id"
                    {...formik.getFieldProps("userId")}
                    type="text"
                    name="userId"
                    autoComplete="off"
                    style={{ width: "200px" }}
                    onChange={handleUserIdChange}
                  />
                </div>
                {formik.touched.userId && formik.errors.userId && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.userId}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="fv-row mb-5" style={{ position: "relative" }}>
                <div className="input-group">
                  <span className="input-group-text bg-white h-40px">
                    <i className="bi bi-key-fill fs-3"></i>
                  </span>
                  <input
                    className="form-control border-secondary fs-5"
                    placeholder="Password"
                    type={passwordType}
                    autoComplete="off"
                    {...formik.getFieldProps("userpwd")}
                    style={{ width: "200px" }}
                  />
                  <span
                    className="input-group-text"
                    onClick={togglePasswordVisibility}
                  >
                    <i
                      className={`bi ${
                        passwordType === "password" ? "bi-eye" : "bi-eye-slash"
                      }`}
                    ></i>
                  </span>
                </div>
                {formik.touched.userpwd && formik.errors.userpwd && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.userpwd}</span>
                    </div>
                  </div>
                )}
              </div>

              <div
                className="text-end text-white fs-7 mt-3 mb-4 "
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => setIsForgotPasswordModalOpen(true)}
              >
                <a
                  className="ms-2 text-white "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Forgot Password?
                </a>
              </div>

              <div className="d-grid mb-2 fs-5">
                <button
                  title="Log In"
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn text-white  rounded-pill"
                  style={{ backgroundColor: "#0a7eac" }}
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className="indicator-label">Log In</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2 "></span>
                    </span>
                  )}
                </button>
              </div>

              <div className="text-end text-white fs-7 mt-3 mb-4 ">
                * By logging in, you agree to the
                <a
                  className="ms-2 text-white "
                  style={{ textDecoration: "underline" }}
                  href={`${process.env.PUBLIC_URL}/assets/Termsofsaleandsupport.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions{" "}
                </a>
                .
              </div>
            </form>
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-5">
                    <hr className="my-4 text-white " />
                  </div>
                  <div className="col-2 text-center">
                    <span className="fs-3 text-white">or</span>
                  </div>
                  <div className="col-5">
                    <hr className="my-4 text-white" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="btn border border-secondary rounded-pill d-flex gap-5 align-items-center justify-content-center mb-3"
                onClick={() => handleClick("google")}
              >
                <img
                  src={google}
                  style={{ height: "20px", width: "20px" }}
                  alt="Google Logo"
                />
                <div className="fw-normal fs-6 text-white">
                  Log In with Google
                </div>
              </div>
              <div
                className="btn border border-secondary rounded-pill  d-flex gap-5 align-items-center justify-content-center "
                onClick={() => handleClick("microsoft")}
              >
                <img
                  src={microsoft}
                  style={{ height: "20px", width: "20px" }}
                  alt="Microsoft Logo"
                />
                <div className="fw-normal fs-6 text-white">
                  Log In with Microsoft
                </div>
              </div>
              {/* Add terms and conditions, privacy policy links, and disclaimer */}
            </div>
          </div>
        </div>
      </>
      {isChangePasswordModalOpen && (
        <UserEditModal
          content="change_password"
          modalText="Change Password"
          onCloseEditModal={closeChangePasswordModal}
          // user="reset"
          userId={userId}
          login={true}
          call={() => {
            setPref(!pref);
            setCall(true);
            setPriority((prevPriority) => ({
              ...prevPriority,
              warning: false,
              expired: false,
            }));
          }}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
      {isPrefrenceModalOpen && (
        <UserEditModal
          content="prefrence"
          modalText="Set Preference"
          onCloseEditModal={closePrefrencedModal}
          state={userId}
          call={() => {
            setPriority((prevPriority) => ({
              ...prevPriority,
              warning: false,
              expired: false,
            }));
            setPref(!pref);
            setCall(true);
          }}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}

      {isForgotPasswordModalOpen && (
        <UserEditModal
          content="forgotpassword"
          modalText="Request for Password Reset"
          onCloseEditModal={closeForgotPasswordModal}
          state={userId}
          call={() => {}}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}

      {itemIdForReset && (
        <AlertModel
          onClose={() => {
            setPriority((prevPriority) => ({
              ...prevPriority,
              warning: false,
            }));
            setItemIdForReset(false);
            setPref(!pref);
          }}
          days={days}
          onReset={handleReset}
        />
      )}
      {moreAttemptModal && (
        <AlertModelMoreAttempt
          onClose={() => {
            setMoreAttemptModal(!moreAttemptModal);
          }}
          days={attemptMessage}
          // onReset={handleReset}
        />
      )}
      {userBlockedModel && (
        <UserBlockModal
          onClose={() => {
            setUserBlockedModel(false);
          }}
          onReset={handleResetBlockModal}
          time={time}
        />
      )}

      {expiredModel && (
        <ExpireModel
          onClose={() => {
            setExpiredModel(false);
            setLoading(false);
          }}
          onOpen={() => {
            setChangePasswordModalOpen(true);
          }}
        />
      )}
    </>
  );
}
