import { formatDatesinArray } from "./FormatData";

export const handleSearchChange = (
  searchValue,
  response,
  columnDefs,
  setRowData
) => {
  
  console.debug("filteredData 1", response);
  const columnToBeSearched = columnDefs.map((column) => column.field);
  if (!response) {
    console.error("Invalid response data");
    return;
  }

  if (!searchValue || searchValue.trim().length === 0) {
    setRowData(formatDatesinArray(response));
    return;
  }

  const searchWords = searchValue.trim().toLowerCase().split(" ");

  const filteredData = formatDatesinArray(response)?.filter((item) => {
    for (const word of searchWords) {
      if (
        !columnToBeSearched.some((columnName) =>
          item[columnName]
            ? item[columnName]
                .toString()
                .toLowerCase()
                .includes(word.toLowerCase())
            : false
        )
      ) {
        return false;
      }
    }
    return true;
  });

  console.debug("filteredData", filteredData);
  setRowData(filteredData);
};
export const handleNotificatonSearchChange = (
  searchValue,
  response,
  setRowData
  
) => {
  console.debug("filteredData 1", response);
  
  if (!response || !response.data) {
    console.error("Invalid response data");
    return;
  }

  if (!searchValue || searchValue.trim().length === 0) {
    setRowData(formatDatesinArray(response.data));
    return;
  }

  const searchWords = searchValue.trim().toLowerCase().split(" ");

  const filteredData = formatDatesinArray(response.data)?.filter((item) => {
    for (const word of searchWords) {
      // Check if any key in the object contains the search word
      if (
        Object.keys(item).some((key) =>
          item[key]
            ? item[key]
                .toString()
                .toLowerCase()
                .includes(word.toLowerCase())
            : false
        )
      ) {
        return true;
      }
    }
    return false;
  });

  console.debug("filteredData", filteredData);
  setRowData(filteredData);
};