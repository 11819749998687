import clsx from "clsx";
import { useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { colors, titleCss } from "../../../constants/colors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const StockEnquiryHeader = () => {
  const {t} = useTranslation();
  const headerRef = useRef(null);
  const pOHeader = useSelector((state: any) => state?.poAndStock?.pOHeader);

  function renderInputField(id, label, value, disabled = false, type) {
    let inputClass = "bg-secondary";
    //Do not add col as it restricts the view in the mobile and the columns are not being responsive.
    let colClass = "";

    // Determine additional classes based on type
    if (type === "expanded") {
      colClass += " col-lg-6 col-xs-12"; // Set width for expanded input field
    } else if (type === "short") {
      colClass += " col-lg-2 col-xs-12";
    } else {
      colClass += " col-lg-2 col-xs-12"; // Set width for reduced input fields
    }

    // Determine label alignment based on type
    const labelClass = type === "Qty" ? "d-flex flex-column align-items-end justify-content-end" : ""; // Align label to the right if type is "Qty"

    return (
      <div className={colClass}>
        <div className="mb-5">
          <label htmlFor={id} className={`fw-bold fs-6 mb-2 ${labelClass}`}>
            {t(label)}
          </label>
          <input
            className={`form-control ${inputClass} ${type === "Qty" ? "text-end" : ""}`}
            type="text"
            id={id}
            name={id}
            value={value}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }


  function formatNumber(value) {
    return value
      ? value.toLocaleString(undefined, {
        maximumFractionDigits: 4,
        minimumFractionDigits: 4,
      })
      : "0.0000";
  }

  return (
    <div className="mt-n2">
      <div className="accordion" id="accordionExample3">
        <div className="accordion-item">
          <div className="px-4 mb-n4">
            <div
              ref={headerRef}
              className="accordion-button bg-white cursor-pointer"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                <div className="col-md-6 d-flex">
                  <div
                    className={clsx("", titleCss)}
                    style={{ color: colors.title }}
                  >
                    {t('Stock Enquiry Details')}
                  </div>
                </div>
              </div>
            </div>
            <div
              id="collapseThree"
              className="accordion-collapse collapse show"
              aria-labelledby="headingTwo"
              aria-expanded="true"
              data-bs-parent="#accordionExample3"
            >
              <div className="accordion-body">
                <Card className="p-4">
                  <Row>
                    {renderInputField("code", "Code", pOHeader?.stock_code || "", true, "short")}
                    {renderInputField("description", "Description", pOHeader?.Details || "", true, "expanded")}
                    {renderInputField("onHandQty", "On Hand Qty", formatNumber(pOHeader?.OnHandQty) || "", true, "Qty")}
                    {renderInputField("orderQty", "Order Qty", formatNumber(pOHeader?.OrderQty) || "", true, "Qty")}

                  </Row>
                  <Row>
                    {/* Render the third input field with increased width */}
                    {/* {renderInputField("oemId", "OEM ID", pOHeader?.oem_id || "", true, "expanded")} */}
                  </Row>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockEnquiryHeader;
