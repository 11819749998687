import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../user/UsersTable.css";
import {
  deleteStockRequestLineItems,
  generatePickupSlip,
  getReqStockAllData,
  getStockItem,
  saveOrUpdateNotifications,
  selectStockCodes,
  createStockRequestV1
} from "../../modules/apps/user-management/users-list/core/_requests";
import { useNavigate } from "react-router-dom";
import { KTCard } from "../../../theme/helpers";
import clsx from "clsx";
import { colors, titleCss } from "../../../constants/colors";
import pdf from "../../../assets/pdf.png";
import excel from "../../../assets/excel.png";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useAuth } from "../../modules/auth";
import imgData from "../../../assets/logo.png";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import { DeleteModal } from "../../modules/widgets/components/DeleteModal";
import CustomLoaders from "../user/CustomLoaders";
import "../../../assets/customStyles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAllExpenseGroup,
  updateLocationDataExpenseGroup,
  updateLookupData,
  updateStockDataExpenseGroup,
  reloadLookupData,
} from "../../../reducers/lookupReducer";
import { updateIndex, updateIsCreateRequest } from "../../../reducers/indexReducer";
import { store } from "../../../reduxStore/store";
import { exportDataToExcel } from "../../../theme/assets/ts/_utils/ExportXLSX";
import {
  formatDateToDDMMYYY,
} from "../../../theme/assets/ts/_utils/FormatData";
import { useTranslation } from "react-i18next";
import DataGridWrapper from "../../utils/DataGrid/DataGridWrapper";
import FilteredRowOFDataGrid from "../../utils/FilteredRowOFDataGrid";
import ActionPopupWithSearchDev from "../user/ActionPopupWIthSearchDev";


const RequestStockTableDev = ({ data, triggerAddFunction, error }) => {
  const {t} = useTranslation();
  const routeData = data;
  const stockExpenseDetailsData = useSelector((state: any) => state?.lookupData?.lookupData);
  const locationDataOfExpenseGroup = useSelector((state: any) => state?.lookupData?.locationDataOfExpenseGroup);
  
  const [selectedDataToBeDeleted, setSelectedDataToBeDeleted] = useState<any>();
  const [showLoaderModal, setShowLoaderModal] = useState(true);
  const navigate = useNavigate();
  const fileName = "stock_details";
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const company = useSelector((state: any) => state?.userConfig?.company);
  const companyName = useSelector((state: any) => state?.userConfig?.companyName);
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState<any>(null);
  const [showExportModal, setShowExportModal] = useState<any>(null);
  const [isSubmit, setIsSubmit] = useState<any>(false);
  const [isSave, setIsSave] = useState<any>(false);
  const [showExportModalExcel, setShowExportModalExcel] = useState<any>(null);
  const currentDate = new Date(Date.now());
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const formattedDate = `${day}/${month}/${year}`;
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const formattedTime = `${hours}:${minutes}:${seconds}`;
  const colIndex = useRef(0);
  const refScrollToIndex = useRef(0);


  const [isLoader, setIsLoader] = useState(false);
  const { currentUser, pageStatus } = useAuth();
  const selectedStockCode = useSelector((state: any) => state?.lookupData?.stockCode);
  const localdataString = localStorage?.getItem("headerDataOfStock");
  const localdata = localdataString ? JSON.parse(localdataString) : null;
  let headerDataOfStockString = data
    ? data
    : localStorage?.getItem("headerDataOfStock");
  let headerDataOfStock: any = {};
  if (headerDataOfStockString && !data) {
    try {
      headerDataOfStock = JSON.parse(headerDataOfStockString);
    } catch (error) {
      console.error("Error parsing headerDataOfStock JSON", error);
    }
  } else {
    headerDataOfStock = data;
  }

  const checkForDuplicates = (parsedData) => {
    const seenCombinations = new Set();
    for (const item of parsedData) {
      const { code, phaseCode, reqCode, workOrderNo, jobCode, glAccount } =
        item;
      const combination = `${code}-${phaseCode}-${reqCode}-${workOrderNo}-${jobCode}-${glAccount}`;
      if (seenCombinations.has(combination)) {
        toastMessage(t("The given combination of stock and expense code already exists."),
          "error"
        );
        return;
      }
      seenCombinations.add(combination);
    }
  };

  const checkHeaderData = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const headerDataOfStockString: any =
          localStorage?.getItem("headerDataOfStock");
        const parsedData = JSON.parse(headerDataOfStockString);
        resolve(parsedData);
      }, 1000);
    });
  };

  const [responseOfStockListing,setResponseOfStockListing]=useState<any>();
  const [requestStockTableData,setRequestStockTableData]=useState<any>();
  const [locationResponse,setLocationResponse]=useState<any>();

  const getStockItemApi=async()=>{
    try {
      const response=await getStockItem(company, "", { advancedFilters: [], sortedList: [] });
      if(response){
        setResponseOfStockListing(response);
      }
      
    } catch (error) {
      toastMessage(t("Failed to load stock item Please try again later."),"error")
    }
  }
  const getReqStockAllDataApi=async()=>{
    try {
      const response=await getReqStockAllData(company);
      if(response){
        setRequestStockTableData(response);
      }
      
    } catch (error) {
      toastMessage(t("Failed to load expense group Please try again later."),"error");
    }

  }
  const selectStockCodesApi=async()=>{
    try {
      const response=await selectStockCodes(company, selectedStockCode);
      if(response){
        setLocationResponse(response);
      }
      
    } catch (error) {
      toastMessage(t("Failed to load location Please try again later."),"error");
    }

  }
  
  useEffect(()=>{
    if (!data?.isViewOnly) {
    getStockItemApi();
    getReqStockAllDataApi();
    }
  },[])
  

  useEffect(()=>{
    console.debug("refetching location");
    if(selectedStockCode){                    // Check for empty selectedstockCode
      selectStockCodesApi();    
    }                       
  },[selectedStockCode])

  useEffect(() => {
    requestStockTableData?.data &&
      dispatch(updateAllExpenseGroup(requestStockTableData.data));
  }, [requestStockTableData]);

  useEffect(() => {
    console.debug("Location Response : ",locationResponse?.data);
    if(locationResponse?.data && locationResponse?.data?.locationDropdown){
      let updatedLocationData = {...locationDataOfExpenseGroup}
      updatedLocationData[selectedStockCode] = locationResponse?.data?.locationDropdown;
      dispatch(updateLocationDataExpenseGroup(updatedLocationData));
    }
    
  }, [locationResponse]);

  useEffect(() => {
    if(responseOfStockListing?.data){
      dispatch(updateStockDataExpenseGroup(responseOfStockListing.data));
      !data?.isViewOnly &&  dispatch(reloadLookupData(responseOfStockListing.data));
    }
  }, [responseOfStockListing]);

  function formatNumber(value) {
    return value
      ? value.toLocaleString(undefined, {
          maximumFractionDigits: 4,
          minimumFractionDigits: 4,
        })
      : "0.0000";
  }

  const CustomRenderer = (params: any) => {
    const placeHolder =
      params?.column?.caption === "Amount"
        ? params?.data?.amount != null
          ? `${parseFloat(params?.data?.amount)?.toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`
          : ""
        : params?.column?.caption === "Stock Description"
        ? params?.data?.description != null
          ? `${params?.data?.description} 
          ${params?.data?.oemId != null ? params?.data?.oemId : ""}
            `
          : ""
        : params?.column?.caption === "Available Quantity At Location"
        ? params?.data?.availableQty != null
          ? formatNumber(params?.data?.availableQty)
          : ""
        : "";
        let rightAligned = false;
            if((params?.column?.caption === "Req Qty")
              || (params?.column?.caption === "Available Quantity At Location")
              || (params?.column?.caption === "Amount")
            ){
              rightAligned=true;
            }
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <input
          type="text"
          className={`search-input btn btn-light btn-active-light-primary btn-sm ${rightAligned?"text-right":""}`}
          placeholder={placeHolder}
          onChange={(e) => console.debug(e)}
          disabled={true}
        />
      </div>
    );
  };

  const CustomInputRenderer = (params: any) => {
    return (
      <div>
        <ActionPopupWithSearchDev data={params} isSeachableDropdown={true} isViewOnly={data?.isViewOnly}/>
      </div>
    );
  };

  const CustomInputBoxRenderer = (params) => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState(
      params?.data?.requiredQty ? parseFloat(params?.data?.requiredQty).toFixed(4) : ""
    );

    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      const regex = /^\d*\.?\d{0,4}$/;
      if (regex.test(inputValue) || inputValue === "") {
        setInputValue(inputValue);
      }
    };

    const handleBlur = () => {
      console.debug("handleBlur");
      const updatedRows: any = [];
      let allRows;
      gridRef?.current?.instance()?.getDataSource()
      .store()
      .load()
      .then((result) => {
        allRows=result;
      });
      allRows?.forEach(row => {
        updatedRows.push(row);
      });
      console.debug("handleInputChange blurr", params);
      if (!inputValue) {
        return;
      }
      if (params?.data?.requiredQty !== inputValue) {
        const newData = [...updatedRows];
        if (newData) {
          let index;
          let tempIndex = newData.findIndex(item => item.tempLineNo == (params?.data?.tempLineNo)); 
          if(!tempIndex){
            index = newData.findIndex(item => item.lineNo == (params?.data?.lineNo)); 
          }else{
            index = tempIndex;
          }
          // const index = params?.data?.lineNo - 1;
          console.debug("newData", updatedRows, params);
          const requiredQty = Number(inputValue);
          const amount = Number(newData[index]?.unitCost) * requiredQty;
          newData[index] = {
            ...newData[index],
            requiredQty: requiredQty.toFixed(4),
            amount: amount.toFixed(2),
          };
          dispatch(updateLookupData(newData));
          if (Number(inputValue) > Number(newData[index]?.availableQty)) {
            toastMessage(`${t('You are entering a required quantity which is greater than available quantity. Available Quantity is')} ${newData[index]?.availableQty}.`,
              "error"
            );
          }
        }
        return true;
      } else {
        return false;
      }
    };

    const handleKeyPress = (e) => {
      if (
        e.key === "Enter" ||
        e.key === "ArrowLeft" ||
        e.key === "ArrowRight" ||
        e.key === "ArrowUp" ||
        e.key === "ArrowDown"
      ) {
        e.target.blur();
      }
    };

    let rightAligned = false;
            if((params?.colDef?.headerName === "Req Qty")
              || (params?.colDef?.headerName === "Available Quantity At Location")
              || (params?.colDef?.headerName === "Amount")
            ){
              rightAligned=true;
            }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <input
          type="number"
          className={`search-input btn btn-light btn-active-light-primary btn-sm  ${rightAligned?"text-right":""}`}
          placeholder="0.0000"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          disabled={pageStatus === "View"}
          onKeyPress={handleKeyPress}
        />
      </div>
    );
  };

  const lineNoValueGetter = (params) => {
    console.debug("Inhere lineValuGetter",params);
    if (params?.lineNo!=0){
      return `${params?.lineNo}`
    }else if (params?.lineNo===0){
        return `${params?.tempLineNo}`
    }
  //   }else{
  //     return `${params?.node?.rowIndex + 1}`       //need to know the usecase of this
  // }
}

  const tempColumnDefs: any = [
    {
      headerName: "Line No",
      caption: "Line No",
      valueGetter: lineNoValueGetter,      
      checkboxSelection: true,
      width: 70,
    },
    {
      headerName: "Stock",
      caption:"Stock",
      field: "code",
      cellRenderer: CustomInputRenderer,
      alignment: "center",
      halign:"center",
      sortable: false,
      minWidth: 100,
      width: 150,
    },
    {
      headerName: "Stock Description",
      caption: "Stock Description",
      editable: false,
      cellRenderer: CustomRenderer,
      minWidth: 100,
      width: 140,
    },
    {
      headerName: "Req Qty",
      caption:"Req Qty",
      field: "requiredQty",
      alignment:"right",
      halign:"right",
      minWidth: 100,
      width: 140,
      editable: false,
      cellRenderer: CustomInputBoxRenderer,
    },
    {
      headerName: "Available Quantity At Location",
      caption: "Available Quantity At Location",
      editable: false,
      cellRenderer: CustomRenderer,
      alignment:"right",
      halign:"right",
      minWidth: 100,
      width: 140,
    },
    {
      headerName: "From Location",
      caption: "From Location",
      cellRenderer: CustomInputRenderer,
      minWidth: 100,
      width: 140,
    },
    {
      headerName: "Amount",
      caption: "Amount",
      editable: false,
      cellRenderer: CustomRenderer,
      alignment:"right",
      halign:"right",
      minWidth: 100,
      width: 160,
    },
    {
      headerName: "Work Order Number",
      caption: "Work Order Number",
      cellRenderer: CustomInputRenderer,
      minWidth: 100,
      width: 180,
    },
    {
      headerName: "Job Code",
      caption: "Job Code",
      cellRenderer: CustomInputRenderer,
      minWidth: 100,
      width: 180,
    },
    {
      headerName: "Phase Code",
      caption: "Phase Code",
      cellRenderer: CustomInputRenderer,
      minWidth: 100,
      width: 160,
    },
    {
      headerName: "Req Code",
      caption: "Req Code",
      cellRenderer: CustomInputRenderer,
      minWidth: 100,
      width: 160,
    },
    {
      headerName: "GL Account",
      caption: "GL Account",
      cellRenderer: CustomInputRenderer,
      minWidth: 100,
      width: 180,
    },
  ];

  const [columnDefs, setColumnDefs] = useState<any>(tempColumnDefs);

  // useEffect(() => {
  //   if (responseOfStockListing) {
  //     setColumnDefs(tempColumnDefs);
  //   }
  // }, []);

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 150, 200];
  }, []);

  const gridRef: any = useRef(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const handleRowClick = useCallback((e) => {
    console.debug("cell clicked listner 334", e);
    dispatch(updateIndex(e?.rowIndex));
    colIndex.current = e?.rowIndex;
    // refScrollToIndex.current=e?.rowIndex;
  }, []);


  const onSelectionChanged = (e) => {
    const selectedRowsData=e?.selectedRowsData; 
    const selectedData = selectedRowsData;
    console.debug("onSelectionChanged", e, selectedData);
    setSelectedDataToBeDeleted(selectedData);
  };

  const onBtnExportExcel = useCallback(
    (type) => {
      function filterUserFields(users) {
        const filteredUsers = users.map((users, index) => {
          console.debug(users, "hhhhhhhhhhhhhhh");
          return {
            LineNo: getLineNo(users,index),
            Stock: users.code ? users.code : "",
            "Req Qty": users.requiredQty ? users.requiredQty : "0.0000",
            "From Location": users.location
              ? users.location || "DEFLT"
              : "DEFLT",
            Amount: users.amount ? users.amount : "0.00",
            "Work Order Number": users.workOrderNo ? users.workOrderNo : "",
            "Job Code": users.jobCode ? users.jobCode : "",
            "Phase Code": users.phaseCode ? users.phaseCode : "",
            "Req Code": users.reqCode ? users.reqCode : "",
            "GL Account": users.glAccount ? users.glAccount : "",
          };
        });

        return filteredUsers;
      }
      let data;
      if (stockExpenseDetailsData) {
        if (type === "all" && stockExpenseDetailsData) {
          console.debug("dataOfStockSended in all", stockExpenseDetailsData);

          data = filterUserFields(stockExpenseDetailsData);
        } else {
          let filterData = FilteredRowOFDataGrid(gridRef);
          data = filterUserFields(filterData);
        }
      }
      exportDataToExcel(data, `${fileName}.xlsx`);
    },
    [stockExpenseDetailsData]
  );


  let [userEmail, userId, userName]: any = currentUser?.sub?.split("#");

  const onBtnExportPdf = useCallback(
    (type) => {
      const pdf: any = new jsPDF();

      var image = new Image();
      image.src = imgData;
      const parsedData = stockExpenseDetailsData;
      let data;
      if (type === "all") {
        data = parsedData;
      } else {
        data = FilteredRowOFDataGrid(gridRef);
      }

      let formattedData = data?.map((users, index) => [
        getLineNo(users,index),
        users?.code,
        users?.description,
        users?.requiredQty,
        users?.availableQty,
        users?.location || "DEFLT",
        users?.amount,
        users?.workOrderNo,
        users?.jobCode,
        users?.phaseCode,
        users?.reqCode,
        users?.glAccount,
      ]);
      autoTable(pdf, {
        didDrawCell: (data) => {
          if (data.section === 'head') {
            const lineWidth = 0.1; 
            const startX = data.cell.x;
            const startY = data.cell.y;
            const endX = startX + data.cell.width;
            const endY = startY;
            pdf.setLineWidth(lineWidth);
            pdf.line(startX, startY, endX, endY);
          }
          if (data.section === "head" && data.column.index === 0) {
            pdf.addImage(image, "PNG", 13, 2, 50, 30);
            let currdate = new Date();
            pdf.setFontSize(10);
            pdf.text(15, 5, 'Printed At: ' + formatDateToDDMMYYY(currdate.toLocaleString()));
            pdf.setFontSize(10);
             pdf.text(90, 15, userId);
             pdf.text(90, 10, userEmail);
             pdf.text(90, 20, companyName + ' - ' + company);
          }
          if (data.row.index !== undefined && data.row.index !== data.table.body.length - 1) {
          
            const lineWidth = 0.1; 
            const startX = data.cell.x;
            const startY = data.cell.y + data.cell.height;
            const endX = startX + data.cell.width;
            const endY = startY;
            pdf.setLineWidth(lineWidth);
            pdf.line(startX, startY, endX, endY);
          }
        },
        didDrawPage: (HookData: any) => {
          pdf.setFontSize(10);
            pdf.setTextColor(214, 214, 214);
            pdf.text(85, 290, "For internal use only.");
            // Get current page number
            const currentPageNumber = pdf.internal.getCurrentPageInfo().pageNumber;
            pdf.setTextColor(0, 0, 0);
            pdf.text(180, 5, `Page ${currentPageNumber}`);
        },
        styles: { fontSize: 8 },
        margin: { top: 30 },
        showHead: "everyPage",
        columnStyles: {
          3: { halign: 'right'} ,
          4: { halign: 'right',cellWidth: 30 } ,
          6: { halign: 'right'} ,

          },
        theme: "plain",
        head: [
          [
            "LineNo",
            "Stock",
            "Stock Description",
            {content: "Req Qty", styles: {halign: 'right'}},
            {content: "Available Quantity At Location", styles: {halign: 'right'}},         
            "From Location",
            {content: "Amount", styles: {halign: 'right'}},
            "Work Order Number",
            "Job Code",
            "Phase Code",
            "Req Code",
            "GL Account",
          ],
        ],
        body: formattedData,
      });

      pdf.save("stock_details.pdf");
    },
    [stockExpenseDetailsData]
  );

  const handleDelete = async() => {
    let selectedIds = selectedDataToBeDeleted?.map((item) => (item.lineNo || item.tempLineNo));
    let newArray:any[] =[];
    store?.getState()?.lookupData?.lookupData?.map(
         (item: any) => {
          if ((item.lineNo>0 && !selectedIds?.includes(item.lineNo) ||(item.lineNo===0 && !selectedIds?.includes(item.tempLineNo)) )){
            newArray.push(item);
          }
        }
      );
      let lastsavedLineNo=0;
      let modifiedArray = newArray?.map((item,index) => {
        let newItem = {...item}
        if(newItem.lineNo!=0){
          lastsavedLineNo=newItem.lineNo;
        }else if (newItem.lineNo===0){
          newItem.tempLineNo = lastsavedLineNo+1;
          lastsavedLineNo = newItem.tempLineNo;
        }
        return newItem
      })
      const shouldCallDeleteApi = selectedDataToBeDeleted.some(item => item.lineNo > 0);
      try{
        if(shouldCallDeleteApi){
          await updateDeletedRequestStockV2(selectedIds,modifiedArray);
        }else{
          await dispatch(updateLookupData(modifiedArray));
          toastMessage(t("Line item deleted successfully"), "success");
        }
        
      }catch(error){
        console.error("Error : ",error);
        toastMessage(t("Error deleting Line items!"), "error");
      }
      setDeleteModal(false);
  };

  const handleCloseDeleteModel = () => {
    setDeleteModal(false);
  };

  const closeExportModal = () => {
    setShowExportModal(false);
    setShowExportModalExcel(false);
  };

  const checkEmpty=(lineItems)=>{
    let reqQtyEmptyArray = new Set<number>();
    let fromLocationEmptyArray = new Set<number>();
    let expenseEmptyArray = new Set<number>();
    let stockCodeEmptyArray = new Set<number>();
    let phaseCodeEmptyArray = new Set<number>();
    let reqCodeEmptyArray = new Set<number>();
    let res = {
      expense: false,
      fromLocation: false,
      requiredQty: false,
      requiredQtyZero: false,
      workOrder: false,
      jobCode: false,
      phaseCode: false,
      reqCode: false,
      glAccount: false,
      stockCode: false,
      reqQtyEmptyRows:reqQtyEmptyArray,
      fromLocationEmptyRows:fromLocationEmptyArray,
      expenseEmptyRows:expenseEmptyArray,
      stockCodeEmptyRows: stockCodeEmptyArray,
      phaseCodeEmptyRows:phaseCodeEmptyArray,
      reqCodeEmptyRows:reqCodeEmptyArray,
    };

    lineItems.forEach((item,index) => {
      if (
        !item.workOrderNo &&
        !item.glAccount &&
        !item.jobCode &&
        !item.phaseCode &&
        !item.reqCode
      ) {
        
        res = {
          ...res,
          expense: true,
        };
        res["expenseEmptyRows"].add(getLineNo(item,index));
      } else if (
        (!item.glAccount && item.jobCode && !item.phaseCode) || !item.reqCode
      ) {
        if (!item.glAccount && !item.phaseCode) {
          res = {
            ...res,
            phaseCode: true,
          };
          res["phaseCodeEmptyRows"].add(getLineNo(item,index));
        }
        if (!item.glAccount && !item.reqCode) {
          res = {
            ...res,
            reqCode: true,
          };
          res["reqCodeEmptyRows"].add(getLineNo(item,index));
        }
      }
      if (!item.requiredQty || Number(item.requiredQty) <= 0) {
        res = {
          ...res,
          requiredQtyZero: true,
        };
        res["reqQtyEmptyRows"].add(getLineNo(item,index));
      }
      if (!item.location) {
        res = {
          ...res,
          fromLocation: true,
        };
        res["fromLocationEmptyRows"].add(getLineNo(item,index));
      }
      if (!item.code) {
        res = {
          ...res,
          stockCode: true,
        };
        res["stockCodeEmptyRows"].add(getLineNo(item,index));
      } 
    }
  );
    return res;
  }

  const raiseErrorMessages=(empty)=>{
    if (empty.requiredQtyZero) {
      toastMessage(t("Required Quantity cannot be zero at line no ")+Array.from(empty?.reqQtyEmptyRows).join(","), "error");
      setIsSave(false);
    }
    if (empty.fromLocation) {
      toastMessage(t("Location code cannot be blank at line no ")+Array.from(empty?.fromLocationEmptyRows).join(","), "error");
      setIsSave(false);
    }
    if (empty.expense) {
      toastMessage(t("Expense Group needs to be assigned at line no ")+Array.from(empty?.expenseEmptyRows).join(","), "error");
      setIsSave(false);
    }
    if (empty.stockCode) {
      toastMessage(t("Stock code cannot be blank at line no ")+Array.from(empty?.stockCodeEmptyRows).join(","), "error");
      setIsSave(false);
    }
    if (empty.jobCode) {
      // toastMessage(t("Job Code cannot be blank at line no ")+Array.from(empty?.stockCodeEmptyRows).join(","), "error");
      setIsSave(false);
    }
    if (empty.phaseCode) {
      toastMessage(t("Phase Code cannot be blank at line no ")+Array.from(empty?.phaseCodeEmptyRows).join(","), "error");
      setIsSave(false);
    }
    if (empty.reqCode) {
      toastMessage(t("Req Code cannot be blank at line no ")+Array.from(empty?.reqCodeEmptyRows).join(","), "error");
      setIsSave(false);
    }
  }

  const getPriority=(providedPriority)=>{
    return providedPriority === "Normal"
                ? "P2"
                : providedPriority === "High"
                ? "P1"
                : providedPriority === "Low"
                ? "P3"
                : providedPriority
  }

  const updateLineNoOnSaveRequestStockForNewItems=async (responseData,stockExpenseDetailsDataUpdated)=>{
            //update the lineNo after saving the data
            stockExpenseDetailsDataUpdated.map((item)=>{
              if(item.lineNo===0){
                responseData.map((dataItem)=>{
                  if(item.sequenceId===dataItem.sequenceId){
                    item.lineNo = dataItem.lineNo;
                  }
                })
              }
            })
            await dispatch(updateLookupData(stockExpenseDetailsDataUpdated));
  }

  const saveRequestStock = async (action) => {
    setIsSave(true);
    triggerAddFunction();
    checkForDuplicates(stockExpenseDetailsData);
    const parsedData: any = await checkHeaderData();

    if(!parsedData.requestedBy){
      toastMessage(t("Please fill requested by."), "error");
         setIsSave(false);
         return;
    }
    if(!parsedData.authorizeBy){
      toastMessage(t("Please fill authorized by."), "error");
         setIsSave(false);
         return;
    }
    if(!parsedData.priority){
      toastMessage(t("Please fill priority."), "error");
         setIsSave(false);
         return;
    }
    let empty = checkEmpty(stockExpenseDetailsData);

    if (
      empty.requiredQtyZero ||
      empty.fromLocation ||
      empty.expense ||
      empty.jobCode ||
      empty.phaseCode ||
      empty.reqCode || 
      empty.stockCode
    ) {
      raiseErrorMessages(empty);
    } else {
      setShowLoaderModal(true);
        try {
          const headerDataOfStockString: any = localStorage?.getItem("headerDataOfStock");
          const parsedData = JSON.parse(headerDataOfStockString);
          let stockExpenseDetailsDataUpdated = JSON.parse(JSON.stringify(stockExpenseDetailsData))
          stockExpenseDetailsDataUpdated.map((item,index)=>{
            item.sequenceId = index+1;
          })
          const updatedHeaderDataOfStock = {
            ...parsedData,
            priority: getPriority(parsedData?.priority),
            lineItems: stockExpenseDetailsDataUpdated,
            action: action,
            editCase: data?.status === "Not Submitted yet" || isSubmit ? true : false,
            url: "",
          };
          console.debug("Updated HeaderStockData : ",updatedHeaderDataOfStock,headerDataOfStockString)
          const createStockApiResponse = await createStockRequestV1(
            company,
            updatedHeaderDataOfStock
          );
          console.debug("createStockApiResponse", createStockApiResponse);
          setShowLoaderModal(false);
          if (createStockApiResponse?.data != null) {
            console.debug("createStockApiResponse 2", createStockApiResponse);
            toastMessage(createStockApiResponse?.successMsg?createStockApiResponse?.successMsg:"Saved line items successfully", "success");
            await updateLineNoOnSaveRequestStockForNewItems(createStockApiResponse?.data,stockExpenseDetailsDataUpdated);
            dispatch(updateIsCreateRequest(false));
            setIsSubmit(true);
            setIsSave(false);
            localStorage.removeItem("requestNo");
          } else {
            const errorArray = createStockApiResponse.errorMsg.split("#");
            errorArray.forEach((error) => {
              toastMessage(error, "error");
            });
            setIsSubmit(false);
            setIsSave(false);
          }
        } catch (error) {
          console.error(error);
          setShowLoaderModal(false);
          toastMessage( t("Sorry due to some reason stock request could not be saved"), "error" );
          setIsSubmit(false);
          setIsSave(false);
        }
    }
  };

  // reduc not updating the valus and not able to hit save
  const updateDeletedRequestStockV2 = async (selectedIds,lineItems) => {
    setIsSave(true);
      setShowLoaderModal(true);
        try {
          const headerDataOfStockString: any = localStorage?.getItem("headerDataOfStock");
          const parsedData = JSON.parse(headerDataOfStockString);
          const updatedHeaderDataOfStock = {
            requestNo:parsedData?.requestNo || routeData?.requestNo,
            lineNos: selectedIds,
          };
          console.debug("Updated HeaderStockData : ",updatedHeaderDataOfStock)
          const deleteLineItemsApiResponse = await deleteStockRequestLineItems(
            company,
            updatedHeaderDataOfStock
          );
          console.debug("deleteLineItemsApiResponse", deleteLineItemsApiResponse);
          setShowLoaderModal(false);
          if (deleteLineItemsApiResponse?.data != null) {
            toastMessage(t("Line item deleted successfully"), "success");
            await dispatch(updateLookupData(lineItems));
            setIsSubmit(true);
            setIsSave(false);
            localStorage.removeItem("requestNo");
          } else {
            const errorArray = deleteLineItemsApiResponse.errorMsg.split("#");
            errorArray.forEach((error) => {
              toastMessage(error, "error");
            });
            setIsSubmit(false);
            setIsSave(false);
          }
        } catch (error) {
          console.error(error);
          setShowLoaderModal(false);
          toastMessage( t("Sorry due to some reason stock request could not be deleted!"), "error" );
          setIsSubmit(false);
          setIsSave(false);
        }
  };

  useEffect(() => {
    if(data?.isViewOnly){
      setShowLoaderModal(false);
    }
    if (stockExpenseDetailsData?.length > 0 && requestStockTableData?.data) {
      setShowLoaderModal(false);
      // gridApi?.ensureIndexVisible(refScrollToIndex.current, 'middle');
    }else if(!stockExpenseDetailsData || stockExpenseDetailsData?.length===0){
      setShowLoaderModal(false);
    }
  }, [stockExpenseDetailsData, requestStockTableData]);

  const submitRequestStock = async (action) => {
    setShowLoaderModal(true);
    setIsLoader(true);
    setIsSubmit(true);
    triggerAddFunction();
    setTimeout(async () => {
      try {
        const headerDataOfStockString: any =
          localStorage?.getItem("headerDataOfStock");
        const parsedData = JSON.parse(headerDataOfStockString);

        let stockExpenseDetailsDataUpdated = JSON.parse(JSON.stringify(stockExpenseDetailsData))
          stockExpenseDetailsDataUpdated.map((item,index)=>{
            item.sequenceId = index+1;
          })

        const updatedHeaderDataOfStock = {
          ...parsedData,
          priority:
            parsedData?.priority === "Normal"
              ? "P2"
              : parsedData?.priority === "High"
              ? "P1"
              : parsedData?.priority === "Low"
              ? "P3"
              : parsedData?.priority,
          lineItems: stockExpenseDetailsDataUpdated,
          action: action,
          editCase: true,
          url: window?.location?.href,
        };

        console.debug("saveRequestStock", updatedHeaderDataOfStock);

        const createStockApiResponse = await createStockRequestV1(
          company,
          updatedHeaderDataOfStock
        );

        {
          updatedHeaderDataOfStock?.authorizeBy != userId &&
            (await saveOrUpdateNotifications({
              isEditCase: false,
              assignTo: updatedHeaderDataOfStock?.authorizeBy,
              companyCode: company,
              description: `${
                localdata?.requestNo || headerDataOfStock?.requestNo
              } is pending for your review`,
              url: "/approve-stock-request",
              mniName: "MNI_StockReq_Authorisation",
              requestedBy: userId,
            }));
        }
        setShowLoaderModal(false);
        console.debug("createStockApiResponse", createStockApiResponse);
        localStorage.removeItem("requestNo");
        toastMessage(createStockApiResponse?.successMsg, "success");
        navigate("/stock");
        setIsSubmit(true);
        setIsLoader(false);
      } catch (error) {
        console.error(error);
        setIsSubmit(true);
        setIsLoader(false);
        setShowLoaderModal(false);
      }
    }, 1000);
  };

  const getLineNo = (item,index)=>{
    let lineNo;
    if(item.lineNo !=0){
      lineNo = item.lineNo
    }else if(item.lineNo===0){
      lineNo = item.tempLineNo
    }else{
      lineNo = index+1;
    }
    return lineNo;
  }

  const formattedPickupData = stockExpenseDetailsData?.map((item, index) => ({
    ...item,
    lineNo: getLineNo(item,index),
  }));


  const [trackLoadingState, setTrackLoadingState] = useState(true);

  useEffect(() => {
    console.debug("stockExpenseDetailsData loader",stockExpenseDetailsData,requestStockTableData);
    if (stockExpenseDetailsData?.length > 0 && requestStockTableData?.data) {
      console.debug("stockExpenseDetailsData loader 2",stockExpenseDetailsData,requestStockTableData);
      setTrackLoadingState(false);
    }
  }, [stockExpenseDetailsData, requestStockTableData]);


  const getMaxLineNo=(parsedData)=>{
    
    const newRowAddedData = [...parsedData];
    const maxLineNo = Math.max(...newRowAddedData.map(o => o.lineNo));
    let tempLineNoArrayObj = JSON.parse(JSON.stringify(newRowAddedData));
    let tempLineNoArray:any[] = [];

    tempLineNoArrayObj.map(item=>{
      if(item?.tempLineNo){
        tempLineNoArray.push(item?.tempLineNo)
      }
    })
    let lineNo = maxLineNo;
    if(tempLineNoArray.length>0){
      const maxTempLineNo = Math.max(...tempLineNoArray);
      if(maxLineNo>=0 && maxTempLineNo>=0){
        if(maxLineNo<maxTempLineNo){
          lineNo = maxTempLineNo;
        }
      }
    }
     //handle if the array is empty then Math.max returns -Infinity.
     if(lineNo===-Infinity){
      lineNo = 0;
    }
    return lineNo;
  }

  const handleAddStockItemClick = () => {
    let parsedData = stockExpenseDetailsData ? stockExpenseDetailsData : [];
    let maxLineNo = getMaxLineNo(parsedData);

    const newObject: any = {
      lineNo: 0,
      lineType: null,
      status: null,
      code: null,
      unitCost: null,
      amount: 0,
      location: null,
      currencyMask: null,
      unit: 0,
      binLocation: null,
      jobNumber: null,
      jobCode: parsedData[parsedData?.length - 1]?.jobCode,
      workOrderNo: parsedData[parsedData?.length - 1]?.workOrderNo,
      glAccount: parsedData[parsedData?.length - 1]?.glAccount,
      description: null,
      onHandQty: 0,
      onOrderQty: 0,
      availableQty: 0,
      requiredQty: 0,
      inQty: null,
      processedQty: null,
      collaq1: null,
      collaq2: null,
      oemId: null,
      phaseCode: parsedData[parsedData?.length - 1]?.phaseCode,
      reqCode: parsedData[parsedData?.length - 1]?.reqCode,
      uom: null,
      inBatch: null,
      requestNo: null,
      tempLineNo:maxLineNo + 1
    };

    const updatedRowData = [...parsedData,newObject];
    console.debug("updatedRowData ===>", updatedRowData);
    dispatch(updateLookupData(updatedRowData));
    setIsSave(false);
    refScrollToIndex.current = updatedRowData?.length-1;
  };


  return (
    <>
      {showLoaderModal && <CustomLoaders />}
      <KTCard className="">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className={clsx("mt-5", titleCss)}
            style={{ color: colors.title }}
          >
            {t('Stock & Expense Details')}
          </div>
          <div className="d-flex justify-content-between mt-5 me-15">
            <div className="icon me-5 d-flex gap-5">
              <div className="col-md-8">
                {data?.authGroup ? (
                  !data?.isViewOnly && (
                    <button
                      className="btn text-white rounded-pill text-wrap fs-6 primary-button w-100"
                      onClick={handleAddStockItemClick}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "45px",
                        flexDirection: "row",
                        justifyContent: "center",
                        backgroundColor: "#0a7eac",
                      }}
                    >
                      <span
                        className="d-flex align-item-center justify-content-center"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {t('Add Line')}
                      </span>
                    </button>
                  )
                ) : (
                  <button
                    className="btn text-white rounded-pill text-wrap fs-6 primary-button w-100"
                    onClick={handleAddStockItemClick}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "45px",
                      flexDirection: "row",
                      justifyContent: "center",
                      backgroundColor: "#0a7eac",
                    }}
                  >
                    <span
                      className="d-flex align-item-center justify-content-center"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {t('Add Line')}
                    </span>
                  </button>
                )}
              </div>
              <img
                src={pdf}
                className="cursor-pointer"
                style={{ height: "35px", width: "35px" }}
                onClick={() => setShowExportModal(true)}
              />
              <img
                src={excel}
                className="cursor-pointer"
                style={{ height: "35px", width: "35px" }}
                onClick={() => setShowExportModalExcel(true)}
              />
            </div>
          </div>
        </div>

        <div
          className="d-flex"
          style={{ overflow: "hidden", marginTop: "30px" }}
        >
            <DataGridWrapper
              gridRef={gridRef}
              rowData={stockExpenseDetailsData}
              columnDefs={columnDefs}
              singleClickEdit={true}
              rowSelection="multiple"
              onGridReady={onGridReady}
              onRowClick={handleRowClick}
              onSelectionChanged={onSelectionChanged}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={paginationPageSizeSelector}
              height={450}
              fileName={fileName}
              exportEnabled={false}
              fontSizeForPdfExport={12}
            />
        </div>
        <div className="text-center mt-5 mb-5 ">
          {data?.authGroup ? (
            !data?.isViewOnly &&
            data?.status === "Not Submitted yet" && (
              <>
                <button
                  type="submit"
                  onClick={() => setDeleteModal(true)}
                  className="btn text-white rounded-pill me-4"
                  style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
                  disabled={selectedDataToBeDeleted?.length > 0 ? false : true}
                >
                  {t("Delete")}
                </button>
                <button
                  type="submit"
                  onClick={() => {saveRequestStock("save");}}
                  className="btn text-white rounded-pill me-4"
                  style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
                  disabled={stockExpenseDetailsData?.length <= 0}
                >
                  {t("Save")}
                </button>
                <button
                  type="submit"
                  onClick={() => submitRequestStock("submit")}
                  className="btn text-white rounded-pill me-4"
                  style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
                  disabled={stockExpenseDetailsData?.length <= 0 || isLoader}
                >
                  {isLoader ? "Processing..." : "Submit"}
                </button>
              </>
            )
          ) : (
            <>
              <button
                type="submit"
                onClick={() => setDeleteModal(true)}
                className="btn text-white rounded-pill me-4"
                style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
                disabled={selectedDataToBeDeleted?.length > 0 ? false : true}
              >
                {t("Delete")}
              </button>
              <button
                type="submit"
                onClick={() => {saveRequestStock("save");}}
                className="btn text-white rounded-pill me-4"
                style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
                disabled={isSave || stockExpenseDetailsData?.length <= 0}
              >
                {t("Save")}
              </button>
              <button
                type="submit"
                onClick={() => submitRequestStock("submit")}
                className="btn text-white rounded-pill me-4"
                style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
                disabled={
                  !isSubmit || stockExpenseDetailsData?.length <= 0 || isLoader
                }
              >
                {isLoader ? `${t('Processing')}...` : t("Submit")}
              </button>
            </>
          )}
          <button
            type="submit"
            onClick={() => {
              generatePickupSlip(
                {
                  companyCode: company,
                  generateDate: formattedDate,
                  generateTime: formattedTime,
                  reqNo: data?.requestNo
                    ? headerDataOfStock.requestNo
                    : localdata?.requestNo,
                  status: data?.requestNo
                    ? headerDataOfStock?.status
                    : localdata?.status,
                },

                formattedPickupData
              );
            }}
            disabled={
              data?.status
                ? false
                : !isSubmit || stockExpenseDetailsData?.length <= 0
            }
            className="btn text-white rounded-pill"
            style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
          >
            {t("Picking Slip")}
          </button>
        </div>
      </KTCard>

      {showExportModal && (
        <UserEditModal
          content="export"
          modalText="Export Data"
          onCloseEditModal={closeExportModal}
          state={""}
          onSave={onBtnExportPdf}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
      {deleteModal && (
        <DeleteModal
          title={t("Are you sure you want to delete this line(s)?")}
          onDelete={handleDelete}
          onCancel={handleCloseDeleteModel}
        />
      )}
      {showExportModalExcel && (
        <UserEditModal
          content="export"
          modalText={t("Export Data")}
          onCloseEditModal={closeExportModal}
          state={""}
          onSave={onBtnExportExcel}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
    </>
  );
};

export default RequestStockTableDev;
