import React from 'react';
import DataGrid, {
  Column,
  GroupPanel,
  Grouping,
  ColumnChooser,
  LoadPanel,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Sorting,
  Paging,
  Pager,
  Selection,
  Toolbar,
  Item,
  Export,
  Scrolling,Summary,GroupItem,
  TotalItem,
  Popup,
  ColumnFixing,
  DataGridTypes,
  StateStoring
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';
import "./DataGridWrapper.css";
import { ExportingToExcel, ExportingToPdf } from './DataGridExport';
import { useSelector } from 'react-redux';
import { useAuth } from '../../modules/auth';
import ValueFormatter from '../ValueFormatter';
const DataGridWrapper = (params) => {

  const gridRef=params?.gridRef;
  const rowData=params?.rowData;
  const columnDefs=params?.columnDefs;
  const rowSelection=params?.rowSelection;
  const onGridReady=params?.onGridReady;
  const enableAdvancedFilter=params?.enableAdvancedFilter;
  const pagination=params?.pagination;
  const paginationPageSize=params?.paginationPageSize;
  const paginationPageSizeSelector=params?.paginationPageSizeSelector;
  const rowGroupPanelShow=params?.rowGroupPanelShow;
  const isFilterRow=params?.isFilterRow;
  const height=params?.height;
  const onContentReady=params?.onContentReady
  const onSelectionChanged=params?.onSelectionChanged;
  const keyExpr=params.keyExpr;
  const isColumnChooserEnabled=params?.isColumnChooserEnabled;
  const onEditorPreparing=params?.onEditorPreparing;
  const onCellPrepared=params?.onCellPrepared;
  const onRowPrepared=params?.onRowPrepared;
  const onRowClick=params?.onRowClick;
  const isColumnFixingEnabled=params?.isColumnFixingEnabled;
  const columnRatios=params?.columnRatioForPdfExport;
  const fontSizeForPdfExport=params?.fontSizeForPdfExport;
  const summaryFooterVisible=params?.summaryFooterVisible;
  const groupFooterVisible=params?.groupFooterVisible;
  const exportEnabled=params?.exportEnabled;
  const isGroupingExpandable=params?.isGroupingExpandable;

  const exportFormats = ['pdf','xlsx'];
  const fileName=params?.fileName;
  const company = useSelector((state: any) => state?.userConfig?.company);
  const companyName = useSelector((state: any) => state?.userConfig?.companyName);
  const { currentUser } = useAuth();
  let [userEmail, userId]: any = currentUser?.sub?.split("#");

  
  // console.log("colDef",columnDefs);

  const renderHeaderCell = (column) => {
    return (
      <div className="custom-header-cell">
        {column?.headerName}{column?.summaryType && column?.summaryType !== 'none'?displayAggerateFunction(column?.summaryType):""}
      </div>
    );
  };
  const parseDateToTimestamp = (date) => {
    if (!date) {
      return new Date("1970-01-01").getTime();
    }
    const [day, month, year] = date.split('/');
    return new Date(`${year}-${month}-${day}`).getTime();
  };

  const displayAggerateFunction=(fn)=>{
   switch(fn){
    case "sum":
      return " (Sum)";
    case "avg":
      return " (Avg)";
    case "count":
      return " (Count)";
    case "min":
      return " (Min)";
    case "max":
      return " (Max)";
    default:
      break;
   }
  }

// To fix touchpad scrolling in windows browser useNative is set to true . It works perfectly for chrome. Need to fix for firefox and edge
  return (
      <DataGrid
        id="gridContainer"
        dataSource={rowData}
        ref={gridRef}
        showBorders={true}
        allowColumnResizing={true}
        columnResizingMode={'widget'}
        allowColumnReordering={true}
        onInitialized={onGridReady}
        onContentReady={onContentReady}
        showRowLines={true}
        height={height}
        width="100%"
        onSelectionChanged={onSelectionChanged}
        keyExpr={keyExpr}
        onEditorPreparing={onEditorPreparing}
        onCellPrepared={onCellPrepared}
        onRowPrepared={onRowPrepared}
        onRowClick={onRowClick}
        onExporting={(e) => {
          if (e.format === 'xlsx') {
            ExportingToExcel(e,fileName);
          } else if (e.format === 'pdf') {
            ExportingToPdf(e,fileName,company,companyName,userEmail,userId,fontSizeForPdfExport);
          }
        }}
      >
            <Export enabled={exportEnabled} formats={exportFormats} allowExportSelectedData={rowSelection} />
            <GroupPanel visible={rowGroupPanelShow} />
            <Grouping contextMenuEnabled={rowGroupPanelShow} autoExpandAll={isGroupingExpandable} />
            {/* <Scrolling useNative={true} />       */}
            <ColumnFixing enabled={true} />  
            <ColumnChooser enabled={isColumnChooserEnabled} mode="select" />
            <LoadPanel enabled={true} />
            <FilterRow visible={isFilterRow} />
            <HeaderFilter visible={enableAdvancedFilter} />
            {/* <SearchPanel visible={true} highlightCaseSensitive={true} />  */}
            <Sorting mode="multiple" />
            <Paging defaultPageSize={paginationPageSize} />
            <Pager visible={true} displayMode={'compact'} showPageSizeSelector={true}  allowedPageSizes={paginationPageSizeSelector || [5, 10, 20]}  showInfo={true} />
            {rowSelection&&<Selection mode={rowSelection} showCheckBoxesMode={"always"} /> }
            {/* <StateStoring enabled={true} type="sessionStorage" storageKey="storage" /> */}
            {columnDefs.map((colDef, index) => (
              <Column
                key={colDef?.field}
                // visibleIndex={index}
                dataField={colDef?.field}
                caption={colDef?.headerName+`${colDef?.summaryType && colDef?.summaryType !== 'none'?displayAggerateFunction(colDef?.summaryType):""}`}
                width={colDef?.width}
                minWidth={colDef?.minWidth}
                dataType={colDef?.dataType}
                alignment={colDef?.halign}           
                visible={colDef?.visible}
                fixed={colDef?.fixed}
                fixedPosition={colDef?.fixedPosition}
                allowEditing={true}
                allowFiltering={true}
                allowSorting={true}
                cellRender={colDef?.cellRenderer}
                sortOrder={colDef?.sortOrder}
                groupIndex={colDef?.rowGroupIndex}
                headerCellRender={() => (renderHeaderCell(colDef))}
                customizeText={colDef?.columnType==="Numeric" ? (cellInfo) => colDef?.valueFormatter(cellInfo) : undefined}
                calculateCellValue={colDef?.valueGetter}
                calculateSortValue={colDef.columnType === "Date" ? (data) => {
                  if (colDef.field) {
                   
                    return parseDateToTimestamp(data[colDef.field]);
                  }
                  return data;
                } : undefined}
              />
            ))}
            <Summary>
            {columnDefs.map((colDef, index) => (
                colDef?.summaryType &&colDef.summaryType!='none'  && (
              <GroupItem
              key={`group-${index}`}
                column={colDef?.field}
                summaryType={colDef?.summaryType}
                alignByColumn={true}
                customizeText={colDef?.columnType==="Numeric" &&colDef?.summaryType !== 'count' ? (cellInfo) => colDef?.valueFormatter(cellInfo) : colDef?.summaryType === 'count'?(cellInfo) => (cellInfo.value): undefined }
                />
                )))}
            {groupFooterVisible&&columnDefs.map((colDef, index) => (
                colDef?.summaryType &&colDef.summaryType!='none' && (
              <GroupItem
              key={`group-${index}`}
                column={colDef?.field}
                summaryType={colDef?.summaryType}
                showInGroupFooter
                // alignByColumn={true}
                customizeText={colDef?.columnType==="Numeric" &&colDef?.summaryType !== 'count' ? (cellInfo) => colDef?.valueFormatter(cellInfo) : colDef?.summaryType === 'count'?(cellInfo) => (cellInfo.value): undefined }
                />
                )))}
             {summaryFooterVisible&&columnDefs.map((colDef, index) => (
                colDef?.summaryType &&colDef.summaryType!='none' && (
               <TotalItem
                key={`total-${index}`}
                column={colDef?.field}
                alignment={colDef?.halign}
                summaryType={colDef?.summaryType}
                customizeText={colDef?.columnType==="Numeric" &&colDef?.summaryType !== 'count' ? (cellInfo) => colDef?.valueFormatter(cellInfo) : colDef?.summaryType === 'count'?(cellInfo) => (cellInfo.value): undefined}
                //  displayFormat="Total: {0}"
                />
              )))}
              {groupFooterVisible&&
              <GroupItem
              column={"show_summary"}
              summaryType={undefined}
              showInGroupFooter
               />
              }
               {summaryFooterVisible&&
              <TotalItem
              column={"show_summary"}
              summaryType={undefined}
               />
              }


            </Summary>
          
      </DataGrid>
  );
};

export default React.memo(DataGridWrapper);
