/**
 *
 * @format
 */

/* eslint-disable react/jsx-no-target-blank */

import { useEffect, useRef, useState } from "react";

import { KTSVG } from "../../../../helpers";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

import { useNavigate } from "react-router-dom";
import {
  savePinsFav
} from "../../../../../app/modules/apps/user-management/users-list/core/_requests";
import { useAuth } from "../../../../../app/modules/auth/core/Auth";
import { useSelector,useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateMenuItem } from "../../../../../reducers/menuReducer";
import { toastMessage } from "../../../../../app/modules/auth/functions/toastMessage";
import { Spinner } from "react-bootstrap";
import { URLS } from "../../../../../app/utils/URLS";

const SidebarMenuMain = ({ menuData }) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const { menuList } = useAuth();

  const [favData, setFavData] = useState([]);
  const [call, setCall] = useState(false);
  const dispatch=useDispatch();

  const handleChange = () => {
    setCall((prev) => !prev);
  };

  const navigate = useNavigate();

  let pinnedData: any = localStorage.getItem("pinnedData");
  const company = useSelector((state: any) => state?.userConfig?.company);
  pinnedData = JSON.parse(pinnedData);
  const [isLoading,setIsLoading]=useState(false);
  const currFav=useRef("default");

  const handleFav = async (e, data) => {
    currFav.current=data.mniName;
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    try {
      const response=await savePinsFav({
        companyCode: company,
        userId: currentUser!.sub.split("#")[1],
        applicationCode: data.applicationCode,
        mniName: data.mniName,
        pinOrFav: "Fav",
        pinOrFavStatus: `${!data.favourited}`,
      });
      if(response.errorCode===0){
        dispatch(updateMenuItem({mniName:data.mniName,type:"Fav"}));
      }
      else{
        toastMessage(t("Could not add the item to favourite Please contact support."),"error");
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    } finally{
      setIsLoading(false);
    }
    
  };

  function getFavoriteItems(data) {
    const favoriteItems: any = [];
    if (data.length > 0) {
      data?.forEach((item) => {
        item?.items &&
          item?.items?.forEach((menu) => {
            menu?.items &&
              menu?.items.forEach((sub) => {
                if (sub.favourited === true) {
                  favoriteItems.push(sub);
                }
              });
          });
      });

      return favoriteItems;
    }
  }
  const handleNavigation=(item)=>{
    if(item?.application==="Requisition"){             // handle Requisition link
      if (item?.webUrl) {
        window.open(`${URLS.REACT_APP_REQUISITION_URL}${item.webUrl}`, "_blank");
        return;
      }
    }
    else{
      navigate(item.path);
    }
  }


  useEffect(() => {
    if (menuList) {
      let temp = getFavoriteItems(menuList);
      setFavData(temp);
    }
  }, [menuList]);

  return (
    <>
      <div className="sidebar">
        <div className="sidebar">
          <div
            className="sidebar-item  mb-3  app-display"
            style={{ borderBottom: "1px solid white ", width: "92%" }}
          >
            <div
              className="sidebar-title mt-10  "
              style={{ color: "white", paddingRight: "-30px" }}
            >
              {t('MENU.FAVOURITES')}
            </div>
            <div
              className="sidebar-submenu expanded ms-5"
              style={{ maxHeight: "220px", overflowY: "auto" }}
            >
              <ul
                style={{
                  listStyleType: "disc",
                  padding: 0,
                  marginTop: 15,
                  marginBottom: 14,
                  marginLeft: 20,
                }}
              >
                {favData?.length === 0 && (
                  <span className="text-white">{t('MENU.NO.FAVORITES')}</span>
                )}
                {favData?.map((item: any) => {
                  return (
                    <li style={{ color: "white", marginBottom: "20px" }}>
                      <span
                        onClick={() => handleNavigation(item)}
                        style={{ color: "white", cursor: "pointer" }} // Add cursor: pointer
                        className="menu-title"
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ flex: 0.9 }}>{t(item.menu_item)}</div>
                          <div
                            className="menu-icon cursor-pointer"
                            style={{
                              verticalAlign: "middle",
                              marginLeft: "3px",
                            }}
                            onClick={(e) => handleFav(e, item)}
                          >
                            {isLoading && currFav.current === item.mniName?( <Spinner animation="border" variant="success" />):
                            (<KTSVG
                              path={"/media/icons/duotune/general/star.svg"}
                              className="svg-icon-2"
                            />)}
                          </div>
                        </div>
                      </span>
                    </li>
                  );
                })}
              </ul>

            </div>
          </div>
          <KTSVG
            path="/media/icons/duotune/general/star.svg"
            className="svg-icon-1 app-icon-minimize ms-5 mt-5 mb-4"
          />
        </div>
      </div>
      {menuData?.map((item, index) => (
        <SidebarMenuItemWithSub
          key={`SidebarMenuItemWithSub1${index}`}
          to={`${item?.application}-${index}`}
          title={`${item?.application}`}
          fontIcon="bi-archive"
          icon={item?.icon}
          data={item}
        >
          {item?.items &&
            item?.items?.map((func, index) => (
              <SidebarMenuItemWithSub
                key={`SidebarMenuItemWithSub2${index}`}
                to={`${func?.menu_item}-${index}`}
                title={`${func?.menu_item}`}
                hasBullet={true}
                data={func}
              >
                {func?.items.map((sub, index) => (
                  <SidebarMenuItem
                    key={`sidebaritem${index}`}
                    to={`/${sub.path}`}
                    title={`${t(sub.menu_item)}`}
                    hasBullet={true}
                    fav={sub.favourited}
                    data={sub}
                    onChange={handleChange}
                  />
                ))}
              </SidebarMenuItemWithSub>
            ))}
        </SidebarMenuItemWithSub>
      ))}
    </>
  );
};

export { SidebarMenuMain };
