import { createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";

const initialState = {
  company: "",
  companyName:"",
  language:"en"
}

const UserConfigSlice = createSlice({
  name: "userConfig",
  initialState: initialState,
  reducers: {
    saveCompany: (state, action) => {
      state.company = action.payload || "";
    },
    saveCompanyName: (state, action) => {
      state.companyName = action.payload || "";
    },
    saveLanguage: (state, action) => {
      state.language = action.payload || "en";
    },
    saveCompanyAndName: (state,action) => {
      state.company = action.payload.code || "";
      state.companyName = action.payload.name || "";
    },
    resetUserConfig: (state,action) => {
      state = initialState
      console.debug("resetting userconfig : ",state,initialState)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state) => {
        state = initialState
    })
  }
});

export const {
  saveCompany,
  saveCompanyName,
  saveLanguage,
  saveCompanyAndName,
  resetUserConfig
} = UserConfigSlice.actions;
export default UserConfigSlice.reducer;
