import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useAuth } from "../../../auth";
import Select from 'react-select';
import ReactSelectStyle from "../../../../utils/ReactSelectStyle";

interface Company {
  compCode: string;
  code: string;
  name: string;
  currency: string | null;
}

const CopyAccessModal: any = (props: any) => {

  const [fromCompanyDropdown, setFromCompanyDropdown] = useState<Company[]>([]);
  const [toCompanyDropdown, setToCompanyDropdown] = useState<Company[]>([]);
  const [companyDropdown, setCompanyDropdown] = useState<Company[]>([]);
  const [selectedFromCompany, setSelectedFromCompany] = useState<string | null>(null);
  const [selectedToCompany, setSelectedToCompany] = useState<string | null>(null);

  useEffect(() => {
    if (Array.isArray(props?.data)) {
      setCompanyDropdown(props?.data);
      setFromCompanyDropdown(props?.data);
      setToCompanyDropdown(props?.toData);
    }
  }, [props?.data, props?.toData]);

  useEffect(() => {
    if (selectedFromCompany) {
      setToCompanyDropdown(props?.toData.filter((item: Company) => item.code !== selectedFromCompany));
    } else {
      setToCompanyDropdown(props?.toData);
    }
  }, [selectedFromCompany, props?.toData]);

  useEffect(() => {
    if (selectedToCompany) {
      setFromCompanyDropdown(props?.data.filter((item: Company) => item.code !== selectedToCompany));
    } else {
      setFromCompanyDropdown(props?.data);
    }
  }, [selectedToCompany, props?.data]);

  const handleSubmit = async (values) => {

    const updatedMenu = {
      selectedCompanyCodeTo: selectedToCompany,
      selectedCompanyCodeFrom: selectedFromCompany,
    };
    console.debug("updatedMenu", updatedMenu);

    props.onCopyAccess(updatedMenu);

    props.onCancel();
  };

  const fromCompanyOptions = fromCompanyDropdown.map((option: Company) => ({
    value: option.code,
    label: option.name,
  }));

  const toCompanyOptions = toCompanyDropdown.map((option: Company) => ({
    value: option.code,
    label: option.name,
  }));

  return (
    <Formik
      initialValues={{
        fromCompany: "",
        toCompany: "",
      }}
      // validationSchema={changePasswordSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">From</label>
            <Select
              name="fromCompany"
              onChange={(selectedOption) => {
                setSelectedFromCompany(selectedOption ? selectedOption.value : null);
              }}
              options={fromCompanyOptions}
              placeholder="From Company"
              styles={ReactSelectStyle}
            />
            {touched.fromCompany && errors.fromCompany && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{errors.fromCompany}</span>
                </div>
              </div>
            )}
          </div>
          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">To</label>
            <Select
              name="toCompany"
              onChange={(selectedOption) => {
                setSelectedToCompany(selectedOption ? selectedOption.value : null);
              }}
              options={toCompanyOptions}
              placeholder="To Company"
              styles={ReactSelectStyle}
            />
            {touched.toCompany && errors.toCompany && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{errors.toCompany}</span>
                </div>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-secondary rounded-pill mx-2"
              onClick={props.onCancel}
              title="Cancel"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn text-white rounded-pill mx-2"
              style={{ backgroundColor: "#0a7eac" }}
              title="Save"
              data-kt-users-modal-action="submit"
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CopyAccessModal;
