import { FC, useState, useEffect } from "react";
import { useAuth } from "../../../../app/modules/auth";
import { toAbsoluteUrl } from "../../../helpers";
import { UserEditModal } from "../../../../app/modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import "./HeaderUsermenu.css";
import { Timeout } from "../../../../app/utils/Config";
import { useIdleTimer } from 'react-idle-timer';
import { useTranslation } from "react-i18next";

const HeaderUserMenu: FC = () => {
  const {t} = useTranslation();
  const { currentUser, logout } = useAuth();
  const [userEmail, userId, userName]: any = currentUser?.sub.split("#");
  
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =useState(false);
  const [isChangeLanguageModalOpen, setChangeLanguageModalOpen] =useState(false);
  const [isPrefrenceModalOpen, setIsPrefrenceModalOpen] = useState(false);

 
  const openChangePasswordModal = () => {
    setChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = (event) => {
    setChangePasswordModalOpen(false);
  };

  const openChangeLanguageModal = () => {
    setChangeLanguageModalOpen(true);
  };

  const closeChangeLanguageModal = (event) => {
    setChangeLanguageModalOpen(false);
  };

  const openPreference = () => {
    setIsPrefrenceModalOpen(true);
  };

  const closePreference = (event) => {
    setIsPrefrenceModalOpen(false);
  };
  const sessionTimeout=Timeout.TIMEOUT;


  const onIdle = () => {
    logout();
    localStorage.setItem('inactivity', 'true');
  }
   useIdleTimer({
    onIdle,
    timeout: sessionTimeout,
    throttle: 500,
    crossTab: true,
    syncTimers:200,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'mousewheel',
      'mousedown',
      'touchmove',
    ],
  });


useEffect(() => {
    const interval = setInterval(() => {
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  return (
    <>
      <div
        className="login-wrapper menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
        data-kt-menu="true"
      >
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-3">
            <div className="symbol symbol-50px me-5">
              <img alt="Logo" src={toAbsoluteUrl("/media/avatars/blank.png")} />
            </div>

            <div className="d-flex flex-column">
              <div className="fw-bolder d-flex align-items-center fs-5">
                {userName}
              </div>
            </div>
          </div>
        </div>
        <div className="menu-item px-5">
          <div className="my-2">
            <a onClick={openChangePasswordModal} className="menu-link px-5">
              {t('Change Password')}
            </a>
            <div className="separator separator-solid my-2"></div>
          </div>
          <a onClick={openChangeLanguageModal} className="menu-link px-5">
          {t('Change Language')}
          </a>

          <div className="separator separator-solid my-2"></div>
          <a onClick={openPreference} className="menu-link px-5">
          {t('Change Login Method')}
          </a>
          <div className="separator separator-solid my-2"></div>
          {/* <div className=" mb-3" style={{ borderTop: "1px solid grey ", width: "92%" }} ></div> */}
          <div className=" d-flex align-item-center justify-content-center  ">
            <button
              onClick={logout}
              className="btn text-center text-white  rounded-pill px-8 mt-2 ms-5 "
              title="Sign Out"
              style={{ backgroundColor: "#0a7eac" }}
            >
              {t('Sign Out')}
            </button>
          </div>
        </div>
      </div>
      {isChangePasswordModalOpen && (
        <UserEditModal
          content="change_password"
          modalText={t("Change Password")}
          onCloseEditModal={closeChangePasswordModal}
          user=""
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
      {isChangeLanguageModalOpen && (
        <UserEditModal
          content="change_language"
          modalText={t("Change Language")}
          onCloseEditModal={closeChangeLanguageModal}
          user=""
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
      {isPrefrenceModalOpen && (
        <UserEditModal
          content="prefrence"
          modalText={t("Change Login Method")}
          onCloseEditModal={closePreference}
          email={currentUser!.sub}
          state={"hide"}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
    </>
  );
};

export { HeaderUserMenu };
