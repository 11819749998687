import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CustomLoaders from '../user/CustomLoaders';
import { Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, Button, IconButton, TextField } from '@mui/material';
import { toastMessage } from '../../modules/auth/functions/toastMessage';
import { useSelector } from 'react-redux';
import { getCompanyWithUserIdFrom, getCostAllocation, getJobCodeList, getPhaseOptionByjobCode, getReqOptionByjobCode, getReqStockAllData, getStockStatus, getvendordropdown, rejectInvoice, saveCostAllocation, saveorUpdateInvoice, uploadAttachments, uploadInvoice, viewInvoice } from '../../modules/apps/user-management/users-list/core/_requests';
import { colors, titleCss } from "../../../constants/colors";
import ReactSelectStyle from "../../utils/ReactSelectStyle";
import Select from "react-select";
import * as Yup from 'yup';
import { format, parse, isDate } from 'date-fns';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadFile from "../../modules/custom components/uploadFile";
import List from 'devextreme-react/list';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import { Document, Page } from 'react-pdf';
import { CheckBox } from 'devextreme-react/check-box';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import PdfViewer from "../../modules/custom components/PdfViewer";
import ImageViewer from "../../modules/custom components/ImageViewer";
import NumberBox from 'devextreme-react/number-box';
import { Popup } from 'devextreme-react/popup';
import { FaPlus } from "react-icons/fa";
import { useAuth } from "../../modules/auth";
import DataGridWrapper from "../../utils/DataGrid/DataGridWrapper";
import { Column, DataGrid, DataGridRef, Editing, HeaderFilter, Lookup, SearchPanel, Selection, Summary, TotalItem, ValidationRule } from "devextreme-react/cjs/data-grid";
import { formatPrice } from "../../utils/Config";
import CustomLoadingCellRender from "../user/CustomLoadingCellRender";

interface LocationState {
  invoiceId?: any;
  previousPage?: any;
  invoiceAmount?: any;
  gstAmount?: any;
  currency?: any;
}

export default function InvoiceCostAllocation() {

  const location = useLocation();
  const { invoiceId } = (location.state as LocationState) || {};
  const { previousPage } = location.state as LocationState || 'create';
  const { invoiceAmount } = (location.state as LocationState) || 0;
  const { gstAmount } = (location.state as LocationState) || 0 ;
  const { currency } = (location.state as LocationState) || null ;
  const company = useSelector((state: any) => state?.userConfig?.company);
  const headerRef: any = useRef(null);
  const { currentUser } = useAuth();
  const [email, userId]: any = currentUser?.sub?.split("#");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [glAllocationList, setGLAllocationList] = useState([]);
  const [jobAllocationList, setJobAllocationList] = useState([]);
  const [companyOption, setCompanyOption] = useState<any>([]);
  const [selectedCompany, setSelectedComapany] = useState(company);
  const [allocationType, setAllocationType] = useState<'GL' | 'Job Cost'>('Job Cost');
  const [allocationArray, setAllocationArray] = useState<any>([]);
  const [tempGlData, setTempGlData] = useState<any>([]);
  const [tempJobData, setTempJobData] = useState<any>([]);
  const [prevAllocatedAmount, setPrevAllocatedAmount] = useState<number>(0);
  const [allocationAmount, setAllocationAmount] = useState(0);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedJobRowKeys, setSelectedJobRowKeys] = useState([]);
  const [selectedGlRowKeys, setSelectedGlRowKeys] = useState([]);
  const [phaseOption, setPhaseOption] = useState([]);
  const [reqOption, setReqOption] = useState([]);
  const [initialValue, setInitialValue] = useState({ allocationAmount: 0 })

  const validationSchema = Yup.object({
    allocationAmount: Yup.number()
      .positive('Amount must be positive')
    // .required('Invoice Amount is required')
  });

  useEffect(() => {
    fetchCostAllocationData();
    fetchOptionList();
    fetchComapanyList();
    fetchJobCodeList();
  }, [selectedCompany]);

  const fetchCostAllocationData = async () => {
    try {
      setLoading(true);
      const response = await getCostAllocation(invoiceId, company);
      if (response && response.actualErrorCode === 0) {
        if (response.data && response.data.length > 0) {
          const totalAmount = response.data.reduce((total, item) => total + item.amount, 0);
          setPrevAllocatedAmount(totalAmount)
          setAllocationAmount(invoiceAmount - totalAmount)
          setInitialValue(({ allocationAmount: invoiceAmount - totalAmount }))

        } else {
          setAllocationAmount(invoiceAmount)
          setInitialValue(({ allocationAmount: invoiceAmount }))

        }
      } else {
        toastMessage(t("An error occurred. Please try again later."), "error");
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const fetchOptionList = async () => {
    try {
      setLoading(true);
      const response = await getReqStockAllData(company);
      if (response && response.data && response.data.glAccounts) {
        setGLAllocationList(response.data.glAccounts);
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const fetchComapanyList = async () => {
    try {
      setLoading(true);
      const response = await getCompanyWithUserIdFrom(userId);
      if (response && response.length > 0) {
        const optionList = response.map((item) => ({
          value: item.code,
          label: item.name
        }));
        setCompanyOption(optionList);
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const fetchJobCodeList = async () => {
    try {
      setLoading(true);
      const response = await getJobCodeList(company);
      if (response && response.data) {
        const list = response.data.map((item) => ({
          'jobCode': item.tenement,
          'description': item.name,
          'class jobCode': item.class
        }))
        setJobAllocationList(list);
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    const data = selectedRowsData.map((item, index) => {
      const newObj = {
        id: allocationType + '-' + index,
        seqNo: invoiceId,
        details: item.description,
        companyCode: selectedCompany,
        jobCode: allocationType !== 'GL' ? item?.jobCode : '',
        lineType: allocationType,
        accountCode: allocationType === 'GL' ? item?.code : '',
        phaseCode: '',
        'reqCode jobCode': '',
        amount: '',
        percentage: ''
      };
  
      // Check allocation array for existing jobCode match, return foundItem or newObj
      if (allocationType !== 'GL' && allocationArray?.length > 0) {
        const foundItem = allocationArray.find(element => item.jobCode === element.jobCode);
        return foundItem || newObj;
      }
  
      return newObj;
    });
  
    // Update states based on allocationType (GL or not)
    if (allocationType === 'GL') {
      setSelectedGlRowKeys(selectedRowKeys);
      setTempGlData(data);
      setAllocationArray(tempJobData.concat(data)); // Append new data to existing tempJobData
    } else {
      setSelectedJobRowKeys(selectedRowKeys);
      setTempJobData(data);
      setAllocationArray(tempGlData.concat(data)); // Append new data to existing tempGlData
    }
  };


  const handleAllocationTypeChange = (type) => {
    setAllocationType(type);
  };

  const resetAllValue = () => {
    setAllocationArray([]);
    setTempGlData([]);
    setTempJobData([]);
    setSelectedGlRowKeys([]);
    setSelectedJobRowKeys([]);
  }


  const updateAllocationRowData = (e: any) => {
    const updateData = allocationArray.map((elememt) => (e.key === elememt.id ? e.data : elememt))
    setAllocationArray(updateData);
  }

  const equalDistribution = () => {
    if (allocationAmount === 0) {
      toastMessage(t("The allocation Amount can't be 0"), "waring");
    } else {
      const count = allocationArray.length;
      const perRowAmount = allocationAmount / count;
      const perRowpercentage = (perRowAmount / allocationAmount) * 100;
      const updateData = allocationArray.map((elememt) => ({
        'id': elememt.id,
        'jobCode': elememt.jobCode,
        'details': elememt.description,
        'companyCode': selectedCompany,
        'lineType': elememt['lineType'],
        'accountCode': elememt['accountCode'],
        'phaseCode': elememt.phaseCode,
        'reqCode': elememt['reqCode'],
        'amount': perRowAmount,
        'percentage': perRowpercentage
      }));
      setAllocationArray(updateData);
      toastMessage(t("Allocation Amount distributed equally"), "success");
    }
  }

  const percentageCalculation = () => {
    const updateData = allocationArray.map((elememt) => ({
      'id': elememt.id,
      'jobCode': elememt.jobCode,
      'companyCode': selectedCompany,
      'details': elememt.description,
      'lineType': elememt['lineType'],
      'accountCode': elememt['accountCode'],
      'phaseCode': elememt.phaseCode,
      'reqCode': elememt['reqCode'],
      'amount': (elememt.percentage * allocationAmount) * 0.01,
      'percentage': elememt.percentage
    }));
    setAllocationArray(updateData);
    toastMessage(t("Percantage recaluculated successfully"), "success");
  }

  const comapanyChangeAction = (value) => {
    setSelectedComapany(value);
    resetAllValue();
  }

  const updatePhaseAndRequirementOption = async (e: any) => {
    if (e.column.dataField === 'phaseCode' || e.column.dataField === 'reqCode') {
      if (e.data.lineType === 'GL') {
        e.cancel = true;  // Disable editing if lineType is 'GL'
      } else {
        if (e.column.dataField === 'phaseCode') {
          try {
            const response = await getPhaseOptionByjobCode(company, e.data.jobCode)
            if (response && response.errorCode === 0 && response.data && response.data.length > 0) {
              setPhaseOption(response.data);
            } else {
              setPhaseOption([]);
            }
          } catch (error) {
            toastMessage(t("An error occurred. Please try again later."), "error");
          }
          finally {
            setLoading(false);
          }
        } else {
          if (e.data.phaseCode != '' && e.column.dataField === "reqCode") {
            try {
              const response = await getReqOptionByjobCode(company, e.data.jobCode, e.data.phaseCode)
              if (response && response.errorCode === 0 && response.data && response.data.length > 0) {
                setReqOption(response.data);
              } else {
                setReqOption([]);
              }
            } catch (error) {
              toastMessage(t("An error occurred. Please try again later."), "error");
              setLoading(false);
            }
            finally {
              setLoading(false);
            }
          } else {
            e.cancel = true;
          }
        }
      }
    }
    if (e.column.dataField === 'accountCode') {
      if (e.data.lineType === 'Job Cost') {
        e.cancel = true;  // Disable editing if lineType is 'Job Cost'
      }
    }
  }

  const modifyArray = (arr) => {
    return arr.map(item => {
      const { id, ...rest } = item;
      return {
        ...rest,
        seqNo: invoiceId
      };
    });
  };


  const saveCostAllocationData = async () => {
    try {
      const hasInvalidValues = allocationArray.some(item => !item.amount || isNaN(Number(item.amount)));

      if (hasInvalidValues) {
        toastMessage(t("Please enter a valid amount for all allocation data"), "error");
        return;  // Stop execution if validation fails
      } else {
        const totalAmount = allocationArray.reduce((total, item) => Number(total) + Number(item.amount), 0);

        if (totalAmount <= allocationAmount) {
          setLoading(true)
          const payload = modifyArray(allocationArray);

          const response = await saveCostAllocation(payload, invoiceId, company, 'save')
          if (response && response.actualErrorCode === 0) {
            toastMessage(t(response.successMsg), "success");
            navigate('/createInvoice', { state: { invoiceId, previousPage } })
          } else {
            if (response.errorMsg) {
              toastMessage(t(response.errorMsg), "error");
            } else {
              toastMessage(t("An error occured Please try again later."), "error");
            }
          }
        } else {
          toastMessage(t("The Total amount allocated is more than the allocation amount"), "error");
        }
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const handleRowDeleted = (e: any) => {
    if(e.data.lineType === 'GL') {
      const updateTemp = tempGlData.filter((item) => (item.accountCode !== e.data.accountCode))
      const updateSelectedRows = selectedGlRowKeys.filter((item: any) => (item.code !== e.data.accountCode))
      setSelectedGlRowKeys(updateSelectedRows);
      setTempGlData(updateTemp)
    } else {
      const updateTemp = tempJobData.filter((item) => (item.jobCode !== e.data.jobCode))
      const updateSelectedRows = selectedGlRowKeys.filter((item: any) => (item.jobCode !== e.data.accountCode))
      setSelectedJobRowKeys(updateSelectedRows)
      setTempJobData(updateTemp)
      
    }
  }

  return (
    <>
      {loading && <CustomLoaders />}
      <div className="container">
        <div className="row h-100">
          <div className={`col-md-${allocationArray && allocationArray.length > 0 ? 6 : 12}`} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            {/* Top row with four divs aligned equally */}
            <Row className="mb-3">
              {/* Column for Invoice, Allocated, and Balanced Amount */}
              <Col sm={4} className="d-flex flex-column mb-4">
                <label htmlFor="genCat" className="fw-bold fs-6">
                  {t('Invoice Amount')}: {currency} {formatPrice(Number(invoiceAmount)+Number(gstAmount))}
                </label>
                <label htmlFor="genCat" className="fw-bold fs-6">
                  {t('Invoice Amount (Gst Excl.)')}: {currency} {formatPrice(invoiceAmount)}
                </label>
                <label htmlFor="genCat" className="fw-bold fs-6">
                  {t('GST Amount')}: {currency} {formatPrice(gstAmount)}
                </label>
                <label htmlFor="genCat" className="fw-bold fs-6">
                  {t('Allocated Amount')}: {currency} {formatPrice(prevAllocatedAmount)}
                </label>
                <label htmlFor="genCat" className="fw-bold fs-6">
                  {t('Balanced Amount')}: {currency} {formatPrice(invoiceAmount - prevAllocatedAmount)}
                </label>
              </Col>

              {/* Formik for Allocation Amount */}
              <Col sm={4} className="mb-4">
                <Formik
                  enableReinitialize
                  initialValues={initialValue}
                  validationSchema={validationSchema}
                  validateOnChange={true}
                  validateOnBlur={true}
                  onSubmit={() => { }}
                >
                  {({ errors, values, setFieldValue }) => {
                    return (
                      <Form>
                        <div className="mb-5">
                          <label htmlFor="allocationAmount" className="fw-bold fs-6 mb-2">
                            {t('Allocation Amount')}
                          </label>
                          <Field name="allocationAmount">
                            {({ field, form }) => (
                              <NumberBox
                                format="#,##0.##"
                                value={Number(values.allocationAmount) || 0}
                                placeholder={t('Allocation Amount')}
                                stylingMode="outlined"
                                height="40px"
                                onValueChanged={(e) => {
                                  if (e.value <= invoiceAmount - prevAllocatedAmount) {
                                    setFieldValue('allocationAmount', e.value);
                                    setInitialValue({ allocationAmount: e.value });
                                    setAllocationAmount(e.value)
                                  } else {
                                    toastMessage(t("The Allocated Amount can't be greater than the Balanced Amount."), "error");
                                  }
                                }}
                              />
                            )}
                          </Field>
                          {errors?.allocationAmount && (
                            <div
                              style={{
                                color: '#f35a7f',
                                marginTop: '5px',
                                fontSize: '11px',
                                fontWeight: '500',
                              }}
                            >
                              {errors?.allocationAmount}
                            </div>
                          )}
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Col>

              {/* Column for Company Select */}
              <Col sm={4} className="mb-4">
                <div>
                  <label htmlFor="companySelect" className="fw-bold fs-6 me-2">
                    {t('Company')}
                  </label>
                  <div style={{ zIndex: 999999999 }}>
                    <Select
                      options={companyOption}
                      onChange={(selectedOption) =>
                        comapanyChangeAction(selectedOption.value)
                      }
                      classNamePrefix="custom-select"
                      value={
                        companyOption.find((option) => option.value === selectedCompany) ||
                        null
                      }
                      styles={ReactSelectStyle}
                      placeholder={t('Select Company')}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-6 mb-6">
              <Col className="d-flex justify-content-around">
                {
                  allocationArray && allocationArray.length === 0 &&
                  <Button
                    style={{ width: "150px" }}
                    size="large"
                    type={'button'}
                    variant={'contained'}
                    onClick={() => navigate('/createInvoice', { state: { invoiceId, previousPage } })}>
                    {t('Back')}
                  </Button>
                }
                <Button
                  style={{ width: "150px" }}
                  size="large"
                  type={'button'}
                  variant={allocationType === 'GL' ? 'contained' : 'outlined'}
                  onClick={() => handleAllocationTypeChange('GL')}
                >
                  {t('GL')}
                </Button>
                <Button
                  style={{ width: "150px" }}
                  size="large"
                  type={'button'}

                  variant={allocationType === 'Job Cost' ? 'contained' : 'outlined'}
                  onClick={() => handleAllocationTypeChange('Job Cost')}
                >
                  {t('Job Cost')}
                </Button>

              </Col>
            </Row>
            {
              allocationType === 'GL' ?
              glAllocationList  && glAllocationList.length > 0 ? 
                <DataGrid
                  showBorders={true}
                  onSelectionChanged={onSelectionChanged}
                  selectedRowKeys={selectedGlRowKeys}  // Set the pre-selected rows here
                  dataSource={glAllocationList}
                >
                  <Selection mode="multiple" allowSelectAll={false} />
                  <HeaderFilter visible={true} />
                  <SearchPanel visible={true} width={240} placeholder="Search..." />
                </DataGrid> : <CustomLoadingCellRender/> :
                jobAllocationList && jobAllocationList.length > 0 ?
                <DataGrid
                  showBorders={true}
                  onSelectionChanged={onSelectionChanged}
                  selectedRowKeys={selectedJobRowKeys}  // Set the pre-selected rows here
                  dataSource={jobAllocationList}
                >
                  <Selection mode="multiple" allowSelectAll={false} />
                  <HeaderFilter visible={true} />
                  <SearchPanel visible={true} width={240} placeholder="Search..." />
                </DataGrid> : <CustomLoadingCellRender/>
            }

          </div>
          {allocationArray && allocationArray.length > 0 && (
            <div className="col-md-6" style={{ padding: '10px' }}>
              <Row className="mt-6 mb-6">
                <div className="col-md-6 mt-3">
                  <button
                    className="btn text-white rounded-pill text-wrap primary-button w-100 h-45px"
                    onClick={() => equalDistribution()}
                    title={t('Equal Distribution')}
                    type="button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#0a7eac",
                    }}
                  >
                    {t('Equal Distribution')}
                  </button>
                </div>
                <div className="col-md-6 mt-3">
                  <button
                    className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                    onClick={() => percentageCalculation()}
                    title={t('Percentage Recalculation')}
                    type="button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#0a7eac",
                    }}
                  >
                    {t('Percentage Recalculation')}
                  </button>
                </div>
              </Row>
              <Row>
                <div className="col-md-4 mt-3">
                  <button
                    className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                    onClick={() => saveCostAllocationData()}
                    title={t('Save')}
                    type="button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#0a7eac",
                    }}
                  >
                    {t('Save')}
                  </button>
                </div>
                <div className="col-md-4 mt-3">
                  <button
                    className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                    onClick={() => navigate('/createInvoice', { state: { invoiceId, previousPage } })}
                    title={t('Cancel')}
                    type="button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#0a7eac",
                    }}
                  >
                    {t('Cancel')}
                  </button>
                </div>
                <div className="col-md-4 mt-3">
                  <button
                    className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                    onClick={() => resetAllValue()}
                    title={t('Clear')}
                    type="button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#0a7eac",
                    }}
                  >
                    {t('Clear')}
                  </button>
                </div>
              </Row>
              <DataGrid
                showBorders={true}
                keyExpr="id"
                onRowUpdated={updateAllocationRowData}
                onRowRemoved={(e) => handleRowDeleted(e)}
                onEditingStart={updatePhaseAndRequirementOption}
                dataSource={allocationArray}
              >
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={240} placeholder="Search..." />

                {/* Command column for the delete button */}
                <Column
                  type="buttons"
                  width={80}
                  buttons={['delete']} // Display only the delete button
                  caption="Actions" // Caption for the column
                />

                <Column dataField="companyCode" caption="Company Code" width={100} allowEditing={false} />
                <Column dataField="lineType" caption="Line Type" width={100} allowEditing={false} />
                <Column dataField="jobCode" caption="Job Code" width={200} allowEditing={false} />
                <Column
                  dataField="phaseCode"
                  caption="Phase"
                  width={150}
                  calculateDisplayValue={"phaseCode"}
                >
                  <Lookup
                    dataSource={phaseOption}
                    displayExpr={(item) =>
                      item ? `${item.phaseCode} - ${item.description}` : ''
                    }
                    valueExpr="phaseCode"
                  />
                </Column>
                <Column
                  dataField="reqCode"
                  caption="Requirement"
                  width={150}
                  calculateDisplayValue={"reqCode"}
                >
                  <Lookup
                    dataSource={reqOption}
                    displayExpr={(item) =>
                      item ? `${item.reqCode} - ${item.description}` : ''
                    }
                    valueExpr="reqCode"
                  />
                </Column>
                <Column dataField="accountCode" caption="Gl Account" width={200} allowEditing={false} />
                <Column dataField="amount" caption="Amount" format="#,##0.##" width={150} dataType="number">
                  <ValidationRule type="numeric" />
                </Column>
                <Column
                  dataField="percentage"
                  caption="Percentage"
                  format="##0.##"
                  width={150}
                  dataType="number"
                >
                  <ValidationRule type="numeric" />
                  <ValidationRule type="range" min={0} max={100} message="Percentage must be between 0 and 100" />
                </Column>

                <Editing mode="cell" allowUpdating={true} allowDeleting={true} />

                <Summary>
                  <TotalItem
                    column="amount"
                    summaryType="sum"
                    displayFormat="Total: {0}"
                    valueFormat="#,##0.##"
                  />
                  <TotalItem
                    column="percentage"
                    summaryType="sum"
                    displayFormat="Total: {0}%"
                    valueFormat={"##.##"}
                  />
                </Summary>
              </DataGrid>

            </div>
          )}
        </div>
      </div>
    </>
  )
}

