import clsx from "clsx";
import React, { useState , useEffect, useRef} from "react";
import { useLocation, useNavigate } from "react-router";
import { WithChildren, checkIsActive } from "../../../../helpers";
import { useLayout } from "../../../core";
import { useTranslation } from "react-i18next";
type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  data?: any;
};

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  data,
}) => {
  const {t} = useTranslation()
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleItemClick = () => {
    console.debug("handleItemClick", data);
    if (data?.menu_item == "PO Enquiry") {
      navigate("/po-enquiry");
    } else if (data?.menu_item == "Stock Enquiry") {
      navigate("/stock-enquiry");
    } else if (data?.menu_item == "Plant Enquiry") {
      navigate("/plantEnquiry");
    } else {
      setDropdownOpen(!isDropdownOpen);
      // Use navigate or other logic as needed
      navigate("/dashboard", { state: { filter: title } });
    }
  };
  useEffect(() => {
    if (isDropdownOpen && dropdownRef.current) {
      dropdownRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [isDropdownOpen]);

  const handleChildClick = (e) => {
    e.stopPropagation();
  };
  return (
    <div
      className={clsx(
        "menu-item bg-hover-transparent bg-hover-opacity-0",
        { "here show": isActive },
        "menu-accordion"
      )}
      data-kt-menu-trigger="click"
      onClick={(e) => {
        // navigate("/dashboard");
        // setMenuData(title);
        // e.stopPropagation();
        handleItemClick();
      }}
    >
      <span className="menu-link">
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
          <span className="menu-icon">
            {/* <KTSVG path={icon} className="svg-icon-2" /> */}
            <img src={icon} style={{ height: "30px", width: "30px" }} />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span className="menu-title pl-3">{t(title)}</span>
        <span className="menu-arrow"></span>
      </span>
      <div
         ref={dropdownRef}
        className={clsx("menu-sub menu-sub-accordion", {
          "menu-active-bg": isActive,
          show: isDropdownOpen,
        })}
        onClick={handleChildClick}
      >
        {/* {children} */}
        {React.Children.map(children, (child) =>
          React.cloneElement(child as React.ReactElement, { parentId: to })
        )}
      </div>
    </div>
  );
};

export { SidebarMenuItemWithSub };
