import { Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../../auth/functions/toastMessage";
import { deleteUser } from "./core/_requests";
import { useTranslation } from "react-i18next";

const DeleteUser = ({ onCancel, userId, user }) => {
  const {t} = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await deleteUser(userId);
      toastMessage(t("M.USER.DELETE.SUCCESS"), "success");
      setTimeout(() => {
        setIsSubmitting(false);
        onCancel();
        navigate("/system/users");
      }, 2000);
    } catch (error) {
      toastMessage(t("M.USER.DELETE.FAIL"), "error");
      setTimeout(() => {
        setIsSubmitting(false);
        onCancel();
      }, 2000);
      console.error("Error deleting user:", error);
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {
        handleSubmit();
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="mb-4">
            <h5 className="fw-bold fs-5 mb-5 ms-7">
              Are you sure you want to delete the user?
            </h5>
          </div>

          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-secondary rounded-pill mx-2"
              onClick={onCancel}
              title="Cancel"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-danger rounded-pill mx-2"
              title="Delete"
              data-kt-users-modal-action="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Deleting..." : "Delete"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DeleteUser;
