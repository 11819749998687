/** @format */

import { Route, Routes } from "react-router-dom";
import { Login } from "./components/Login";
import { AuthLayout } from "./AuthLayout";

import TwoFactorAuth from "./components/TwoFactorAuth";
import { SelectCompanyPage } from "./components/SelectCompanyList";
import { MasterLayout } from "../../../theme/layout/MasterLayout";
import '../styles/GlobalCss.css';   // it has css for input,field and card and Aggrid Pagination for mobile view
import '../apps/user-management/users-list/table/acoordionTableStyle.css'; // it has css for custom select

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="selectcompany" element={<SelectCompanyPage />} />
      <Route index element={<Login />} />
      <Route path="TwoFactorAuth" element={<TwoFactorAuth />} />

      <Route path="dashboard" element={<MasterLayout />} />
      <Route />
      <Route
        path="two-factor-authentication"
        element={
          <>
            <TwoFactorAuth />
          </>
        }
      ></Route>
    </Route>
  </Routes>
);

export { AuthPage };
