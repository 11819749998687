import { useTranslation } from "react-i18next";
import loader from "../../../assets/erpAssets/loader.gif";

function CustomSSELoader({
    messageArray = []
}) {
  const {t}=useTranslation();
  return (
    <div
      className="ag-custom-loading-cell d-flex flex-column align-items-center justify-content-center w-100 h-100 text-light fs-5"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          textAlign: "center",
          width: "300px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "black", // Set text color to black for visibility

        }}
      >
      <img
          src={loader}
          alt={`${t("Loading")}...`}
          style={{ height: "100px", width: "100px", marginBottom: "20px" }}
        />

        {/* Display each message in messageArray */}
        {messageArray && messageArray.length > 0 && (
          <div style={{ width: "100%", textAlign: "center" }}>
            {messageArray.map((message, index) => (
              <div key={index} style={{ marginBottom: "10px" }}>
                {message}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomSSELoader;
