import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";

const CustomizeFunction = (props) => {
  let summaryFunctionName = props.columnHeaders?.find(
    (item) => item?.caption == props?.applySummaryFooter
  );
  console.debug(
    "summaryFunctionName columnType",
    summaryFunctionName?.columnType
  );

  const company = useSelector((state: any) => state?.userConfig?.company);
  const [selectedRow, setSelectedRow] = useState(
    summaryFunctionName?.summaryFooterFunction || "none"
  );

  function getSummaryFunctions(columnType) {
    switch (columnType) {
      case "Numeric":
        return [
          { id: 1, function: "Sum" },
          { id: 2, function: "Min" },
          { id: 3, function: "Max" },
          { id: 4, function: "Count" },
          { id: 5, function: "Average" },
          { id: 6, function: "None" },
        ];
      case "Date":
        return [
          { id: 1, function: "Min" },
          { id: 2, function: "Max" },
          { id: 3, function: "Count" },
          { id: 5, function: "None" },
        ];
      case "Char":
      case "Memo":
      case "VarChar":
        return [
          { id: 3, function: "Count" },
          { id: 4, function: "None" },
        ];
    }
  }

  const summaryFunctions = getSummaryFunctions(summaryFunctionName?.columnType);

  const handleInputChange = (e, caption) => {
    setSelectedRow(caption?.toLowerCase());
  };

  const handleApply = () => {
    const updatedSummaryFunctionName = {
      ...summaryFunctionName,
      summaryFooterFunction: selectedRow.toLowerCase(),
    };

    const payload = {
      tabStorage: props.tabStorage,
      tabSqlName: props.tabSqlName,
      summarizedColumn: updatedSummaryFunctionName,
      companyCode: company,
    };
    console.debug("Payload in customize functions", payload);
    props.onApply(payload);
    props.onCancel();
  };

  const handleMouseEnter = () => {
    const accordionHeader = document.querySelector(".accordion-header");
    if (accordionHeader) {
      accordionHeader.setAttribute("data-bs-toggle", "collapse");
    }
  };

  const handleMouseLeave = () => {
    const accordionHeader = document.querySelector(".accordion-header");
    if (accordionHeader) {
      accordionHeader.setAttribute("data-bs-toggle", "");
    }
  };

  const handleReset = () => {
    props.onCancel();
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginTop: "-28px",
        }}
      >
        <thead></thead>
        <tbody>
          {summaryFunctions?.map((row, index) => {
            return (
              <tr key={row.id}>
                <td
                  className="radio-cell"
                  style={{ width: "20px", height: "20px" }}
                >
                  <input
                    type="radio"
                    onChange={(e) => handleInputChange(e, row?.function)}
                    checked={selectedRow === row.function.toLowerCase()}
                  />
                </td>
                <td
                  style={{
                    border: "0",
                    padding: "8px",
                    color: "black",
                  }}
                  className="fs-5"
                >
                  {row.function}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-center gap-15 mt-5">
        <button
          type="button"
          className="btn btn-secondary rounded-pill mx-2"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleReset}
          title="Reset"
        >
          Reset
        </button>
        <button
          type="submit"
          className="btn text-white rounded-pill mx-2"
          title="Apply"
          style={{ backgroundColor: "#0a7eac" }}
          data-kt-users-modal-action="submit"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleApply}
        >
          Apply
        </button>
      </div>
    </DndProvider>
  );
};

export default CustomizeFunction;
