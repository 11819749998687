import React, { useState, useEffect, InputHTMLAttributes } from "react";
import "./NotificationApprove.css";

const NotificationApprove = ({
  onCancel,
  onSave,
  rejectModal,
  data,
  setUserName,
  userName,
  setPassword,
  password,
  isFormValid,
  setIsFormValid,
  reauthoriseLoader,
}) => {
  interface InputHTMLAttributesWithTextSecurity
    extends InputHTMLAttributes<HTMLInputElement> {
    "-webkit-text-security"?: string;
    "-moz-text-security"?: string;
    "text-security"?: string;
  }
  const [showPassword, setShowPassword] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showError, setShowError] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setIsFormValid(!userNameError && !passwordError);
  }, [userNameError, passwordError]);

  const handleUserNameChange = (e) => {
    const value = e.target.value;
    setUserName(value);
    setUserNameError(value.trim() === "");
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordError(value.trim() === "");
    setShowPassword(true);
    setTimeout(() => {
      setShowPassword(false);
    }, 1000);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLInputElement>) => {
    // Check for specific browser behavior or popup elements
    // If popup is detected:
    event.preventDefault();
  };

  const onClickSave = () => {
    if (isFormValid) {
      onSave();
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="d-flex flex-column gap-5">
      <label htmlFor="userName" className="fw-bold">
        Enter User ID:
      </label>
      <input
        id="userName"
        className="form-control"
        placeholder="Enter User ID"
        value={userName}
        onChange={handleUserNameChange}
        disabled={true}
      />
      {userNameError && (
        <div className="text-danger">* User ID is required</div>
      )}

      <div className="fv-row mb-5" style={{ position: "relative" }}>
        <label htmlFor="password" className="fw-bold">
          Enter Password:
        </label>
        <div className="input-group">
          <span className="input-group-text bg-white h-40px">
            <i className="bi bi-key-fill fs-3"></i>
          </span>
          <input
            className="form-control border-secondary fs-5"
            placeholder="Password"
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            onChange={handlePasswordChange}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onClickSave();
              }
            }}
            style={{ width: "200px" }}
          />
          <span className="input-group-text" onClick={togglePasswordVisibility}>
            <i
              className={
                showPassword ? "bi-eye" : "bi-eye-slash"
              }
            ></i>
          </span>
        </div>
        {passwordError && (
          <div className="text-danger">* Password is required</div>
        )}
      </div>

      {showError && (
        <div className="d-flex justify-content-center text-danger">
          * Please fill all the fields
        </div>
      )}

      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="btn btn-secondary rounded-pill mx-2"
          onClick={onCancel}
          title="Cancel"
        >
          Cancel
        </button>
        {userName == "" || password == "" ? (
          <button
            type="submit"
            className="btn text-white rounded-pill mx-2"
            title="Save"
            style={{ backgroundColor: "#0a7eac" }}
            data-kt-users-modal-action="submit"
            disabled={true}
            onClick={() => setShowError(true)}
          >
            Submit
          </button>
        ) : (
          <button
            type="submit"
            className="btn text-white rounded-pill mx-2"
            title="Submit"
            style={{ backgroundColor: "#0a7eac" }}
            data-kt-users-modal-action="submit"
            disabled={!isFormValid || reauthoriseLoader}
            onClick={onClickSave}
          >
            {reauthoriseLoader ? "Processing ..." : "Submit"}
          </button>
        )}
      </div>
    </div>
  );
};

export default NotificationApprove;
