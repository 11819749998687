import { createSlice } from "@reduxjs/toolkit";

const counterSlice = createSlice({
  name: "lineItemDetails",
  initialState: {
    lineItemDetails: [],
  },
  reducers: {
    updateLineItemDetails: (state, action) => {
      state.lineItemDetails = action.payload;
    },
  },
});

export const { updateLineItemDetails } = counterSlice.actions;
export default counterSlice.reducer;
