import clsx from "clsx";
import { useRef } from "react";
import { Card, Row } from "react-bootstrap";
import { colors, titleCss } from "../../../constants/colors";
import { formatDate } from "../../../theme/assets/ts/_utils/FormatData";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PlantEnquiryHeader = () => {
  const {t} = useTranslation();
  const headerRef = useRef(null);

  const pOHeader = useSelector((state:any)=>state?.poAndStock?.pOHeader);

  console.debug(pOHeader,"poheader data")
  function renderInputField(label, valueKey, value = "", width = "20%",type) {
    const additionalClass = type === "Qty" ? "text-end" : "";
    return (
      <div className="pe-4" style={{ width: width }}>
        <div className="mb-5">
          <label
            htmlFor={label}
            className="fw-bold fs-6 mb-0"
            style={{ whiteSpace: "nowrap", overflow: "hidden" }}
          >
            {t(label)}
          </label>
          <input
            className={`bg-secondary ${additionalClass}`}
            type="text"
            id={label}
            name={label}
            value={valueKey ? pOHeader?.[valueKey] : value}
            disabled={true}
          />
        </div>
      </div>
    );
  }

  function formatAmount(value) {
    return value
      ? value.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : "0.00";
  }

  return (
    <div className="mt-n2">
      <div className="accordion" id="accordionExample3">
        <div className="accordion-item">
          <div className="px-4 mb-n5">
            <div
              ref={headerRef}
              className="accordion-button bg-white cursor-pointer"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                <div className="col-md-6 d-flex">
                  <div
                    className={clsx("", titleCss)}
                    style={{ color: colors.title }}
                  >
                    {t('Plant Details')}
                  </div>
                </div>
              </div>
            </div>
            <div
              id="collapseThree"
              className={`accordion-collapse collapse show`}
              aria-labelledby="headingTwo"
              aria-expanded="true"
              data-bs-parent="#accordionExample3"
            >
              <div className="accordion-body">
                <Card className="p-4">
                  <Row>
                    {renderInputField("Plant No", "plantno", "", "20%", "")}
                    {renderInputField("Class", "class1","","20%","")}
                    {renderInputField("Dept Code", "deptcode","","20%","")}
                    {renderInputField("Group Code", "grpcode","","20%","")}
                    {renderInputField("Loc Code", "loccode","","20%","")}
                  </Row>
                   <Row>
                    {renderInputField("Short Description","sdesc","","40%","")}
                    {renderInputField("Status","status","","20%","")}
                  </Row>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlantEnquiryHeader;
