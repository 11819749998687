import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { colors, titleCss } from "../../../constants/colors";
import {
  getPOEnquiry,
  getPOEnquiryOthersTabs,
  getStockEnquiry,
  getStockOthersTabs,
} from "../../modules/apps/user-management/users-list/core/_requests";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { updateLookupData, updateSelectedRecords } from "../../../reducers/lookupReducer";
import "./POEnquiry.css";
import StockEnquiryHeader from "./StockEnquiryHeader";
import CustomLoaders from "../user/CustomLoaders";
import POEnquiryHeader from "./POEnquiryHeader";
import POAndStockTablesOpti from "./POAndStockTablesOpti";
import DynamicGridTable from "./DynamicGridTable";
import { useTranslation } from "react-i18next";


const POAndStockEnquiry = ({ type }) => {
  const {t}=useTranslation();
  const dispatch = useDispatch();
  const company = useSelector((state: any) => state?.userConfig?.company);
  const headerRef = useRef(null);
  const poNumberRef = useRef(null);
  const currentPoNumberRef = useRef(null);
  const selectedPoNumberRef = useRef([]);
  const poEnquiryDataRef = useRef();
  const [defaultTab, setDefaultTab] = useState<any>();
  const [otherTabs, setOtherTabs] = useState<any>();
  const [isLoadingApi, setIsLoadingApi] = useState<any>(false);
  
  const currentDate = new Date();
  const oneYearBack = new Date(currentDate);
  oneYearBack.setFullYear(currentDate.getFullYear() - 1);
  const formattedEndDate = currentDate.toISOString().split("T")[0];
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  const formattedStartDate = oneYearAgo.toISOString().split("T")[0];
  const [startDate, setStartDate] = useState<any>(formattedStartDate);
  const [endDate, setEndDate] = useState<any>(formattedEndDate);

  useEffect(() => {
    dispatch(updateLookupData([]));
    localStorage.removeItem("headerDataOfStock");
    localStorage.removeItem("requestNo");
  }, []);

  const fetchApi = async () => {
    try {
      setIsLoadingApi(true);
      const apiCall =
        type === "STOCK_ENQUIRY" ? getStockEnquiry : getPOEnquiry;
      const poEnquiryData = await apiCall(
        company,
        type === "STOCK_ENQUIRY" ? "IN" : "PO",
        startDate,
        endDate
      );
      poEnquiryDataRef.current = poEnquiryData.data;

      if (!poEnquiryData) {
        // Handle the case when poEnquiryData is null or undefined
        setIsLoadingApi(false);
        return;
      }

      const { tabList } = poEnquiryData?.data || {};
      if (!tabList || tabList.length === 0) {
        setIsLoadingApi(false);
        return;
      }

      const firstTab = tabList[0];
      setDefaultTab(firstTab);
      let otherTabsLocal = {}
      tabList.map((item,index)=>{
        if(index !==0){
          otherTabsLocal[item.tabId]=item;
        }
      })
      setOtherTabs(otherTabsLocal);
      if(!poNumberRef.current){
        console.debug("poNumberRef is not set.");
        if (type === "STOCK_ENQUIRY") {
          currentPoNumberRef.current = firstTab.tabSqlData[0]?.stock_code;
        } else {
          currentPoNumberRef.current = firstTab.tabSqlData[0]?.ponumber;
        }
        dispatch(updateSelectedRecords([]));
      }
      setIsLoadingApi(false);
    } catch (error) {
      setIsLoadingApi(false);
    }
  };

  useEffect(() => {
    fetchApi();
  }, [type]);

  const fetchOthersTabData = async (tabId,forceFetch=false) => { 
    console.debug("fetchOthersTabData Called");
    setIsLoadingApi(true);
    
    let currentTabData = otherTabs[tabId];
    
    let pONumberchanged = false;

    if(currentTabData["poNumber"]!==currentPoNumberRef.current){
      pONumberchanged = true;
    }

    console.debug(currentTabData["poNumber"],currentPoNumberRef.current,pONumberchanged);
    if (!forceFetch && (!currentPoNumberRef.current || !pONumberchanged)) {
      setIsLoadingApi(false);
      return;
    }
    try {
      const apiCall =
        type === "STOCK_ENQUIRY" ? getStockOthersTabs : getPOEnquiryOthersTabs;
      const getPOEnquiryOthersTabsResponse = await apiCall(
        company,
        type === "STOCK_ENQUIRY" ? "IN" : "PO",
        currentPoNumberRef.current,
        tabId
      );

      console.debug("getPOEnquiryOthersTabsResponse : ",getPOEnquiryOthersTabsResponse);
      let tabData = getPOEnquiryOthersTabsResponse.data;
      tabData["poNumber"]=currentPoNumberRef.current;
      //update the Data for that tabId.
      let localTabId = tabData.tabId;
      let updatedTabData = {}
      updatedTabData[localTabId]=tabData
      let updatedOtherTabs = {
        ...otherTabs,
        ...updatedTabData
      }
      setOtherTabs(updatedOtherTabs);
      setIsLoadingApi(false);
    } catch (error) {
      setIsLoadingApi(false);
    }
  };
  const  isValidDate=(dateString)=> {
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    return (
        date.getFullYear() === year &&
        date.getMonth() === month - 1 &&
        date.getDate() === day
    );
}

  const handleStartDateChange = (event) => {
    if(event){
    const selectedDate = dayjs(event?.$d).format("YYYY-MM-DD");
      setStartDate(selectedDate);
    }
    else{
      setStartDate("");
    }

  };

  const handleEndDateChange = (event) => {
    if(event){
      const selectedDate = dayjs(event?.$d).format("YYYY-MM-DD");
      setEndDate(selectedDate);
      
    }
    else{
      setEndDate("");
    }
  };


  // handle the tab click of the multiple tabs to fetch and populate data in the respective tables.
  const onTabClick = async(tabId)=>{
    if(tabId !=="TAB1"){
      console.debug("onTabClick : ",tabId);
      fetchOthersTabData(tabId);
    }
  }

  const updatePONumber = (poNumber) => {
    currentPoNumberRef.current = poNumber;
  };

  const updateSelectedPOList = (poNumber,add)=>{
    let previousSelectedPoNumber = JSON.parse(JSON.stringify(selectedPoNumberRef.current));
    if(add){
      const exists = previousSelectedPoNumber.includes(poNumber)
      if(!exists){
        previousSelectedPoNumber.push(poNumber);
      }
    }else{
      previousSelectedPoNumber = previousSelectedPoNumber.filter((c) => { return c !== poNumber })
    }
    selectedPoNumberRef.current = previousSelectedPoNumber
  }

  return (
    <div>
      {isLoadingApi && <CustomLoaders />}
      <div>
        {type == "STOCK_ENQUIRY" ? <StockEnquiryHeader /> : <POEnquiryHeader />}
      </div>
      <div>
        <div className="accordion mt-5" id="accordionExample4">
          <div className="accordion-item">
            <div className="px-4">
              <div ref={headerRef} className="accordion-button bg-white cursor-pointer " data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" onClick={(e) => e.stopPropagation()} >
                <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                  <div className="col-md-6 d-flex">
                    <div className={clsx("", titleCss)} style={{ color: colors.title }} >
                      {type == "STOCK_ENQUIRY" ? t("Stock Enquiry")  : t("Purchase Orders")}
                    </div>
                  </div>
                </div>
              </div>
              <div id="collapseFour" className={`accordion-collapse collapse show`} aria-labelledby="headingTwo" aria-expanded="true" data-bs-parent="#accordionExample4" >
                <div className="accordion-body">
                  <ul className="nav nav-pills mb-3" id="pills-tab1" role="tablist" >
                    {defaultTab && 
                      <li className="nav-item" role="presentation" key={`nav-pills-items0`} >
                      <div className={`nav-link text-active-primary active`} id="pills-first-tab" data-bs-toggle="pill" data-bs-target={`#pills-${defaultTab?.tabId}`} role="tab" aria-controls="pills-home" aria-selected="true">{defaultTab.tabTitle}</div>
                    </li>
                    }
                    {otherTabs && Object.keys(otherTabs).map((key)=>(
                      <li className="nav-item" role="presentation" key={`nav-pills-items${key}`} onClick={()=>onTabClick(otherTabs[key].tabId)}>
                      <div className={`nav-link text-active-primary`} id="pills-home-tab" data-bs-toggle="pill" data-bs-target={`#pills-${otherTabs[key]?.tabId}`} role="tab" aria-controls="pills-home" aria-selected="true">{otherTabs[key]?.tabTitle}</div>
                    </li>  
                    ))}
                  </ul>
                  <div className="tab-content" id="pills-tabContent1">
                    {defaultTab && 
                    <div key={`nav-pills-items-contentfirst`} className={`tab-pane fade  active show`} id={`pills-${defaultTab?.tabId}`} role="tabpanel" aria-labelledby={`pills-${defaultTab?.tabId}-tab`}>
                       <POAndStockTablesOpti
                        type={type}
                        fetchApi={fetchApi}
                        isFirstTab={true}
                        tabTitle={defaultTab.tabTitle}
                        tabSqlData={defaultTab.tabSqlData}
                        tabStorageData={defaultTab.tabStorageData}
                        tabStorage={defaultTab.tabStorage}
                        tabSqlName={defaultTab.tabSqlName}  
                        tableConfiguration={defaultTab.tableConfiguration}
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        tabId={defaultTab.tabId}
                        customizeGridEnabled={defaultTab.customizeGridEnabled}
                        updatePONumber={updatePONumber} 
                        updateSelectedPOList={updateSelectedPOList}
                        selectedPoNumberRef={selectedPoNumberRef}
                        currentPoNumberRef={currentPoNumberRef}
                        poNumberRef={poNumberRef}
                     />
                     </div>
                    }
                    {otherTabs && Object.keys(otherTabs).map(key=>(
                      <div key={`nav-pills-items-content${key}`} className={`tab-pane fade `} id={`pills-${otherTabs[key]?.tabId}`} role="tabpanel" aria-labelledby={`pills-${otherTabs[key]?.tabId}-tab`}>
                        <DynamicGridTable
                          type={type}
                          tabTitle={otherTabs[key].tabTitle}
                          tabSqlData={otherTabs[key].tabSqlData}
                          tabStorageData={otherTabs[key].tabStorageData}
                          tabStorage={otherTabs[key].tabStorage}
                          tabSqlName={otherTabs[key].tabSqlName}  
                          tableConfiguration={otherTabs[key].tableConfiguration}
                          tabId={otherTabs[key].tabId}
                          fetchOthersTabData={fetchOthersTabData}
                          customizeGridEnabled={otherTabs[key].customizeGridEnabled}
                        />
                      </div>  
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default POAndStockEnquiry;
