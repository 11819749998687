import numeral from "numeral";
const ValueFormatter = (value, numericFormat, columnName) => {
  if (numericFormat) {
    const symbol = numericFormat.includes("#")
      ? numericFormat
      : numericFormat + "#";
    const formatParts = symbol.split(/[^a-zA-Z]/);
    const prefix = formatParts.length > 1 ? formatParts[0] : "";
    const finalValue = value || value == 0 ? numeral(value).format(symbol) : "";
    return prefix ? prefix + " " + finalValue : finalValue;
  } else if (
    columnName.toLowerCase().includes("amount") ||
    columnName.toLowerCase().includes("amt") ||
    columnName.toLowerCase().includes("cost") ||
    columnName.toLowerCase().includes("rate") ||
    columnName.toLowerCase().includes("value") ||
    columnName.toLowerCase().includes("price")
  ) {
    return value || value == 0 
    ? parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : "";
  } else if (
    columnName.toLowerCase().includes("qty") ||
    columnName.toLowerCase().includes("quantity")
  ) {
    return value || value == 0 
    ? parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
    : "";
  }
  return value || value == 0 ? String(value) : "";
};

export default ValueFormatter;
