import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en-gb";
import "./CustomDate.scss";
import dayjs from "dayjs";

const CustomDatePicker = ({ onChange, value, error = "" }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DatePicker
        className="h-40px w-100"
        slotProps={{ textField: { placeholder: value ? value : "DD/MM/YYYY" },field: { clearable: true }}}
        onChange={onChange}
        value={value ? dayjs(value) : null}
        // minDate={disableDateBeforeToday?dayjs():undefined}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
