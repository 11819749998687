import { useEffect, useRef, useState } from "react";
import { Tab } from "bootstrap";
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
  ScrollTopComponent,
  StickyComponent,
  ToggleComponent,
  SwapperComponent,
} from "../assets/ts/components";
import { ThemeModeComponent } from "../assets/ts/layout";
import { useLayout } from "./core";
import axios from "axios";
import {
  refreshToken,
} from "../../app/modules/apps/user-management/users-list/core/_requests";
import { useAuth } from "../../app/modules/auth";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { descSchema, pathSchema, schema } from "../../constants/config";
import { useSelector } from "react-redux";
import { Message } from "../../app/utils/Config";
import { toastMessage } from "../../app/modules/auth/functions/toastMessage";
import {saveCompanyAndName, saveLanguage } from "../../reducers/UserConfigReducer";
import { store } from "../../reduxStore/store";
import { setMenuData, setMniName } from "../../reducers/menuReducer";

export function MasterInit() {
  const { config } = useLayout();
  const { currentUser, setCurrentUser, saveAuth, clearLocalStorage } =
    useAuth();
  const Errormessage=Message.MESSAGE;
  console.debug("masterinit rener")
  const refetchRefreshApiState = useSelector(
    (state: any) => state?.refreshState?.refreshApiState
  );

  const menuData = useSelector((state: any) => state?.menuConfig?.menuData);

  const [refreshTokenFromLocalStorage, setRefreshTokenFromLocalStorage] = useState<string>(currentUser?.refreshToken || "");

  useEffect(() => {
    const refreshListener = (e) => {
      setRefreshTokenFromLocalStorage(
        (prevRefreshToken) => currentUser?.refreshToken || prevRefreshToken
      );
    };

    window.addEventListener("refreshToken", refreshListener);

    return () => {
      window.removeEventListener("refreshToken", refreshListener);
    };
  }, []);

  const { setMenuList } = useAuth();
  const navigate = useNavigate();
  const isFirstRun = useRef(true);

  const pluginsInitialization = () => {
    isFirstRun.current = false;
    ThemeModeComponent.init();
    setTimeout(() => {
      ToggleComponent.bootstrap();
      ScrollTopComponent.bootstrap();
      DrawerComponent.bootstrap();
      StickyComponent.bootstrap();
      MenuComponent.bootstrap();
      ScrollComponent.bootstrap();
      SwapperComponent.bootstrap();
      document.querySelectorAll('[data-bs-toggle="tab"]')?.forEach((tab) => {
        Tab.getOrCreateInstance(tab);
      });
    }, 500);
  };

  let pinnedData: any = localStorage.getItem("pinnedData");
  pinnedData = JSON.parse(pinnedData);

  const refreshApiCall = async () => {
    const response = await refreshToken(currentUser?.refreshToken);

    if (response.data) {
      let decryptData: any = jwt_decode(response.data ? response.data : "");
      let temp = {
        sub: decryptData?.sub,
        roles: decryptData?.roles,
        token: response.data,
        refreshToken: currentUser!.refreshToken,
      };
      setCurrentUser(temp);
      saveAuth(temp);
    }
  };

  const addType = (data) => {
    data?.forEach((res) => {
      if (res.items?.length > 0) {
        res.items?.forEach((item) => {
          item.type = "function";
        });
      }
    });
    return data;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentUser) {
        refreshApiCall();
      }
    }, 180000);

    return () => clearInterval(intervalId);
  }, [refreshTokenFromLocalStorage, currentUser, refetchRefreshApiState]);

  useEffect(() => {
    if (currentUser) {
      refreshApiCall();
    }
  }, [refetchRefreshApiState]);



  useEffect(() => {
    if (menuData) {
      setMenuList(JSON.parse(JSON.stringify(menuData)));
      const newMenuData=JSON.parse(JSON.stringify(menuData));
      let data = addIconsToMenu(newMenuData);
      data = addNewKey(data);
  
      data = sortMenuDataOn(data);
  
      data = addType(data);
  
      data = addTextDisplay(data);
  
      setMenuList(data);
      }
  }, [menuData]);


  const addIconsToMenu = (menuData) => {
    const iconMapper = {};
    schema?.forEach((entry) => {
      iconMapper[entry.name] = entry.icon;
    });

    const applyIconToChildren = (parentIcon, items) => {
      if (!items || items?.length === 0) {
        return;
      }
      items?.forEach((item) => {
        item.icon = parentIcon;
        applyIconToChildren(parentIcon, item.items);
      });
    };

    menuData?.forEach((item) => {
      const icon = iconMapper[item.application];
      item.icon = icon;
      applyIconToChildren(icon, item.items);
    });

    return menuData;
  };
  const addTextDisplay = (menuData) => {
    menuData?.forEach((element) => {
      element.items?.forEach((item) => {
        item.textDisplay = descSchema.find(
          (x) => x.Function === item.menu_item
        )!?.textDisplay;
      });
    });
    return menuData;
  };

  const addNewKey = (menuData) => {
    const traverseItems = (items) => {
      items?.forEach((item) => {
        if (item.items && item.items?.length > 0) {
          traverseItems(item.items);
        } else {
          const schemaEntry = pathSchema.find(
            (entry) => entry.name === item.menu_item
          );
          if (item.menu_item !== "") {
            const pathValue = schemaEntry ? schemaEntry.to : "coming-soon";
            const textDisplay = schemaEntry ? schemaEntry.textDisplay : "";
            item.path = pathValue;
            item.textDisplay = textDisplay;
          }
        }
      });
    };

    traverseItems(menuData);
    return menuData;
  };

  const sortMenuDataOn = (menuData) => {
    menuData?.sort((a, b) => {
      const appA = a.application.toUpperCase();
      const appB = b.application.toUpperCase();
      if (appA < appB) {
        return -1;
      }
      if (appA > appB) {
        return 1;
      }
      return 0;
    });

    menuData?.forEach((item) => {
      if (item.items && item.items?.length > 0) {
        item.items?.sort((a, b) => {
          const menuItemA = a.menu_item.toUpperCase();
          const menuItemB = b.menu_item.toUpperCase();
          if (menuItemA < menuItemB) {
            return -1;
          }
          if (menuItemA > menuItemB) {
            return 1;
          }
          return 0;
        });
      }
    });

    return menuData;
  };

  function urlContainsAny(url, terms) {
    return terms.some(term => url.includes(term));
  }

  useEffect(() => {
    axios.interceptors.request.use(
      function (config) {
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        if (response.status === 200) {
        }

        return response;
      },
      async function (error) {
        if (error.response.status === 401 && !urlContainsAny(error.response.request.responseURL, ['login', 'logoutuser'])) {
           store?.dispatch(saveCompanyAndName({code:"",name:""}));
           store?.dispatch(saveLanguage("en"));
           store?.dispatch(setMenuData([]));
           store?.dispatch(setMniName([]));
            clearLocalStorage();
            toastMessage(Errormessage, "error");
        } else if (error.response.message === "Network Error") {
          navigate("error");
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      pluginsInitialization();
    }
  }, [config]);

  return <></>;
}
