import  {useState,useCallback } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const SearchInputDev = ({ gridRef,type,className,screen}) => {
  const [searchTerm, setSearchTerm] = useState('');
 const { t } = useTranslation();

 

  const onFilterTextBoxChanged = useCallback(() => {
    const value = (document.getElementById(`table-search-box-${type}`) as HTMLInputElement)?.value;
    setSearchTerm(value);
    gridRef?.current?.instance()?.option('searchPanel.text', value);
  }, []);

  const clearSearch = () => {
    setSearchTerm("");
    gridRef?.current?.instance()?.option('searchPanel.text', "");
  };

  return (
    <div className={className} style={{ position: "relative", display: "inline-block" }}>
      <input
        type="text"
        id={`table-search-box-${type}`}
        className="form-control"
        placeholder={t("Search...")}
        value={searchTerm}
        onInput={onFilterTextBoxChanged}
        style={{ paddingRight: "2rem" }} // Adding padding to accommodate the clear icon
      />
      {searchTerm && (
        <FaTimes
          onClick={clearSearch}
          style={{
            position: "absolute",
            right: "1.4rem",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: "#aaa",
          }}
        />
      )}
    </div>
  );
};

export default SearchInputDev;
