import { KTSVG } from "../../../../helpers";
import { SidebarMenuMain } from "./SidebarMenuMain";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../../app/modules/auth";
import { useTranslation } from 'react-i18next';
const SidebarMenu = () => {
  const { menuList } = useAuth();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(menuList ? menuList : []);
  function searchMenu(data, searchTerm) {
    const uniqueApplications = {};

    data.forEach((item) => {
      if (item.application.toLowerCase().includes(searchTerm)) {
        uniqueApplications[item.application] = item;
      }
      item.items.forEach((menu) => {
        if (menu.menu_item.toLowerCase().includes(searchTerm)) {
          uniqueApplications[item.application] = item;
        }
        menu.items.forEach((sub) => {
          if (sub.menu_item.toLowerCase().includes(searchTerm)) {
            uniqueApplications[item.application] = item;
          }
        });
      });
    });

    const results = Object.values(uniqueApplications);
    return results;
  }


  const handleChange = (val) => {
    setSearchText(val);
  };

  useEffect(() => {
    if (searchText?.length > 0) {
      let result = searchMenu(menuList, searchText.toLowerCase());
      setFilteredData(result);
    } else if (menuList?.length > 0 && searchText?.length === 0) {
      setFilteredData(menuList);
    }
  }, [searchText, menuList]);

  return (
    <div
      className="app-sidebar-menu bg-primary flex-column-fluid "
      style={{
        overflowY: "scroll",
        overflowX: "hidden",
        height: window.innerHeight - 100,
      }}
    >
      <div
        id="kt_app_sidebar_menu_wrapper"
        className="app-sidebar-wrapper  my-5"
        data-kt-scroll="true"
        data-kt-scroll-activate="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
        data-kt-scroll-wrappers="#kt_app_sidebar_menu"
        data-kt-scroll-offset="5px"
        data-kt-scroll-save-state="true"
      >
        <div
          className="menu menu-column menu-rounded px-3"
          id="#kt_app_sidebar_menu"
          data-kt-menu="true"
          data-kt-menu-expand="false"
        >
          <div className="menu-item app-display" style={{ position: "relative" }} >
            <input
              className="bg-white mb-5 h-30px w-100 fs-6"
              placeholder={t("Search...")}
              style={{ borderRadius: 5, paddingLeft: 35 }}
              value={searchText}
              onChange={(e) => handleChange(e.target.value)}
            />
            <div style={{ position: "absolute", top: 6, left: 0 }}>
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-1 position-absolute ms-3"
              />
            </div>
            {filteredData.length === 0 && searchText && (
              <div className="text-center mt-5">
                <div className="text-danger fs-5 fw-bold">{t('MENU.NO.SEARCH.RESULTS')}</div>
              </div>
            )}
          </div>

          <KTSVG
            path="/media/icons/duotune/general/search-white.svg"
            className="svg-icon-1  app-icon-minimize ms-5"
          />

          <SidebarMenuMain menuData={filteredData} />
        </div>
      </div>
    </div>
  );
};

export { SidebarMenu };
