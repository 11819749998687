import clsx from "clsx";
import { useRef, useState, useEffect } from "react";
import { colors, titleCss } from "../../../../constants/colors";
import { UserEditModal } from "../../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import { useLocation, useNavigate } from "react-router-dom";
import {
  generatePickupSlip,
  reauthoriseStockRequest,
} from "../../../modules/apps/user-management/users-list/core/_requests";
import { useAuth } from "../../../modules/auth";
import LookUpScreenForApproveStock from "../../../modules/custom components/LookUpScreenForApproveStock";
import ClearStorage from "../../../modules/auth/functions/ClearStorage";
import { toastMessage } from "../../../modules/auth/functions/toastMessage";
import ConditionalButtons from "./ConditionalButton";
import { useSelector } from "react-redux";
import { ApproveAuth } from "../../../utils/Config";
import { useTranslation } from "react-i18next";

const ApproveStockRequest = ({ type }) => {
  const {t} = useTranslation();
  const {
    setCurrentUser,
    saveAuth,
    setCurrentUsers,
    setCurrent,
    createIssueButtonDisabled,
    setCreateIssueButtonDisabled,
    currentUser,
  } = useAuth();
  const navigate = useNavigate();
  const company = useSelector((state: any) => state?.userConfig?.company);
  const headerRef: any = useRef(null);
  const [isAuthNeeded, setIsAuthNeeded] = useState(false);
  const currentDate = new Date(Date.now());
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const formattedDate = `${day}/${month}/${year}`;
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const formattedTime = `${hours}:${minutes}:${seconds}`;
  const [selectedData, setselectedData] = useState<any>([]);
  const [reqNumber, setreqNumber] = useState();
  const [userName, setUserName] = useState(currentUser!.sub.split("#")[1]);
  const [password, setPassword] = useState("");
  const [reauthoriseLoader, setReauthoriseLoader] = useState(false);
  const [rejectStock, setRejectStock] = useState(false);
  const [loader, setLoader] = useState<any>(true);
  const [loaderReject, setLoaderReject] = useState<any>(false);
  const [actionToBeCalled, setActionToBeCalled] = useState<any>(false);
  const reAuth = ApproveAuth.REAUTH;

  const currentUrl = new URL(window.location.href);
  const urlParams = new URLSearchParams(currentUrl.search);
  const isAuthScreen = urlParams.get("userId");
  urlParams.delete("userId");
  currentUrl.search = urlParams.toString();
  const newUrl = currentUrl.toString();
  window.history.replaceState({}, document.title, newUrl);
  const currentUrlToHide = window.location.href;
  const currentPath = window.location.pathname;
  const localStorageKeyHeaderFilter = `filters_${currentPath}_Header`;  // To Remove the header(section) 
  const localStorageKeyDetailsFilter = `filters_${currentPath}_Details`;       // To Remove the Details(section) 
  const localStorageKeyHeaderSort = `sorting${currentPath}_Header`;  
  const localStorageKeyDetailsSort = `sorting${currentPath}_Details`;       
  const location=useLocation();

useEffect(() => {
    // Clear local storage when navigating away from the component
    return () => {
      localStorage.removeItem(localStorageKeyHeaderFilter);
      localStorage.removeItem(localStorageKeyDetailsFilter);
      localStorage.removeItem(localStorageKeyHeaderSort);
      localStorage.removeItem(localStorageKeyDetailsSort);
    };
  }, [localStorageKeyHeaderFilter,localStorageKeyDetailsFilter,localStorageKeyHeaderSort,localStorageKeyDetailsSort, location]);
  useEffect(() => {
    const isApproveStockRequest = currentUrlToHide.includes(
      "approve-stock-request"
    );
    setIsAuthNeeded(isApproveStockRequest);
  }, [currentUrlToHide]);

  useEffect(() => {
    setCreateIssueButtonDisabled(true);
  }, [type]);

  useEffect(() => {
    if (isAuthScreen) {
      if (
        isAuthScreen?.toLowerCase() !==
        currentUser!.sub.split("#")[1]?.toLowerCase()
      ) {
        ClearStorage({
          saveAuth,
          setCurrentUser,
          setCurrentUsers,
          setCurrent,
        });
      }
    }
  }, [isAuthScreen]);

  const onClickAuthorize = () => {
    setActionToBeCalled("Authorize");
  };

  const onClickReject = () => {
    setActionToBeCalled("reject");
  };

  const onClickClose = () => {
    setActionToBeCalled("Close Unprocess");
  };

  const onClickIssueBatch = () => {
    setActionToBeCalled("Issue Batch");
  };

  const closeAuthModal = () => {
    setIsAuthNeeded(false);
    navigate(-1);
  };

  const callAuthModal = async () => {
    setReauthoriseLoader(true);
    try {
      const userData = {
        userName: userName,
        password: password,
      };
      const reauthoriseApiResponse = await reauthoriseStockRequest(userData);

      if (reauthoriseApiResponse?.data?.errorCode == 1) {
        toastMessage(reauthoriseApiResponse?.data?.errorMsg, "error");
        setReauthoriseLoader(false);
        setIsAuthNeeded(true);
      } else {
        toastMessage(reauthoriseApiResponse?.data?.successMsg, "success");
        setReauthoriseLoader(false);
        setIsAuthNeeded(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setIsAuthNeeded(true);
        setReauthoriseLoader(false);
      } else {
        setIsAuthNeeded(true);
      }
    }
  };

  const renderCard = (title, collapseId) => {
    return (
      <div
        ref={headerRef}
        className="accordion-button bg-white cursor-pointer "
        data-bs-toggle="collapse"
        data-bs-target={`#${collapseId}`} // Dynamically set target
        aria-expanded="true"
        aria-controls={collapseId} // Dynamically set controls
        onClick={(e) => e.stopPropagation()}
        style={{height: "32px"}}
      >
        <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-1">
          <div className="col-md-6 d-flex">
            <div className={clsx("", titleCss)} style={{ color: colors.title }}>
              {title}
            </div>
          </div>
        </div>
      </div>
    );
  };
  

  return (
    <>
      {(!isAuthNeeded || !reAuth) ? (
        <>
          {/* {isLoadingInitial && <CustomLoaders />} */}
          <div className="accordion mb-n5" id="accordionExample">
          <div className="accordion-item">
            <div className="px-4">
              {renderCard(t("Header"), "collapseOne")} {/* Pass unique ID for first accordion */}
              <div
                id="collapseOne"
                className="accordion-collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-n14">
                  <LookUpScreenForApproveStock
                    type={
                      type === "create-issue-batch"
                        ? "create-issue-batch"
                        : type === "approve-stock-request"
                        ? "approve-stock"
                        : "close-unprocessed-stock"
                    }
                    section={"Header"}
                    stockDetail={selectedData}
                    setStockDetail={setselectedData}
                    reqNumber={reqNumber}
                    setreqNumber={setreqNumber}
                    setLoader={setLoader}
                    loader={loader}
                    loaderReject={loaderReject}
                    setLoaderReject={setLoaderReject}
                    rejectStock={type !== "create-issue-batch" && rejectStock}
                    setRejectStock={
                      type !== "create-issue-batch" && setRejectStock
                    }
                    actionToBeCalled={actionToBeCalled}
                    setActionToBeCalled={setActionToBeCalled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion mt-10" id="accordionExample2">
          <div className="accordion-item">
            <div className="px-4">
              {renderCard(`${t("Detail")}-${reqNumber}`  , "collapseTwo")} 
              <div
                id="collapseTwo"
                className="accordion-collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample2" 
              >
                <div className="accordion-body mt-n13">
                  <LookUpScreenForApproveStock
                    type={
                      type === "create-issue-batch"
                        ? "create-issue-batch-detail"
                        : type === "approve-stock-request"
                        ? "approve-stock-detail"
                        : "close-unprocessed-stock-detail"
                    }
                    section="Details"
                    setStockDetail={setselectedData}
                    stockDetail={selectedData}
                    reqNumber={reqNumber}
                    setreqNumber={setreqNumber}
                    setLoader={setLoader}
                    loader={loader}
                    loaderReject={loaderReject}
                    setLoaderReject={setLoaderReject}
                    rejectStock={rejectStock}
                    setRejectStock={setRejectStock}
                    actionToBeCalled={""}
                    setActionToBeCalled={setActionToBeCalled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
          <ConditionalButtons
            type={type}
            onClickIssueBatch={onClickIssueBatch}
            generatePickupSlip={generatePickupSlip}
            loader={loader}
            selectedData={selectedData}
            createIssueButtonDisabled={createIssueButtonDisabled}
            onClickReject={onClickReject}
            onClickAuthorize={onClickAuthorize}
            loaderReject={loaderReject}
            company={company}
            formattedDate={formattedDate}
            formattedTime={formattedTime}
            onClickClose={onClickClose}
          />
        </>
      ) : (
        <UserEditModal
          content="approve_notification_modal"
          modalText={t("Authorization")}
          onCloseEditModal={closeAuthModal}
          onSave={callAuthModal}
          setUserName={setUserName}
          userName={userName}
          setPassword={setPassword}
          password={password}
          reauthoriseLoader={reauthoriseLoader}
          rejectModal={rejectStock}
          data={[]}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
    </>
  );
};

export default ApproveStockRequest;
