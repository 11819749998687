import loader from "../../../assets/erpAssets/loader.gif";

export default () => {
  return (
    <div
      className="ag-custom-loading-cell d-flex align-items-center justify-content-center w-100 text-dark fs-5"
      style={{ height: "72vh" }}
    >
      <img
        src={loader}
        alt="Loading..."
        style={{ height: "100px", width: "100px" }}
      />
    </div>
  );
};
