/** @format */

import clsx from "clsx";
import { useLayout } from "../../core";
import { Footer } from "./Footer";

const FooterWrapper = () => {
	const { config } = useLayout();
	if (!config.app?.footer?.display) {
		return null;
	}

	return (
		<div className="app-footer w-100 d-flex justify-content-center align-items-center align-self-center " id="kt_app_footer" >
			{config.app.footer.containerClass ? (
				<div className={clsx( "app-container", config.app.footer.containerClass, "w-100" )} >
					<Footer />
				</div>
			) : (
				<Footer />
			)}
		</div>
	);
};

export { FooterWrapper };
