import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useAuth } from "../../../auth";
import { toastMessage } from "../../../auth/functions/toastMessage";
import { changePassword, getRules, resetPassword } from "./core/_requests";
import { useTranslation } from "react-i18next";
const defaultPasswordRules = {
  "defaultExpiry": 5,
  "skipDays": 10,
  "minLength": 8,
  "maxLength": 20,
  "expiryDays": 90,
  "warningDays": 7,
  "capitalLetter": "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  "lowercaseLetter": "abcdefghijklmnopqrstuvwxyz",
  "number": "0123456789",
  "specialChar": "!@#$%^&*()[{}]",
  "lockingDuration": 30,
  "maxWrongAttempts": 5,
  "xexcludeChar": "~! _-+=`|\\(){}[]:;\"'<>,.?/"
};

const ChangePasswordPage: any = ({
  onCancel,
  user,
  userId = {},
  login = false,
  call,
}: any) => {
  const {t} = useTranslation();
  const { currentUser } = useAuth();
  const [error, setError] = useState(false);
  const changePasswordSchema = {
    oldPassword: Yup.string().required("Current Password is required"),
    newPassword: Yup.string()
    .required("New Password is required")
    .test(
      'passwords-different',
      'New password cannot be same as the old password',
      function (value) {
        return value !== this.parent.oldPassword;
      }
    ),

    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  };

  const [passwordRules, setPasswordRules] = useState<any>(defaultPasswordRules);
  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const fetchPasswordRules = async () => {
    try {
      const rules = await getRules();
      if (rules?.data) {
        const filteredRules = Object.keys(rules.data).reduce((acc, key) => {
          if (rules.data[key] !== null) {
            acc[key] = rules.data[key];
          } else {
            acc[key] = passwordRules[key];
          }
          return acc;
        }, {});
        setPasswordRules(filteredRules);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchPasswordRules();
  }, []);

  const toggleOldPasswordVisibility = () => {
    setOldPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  let modifiedValidationSchema = passwordRules
    ? {
        ...changePasswordSchema,
        newPassword: changePasswordSchema.newPassword

          .min(
            passwordRules.minLength,
            `Password must be at least ${passwordRules.minLength} characters`
          )
          .max(
            passwordRules.maxLength,
            `Password must be at most ${passwordRules.maxLength} characters`
          )
          .test(
            "uppercase",
            "Password must contain at least one uppercase letter",
            (value: any) => /[A-Z]/.test(value)
          )
          .test(
            "lowercase",
            "Password must contain at least one lowercase letter",
            (value: any) => /[a-z]/.test(value)
          )
          .test(
            "number",
            "Password must contain at least one number",
            (value: any) => /\d/.test(value)
          )
          .test(
            "special",
            "Password must contain at least one special character",
            (value: any) => /[!@#$%^&*(),.?":{}|<>]/.test(value)
          )
          .notOneOf(
            Array.from(passwordRules.xexcludeChar),
            "Password cannot contain certain special characters"
          ),
      }
    : changePasswordSchema;

  const [splitEmail, userName] = currentUser ? currentUser!.sub.split("#") : "";
  const PasswordRulesTooltip = () => {
    const [tooltipContent, setTooltipContent] = useState<any>("");
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const showTooltip = () => setTooltipVisible(true);
    const hideTooltip = () => setTooltipVisible(false);
    useEffect(() => {
      const content = `
        <div>
          Password Rules
          <ul>
            <li>Minimum length: ${passwordRules?.minLength}</li>
            <li>Maximum length: ${passwordRules?.maxLength}</li>
            <li>Include at least one uppercase letter: ${passwordRules?.capitalLetter}</li>
            <li>Include at least one lowercase letter: ${passwordRules?.lowercaseLetter}</li>
            <li>Include at least one number: ${passwordRules?.number}</li>
            <li>Include at least one special character: ${passwordRules?.specialChar}</li>
          </ul>
        </div>
      `;

      setTooltipContent(content);
    }, []);

    return (
      <span
        className="ms-2"
        style={{ cursor: "pointer" }}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        <i className="bi bi-info-circle"></i>
        {tooltipVisible && (
          <div
            style={{
              position: "absolute",
              zIndex: 100000,
              background: "#fff",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              marginRight: "20px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: tooltipContent }} />
          </div>
        )}
      </span>
    );
  };

  const handleSubmit = async (values, actions) => {
    try {
      let data;
      if (user !== "reset") {
        if (login) {
          data = await changePassword({
            ...values,
            userId: userName || userId,
            userEmail: "",
          });
          toastMessage(t("M.PASSWORD.CHANGE.SUCCESS"), "success");
          call();
          actions.setSubmitting(true);
        } else {
          data = await changePassword({
            ...values,
            userId: userName || userId,
          });
          actions.setSubmitting(true);
          toastMessage(t("M.PASSWORD.CHANGE.SUCCESS"), "success");
        }
      } else {
        data = await resetPassword({
          newPassword: values.newPassword,
          userId: userId,
        });
        toastMessage(t("M.PASSWORD.CHANGE.SUCCESS"), "success");
        actions.setSubmitting(true);
      }
      if (data?.data?.statusCodeValue === 403) {
        setError(true);
        actions.setSubmitting(true);
        return;
      }
      onCancel();
    } catch (error) {
      actions.setSubmitting(false);
      actions.setErrors({
        oldPassword: "Old password is incorrect",
      });
      // actions.isValid(true);
    }
  };
  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={Yup.object().shape(
        user === "reset"
          ? { ...modifiedValidationSchema, oldPassword: Yup.string() }
          : modifiedValidationSchema
      )}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
    >
      {({
        errors,
        touched,
        isSubmitting,
        isValid,
        setSubmitting,
        setFieldValue,
        setErrors,
      }) => (
        <Form>
          {user !== "reset" && (
            <>
              <div className=" mb-7" style={{ marginTop: "-10px" }}>
                <label className="required fw-bold fs-6 mb-2">
                  {" "}
                  {login ? "User Id" : "Email"}
                </label>
                <div
                  className="form-control form-control-light"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {login ? userId : splitEmail}
                </div>
              </div>
              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">
                  Old Password
                </label>
                <div style={{ position: "relative" }}>
                  <Field
                    type={oldPasswordType}
                    name="oldPassword"
                    className={clsx("form-control form-control-light", {
                      "is-invalid": touched.oldPassword && errors.oldPassword,
                    })}
                  />
                  <div
                    onClick={toggleOldPasswordVisibility}
                    style={{
                      position: "absolute",
                      right: 18,
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    <i
                      className={`bi ${
                        oldPasswordType === "password"
                          ? "bi-eye"
                          : "bi-eye-slash"
                      }`}
                    ></i>
                  </div>
                </div>
                {touched.oldPassword && errors.oldPassword && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{errors.oldPassword}</span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">
              New Password
              <PasswordRulesTooltip />
            </label>
            <div style={{ position: "relative" }}>
              <Field
                type={newPasswordType}
                name="newPassword"
                className={clsx("form-control form-control-light", {
                  "is-invalid": touched.newPassword && errors.newPassword,
                })}
              />
              <div
                onClick={toggleNewPasswordVisibility}
                style={{
                  position: "absolute",
                  right: 18,
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                <i
                  className={`bi ${
                    newPasswordType === "password" ? "bi-eye" : "bi-eye-slash"
                  }`}
                ></i>
              </div>
            </div>
            {touched.newPassword && errors.newPassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{errors.newPassword}</span>
                </div>
              </div>
            )}
          </div>

          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">
              Confirm Password
            </label>
            <div style={{ position: "relative" }}>
              <Field
                type={confirmPasswordType}
                name="confirmPassword"
                className={clsx("form-control form-control-light", {
                  "is-invalid":
                    touched.confirmPassword && errors.confirmPassword,
                })}
              />
              <div
                onClick={toggleConfirmPasswordVisibility}
                style={{
                  position: "absolute",
                  right: 18,
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                <i
                  className={`bi ${
                    confirmPasswordType === "password"
                      ? "bi-eye"
                      : "bi-eye-slash"
                  }`}
                ></i>
              </div>
            </div>
            {touched.confirmPassword && errors.confirmPassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-secondary rounded-pill mx-2"
              onClick={onCancel}
              title="Cancel"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn text-white rounded-pill mx-2"
              title="Save"
              style={{ backgroundColor: "#0a7eac" }}
              data-kt-users-modal-action="submit"
              disabled={isSubmitting || !isValid}
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordPage;
