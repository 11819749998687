export const functionsData = [
  { id: 1, label: "Rename columns" },
  { id: 2, label: "Show Grouping Panel" },
  { id: 3, label: "Show Search Panel" },
  // { id: 4, label: "Show Summary Footer" },
  // { id: 5, label: "Show Group Footer" },
  { id: 6, label: "Show Filtering" },
  // { id: 7, label: "Hide All columns" },
  // { id: 8, label: "Enable Multi-Line Selection" },
  { id: 9, label: "Customize Numeric Formatting" },
  { id: 10, label: "Save Customized Layout" },
  { id: 12, label: "Restore Customized Layout" },
  { id: 11, label: "Restore Default Layout" },
];
