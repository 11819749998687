import sortalphadownicon from "../../../../src/assets/sortalphadown.png";
import onenineicon from "../../../../src/assets//sortnumericdown.png";

export const createIssueBatchColumnsConfig = [
  { value: "inreqd.LINE_NO", label: "Line No", type: "number" },
  { value: "inreqd.STOCK", label: "Stock Code", type: "text" },
  { value: "inreqd.LOCATION", label: "Location", type: "text" },
  { value: "inreqd.[DESC]", label: "Description", type: "text" },
  { value: "inreqd.REQ_QTY", label: "Available Qty", type: "number" },
  { value: "inreqd.REQ_QTY", label: "Req Qty", type: "number" },
  { value: "inreqd.BASE_EST", label: "Rate", type: "number" },
  { value: "inreqd.BASE_AMT", label: "Amount", type: "number" },
  { value: "inreqd.JOB_CODE", label: "Job Code", type: "text" },
  { value: "inreqd.MK_WONO", label: "Work Order No", type: "text" },
  { value: "inreqd.GL_ACCOUNT", label: "GL Account", type: "text" },
];

export const closeUnprocessedDetailFilterColumnConfig = [
  { value: "inreqd.LINE_NO", label: "Line No", type: "number" },
  { value: "inreqd.STOCK", label: "Stock Code", type: "text" },
  { value: "inreqd.LOCATION", label: "Location", type: "text" },
  { value: "inreqd.[DESC]", label: "Description", type: "text" },
  { value: "inreqd.REQ_QTY", label: "Req Qty", type: "number" },
  { value: "inreqd.BASE_EST", label: "Rate", type: "number" },
  { value: "inreqd.BASE_AMT", label: "Amount", type: "number" },
  { value: "inreqd.JOB_CODE", label: "Job Code", type: "text" },
  { value: "inreqd.MK_WONO", label: "Work Order No", type: "text" },
  { value: "inreqd.GL_ACCOUNT", label: "GL Account", type: "text" },

];
export const approvestockdetailConfig = [
  { value: "inreqd.LINE_NO", label: "Line No", type: "number" },
  { value: "inreqd.STOCK", label: "Stock Code", type: "text" },
  { value: "inreqd.LOCATION", label: "Location", type: "text" },
  { value: "inreqd.[DESC]", label: "Description", type: "text" },
  { value: "inreqd.REQ_QTY", label: "Req Qty", type: "number" },
  { value: "inreqd.BASE_EST", label: "Rate", type: "number" },
  { value: "inreqd.BASE_AMT", label: "Amount", type: "number" },
  { value: "inreqd.JOB_CODE", label: "Job Code", type: "text" },
  { value: "inreqd.MK_WONO", label: "Work Order No", type: "text" },
  { value: "inreqd.GL_ACCOUNT", label: "GL Account", type: "text" },
];

export const approvestockFilterConfig = [
  { value: "inreqh.REQ", label: "Request No", type: "text" },
  { value: "inreqh.REQ_DATE", label: "Request Date", type: "date" },
  { value: "inreqh.NOTES", label: "Notes", type: "text" },
  { value: "inreqh.MULTI_EXPT", label: "Amount", type: "number" },
  { value: "rq.[DESC]", label: "Priority", type: "text" },
  { value: "inlk.[DESC]", label: "Status", type: "text" },
  { value: "inreqh.ORG_AUTHID", label: "Requested By", type: "text" },
  { value: "inreqh.NXT_AUTHID", label: "Next Approver", type: "text" },
  { value: "inreqh.LST_AUTHID", label: "Last Approver", type: "text" },
  { value: "inreqh.RQH_DESC", label: "Instruction", type: "text" },
  { value: "inreqh.CONTACT", label: "Contact Person", type: "text" },
  { value: "inreqh.AUTHORIZE", label: "Auth Group", type: "text" },
  { value: "inreqh.AUTH_HIST", label: "Auth.History", type: "text" },
];
export const closeUnprocessedFilterColumnConfig = [
  { value: "inreqh.REQ", label: "Request No", type: "text" },
  { value: "inreqh.REQ_DATE", label: "Request Date", type: "date" },
  { value: "inreqh.NOTES", label: "Notes", type: "text" },
  { value: "inreqh.MULTI_EXPT", label: "Amount", type: "number" },
  { value: "rq.[DESC]", label: "Priority", type: "text" },
  { value: "inlk.[DESC]", label: "Status", type: "text" },
  { value: "inreqh.ORG_AUTHID", label: "Requested By", type: "text" },
  { value: "inreqh.NXT_AUTHID", label: "Next Approver", type: "text" },
  { value: "inreqh.LST_AUTHID", label: "Last Approver", type: "text" },
  { value: "inreqh.RQH_DESC", label: "Instruction", type: "text" },
  { value: "inreqh.CONTACT", label: "Contact Person", type: "text" },
  { value: "inreqh.AUTHORIZE", label: "Auth Group", type: "text" },
  { value: "inreqh.AUTH_HIST", label: "Auth.History", type: "text" },
];

export const createIssueBatchFilterColumnsConfig = [
  { value: "inreqh.REQ", label: "Request No", type: "text" },
  { value: "inreqh.REQ_DATE", label: "Request Date", type: "date" },
  { value: "inreqh.NOTES", label: "Notes", type: "text" },
  { value: "inreqh.MULTI_EXPT", label: "Amount", type: "number" },
  { value: "rq.[DESC]", label: "Priority", type: "text" },
  { value: "inlk.[DESC]", label: "Status", type: "text" },
  { value: "inreqh.ORG_AUTHID", label: "Requested By", type: "text" },
  { value: "inreqh.NXT_AUTHID", label: "Next Approver", type: "text" },
  { value: "inreqh.LST_AUTHID", label: "Last Approver", type: "text" },
  { value: "inreqh.RQH_DESC", label: "Instruction", type: "text" },
  { value: "inreqh.CONTACT", label: "Contact Person", type: "text" },
  { value: "inreqh.AUTHORIZE", label: "Auth Group", type: "text" },
  { value: "inreqh.AUTH_HIST", label: "Auth.History", type: "text" },
];
export const otherColumnsConfig = [
  { value: "inreqh.REQ", label: "Request No", type: "text" },
  { value: "inreqh.REQ_DATE", label: "Request Date", type: "date" },
  { value: "rq.[DESC]", label: "Priority", type: "text" },
  { value: "inreqh.RQH_DESC", label: "Instruction", type: "text" },
  { value: "inreqd.BASE_AMT", label: "Amount", type: "number" },
  { value: "inreqh.ORG_AUTHID", label: "Requested By", type: "text" },
  { value: "inreqh.AUTHORIZE", label: "Auth Group", type: "text" },
  { value: "inreqh.LST_AUTHID", label: "Last Approver", type: "text" },
  { value: "inreqh.NXT_AUTHID", label: "Authorized By", type: "text" },
  { value: "inreqh.CONTACT", label: "Contact Person", type: "text" },
  { value: "inreqh.NOTES", label: "Notes", type: "text" },
  { value: "inreqh.REQ_NOTES", label: "Req.Notes", type: "text" },
  { value: "inreqh.AUTH_HIST", label: "Auth.History", type: "text" },
];



const generateColumn = (id, col1, col2, col3, column, col4, iconIndex) => ({
  id,
  col1,
  col2,
  col3,
  column,
  col4,
  iconIndex,
});

const sortalphadown = sortalphadownicon;
const onenine = onenineicon;

export const approveColumnsConfig = [
  generateColumn("1", "⋮⋮⋮", false, "Request No", "inreqh.reqNo", sortalphadown, 0),
  generateColumn("2", "⋮⋮⋮", false, "Request Date", "inreqh.reqDate", onenine, 2),
  generateColumn("4", "⋮⋮⋮", false, "Amount", "inreqh.amount", onenine, 2),   
  generateColumn("5", "⋮⋮⋮", false, "Priority", "rq.[DESC]", sortalphadown, 0),
  generateColumn("6", "⋮⋮⋮", false, "Status", "inlk.[DESC]", sortalphadown, 0),
  generateColumn("7", "⋮⋮⋮", false, "Requested By", "inreqh.reqById", sortalphadown, 0),
  generateColumn("8", "⋮⋮⋮", false, "Next Approver", "inreqh.authById", sortalphadown, 0),
  generateColumn("9", "⋮⋮⋮", false, "Last Approver", "inreqh.lastAuthBy", sortalphadown, 0),
  generateColumn("10", "⋮⋮⋮", false, "Instruction", "inreqh.instructions", sortalphadown, 0),
  generateColumn("11", "⋮⋮⋮", false, "Contact Person", "inreqh.contact", sortalphadown, 0),
  generateColumn("12", "⋮⋮⋮", false, "Auth Group", "inreqh.authGroup", sortalphadown, 0)
];

export const createIssueColumnsConfig = [
  generateColumn("1", "⋮⋮⋮", false, "Request No", "inreqh.reqNo", sortalphadown, 0),
  generateColumn("2", "⋮⋮⋮", false, "Request Date", "inreqh.reqDate", onenine, 2),
  generateColumn("4", "⋮⋮⋮", false, "Amount", "inreqh.amount", onenine, 2),   
  generateColumn("5", "⋮⋮⋮", false, "Priority", "rq.[DESC]", sortalphadown, 0),
  generateColumn("6", "⋮⋮⋮", false, "Status", "inlk.[DESC]", sortalphadown, 0),
  generateColumn("7", "⋮⋮⋮", false, "Requested By", "inreqh.reqById", sortalphadown, 0),
  generateColumn("8", "⋮⋮⋮", false, "Next Approver", "inreqh.authById", sortalphadown, 0),
  generateColumn("9", "⋮⋮⋮", false, "Last Approver", "inreqh.lastAuthBy", sortalphadown, 0),
  generateColumn("10", "⋮⋮⋮", false, "Instruction", "inreqh.instructions", sortalphadown, 0),
  generateColumn("11", "⋮⋮⋮", false, "Contact Person", "inreqh.contact", sortalphadown, 0),
  generateColumn("12", "⋮⋮⋮", false, "Auth Group", "inreqh.authGroup", sortalphadown, 0)    
];

export const closeUnprocessedColumnConfig = [
  generateColumn("1", "⋮⋮⋮", false, "Request No", "inreqh.reqNo", sortalphadown, 0),
  generateColumn("2", "⋮⋮⋮", false, "Request Date", "inreqh.reqDate", onenine, 2),
  generateColumn("4", "⋮⋮⋮", false, "Amount", "inreqh.amount", onenine, 2),   
  generateColumn("5", "⋮⋮⋮", false, "Priority", "rq.[DESC]", sortalphadown, 0),
  generateColumn("6", "⋮⋮⋮", false, "Status", "inlk.[DESC]", sortalphadown, 0),
  generateColumn("7", "⋮⋮⋮", false, "Requested By", "inreqh.reqById", sortalphadown, 0),
  generateColumn("8", "⋮⋮⋮", false, "Next Approver", "inreqh.authById", sortalphadown, 0),
  generateColumn("9", "⋮⋮⋮", false, "Last Approver", "inreqh.lastAuthBy", sortalphadown, 0),
  generateColumn("10", "⋮⋮⋮", false, "Instruction", "inreqh.instructions", sortalphadown, 0),
  generateColumn("11", "⋮⋮⋮", false, "Contact Person", "inreqh.contact", sortalphadown, 0),
  generateColumn("12", "⋮⋮⋮", false, "Auth Group", "inreqh.authGroup", sortalphadown, 0)
];


export const approveDetailColumnsConfig = [
  generateColumn("1", "⋮⋮⋮", false, "Line No", "inreqd.LINE_NO", onenine, 2),
  generateColumn("2", "⋮⋮⋮", false, "Stock Code", "inreqd.STOCK", sortalphadown, 0),
  generateColumn("3", "⋮⋮⋮", false, "Location", "inreqd.LOCATION", sortalphadown, 0),
  // generateColumn("4", "⋮⋮⋮", false, "Description", "CONVERT(inreqd.[DESC], SQL_CHAR)", sortalphadown, 0),
  generateColumn("6", "⋮⋮⋮", false, "Req Qty", "inreqd.REQ_QTY", onenine, 2),
  generateColumn("7", "⋮⋮⋮", false, "Rate", "inreqd.BASE_EST", onenine, 2),
  generateColumn("8", "⋮⋮⋮", false, "Amount", "inreqd.BASE_AMT", onenine, 2),
  generateColumn("9", "⋮⋮⋮", false, "Job Code", "inreqd.JOB_CODE", sortalphadown, 0),
  generateColumn("10", "⋮⋮⋮", false, "Work Order No", "inreqd.MK_WONO", sortalphadown, 0),
  generateColumn("11", "⋮⋮⋮", false, "GL Account", "inreqd.GL_ACCOUNT", sortalphadown, 0)
];

export const createIssueDetailColumnsConfig = [
  generateColumn("1", "⋮⋮⋮", false, "Line No", "inreqd.LINE_NO", onenine, 2),
  generateColumn("2", "⋮⋮⋮", false, "Stock Code", "inreqd.STOCK", sortalphadown, 0),
  generateColumn("3", "⋮⋮⋮", false, "Location", "inreqd.LOCATION", sortalphadown, 0),
  generateColumn("4", "⋮⋮⋮", false, "Description", "CONVERT(inreqd.[DESC], SQL_CHAR)", sortalphadown, 0),
  generateColumn("5", "⋮⋮⋮", false, "Available Qty", "inreqd.REQ_QTY", onenine, 2),
  generateColumn("6", "⋮⋮⋮", false, "Req Qty", "inreqd.REQ_QTY", onenine, 2),
  generateColumn("7", "⋮⋮⋮", false, "Rate", "inreqd.BASE_EST", onenine, 2),
  generateColumn("8", "⋮⋮⋮", false, "Amount", "inreqd.BASE_AMT", onenine, 2),
  generateColumn("9", "⋮⋮⋮", false, "Job Code", "inreqd.JOB_CODE", sortalphadown, 0),
  generateColumn("10", "⋮⋮⋮", false, "Work Order No", "inreqd.MK_WONO", sortalphadown, 0),
  generateColumn("11", "⋮⋮⋮", false, "GL Account", "inreqd.GL_ACCOUNT", sortalphadown, 0),
];


export const closeUnprocessedDetailColumnConfig = [
  generateColumn("1", "⋮⋮⋮", false, "Line No", "inreqd.LINE_NO", onenine, 2),
  generateColumn("2", "⋮⋮⋮", false, "Stock Code", "inreqd.STOCK", sortalphadown, 0),
  generateColumn("3", "⋮⋮⋮", false, "Location", "inreqd.LOCATION", sortalphadown, 0),
  generateColumn("4", "⋮⋮⋮", false, "Description", "CONVERT(inreqd.[DESC], SQL_CHAR)", sortalphadown, 0),
  generateColumn("6", "⋮⋮⋮", false, "Req Qty", "inreqd.REQ_QTY", onenine, 2),
  generateColumn("7", "⋮⋮⋮", false, "Rate", "inreqd.BASE_EST", onenine, 2),
  generateColumn("8", "⋮⋮⋮", false, "Amount", "inreqd.BASE_AMT", onenine, 2),
  generateColumn("9", "⋮⋮⋮", false, "Job Code", "inreqd.JOB_CODE", sortalphadown, 0),
  generateColumn("10", "⋮⋮⋮", false, "Work Order No", "inreqd.MK_WONO", sortalphadown, 0),
  generateColumn("11", "⋮⋮⋮", false, "GL Account", "inreqd.GL_ACCOUNT", sortalphadown, 0),
];

export const approveStockColdefConfig = [
  {
    columnName: "Request No",
    caption: "Request No",
    field: "requestNo",
    width: 170
  },
  {
    columnName: "Request Date",
    caption: "Request Date",
    field: "date",
    width: 140,
    columnType: "Date"
  },
  {
    columnName: "Notes",
    caption: "Notes",
    field: "notes",
    width: 100
  },
  {
    columnName: "Amount",
    caption: "Amount",
    field: "totalAmount",
    alignment: "right", 
    width: 120,
    columnType: "Numeric"
    
  },
  {
    columnName: "Priority",
    caption: "Priority",
    field: "priority",
    width: 100
    
  },
  {
    columnName: "Status",
    caption: "Status",
    field: "status",
    width: 110
  },
  {
    columnName: "Requested By",
    caption: "Requested By",
    field: "requestedBy",
    width: 130
  },
  {
    columnName: "Next Approver",
    caption: "Next Approver",
    field: "authorizeBy",
    width: 130
  },
  {
    columnName: "Last Approver",
    caption: "Last Approver",
    field: "lastAuthorizeBy",
    width: 130
  },
  {
    columnName: "Instruction",
    caption: "Instruction",
    field: "instructions",
    width: 150
  },
  {
    columnName: "Contact Person",
    caption: "Contact Person",
    field: "contactPerson",
    width:150
  },

  {
    columnName: "Auth Group",
    caption: "Auth Group",
    field: "authGroup",
    width: 130
  },
  {
    columnName: "Auth.History",
    caption: "Auth.History",
    field: "authHistory",
    width: 150
  },
];

export const approveStockDetailsColdefConfig = [
  {
    columnName: "Line No",
    caption: "Line No",
    field: "lineNo",
    // valueGetter: "node.rowIndex + 1",      //need to check
    width: 100
  },
  {
    columnName: "Stock Code",
    caption: "Stock Code",
    field: "code",
    width: 130
  },
  {
    columnName: "Location",
    caption: "Location",
    field: "location",
    width: 110
  },
  {
    columnName: "Description",
    caption: "Description",
    field: "description",
    width: 300,
  
  },
  {
    columnName: "Req.Qty",
    caption: "Req.Qty",
    field: "requiredQty",
    width: 120,
    alignment:"right",
    columnType: "Numeric"
  },
  {
    columnName: "Rate",
    caption: "Rate",
    field: "unitCost",
    width: 100,
    alignment:"right",
    columnType: "Numeric"
  
  },
  {
    columnName: "Amount",
    caption: "Amount",
    field: "amount",
    filter: "agNumberColumnFilter",
    width: 120,
    alignment:"right",
    columnType: "Numeric"
  },
  

  {
    columnName: "Job Code",
    caption: "Job Code",
    field: "jobCode",
    filter: "agTextColumnFilter",
    width: 160,
    valueGetter: (params) =>
      `${params?.jobCode}.${params?.phaseCode}.${params?.reqCode}`,  //need to check
  },
  {
    columnName: "Work Order No",
    caption: "Work Order No",
    field: "workOrderNo",
    width: 120
  },
  {
    columnName: "GL Account",
    caption: "GL Account",
    field: "glAccount",
    width: 120
  },
];

export const closeUnprocesStockColdefConfig = [
  {
    columnName: "Request No",
    caption: "Request No",
    field: "requestNo",
    width: 170,
    rowDrag: true
    // cellRenderer: "iconComponent",    //need to check

  },
  {
    columnName: "Request Date",
    caption: "Request Date",
    field: "date",
    width: 140,
    columnType:"Date"
  },
  {
    columnName: "Notes",
    caption: "Notes",
    field: "notes",
    width: 100,
  },
  {
    columnName: "Amount",
    caption: "Amount",
    field: "totalAmount",
    alignment:"right",
    width: 120,
    columnType:"Numeric"
  },
  {
    columnName: "Priority",
    caption: "Priority",
    field: "priority",
    width: 100
  },
  {
    columnName: "Status",
    caption: "Status",
    field: "status",
    width: 160
  },
  {
    columnName: "Requested By",
    caption: "Requested By",
    field: "requestedBy",
    width: 130
  },
  {
    columnName: "Next Approver",
    caption: "Next Approver",
    field: "authorizeBy",
    width: 130
  },
  {
    columnName: "Last Approver",
    caption: "Last Approver",
    field: "lastAuthorizeBy",
    width: 130
  },
  {
    columnName: "Instruction",
    caption: "Instruction",
    field: "instructions",
    width: 150
  },
  {
    columnName: "Contact Person",
    caption: "Contact Person",
    field: "contactPerson",
    width:150
  },
  {
    columnName: "Auth Group",
    caption: "Auth Group",
    field: "authGroup",
    width: 130
  },
  {
    columnName: "Auth.History",
    caption: "Auth.History",
    field: "authHistory",
    width: 600
  },
];

export const closeUnprocesStockDetailsColdefConfig = [
  {
    columnName: "Line No",
    caption: "Line No",
    field: "lineNo",
    width: 100
  },
  {
    columnName: "Stock Code",
    caption: "Stock Code",
    field: "code",
    width: 130
  },
  {
    columnName: "Location",
    caption: "Location",
    field: "location",
    width: 130,
  },
  {
    columnName: "Description",
    caption: "Description",
    field: "description",
    width: 250,
  },
  {
    columnName: "Req Qty",
    caption: "Req Qty",
    field: "requiredQty",
    width: 120,
    alignment:"right",
    columnType:"Numeric"
  },
  {
    columnName: "Rate",
    caption: "Rate",
    field: "unitCost",
    width: 100,
    alignment:"right",
    columnType:"Numeric"
  },
  {
    columnName: "Amount",
    caption: "Amount",
    field: "amount",
    width: 120,
    alignment:"right",
    columnType:"Numeric"
   
  },
  {
    columnName: "Job Code",
    caption: "Job Code",
    field: "jobCode",
    width: 160,
    valueGetter: (params) =>{
      return `${params?.jobCode}.${params?.phaseCode}.${params?.reqCode}`;  //need to check
    }
    },
  {
    columnName: "Work Order No",
    caption: "Work Order No",
    field: "workOrderNo",
    width: 120,
  },
  {
    columnName: "GL Account",
    caption: "GL Account",
    field: "glAccount",
    width: 120,
  },
  
];
export const createIssueBatchColdefConfig = [
  {
    columnName: "Request No",
    caption: "Request No",
    field: "requestNo",
    filter: "agTextColumnFilter",
    width: 170
    // cellRenderer: "iconComponent",  //need to check
  },
  {
    columnName: "Request Date",
    caption: "Request Date",
    field: "date",
    width: 140,
    columnType:"Date"
  },
  {
    columnName: "Notes",
    caption: "Notes",
    field: "notes",
    width:100
  },
  {
    columnName: "Amount",
    caption: "Amount",
    field: "totalAmount",
    width: 120,
    alignment: 'right',
    columnType: "Numeric"
    
  },
  {
    columnName: "Priority",
    caption: "Priority",
    field: "priority",
    width: 100
  },
  {
    columnName: "Status",
    caption: "Status",
    field: "status",
    width: 140
  },
  {
    columnName: "Requested By",
    caption: "Requested By",
    field: "requestedBy",
    width: 130
  },
  {
    columnName: "Next Approver",
    caption: "Next Approver",
    field: "authorizeBy",
    width: 130
  },
  {
    columnName: "Last Approver",
    caption: "Last Approver",
    field: "lastAuthorizeBy",
    width: 130
  },
  {
    columnName: "Instruction",
    caption: "Instruction",
    field: "instructions",
    width: 150
  },
  {
    columnName: "Contact Person",
    caption: "Contact Person",
    field: "contactPerson",
    width:150
  },
  {
    columnName: "Auth Group",
    caption: "Auth Group",
    field: "authGroup",
    width: 130
  },
  {
    columnName: "Auth.History",
    caption: "Auth.History",
    field: "authHistory",
    width: 500
  },
];

export const createIssueBatchDetailsColdefConfig = [

  {
    columnName: "Stock Code",
    caption: "Stock Code",
    field: "code",
    width: 130
  },
  {
    columnName: "Location",
    caption: "Location",
    field: "location",
    width: 120
  },
  {
    columnName: "Description",
    caption: "Description",
    field: "description",
    width: 250
  },
  {
    columnName: "Available Qty",
    caption: "Available Qty",
    field: "availableQty",
    width: 140,
    columnType:"Numeric",
    alignment:"right"

  },
  {
    columnName: "Req Qty",
    caption: "Req Qty",
    field: "requiredQty",
    filter: "agNumberColumnFilter",
    width: 120,
    columnType:"Numeric",
    alignment:"right"
   
  },
  {
    columnName: "Rate",
    caption: "Rate",
    field: "unitCost",
    filter: "agNumberColumnFilter",
    type:"rightAligned",
    width: 110,
    columnType:"Numeric",
    alignment:"right"
  },
  {
    columnName: "Amount",
    caption: "Amount",
    field: "amount",
    width: 120,
    columnType:"Numeric",
    alignment:"right"
  },
  {
    columnName: "Job Code",
    caption: "Job Code",
    field: "jobNumber",
    width: 160,
    valueGetter: (params) =>
      `${params?.jobCode}.${params?.phaseCode}.${params?.reqCode}`,
  },
  {
    columnName: "Work Order No",
    caption: "Work Order No",
    field: "workOrderNo",
    width: 160,
  },
  {
    columnName: "GL Account",
    caption: "GL Account",
    field: "glAccount",
    width: 160
  },
  
 
];
