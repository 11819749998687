/**
 * /* eslint-disable react/jsx-no-target-blank
 *
 * @format
 */

import { useEffect } from "react";
import { ILayout, useLayout } from "../../core";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { config } = useLayout();
  const {t}=useTranslation();
  useEffect(() => {
    updateDOM(config);
  }, [config]);


  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="d-flex justify-content-between align-items-center w-100 ">
        <div className="text-dark ">
          <span className="text-muted fw-semibold me-1">
            {currentYear}&copy;
          </span>
          <a
            href="https://classicinfo.com.au/"
            target="_blank"
            className="text-gray-800 text-hover-primary"
          >
            <b>Classic Information Systems</b>
          </a>
        </div>
        <div>
          <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
            <li className="menu-item">
              <a
                href="https://classicinfo.com.au/"
                target="_blank"
                className="menu-link px-2"
              >
                {t("About")}
              </a>
            </li>

            <li className="menu-item">
              <a
                href="https://classicinfo.com.au/"
                target="_blank"
                className="menu-link px-2"
              >
                {t("Support")}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add("data-kt-app-footer-fixed", "true");
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add("data-kt-app-footer-fixed-mobile", "true");
  }
};

export { Footer };
