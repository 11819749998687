// store.js
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore,FLUSH,REHYDRATE,PAUSE,PERSIST,PURGE,REGISTER, } from "redux-persist";
import storage from "redux-persist/lib/storage";

import indexReducer from "../reducers/indexReducer";
import lookupReducer from "../reducers/lookupReducer";
import refreshApiReducer from "../reducers/refreshApiState";
import lineItemsDetailsReducer from "../reducers/lineItemsDetails";
import PoAndStockReducer from "../reducers/PoAndStockReducer";
import LayoutReducer from "../reducers/LayoutReducer";
import UserConfigReducer from "../reducers/UserConfigReducer";
import menuReducer from "../reducers/menuReducer";

const rootReducer = combineReducers({
  currentIndex: indexReducer,
  lookupData: lookupReducer,
  refreshState: refreshApiReducer,
  lineItemDetails: lineItemsDetailsReducer,
  poAndStock: PoAndStockReducer,
  layout:LayoutReducer,
  userConfig:UserConfigReducer,
  menuConfig:menuReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
