import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../user/UsersTable.css";
import {
  deletestock,
  getStockRequestList,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { useLocation, useNavigate } from "react-router-dom";
import { KTCard } from "../../../theme/helpers";
import ActionPopupDev from "../user/ActionPopupDev";
import pdf from "../../../assets/pdf.png";
import pending from "../../../assets/Data Pending.png";
import Invite from "../../../assets/Invite.png";
import CancelOrder from "../../../assets/CancelOrder.png";
import GoodQuality from "../../../assets/GoodQuality.png";
import TotalSales from "../../../assets/TotalSales.png";
import excel from "../../../assets/excel.png";
import AdvancedFilterPopup from "../../modules/widgets/components/Filterpopup";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import sortalphadown from "../../../../src/assets/sortalphadown.png";
import onenine from "../../../../src/assets//sortnumericdown.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useAuth } from "../../modules/auth";
import imgData from "../../../assets/logo.png";
import { DeleteModal } from "../../modules/widgets/components/DeleteModal";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import CustomLoadingCellRender from "../user/CustomLoadingCellRender";
import {
  formatDate,
  formatDatesinArray,
} from "../../../theme/assets/ts/_utils/FormatData";
import { exportDataToExcel } from "../../../theme/assets/ts/_utils/ExportXLSX";
import { useSelector } from "react-redux";
import {
  formatDateToDDMMYYY,
} from "../../../theme/assets/ts/_utils/FormatData";
import { useTranslation } from "react-i18next";
import DataGridWrapper from "../../utils/DataGrid/DataGridWrapper";
import SearchInputDev from "../../utils/SearchInputDev";
import FilteredRowOFDataGrid from "../../utils/FilteredRowOFDataGrid";
import ValueFormatter from "../../utils/ValueFormatter";

const StockTableDev = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const company = useSelector((state: any) => state?.userConfig?.company);
  const companyName = useSelector((state: any) => state?.userConfig?.companyName);
  const { currentUser, setPageStatus, deleteid, setDeleteid } = useAuth();
  const [status, setStatus] = useState<any>("");
  const [trackLoadingState, setTrackLoadingState] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [sortData, setSortData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isadvanceShort, setIsadvanceShort] = useState(false);
  const [gridApi, setGridApi] = useState<any>(null);
  const [showExportModal, setShowExportModal] = useState<any>(null);
  const [showExportModalExcel, setShowExportModalExcel] = useState<any>(null);
  const storedRequestNo = localStorage.getItem("deleteRequestNo");
  const [searchTerm, setSearchTerm] = useState("");
  let [userEmail, userId, userName]: any = currentUser?.sub?.split("#");
  const [overAllRequestSummary,setOverAllRequestSummary]=useState<any>();
  const currentPath = window.location.pathname;
  const localStorageKeyFilter = `filters_${currentPath}`;
  const localStorageKeySort=`sorting${currentPath}`
  const location=useLocation();
  const [exportAllData,setExportAllData]=useState<any>();

  useEffect(() => {
      // Clear local storage when navigating away from the component
      return () => {
        localStorage.removeItem(localStorageKeyFilter);
        localStorage.removeItem(localStorageKeySort);
      };
    }, [localStorageKeyFilter,localStorageKeySort, location]);

  const extractValueBetweenHash = () => {
    const regex = /#(.*?)#/;
    const match = currentUser?.sub?.match(regex);
    if (match) {
      return match[1];
    }
    return null;
  };

  const currentUserName = extractValueBetweenHash() || "";


  const fileName = "stock_listing";

  const columnOptions = [
    { value: "inreqh.REQ", label: "Request No", type: "text" },
    { value: "inreqh.REQ_DATE", label: "Request Date", type: "date" },
    { value: "inreqh.NOTES", label: "Notes", type: "text" },
    { value: "inreqh.MULTI_EXPT", label: "Amount", type: "number" },
    { value: "rq.[DESC]", label: "Priority", type: "text" },
    { value: "inlk.[DESC]", label: "Status", type: "text" },
    { value: "inreqh.ORG_AUTHID", label: "Requested By", type: "text" },
    { value: "inreqh.NXT_AUTHID", label: "Next Approver", type: "text" },
    { value: "inreqh.LST_AUTHID", label: "Last Approver", type: "text" },
    { value: "inreqh.CONTACT", label: "Contact", type: "text" },
  ];

  const fetchApi=async(calledFor)=>{
    setTrackLoadingState(true);
    try {
      const response=await getStockRequestList(
        currentUserName,
        company,
        { advancedFilters: filterData, sortedList: sortData },
        searchTerm,
        status
      );
      if(response.data){
        setRowData(formatDatesinArray(response?.data?.stockRequestListingData));
          if(!overAllRequestSummary||calledFor==="delete"){            // this will update the request summary if stock is deleted
            setOverAllRequestSummary(response?.data?.overAllRequestSummary);
          }
          if(filterData.length===0){
            setExportAllData(response?.data?.stockRequestListingData);
          }
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
    
  }

  useEffect(() => {
    localStorage.removeItem("headerDataOfStock");
    localStorage.removeItem("requestNo");
  }, []);


  const closeChangePasswordModal = (event) => {
    setIsadvanceShort(false);
  };


  useEffect(() => {
    fetchApi("default");
  }, [filterData, status, sortData]);

  const columnForSort = [
    {
      id: "1",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Request No",
      column: "inreqh.reqNo",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "2",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Request Date",
      column: " inreqh.reqDate",
      col4: onenine,
      iconIndex: 2,
    },
    // {
    //   id: "3",
    //   col1: "⋮⋮⋮",
    //   col2: false,
    //   col3: "Notes",
    //   column: "inlk.[DESC]",
    //   col4: sortalphadown,
    //   iconIndex: 0,
    // },
    {
      id: "4",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Amount",
      column: "inreqh.amount",
      col4: onenine,
      iconIndex: 2,
    },
    {
      id: "5",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Priority",
      column: "rq.[DESC]",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "6",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Status",
      column: "inlk.[DESC]",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "7",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Requested By",
      column: "inreqh.reqById",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "8",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Next Approver",
      column: "inreqh.authById",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "9",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Last Approver",
      column: "inreqh.lastAuthBy",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "10",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Contact",
      column: "inreqh.contact",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "11",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Auth Group",
      column: "inreqh.authGroup",
      col4: sortalphadown,
      iconIndex: 0,
    }
  ];

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      columnName: "Request No",
      caption: "Request No",
      field: "requestNo",
      width: 150,
      alignment:"left",
      columnType: "Char",

    },
    {
      columnName: "Request Date",
      caption: "Request Date",
      field: "requestDate",
      width: 150,
      alignment:"left",
      columnType: "Date",
    },
    {
      columnName: "Notes",
      caption: "Notes",
      field: "notes",
      width: 150,
      alignment:"left",
      columnType: "Char",
    },
    {
      columnName: "Amount",
      caption: "Amount",
      field: "totalAmount",
      width: 130,
      alignment:"right",
      columnType: "Numeric",
      numericFormat: null
    },
    {
      columnName: "Priority",
      caption: "Priority",
      field: "priority",
      width: 150,
      alignment:"left",
      columnType: "Char",
    },
    {
      columnName: "Status",
      caption: "Status",
      field: "status",
      width: 150,
      alignment:"left",
      columnType: "Char",
    },
    {
      columnName: "Requested By",
      caption: "Requested By",
      field: "requestedBy",
      width: 150,
      alignment:"left",
      columnType: "Char",
    },
    {
      columnName: "Next Approver",
      caption: "Next Approver",
      field: "authorizeBy",
      width: 150,
      alignment:"left",
      columnType: "Char",
    },
    {
      columnName: "Last Approver",
      caption: "Last Approver",
      field: "lastAuthorizeBy",
      width: 150,
      alignment:"left",
      columnType: "Char",
    },
    {
      columnName: "Contact",
      caption: "Contact",
      field: "contactPerson",
      width: 150,
      alignment:"left",
      columnType: "Char",
    },
    {
      columnName: "Actions",
      caption: "Actions",
      field: "actions",
      cellRenderer: ActionPopupDev,
      width: 100,
      alignment:"left",
      columnType: "Char",
    },
  ]);

  useEffect(() => {
    const col = columnDefs.map((item) => {
      let valueFormatter;
          if (
            item?.columnType==='Numeric'
          ) {
              valueFormatter = (params) => { return (params.value || (params.value == 0)) ? ValueFormatter(params?.value,item?.numericFormat,item?.columnName) : "" };

          }
      return {
        headerName: item?.caption,
        columnType: item?.columnType,
        field: item?.field,
        width: item?.width,
        valueFormatter:valueFormatter,
        halign: item?.alignment,
        cellRenderer:item?.cellRenderer,
        hide: false,
      };
    });
    setColumnDefs(col);

  },[])
  
  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      resizable: true,
      minWidth: 150,
    }),
    []
  );

  const gridRef: any = useRef(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };


  const onShortButtonClick = () => {
    setIsadvanceShort(!isadvanceShort);
  };
  const onFilterButtonClick = () => {
    setShowFilter(!showFilter);
  };

  const getAllDataStatusWise=(exportAllData)=>{
    const statusMap = {
      approved: "Fully Authorized",
      rejected: "Rejected",
      inProcess: "In Process"
  };
    const filteredData = exportAllData.filter(item => item.status === statusMap[status]);
    return filteredData;
  }

  const onBtnExport =async (type, isChecked) => {
      function filterUserFields(users) {
        const filteredUsers = users?.map((user) => {
          const mainData = {
            "Request No": user?.requestNo,
            "Request Date": formatDate(user?.requestDate) || " ",
            Amount: `${user?.amount?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
            "Request By": user?.requestedBy,
            "Authorized By": user?.authorizeBy,
            "Last Authorized By": user?.lastAuthorizeBy,
            Priority: user?.priority,
            Contact: user?.contactPerson || " ",
            Notes: user?.notes || "",
            Status: user?.status,
          };

          let userWithLineItems = [mainData];

          if (isChecked) {
            const lineItems = user?.lineItems?.map((lineItem) => ({
              "Request No": user?.requestNo,
              "Request Date": formatDate(user?.requestDate) || " ",
              Amount: `${user?.amount?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
              "Request By": user?.requestedBy,
              "Authorized By": user?.authorizeBy,
              "Last Authorized By": user?.lastAuthorizeBy,
              Priority: user?.priority,
              Contact: user?.contactPerson || " ",
              Notes: user?.notes || "",
              Status: user?.status,
              "Line No": lineItem?.lineNo ? lineItem?.lineNo : "",
              Stock: lineItem?.code ? lineItem?.code : "",
              Description: lineItem?.description ? lineItem?.description : "",
              UOM: lineItem?.uom ? lineItem?.uom : "",
              Location: lineItem?.location ? lineItem?.location : "",
              WorkOrder: lineItem?.workOrderNo ? lineItem?.workOrderNo : "",
              job: lineItem?.jobCode ? lineItem?.jobCode : "",
              Phase: lineItem?.phaseCode ? lineItem?.phaseCode : "",
              "Req.Code": lineItem?.reqCode ? lineItem?.reqCode : "",
              "GL Account": lineItem?.glAccount ? lineItem?.glAccount : "",
              "Req Qty": lineItem?.requiredQty ? lineItem?.requiredQty : "",
              Rate: lineItem?.unitCost ? lineItem?.unitCost : "",
              amount: `
               ${lineItem?.amount?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            }));
            return lineItems;
          }
          return userWithLineItems;
        });

        return filteredUsers?.flat();
      }
      let data;
      
        if (type === "all") {
          const exportAllDataForStatus=status?getAllDataStatusWise(exportAllData):exportAllData;
          if(exportAllDataForStatus){
          data = filterUserFields(exportAllDataForStatus);
          }
        } else {
          let filterData = FilteredRowOFDataGrid(gridRef);
          data = filterUserFields(filterData);
        }
        exportDataToExcel(data, `${fileName}.xlsx`);

    };

  const onBtnExportPdf =
    async(type, isChecked) => {
      const pdf: any = new jsPDF({
        orientation: "landscape",
      });
      var image = new Image();
      image.src = imgData;
      let data;
      if (type === "all") {
        const exportAllDataForStatus=status?getAllDataStatusWise(exportAllData):exportAllData;
        if(exportAllDataForStatus){
        data = exportAllDataForStatus;
        }
      } else {
        data = FilteredRowOFDataGrid(gridRef);
      }
      let formattedData: any = [];
      {
        isChecked &&
          formattedData.push(["", "", "", "", "", "", "", "", "", "", ""]);
      }
      data?.forEach((user, index) => {
        formattedData.push([
          user?.requestNo,
          formatDate(user?.requestDate) || "Invalid Date",
          user?.notes || "",
          `${parseFloat(user?.amount?.replace(/,/g, ''))?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,

          user?.priority,
          user?.status,
          user?.requestedBy,
          user?.authorizeBy,
          user?.lastAuthorizeBy,
          user?.contactPerson,
        ]);

        if (isChecked) {
          user?.lineItems?.forEach((lineItem) => {
            formattedData.push([
              lineItem?.lineNo,
              lineItem?.code,
              lineItem?.description,
              lineItem?.uom,
              lineItem?.location,
              lineItem?.workOrderNo,
              lineItem?.jobCode,
              lineItem?.phaseCode,
              lineItem?.reqCode,
              lineItem?.glAccount,
              lineItem?.requiredQty,
              lineItem?.unitCost,
              `${parseFloat(lineItem?.amount)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            ]);
          });
        }

      });

      autoTable(pdf, {
        didDrawCell: (data) => {
          
          if (data.section === 'head') {
            const lineWidth = 0.1; 
            const startX = data.cell.x;
            const startY = data.cell.y;
            const endX = startX + data.cell.width;
            const endY = startY;
            pdf.setLineWidth(lineWidth);
            pdf.line(startX, startY, endX, endY);
          }

    
    if (data.section === 'head' && data.column.index === 0) {
      pdf.addImage(image, 'PNG', 13, 2, 50, 30);
      pdf.setFontSize(10);
      pdf.text(120, 15, userId);
      pdf.text(120, 10, userEmail);
      pdf.text(120, 20, companyName + ' - ' + company);
  }
  

    if (data.row.index !== undefined && data.row.index !== data.table.body.length - 1) {
      // Draw horizontal line between rows
      const lineWidth = 0.1; // Adjust the line width as needed
      const startX = data.cell.x;
      const startY = data.cell.y + data.cell.height;
      const endX = startX + data.cell.width;
      const endY = startY;
      pdf.setLineWidth(lineWidth);
      pdf.line(startX, startY, endX, endY);
    }  
    
    
    
        },
        didDrawPage: (HookData) => {
          pdf.setFontSize(10);
          pdf.setTextColor(214, 214, 214);
          pdf.text(85, 290, "For internal use only.");

          // Get current page number
          const currentPageNumber = pdf.internal.getCurrentPageInfo().pageNumber;
          pdf.setTextColor(0, 0, 0);
          pdf.text(260, 5, `Page ${currentPageNumber}`);
          let currdate = new Date();
          pdf.setFontSize(10);
          pdf.text(15, 5, 'Printed At: ' + formatDateToDDMMYYY(currdate.toLocaleString()));
      },
        styles: { fontSize: 8 },
        margin: { top: 30 },
        showHead: "everyPage",
        columnStyles: {
          3: { halign: 'right' } ,
          },
        theme: "plain",
        
        head: [
          [
            "Request No",
            "Request Date",
            "Notes",
            {content: "Amount", styles: {halign: 'right'}},
            "Priority",
            "Status",
            "Requested By",
            "Next Approver",
            "Last Approver",
            "Contact",
          ],
          isChecked && [
            "Line No",
            "Stock",
            "Description",
            "UOM",
            "Location",
            "WorkOrder",
            "job",
            "Phase",
            "Req.Code",
            "GL Account",
            "Req Qty",
            "Rate",
            {content: "Amount", styles: {halign: 'right'}},
          ],
        ],

        body: formattedData,
      });

      pdf.save("stock_listing.pdf");
    };

  const handleApply = (data) => {
    setFilterData(data);
  };

  const handleApplysort = (data) => {
    const sortPayload = data.map(({...newObject }) => ({
      column: newObject.column,
      ascDesc: newObject.ascDesc
    }));
    setSortData(sortPayload);
  };

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 150, 200];
  }, []);

  const closeExportModal = () => {
    setShowExportModal(false);
    setShowExportModalExcel(false);
  };
  const closeDeleteModal = (event) => {
    setDeleteid(false);
  };

  const handleDelete = async () => {
    try {
      const res = await deletestock(storedRequestNo, company);
      if (res?.data?.errorCode == 0) {
        setExportAllData(exportAllData.filter(item => item.requestNo !== storedRequestNo));
        setDeleteid(false);
        toastMessage(res?.data?.successMsg, "success");
        fetchApi("delete");                  // calling api to updated the table and summary.
      } else {
        setDeleteid(false);
        toastMessage(res?.data?.errorMsg, "error");
      }
    } catch (error) {
      setDeleteid(false);
    }
  };

  return (
    <>
    
    <>
      <KTCard>
        <div className="mt-n2 row row-cols-1 row-cols-lg-6 gy-1 w-100 ms-5" style={{ alignItems: "center" }}>
          <div className="col-md-3">
            <button className="btn text-white rounded-pill text-wrap fs-6 primary-button w-100 h-40px" onClick={() => setStatus("")} style={{ padding: "5px", display: "flex", alignItems: "center", backgroundColor: "#0a7eac", width: "100%" }}>
              <img src={TotalSales} className="cursor-pointer" style={{ height: "40px", width: "35px", marginRight: "3px", marginTop: "5px" }} />
              <div style={{ flex: "1", textAlign: "center" }}>
                <span>{t("Total Requests")} - {overAllRequestSummary?.totalRequests}</span>
              </div>
            </button>
          </div>

          <div className="col-md-3">
            <button className="btn text-white rounded-pill text-wrap fs-6 primary-button w-100 h-40px" onClick={() => setStatus("approved")} style={{ padding: "5px", display: "flex", alignItems: "center", backgroundColor: "#0a7eac", width: "100%" }}>
              <img src={GoodQuality} className="cursor-pointer" style={{ height: "40px", width: "35px", marginRight: "6px", marginTop: "5px" }} />
              <div style={{ flex: "1", textAlign: "center" }}>
                <span>{t("Fully Authorized")} - {overAllRequestSummary?.totalApproved}</span>
              </div>
            </button>
          </div>

          <div className="col-md-3">
            <button className="btn text-white rounded-pill text-wrap fs-6 primary-button w-100 h-40px" onClick={() => setStatus("rejected")} style={{ padding: "5px", display: "flex", alignItems: "center", backgroundColor: "#0a7eac", width: "100%" }}>
              <img src={CancelOrder} className="cursor-pointer" style={{ height: "35px", width: "35px", marginRight: "6px", marginTop: "5px" }} />
              <div style={{ flex: "1", textAlign: "center" }}>
                <span>{t("Rejected")} - {overAllRequestSummary?.totalRejected}</span>
              </div>
            </button>
          </div>

          <div className="col-md-3">
            <button className="btn text-white rounded-pill text-wrap fs-6 primary-button w-100 h-40px" onClick={() => setStatus("inProcess")} style={{ padding: "5px", display: "flex", alignItems: "center", backgroundColor: "#0a7eac", width: "100%" }}>
              <img src={pending} className="cursor-pointer" style={{ height: "40px", width: "35px", marginRight: "6px", marginTop: "5px" }} />
              <div style={{ flex: "1", textAlign: "center" }}>
                <span>{t("In Process")} - {overAllRequestSummary?.totalInProgess}</span>
              </div>
            </button>
          </div>
        </div>

      </KTCard>
      <div>

      </div>
      {trackLoadingState ? (
      <CustomLoadingCellRender />
    ) : (
      <KTCard className="px-10">
        <div className="mb-5 w-100">
          <div
            className="mt-5 row row-cols-1  gy-3 w-100"
            style={{ alignItems: "center" }}
          >
            <SearchInputDev gridRef={gridRef} type="stockTable" className="col-md-5" screen={"StockTable"} />
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill text-wrap fs-6 primary-button w-100 h-40px"
                onClick={() => {
                  setPageStatus("");
                  navigate("/stockdetail");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                  flexDirection: "row",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                <img
                  src={Invite}
                  className="cursor-pointer"
                  style={{
                    height: "35px",
                    width: "40px",
                    marginRight: "1px",
                    marginTop: "5px",
                  }}
                />
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                  }}
                >
                  {t("Request stock")}
                </span>
              </button>
            </div>
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-40px"
                onClick={onShortButtonClick}
                title={t("Sort")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                {t("Sort")}
              </button>
            </div>
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-40px"
                onClick={onFilterButtonClick}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
                title={t("Filter")}
              >
                {t("Filter")}
              </button>
            </div>
            <div className="col-md-1 d-flex align-item-centre justify-content-end pl-2">
              <div className="icon d-flex gap-2">
                <img
                  src={pdf}
                  className="cursor-pointer"
                  style={{ height: "35px", width: "35px" }}
                  onClick={() => setShowExportModal(true)}
                />
                <img
                  src={excel}
                  className="cursor-pointer"
                  style={{ height: "35px", width: "35px" }}
                  onClick={() => setShowExportModalExcel(true)}
                />
              </div>
            </div>

            {showFilter && (
              <AdvancedFilterPopup
                onClose={() => {
                  setShowFilter(false);
                }}
                columnOptions={columnOptions}
                onApply={handleApply}
              />
            )}
            {isadvanceShort && (
              <UserEditModal
                content="advancesort"
                modalText={t("Sort")}
                onCloseEditModal={closeChangePasswordModal}
                user={columnForSort}
                onApply={handleApplysort}
                style={{
                  position: "relative",
                  zIndex: 1000,
                }}
              />
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-end mb-5">
          <div></div>

          <div className="icon"></div>
        </div>
          <DataGridWrapper
           gridRef={gridRef}
           rowData={rowData}
           columnDefs={columnDefs}
           defaultColDef={defaultColDef}
           onGridReady={onGridReady}
           enableAdvancedFilter={true}
           pagination={true}
           paginationPageSize={50}
           paginationPageSizeSelector={paginationPageSizeSelector}
           height={600}
           fileName={fileName}
           exportEnabled={true}
           fontSizeForPdfExport={10}
           />
      </KTCard>)}

      {showExportModal && (
        <UserEditModal
          content="export"
          modalText={t("Export Data")}
          onCloseEditModal={closeExportModal}
          state={""}
          onSave={onBtnExportPdf}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
      {showExportModalExcel && (
        <UserEditModal
          content="export"
          modalText={t("Export Data")}
          onCloseEditModal={closeExportModal}
          state={""}
          onSave={onBtnExport}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
      {deleteid !== false && (
        <DeleteModal
          title={t("Are you sure you want to delete this record?")}
          onDelete={handleDelete}
          onCancel={closeDeleteModal}
        />
      )}
    </>
    </>
  );
};

export default StockTableDev;
