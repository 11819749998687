import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toastMessage } from "../../../auth/functions/toastMessage";
import { forgotPassword } from "./core/_requests";

const ForgotPasswordModal = ({ onCancel, state, email, call }) => {
  const setPreferenceSchema = Yup.object().shape({
    user_id: Yup.string().required("User Id is required"),
    email: Yup.string().email("Invalid Email"),
    // .required("Email is required"),
  });

  const handleSubmit = async (values, actions) => {
    try {
      const responseOfForgot = await forgotPassword(
        values?.user_id,
        values?.email
      );
      console.debug("responseOfForgot", responseOfForgot?.data);
      if(responseOfForgot?.data){
        toastMessage(responseOfForgot?.data?.data, "success");
        call();
        onCancel();
      }
    } catch (error: any) {
      console.debug("error?.response", error?.response?.status);
      if(error?.response?.status == 403){
        toastMessage(error?.response?.data?.data, "error");
        call();
        onCancel();
      }
      // toastMessage(error?.response?.data?.data, "success");
      actions.setSubmitting(false);
      actions.setErrors({
        user_id: error?.response?.data?.data,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        user_id: "",
        email: "",
      }}
      validationSchema={setPreferenceSchema}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
    >
      {({ errors, touched, isValid, setFieldValue, isSubmitting }) => (
        <Form>
          <div className="fv-row mb-2">
            <label className="required fw-bold fs-6 mb-2">User Id</label>
            <Field
              // type="user"
              name="user_id"
              className={clsx("form-control form-control-light", {
                "is-invalid": touched.user_id && errors.user_id,
              })}
            />
            {touched.user_id && errors.user_id && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{errors.user_id}</span>
                </div>
              </div>
            )}
          </div>

          <div className="fv-row mb-2 mt-4">
            <label className="fw-bold fs-6 mb-2">Email</label>
            <Field
              // type="email"
              name="email"
              className={clsx("form-control form-control-light", {
                "is-invalid": touched.email && errors.email,
              })}
            />
            {touched.email && errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{errors.email}</span>
                </div>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-center mt-5">
            <button
              type="button"
              className="btn btn-secondary rounded-pill mx-2"
              onClick={onCancel}
              title="Cancel"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn text-white rounded-pill mx-2"
              style={{ backgroundColor: "#0a7eac" }}
              title="Notify Admin"
              disabled={!isValid || isSubmitting}
            >
              Notify Admin
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordModal;
