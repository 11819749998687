import { useState, useEffect } from "react";
import { KTCard, KTSVG } from "../../../../theme/helpers";
import { useNavigate } from "react-router-dom";
import { getCompanyWithUserId,getMenuByUser } from "../../../modules/apps/user-management/users-list/core/_requests";
import { useAuth } from "../../../modules/auth";
import { saveCompanyAndName, saveLanguage } from "../../../../reducers/UserConfigReducer";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toastMessage } from "../functions/toastMessage";
import { Spinner } from "react-bootstrap";
import { setMenuData, setMniName } from "../../../../reducers/menuReducer";


const SelectCompanycard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [tableWidth, setTableWidth] = useState(350);
  const [searchTerm, setSearchTerm] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [companyList,setCompanyList]=useState<any>();
  const [isLoadingMenu,setIsLoadingMenu]=useState(false);
  const [companySelected, setCompanySelected] = useState(false);
  const navigate = useNavigate();
  const [errorInFetchingMenu, setErrorInFetchingMenu] = useState(false);
  const {logout} = useAuth();

const extractAllMniNames = (data) => {
    const allMniNames = new Set();

    const traverseItems = (items, level = 0) => {
        items.forEach(item => {
          // console.log("Traversing item at level", level, ": ", item.mniName);  // Log the mniName being processed
            if (item.mniName) {
                allMniNames.add(item.mniName);
            }
            if (item.items && item.items.length > 0) {
                traverseItems(item.items);
            }
        });
    };

    data.forEach(app => {
      console.log("Processing application:", app.application);
        traverseItems(app.items);
    });

    return Array.from(allMniNames); // Convert Set to Array
};

  const fetchMenuApi = async (company) => {
    setIsLoadingMenu(true);
    try {
      const response = await getMenuByUser(company);
      if (response.length>0) {

        const rawResponse = response.map((item) => {
          if(item.application === "Plant Maintenance") {
            const oldArray = item.items;
            const newArray = [
              ...oldArray,
              {
              "menu_item": "Plant Enquiry",
              "menu_group": "AB",
              "mni_status": "TRUE",
              "applicationCode": "MK",
              "application": "Plant Maintenance",
              "menuLevel": null,
              "menuSeq": null,
              "mniName": "MNI_WORequest",
              "pinned": false,
              "favourited": false,
              "pinnedTimeStamp": null,
              "webUrl": "",
              "webParam": "",
              "items": []
          }]
          return {
            application: "Plant Maintenance",
            items: newArray
          }
          } else {
            return item
          }
        })
        dispatch(setMenuData(rawResponse));
        const mniNameList=extractAllMniNames(rawResponse);
        dispatch(setMniName(mniNameList));
      }
      else{
        toastMessage(t("You do not have access to any module for the selected company Please contact your admin."), "error");
        setErrorInFetchingMenu(true);
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."), "error");
      setErrorInFetchingMenu(true);
    } finally {
      setIsLoadingMenu(false);
    }
  };
  

  const fetchComapanyAPI = async () => {
    setIsLoading(true);
    try {
      const response = await getCompanyWithUserId();
        if (response.length === 1) {
          setCompanyList(response);
          const selectedCompany = response[0];
          setIsLoading(false);                // company is automatically selected. set the loader for company to false
          await dispatch(saveCompanyAndName({ code: selectedCompany.code, name: selectedCompany.name }));
          await fetchMenuApi(selectedCompany.code);
          setCompanySelected(true);  // Company is selected
        } else {
          setCompanyList(response);
        }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."), "error");
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    fetchComapanyAPI();
  }, []);

  useEffect(() => {
    if (!isLoadingMenu && companySelected && !errorInFetchingMenu) {
       setTimeout(() => {       // Navigate to dashboard after timeout. It will give time to load menu
        navigate("/dashboard");
        // window.location.reload();   
       }, 100);
    }
  }, [isLoadingMenu, companySelected, navigate]);


  const handleRowClick = (row) => {
    setSelectedRow(row.code);
    setCompanyName(row.name);
  };

  const handleNextClick = async () => {
    setErrorInFetchingMenu(false);
    if (companyName.length > 0) {
      dispatch(saveCompanyAndName({ code: selectedRow, name: companyName }));
      await fetchMenuApi(selectedRow);
      setCompanySelected(true);  // Company is selected
    }
  };
  

  const filteredData = companyList?.filter(
    (row) =>
      row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <KTCard className="">
        <div
          className="px-5 pt-2 pb-5"
          style={{
            marginTop: "15px",
            color: "#757575",
            position: "relative",
          }}
        >
          <h2>{t("Select Company")}</h2>
          <input
            type="text"
            className="form-control fs-6 pl-5"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div style={{ position: "absolute", top: 40, left: 15 }}>
            <KTSVG
              path="/media/icons/duotune/general/gen021.svg"
              className="svg-icon-1 position-absolute ms-3"
            />
          </div>
        </div>
        <div
          className=""
          style={{ marginTop: "-10px", width: tableWidth + "px" }}
        >
          <table
            className="table table-responsive ps-9"
            style={{ tableLayout: "fixed" }}
          >
            <thead className="">
              <tr>
                <th className="fs-6">Code</th>
                <th className="fs-6 ps-18" style={{ paddingRight: "120px" }}>
                  Name
                </th>
              </tr>
            </thead>
            <div className="" style={{ maxHeight: "270px", overflowY: "auto" }}>
              <tbody>
                {isLoading ? (
                  <div className="d-flex justify-content-center my-3">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  filteredData?.map((row) => (
                    <tr
                      key={row.code}
                      className={`${
                        selectedRow === row.code ? "selected-row" : ""
                      }`}
                      onClick={() => handleRowClick(row)}
                    >
                      <td
                        style={{
                          color: selectedRow === row.code ? "#FFFFFF" : "",
                          backgroundColor:
                            selectedRow === row.code ? "#0a7eac" : "#FFFFFF",
                          paddingLeft: selectedRow === row.code ? "6px" : "6px",
                          lineHeight: "1",
                          width: "",
                          borderRadius: "15px 0 0 15px",
                          cursor: "pointer",
                        }}
                      >
                        {row.code}
                      </td>
                      <td
                        style={{
                          color: selectedRow === row.code ? "#FFFFFF" : "",
                          backgroundColor:
                            selectedRow === row.code ? "#0a7eac" : "#FFFFFF",
                          paddingLeft:
                            selectedRow === row.code ? "63px" : "63px",
                          lineHeight: "1",
                          width: "240px",
                          whiteSpace: "nowrap",
                          borderRadius: "0 15px 15px 0",
                          cursor: "pointer",
                        }}
                      >
                        {row.name}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </div>
          </table>
        </div>
        <div className="d-flex justify-content-center mx-9 mb-9 mt-5">
          <div className="d-flex justify-content-center align-items-center al w-100 ">
            {isLoadingMenu ? (
              <Spinner animation="border" variant="primary" />
            ):
            (<button
              className="btn text-white  rounded-pill w-100 pe-3 ps-3"
              style={{ width: "100%", backgroundColor: "#0a7eac" }}
              title="Next"
              disabled={companyName.length == 0}
              onClick={handleNextClick}
            >
              {t("Next")}
            </button>
            )}
          </div>
        </div>
        {errorInFetchingMenu &&(
        <div className="d-flex justify-content-center mx-9 mb-9 mt-5">
          <div className="d-flex justify-content-center align-items-center al w-100 ">
            <button
              className="btn text-white  rounded-pill w-100 pe-3 ps-3"
              style={{ width: "100%", backgroundColor: "#0a7eac" }}
              title="signout"
              onClick={logout}
            >
              {t("Sign Out")}
            </button>
          </div>
        </div>
      )}
      </KTCard>
    </>
  );
};

export default SelectCompanycard;
