import {Formik, Form, Field} from "formik";

const ExportModal = ({ onCancel, onSave, state,isLoading }) => {
  const path = window?.location?.pathname.includes("/stock-enquiry")
    ? false
    : window?.location?.pathname.includes("/stock");
  const newPath = window?.location?.pathname.includes("/stockdetail");
  console.debug(window?.location?.pathname, "nnnnnnnnnnnn");

  return (
    <Formik
      initialValues={{ val: "all", isChecked: false }}
      onSubmit={(values) => {
        onSave(values.val, values.isChecked);
        onCancel();
      }}
    >
      {() => (
        <Form>
          <div className="d-flex w-100 align-items-center justify-content-center gap-10">
            <div className="d-flex gap-5 align-items-center justify-content-center">
              <span className="fs-4">All Data</span>
              <Field
                type="radio"
                style={{ height: "20px", width: "20px" }}
                className="cursor-pointer"
                name="val"
                value="all"
              />
            </div>
            <div className="d-flex gap-5 align-items-center">
              <span className="fs-4">Filtered data</span>
              <Field
                type="radio"
                style={{ height: "20px", width: "20px" }}
                className="cursor-pointer"
                name="val"
                value="filtered"
              />
            </div>
          </div>

          {path && !newPath && (
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center ms-14 mt-4 gap-8 justify-content-center">
                <Field
                  type="checkbox"
                  style={{ height: "20px", width: "20px" }}
                  name="isChecked"
                />
                <span
                  className="text-black fs-5 ms-3"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Include Details
                </span>
              </div>
            </div>
          )}

          <div
            className="mt-7"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              type="submit"
              className="btn text-white rounded-pill  px-17"
              style={{ width: "50%", backgroundColor: "#0a7eac" }}
              title="Export"
              disabled={isLoading}
            >
              Export
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ExportModal;
