/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import background from "../../../assets/classic.png";
import classiclogo from "../../../assets/logo.png";
import { useTranslation } from "react-i18next";

const AuthLayout = () => {
  const {t} = useTranslation();
  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
    return () => {
      if (root) {
        root.style.height = "100%";
      }
    };
  }, []);

  return (
    <div className="h-100" style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          flex: 1,
          minHeight: "100%",
          backgroundColor: "#EDF1F8",
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div className="d-flex flex-column flex-lg-row flex-column-fluid ">
          {/* begin::Body */}
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50  order-2 order-lg-1">
            {/* begin::Form */}
            <div
              className="d-flex flex-center flex-column flex-lg-row-fluid "
              // style={{ backgroundColor: "red" }}
              style={
                {
                  // backgroundImage: `url(${background})`,
                }
              }
            >
              <img
                src={classiclogo}
                height="120px"
                width="230px"
                alt="logo"
                loading="lazy"
              />
              <div className="w-lg p-10">
                <Outlet />
              </div>
              {/* end::Wrapper */}
            </div>
            
          </div>
          {/* end::Body */}

            </div>
        <div
          className=" text-end  fs-6 pt-3"
          style={{ position: "absolute", bottom: 40, right: 40 }}
        >
          <a
            href={`${process.env.PUBLIC_URL}/assets/Termsofsaleandsupport.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
            style={{
              color: "" /* Set the default text color to blue */,
              textDecoration: "none" /* Remove underline on links */,
              transition:
                "color 0.3s ease" /* Add a smooth transition effect */,
            }}
          >
            {t('Terms and Conditions')}
          </a>
          <span className="mx-2 text-white"> | </span>
          <a
            href={`${process.env.PUBLIC_URL}/assets/PrivacyStatement.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
            style={{
              color: "" /* Set the default text color to blue */,
              textDecoration: "none" /* Remove underline on links */,
              transition:
                "color 0.3s ease" /* Add a smooth transition effect */,
            }}
          >
            {t('Privacy Policy')}
          </a>
        </div>
      </div>
    </div>
  );
};

export { AuthLayout };
