import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Unauthorized: React.FC = () => {
  const {t} =useTranslation(); 
  return (
    <Container fluid className="vh-100 d-flex justify-content-center align-items-center">
      <Row className="text-center">
        <Col>
          <h1 className="display-1 text-danger">{t("Oops!")}</h1>
          <p className="lead">{t("You do not have access to the given page.")}</p>
          <p className="lead">
            {t("Please contact the Support Team at- ")}
            <a href="https://classicinfo.com.au/" target="_blank" className="text-info">Classic Info</a>
          </p>
          <Link to="/" className="btn btn-primary mt-3">{t("Go to Home Page")}</Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Unauthorized;
