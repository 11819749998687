import { useDispatch, useSelector } from "react-redux";
import {
  changeLanguage,
} from "../../../../app/modules/apps/user-management/users-list/core/_requests";
import { Language } from "../../../../app/utils/Config";
import Select from 'react-select';

import { saveLanguage } from "../../../../reducers/UserConfigReducer";
import { useTranslation } from 'react-i18next';
import ReactSelectStyle from "../../../../app/utils/ReactSelectStyle";
import { Formik, Form, Field } from "formik";



function ChangeLanguage({ onCancel }) {
  const { t, i18n } = useTranslation();
  const userLanguage = useSelector((state: any) => state?.userConfig?.language);
  const languages=Language.LANGUAGE;

  const dispatch = useDispatch();

  const translatedLanguages = languages.map(language => {
    return {
      value: language.value,
      label: t(language.label),
    };
  });

  const onSave = async (values, { setSubmitting }) => {
    if (values.setDefaultLanguage) {
      const languageDefault = languages.find(lang => lang.value == values.selectedLanguage);
      const languageLabel=languageDefault?.label;
      await changeLanguage(languageLabel);
    }
    let obj: any = languages.find((lang) => lang.value === values.selectedLanguage);
    if(obj){
      localStorage.setItem("language", obj.value);
      i18n.changeLanguage(obj.value);//changing language
      dispatch(saveLanguage(obj.value));
    }
    onCancel();
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ selectedLanguage: userLanguage, setDefaultLanguage: false }}
      onSubmit={onSave}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div>
            <div style={{ zIndex: 99999999 }} className="my-4">
              <Select
                options={translatedLanguages}
                onChange={option => setFieldValue('selectedLanguage', option?.value)}
                isSearchable={true} // Enables search functionality
                placeholder={values.selectedLanguage}
                maxMenuHeight={120}
                styles={ReactSelectStyle}
                defaultValue={translatedLanguages?.find((e) => e.value == userLanguage)}
              />
            </div>
            <div className="d-flex fs-5 gap-5 mb-2 mx-1">
              <div className="d-flex align-items-center justify-content-center">
                <Field
                  type="checkbox"
                  name="setDefaultLanguage"
                  style={{ transform: "scale(1.2)" }}
                />
                <span
                  className="fs-5 text-black ms-4"
                  style={{
                    marginLeft: "30px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t('Set as default language')}
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-4">
              <button
                className="btn btn-secondary rounded-pill flex-grow-1 me-2"
                onClick={onCancel}
              >
                {t('Cancel')}
              </button>
              <button
                className="btn btn-primary rounded-pill flex-grow-1"
                style={{ backgroundColor: "#0a7eac" }}
                type="submit"
              >
                {t('Save')}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
export default ChangeLanguage;
