const ReactSelectStyle = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#d6d6d6",
      minHeight: "40px",
      height: "40px",
      boxShadow: state.isFocused ? null : null,
    }),
  
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 6px",
    }),
  
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
  
    indicatorSeparator: (state) => ({
      display: "none",
    }),
  
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
    })
};
  
  export default ReactSelectStyle;
  