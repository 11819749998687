import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { colors, titleCss } from "../../../constants/colors";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import StockHeader from "./StockHeader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const RequestStockdetailDev = ({ data, triggerAdd, setError }) => {
  const {t} = useTranslation();
  const stockExpenseDetailsData = useSelector((state: any) => state?.lookupData?.lookupData);
  const selectedRowIndex = useSelector((state: any) => state?.currentIndex?.value);
  const [detail, setDetail] = useState<any>();

  useEffect(() => {
    console.debug(
      "stockExpenseDetailsData jhgfdsx",
      stockExpenseDetailsData[selectedRowIndex]
    );

    setDetail(stockExpenseDetailsData[selectedRowIndex]);
  }, [selectedRowIndex, stockExpenseDetailsData]);

  const headerRef: any = useRef(null);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const closeChangePasswordModal = (event) => {
    setChangePasswordModalOpen(false);
  };

  const closeDeleteModal = (event) => {
    setDeleteModalOpen(false);
  };

  function formatNumber(value) {
    return value
      ? value.toLocaleString(undefined, {
          maximumFractionDigits: 4,
          minimumFractionDigits: 4,
        })
      : "0.0000";
  }

  const renderInput = (label, value, isQuantityField) => (
    <Col sm={4}>
      <div className="mb-5">
        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
          {t(label)}
        </label>
        <div className="input-group">
          <input
            className={`form-control border-secondary fs-5 ${
              isQuantityField ? "text-right" : ""
            }`}
            placeholder=""
            type="text"
            name="userId"
            autoComplete="off"
            style={{ width: "200px" }}
            disabled={true}
            value={value}
          />
          {label == "Required Qty" && (
            <span className="input-group-text text-right bg-white h-40px">
              {detail?.unit}
            </span>
          )}
        </div>
      </div>
    </Col>
  );

  return (
    <>
      <StockHeader data={data} triggerAdd={triggerAdd} setError={setError} currentPage="requestStock" />
      <div className="accordion mt-10" id="accordionExample2">
        <div className="accordion-item">
          <div className="px-4">
            <div
              ref={headerRef}
              className="accordion-button bg-white cursor-pointer "
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                <div className="col-md-6 d-flex">
                  <div
                    className={clsx("", titleCss)}
                    style={{ color: colors.title }}
                  >
                    {t('Details - Line')} {detail?.lineNo?detail?.lineNo:detail?.tempLineNo}
                  </div>
                </div>
              </div>
            </div>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample2"
            >
              <div className="accordion-body">
                <Card className="p-4">
                  <Row>
                    {renderInput("Stock Item", detail?.code, false)}
                    {renderInput("Item Details", detail?.description, false)}
                    {renderInput("Order Qty", formatNumber(detail?.onOrderQty), true)}
                  </Row>
                  <Row>
                    {renderInput("Required Qty", formatNumber(detail?.requiredQty), true)}
                    {renderInput("Bin Location", detail?.binLocation, false)}
                    {renderInput("On Hand Qty", formatNumber(detail?.onHandQty), true)}
                  </Row>
                  <Row>
                    {renderInput("From Location", detail?.location, false)}
                    <Col sm={4}>
                      <div className="mb-5">
                        <div className="d-flex">
                          <div className="flex-grow-1 me-2">
                            <label
                              htmlFor="genCat"
                              className="fw-bold fs-6 mb-2"
                            >
                              {t('Rate')}
                            </label>
                            <input
                              type="text"
                              disabled={true}
                              className="form-control text-right"
                              value={
                                detail?.unitCost
                                  ? Number(detail.unitCost).toFixed(2)
                                  : "0.00"
                              }
                            />
                          </div>

                          <div className="flex-grow-1 ms-2">
                            <label
                              htmlFor="genCat"
                              className="fw-bold fs-6 mb-2"
                            >
                              {t('Amount')}
                            </label>
                            <input
                              type="text "
                              disabled={true}
                              className="form-control text-right"
                              value={` ${
                                detail?.amount !== undefined &&
                                detail?.amount !== null
                                  ? Number.isInteger(detail?.amount)
                                    ? `${parseFloat(
                                        detail?.amount
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}`
                                    : `${parseFloat(
                                        detail?.amount
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}`
                                  : "0.00"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                    {renderInput( "Available Qty", formatNumber(detail?.availableQty),true)}
                  </Row>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isChangePasswordModalOpen && (
        <UserEditModal
          content="reset_password"
          modalText={t("Reset Password")}
          onCloseEditModal={closeChangePasswordModal}
          user="reset"
          userId={data}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}

      {deleteModalOpen && (
        <UserEditModal
          content="delete_user"
          modalText={t("Delete")}
          onCloseEditModal={closeDeleteModal}
          user="reset"
          userId={data?.userId}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
    </>
  );
};

export default RequestStockdetailDev;
