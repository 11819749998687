import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";
import { Formik,Form,Field } from "formik";

const CustomizeColumnsModalDev = (props) => {
  const [data, setData] = useState(props.columnHeaders);
  const company = useSelector((state: any) => state?.userConfig?.company);

  const handleApply = () => {
    const payload = {
      tabStorage: props.tabStorage,
      tabSqlName: props.tabSqlName,
      modifiedColumnNames: data,
      companyCode: company,
    };
    console.debug("Payload:", payload);
    props.onApply(payload);
    props.onCancel();
  };

  const handleMouseEnter = () => {
    const accordionHeader = document.querySelector(".accordion-header");
    if (accordionHeader) {
      accordionHeader.setAttribute("data-bs-toggle", "collapse");
    }
  };

  const handleMouseLeave = () => {
    const accordionHeader = document.querySelector(".accordion-header");
    if (accordionHeader) {
      accordionHeader.setAttribute("data-bs-toggle", "");
    }
  };

  const handleReset = () => {
    props.onCancel();
  };

  const handleInputChange = (e, caption) => {
    var result = [...data];
    result = result.map((x) => {
      if (x?.caption === caption) {
        x.numericFormat = e.target.value;
        return x;
      } else return x;
    });
    setData(result);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Formik
        initialValues={data.reduce((acc, row) => ({ ...acc, [row.caption]: row.numericFormat }), {})}
        onSubmit={handleApply}
        onReset={handleReset}
      >
        {({ isSubmitting }) => (
          <Form>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "-28px",
              }}
            >
              <thead>{/* Add your table header here if needed */}</thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={row.defaultColumnIndex}>
                    <td
                      style={{
                        border: "0",
                        padding: "8px",
                        color: "black",
                      }}
                      className="fs-5"
                    >
                      {row.caption}
                    </td>
                    <td
                      style={{
                        border: "0",
                        padding: "8px",
                        color: "black",
                      }}
                      className="fs-5"
                    >
                     <Field
                      type="text"
                      value={row?.numericFormat}
                      placeholder="Enter value"
                      onChange={(e) => {
                        handleInputChange(e, row.caption);
                      }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-center gap-15 mt-5">
              <button
                type="reset"
                className="btn btn-secondary rounded-pill mx-2"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                title="Reset"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn text-white rounded-pill mx-2"
                title="Apply"
                style={{ backgroundColor: "#0a7eac" }}
                data-kt-users-modal-action="submit"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                disabled={isSubmitting}
              >
                Apply
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </DndProvider>
  );
};

export default CustomizeColumnsModalDev;
