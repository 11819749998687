import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef } from "ag-grid-community";
import "../../pages/user/UsersTable.css";

import {
  approveStockRequest,
  closerequest,
  getLineitemList,
  getStockRequestList,
  issuebatch,
  lockRequest,
  rejectStockRequest,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { KTCard } from "../../../theme/helpers";
import pdf from "../../../assets/pdf.png";
import excel from "../../../assets/excel.png";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useAuth } from "../../modules/auth";
import dayjs from "dayjs";
import imgData from "../../../assets/logo.png";
import { toastMessage } from "../auth/functions/toastMessage";
import CustomLoadingCellRender from "../../pages/user/CustomLoadingCellRender";
import { HandleApiCall } from "../auth/functions/HandleApiCall";
import CustomLoaders from "../../pages/user/CustomLoaders";
import { formatDate } from "../../../theme/assets/ts/_utils/FormatData";
import {
  approveDetailColumnsConfig,
  approveStockColdefConfig,
  approveStockDetailsColdefConfig,
  approvestockConfig,
  approvestockdetailConfig,
  closeUnprocesStockColdefConfig,
  closeUnprocesStockDetailsColdefConfig,
  closeUnprocessedColumnConfig,
  closeUnprocessedDetailColumnConfig,
  closeUnprocessedDetailFilterColumnConfig,
  closeUnprocessedFilterColumnConfig,
  createIssueBatchColdefConfig,
  createIssueBatchColumnsConfig,
  createIssueBatchDetailsColdefConfig,
  createIssueBatchFilterColumnsConfig,
  createIssueColumnsConfig,
  createIssueDetailColumnsConfig,
  approveColumnsConfig,
} from "./LookUpFilterConfig";
import { exportDataToExcel } from "../../../theme/assets/ts/_utils/ExportXLSX";
import { useSelector } from "react-redux";
import {
  formatDateToDDMMYYY,
} from "../../../theme/assets/ts/_utils/FormatData";
import { useTranslation } from "react-i18next";
import SearchInput from "../../utils/SearchInput";

interface LookUpScreenProps {
  type: string;
  section: string,
  setStockDetail?: any;
  stockDetail?: any;
  rejectStock?: any;
  setRejectStock?: any;
  reqNumber?: any;
  setreqNumber?: any;
  setLoader?: any;
  loader?: any;
  loaderReject?: any;
  setLoaderReject?: any;
  actionToBeCalled?: any;
  setActionToBeCalled?: any;
}

const LookUpScreenForApproveStock: React.FC<LookUpScreenProps> = ({
  type,
  section,
  setStockDetail,
  stockDetail = [],
  rejectStock = false,
  setRejectStock,
  reqNumber,
  loader,
  setreqNumber,
  setLoader = false,
  setLoaderReject = false,
  actionToBeCalled,
  setActionToBeCalled,
}) => {
  const {t} = useTranslation();
  const company = useSelector((state: any) => state?.userConfig?.company);
  const companyName = useSelector((state: any) => state?.userConfig?.companyName);
  const { currentUser, setCreateIssueButtonDisabled } = useAuth();
  const [fileName, setFileName] = useState("");
  const [columnOptions, setColumnOptions] = useState<any>([]);
  const [comments, setComments] = useState("");
  const userName = currentUser!.sub.split("#")[1];
  const [rowData, setRowData] = useState<any>([]);
  const [sortData, setSortData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isadvanceShort, setIsadvanceShort] = useState(false);
  const [gridApi, setGridApi] = useState<any>(null);
  const [showExportModal, setShowExportModal] = useState<any>(null);
  const [showExportModalExcel, setShowExportModalExcel] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [backupData, setBackupData] = useState([]);
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [initialLoad, setInitialLoad] = useState(false);
  const [trackLoadingState, setTrackLoadingState] = useState(false);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [dataArray, setDataArray] = useState<any[]>([]);
  const dropdownRef: any = useRef();
  let [userEmail, userId]: any = currentUser?.sub?.split("#");

  const extractValueBetweenHash = () => {
    const regex = /#(.*?)#/;
    const match = currentUser?.sub?.match(regex);
    if (match) {
      return match[1];
    }
    return null;
  };
  const currentUserName = extractValueBetweenHash() || "";

  const isNotDetailScreen =
    type == "approve-stock-detail" ||
    type == "create-issue-batch-detail" ||
    type == "close-unprocessed-stock-detail" ||
    type == "close-unprocessed-stock";

  useEffect(() => {
    const temp =
      type == "approve-stock"
        ? "stock_list_approve"
        : type == "create-issue-batch"
        ? "stock_list_issue_batch"
        : type == "close-unprocessed-stock-detail"
        ? "stock_list_close_unprocessed_stock_detail"
        : "stock_list_close-unprocessed-stock";
    setFileName(temp);
  }, [type]);

  useEffect(() => {
    const selectedColumns =
      type === "create-issue-batch-detail" 
        ? createIssueBatchColumnsConfig
        : type === "close-unprocessed-stock-detail"
        ? closeUnprocessedDetailFilterColumnConfig
        : type === "create-issue-batch" 
        ? createIssueBatchFilterColumnsConfig
        : type === "close-unprocessed-stock"
        ? closeUnprocessedFilterColumnConfig
        : type === "approve-stock"
        ? approvestockConfig
        : type==="approve-stock-detail"
        ? approvestockdetailConfig
        : approvestockdetailConfig;
    setColumnOptions(selectedColumns);
  }, [type]);

  const closeChangePasswordModal = (event) => {
    setIsadvanceShort(false);
  };

  const [exportAllData,setExportAllData]=useState<any>();

  const fetchApiCall=async()=>{
    setTrackLoadingState(true);
    try {
      const sortDataResponse=await getStockRequestList(
        currentUserName,
        company,
        { advancedFilters: filterData, sortedList: sortData },
        searchTerm,
        "",
        type == "create-issue-batch" || type == "create-issue-batch-detail"
          ? "issue"
          : type == "approve-stock" || type == "approve-stock-detail"
          ? "approve"
          : "close",
        ""
      );
      if(sortDataResponse.data){
          const formattedRowData =
          sortDataResponse?.data?.stockRequestListingData?.map((item) => {
            const formattedAmount = parseFloat(item.amount);
            return {
              ...item,
              amountu: `${formattedAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
              date: item?.requestDate != null ? formatDate(item.requestDate) : "",
            };
          });
          setRowData(formattedRowData);

          if(filterData.length === 0 )
          setExportAllData(sortDataResponse?.data?.stockRequestListingData);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
      }
      setTrackLoadingState(false);
      
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
      setTrackLoadingState(false);
    }
  }

  useEffect(() => {
     if(section==='Header'){
        fetchApiCall();
     }
  }, [sortData, filterData]);


  const callSortedLineApi = async () => {
    try {
      const sortDataLineResponse = await getLineitemList(company, reqNumber, {
        advancedFilters: filterData,
        sortedList: sortData,
      });
      
      if(sortDataLineResponse.data){
        const modifiedData = sortDataLineResponse?.data?.map((item) => {
          item.disabled = item.status === "I1" || item.status === "IX";
          return item;
        });
        setStockDetail(modifiedData);
        setBackupData(modifiedData);
    }
      
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
   
  };

  useEffect(() => {
    if(section==='Details'){
      if (reqNumber && (sortData || filterData)) {
        callSortedLineApi();
      }
  }
  }, [sortData, reqNumber, filterData]);

  const handleRowUnselection=(e)=>{
    const allSelectedData = gridRef?.current?.getSelectedRows();
    if(allSelectedData?.length>0){
    const lastSelectedData = allSelectedData[allSelectedData.length - 1];     
    setreqNumber(lastSelectedData?.requestNo);        //call getline for last selected row if last selection was removed
    }
  }

  const cellClickedListener = useCallback(
    async (e) => {
      if (
        type == "approve-stock-detail" ||
        type == "create-issue-batch-detail" ||
        type == "close-unprocessed-stock-detail"
      ) {
        return;
      }

      const payload = {
        company: company,
        requestNo: e?.data?.requestNo,
        selected: e.node.selected ? "true" : "false",
      };
      const response = initialLoad
        ? await HandleApiCall(lockRequest, payload)
        : [];
      
        if(e&&e?.node?.selected) { 
          setreqNumber(e?.data?.requestNo);
        }
        else if(e&&!e?.node?.selected){
          handleRowUnselection(e);
        }
      const allSelectedData = gridRef?.current?.getSelectedRows();

      if (allSelectedData?.length > 0) {
        setCreateIssueButtonDisabled(false);
        setDataArray(allSelectedData);
      } else {
        setCreateIssueButtonDisabled(true);
        setDataArray([]);
      }
    },
    [initialLoad]
  );

  useEffect(() => {
    if (rowData?.length > 0) {
      const firstElementArray = [rowData[0]];
      setreqNumber(firstElementArray[0]?.requestNo);
    }
  }, [rowData]);

  useEffect(() => {
    if (!initialLoad) {
      setTimeout(() => {
        setInitialLoad(true);
      }, 1000);
    }
  }, [initialLoad]);
  
  const handleApiCall = async (
    apiFunction,
    data,
    successCallback,
    errorCallback,
    loaderSetter
  ) => {
    loaderSetter(true);
    setActionToBeCalled("");
    try {
      const response = await apiFunction(company, data);
      loaderSetter(false);
      console.debug("response handle api call", response);

      if (response?.data) {
        setCreateIssueButtonDisabled(true);
        successCallback(response.data);
        //refetchStock();
        fetchApiCall();
      } else {
        setCreateIssueButtonDisabled(false);
        // errorCallback("Server Error.");
      }
    } catch (error) {
      loaderSetter(false);
      errorCallback(error);
    }
  };
  const callrejectStockApi = async () => {
    let sample = [...dataArray];
    sample.forEach((item) => {
      item.notes = comments;
    });


    handleApiCall(
      rejectStockRequest,
      sample,
      (successMessage) => {
        console.debug(successMessage, "sucess");
        toastMessage(t(successMessage), "success");
      },
      (error) => {
        // setRejectStock(false);
      },
      setLoader
    );
  };

  const callAuthorizeApiOnSubmit = async () => {

    handleApiCall(
      approveStockRequest,
      dataArray.map((item) => ({ ...item, url: window.location.href })),
      (successMessage) => {
        toastMessage(t(successMessage), "success");
      },
      (error) => {},
      setLoader
    );
  };

  const callcloseStockApi = async () => {

    handleApiCall(
      closerequest,
      dataArray,
      (successMessage) => {
        toastMessage(t(successMessage), "success");
      },
      (error) => {},
      setLoader
    );
  };

  const callIssueBatchApi = async () => {
    const storedData: any = localStorage.getItem("createIssueBatch");
    const parsedData = JSON.parse(storedData) || [];
    const filteredData = parsedData;


    if (filteredData?.length > 0) {
      handleApiCall(
        issuebatch,
        filteredData,
        (successMessage) => {
          toastMessage(t(successMessage), "success");
          callSortedLineApi();
        },
        (error) => {},
        setLoader
      );
    } else {
      toastMessage(t("Nothing to Process."), "error");
      setLoader(false);
    }
  };

  useEffect(() => {
    if (actionToBeCalled == "Authorize") {
      callAuthorizeApiOnSubmit();
    } else if (actionToBeCalled == "Close Unprocess") {
      callcloseStockApi();
    } else if (actionToBeCalled == "Issue Batch") {
      callIssueBatchApi();
    }
  }, [actionToBeCalled, dataArray]);

  const getRowStyle = useCallback(
    (params) => {
      const isChecked = checkedRows?.includes(params?.data?.requestNo);
      const isDifferentUserAndChecked = params?.data?.checkedUser !== userId && params?.data?.checkedStatus;
      const isProcessed =params?.data?.status === "I1" || params?.data?.status === "IX" ;
      let color = "#000000";
      let backgroundColor = "";
  
      if (isDifferentUserAndChecked) {
        color = "#A9A9A9";
      } else if (isChecked) {
        color = "#0A7EAC";
      } else if (isProcessed) {
        backgroundColor = "#E3F2FD";
       }
  
      return {
        color: color,
        backgroundColor: backgroundColor,
        pointerEvents: isDifferentUserAndChecked ? "none" : "auto",
      };
    },
    [checkedRows, userId]
  );
  
  

  const CustomCheckbox = (props) => {
    const handleCheckboxClick = () => {
      const storedData: any = localStorage.getItem("createIssueBatch");
      const parsedData = JSON.parse(storedData) || [];
      let matchingEntry = parsedData?.find(
        (entry) => entry.requestNo === props.data.requestNo
      );
      if (matchingEntry) {
        matchingEntry?.lineItems?.forEach((lineItem) => {
          if (lineItem.code === props?.data?.code) {
            lineItem.isChecked = !lineItem.isChecked;
          }
        });
      }
      const updatedStockDetail = stockDetail.map((item) => {
        if (item.lineNo === props.data.lineNo) {
          return { ...item, isChecked: item.isChecked == true ? false : true };
        } else {
          return item;
        }
      });
      const index = parsedData?.findIndex(data => data.requestNo === props.data.requestNo);

      if (index !== -1) {
          // Update the lineItems key of the found element with updatedStockDetail
          parsedData[index].lineItems = updatedStockDetail;
        }
      localStorage.setItem("createIssueBatch", JSON.stringify(parsedData));
      setStockDetail(updatedStockDetail);
    };

    return (
      <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
      <label>{props.data?.lineNo}</label>
      <input
        type="checkbox"
        onClick={handleCheckboxClick}
        checked={(props.isDisabled) ? "": props.data.isChecked}
        disabled={props.isDisabled} 
        style={{ width: "16px", height: "16px", marginLeft: "10px", marginBottom: "5px" }}
      />
    </div>
    );
  };

  useEffect(() => {
    let ColOpt;

    switch (type) {
      case "approve-stock":
        ColOpt = approveStockColdefConfig;
        break;
      case "approve-stock-detail":
        ColOpt = approveStockDetailsColdefConfig;
        break;
      case "close-unprocessed-stock":
        ColOpt = closeUnprocesStockColdefConfig;
        break;

      case "close-unprocessed-stock-detail":
        ColOpt = closeUnprocesStockDetailsColdefConfig;
        break;

      default:
        break;
    }

    setColumnDefs(ColOpt);
  }, [type]);

  useEffect(() => {
    let ColOpt;

    switch (type) {
      case "create-issue-batch":
        ColOpt = createIssueBatchColdefConfig;
        break;

      case "create-issue-batch-detail":
        const extraObject = {
          headerName: "Line No",
          field: "lineNo",
          minWidth: 100,
          filter: "agNumberColumnFilter",
          rowDrag: true,
          cellRenderer: CustomCheckbox,
          cellRendererParams: function(params) {
            // Compute isDisabled based on the condition
            console.debug("create-issue",params.data.availableQty,params.data.requiredQty,params.data)
            let isDisabled = false
            if(params?.data?.availableQty <= (params?.data?.requiredQty - params?.data?.inQty)||  params.data.status == "I1" ){
              isDisabled = true;
            }
            // Return isDisabled as part of the params
            return {
              isDisabled: isDisabled,
              data: params.data, // Ensure data is also passed if not automatically included
            };
          },
        };
        
        ColOpt = [extraObject, ...createIssueBatchDetailsColdefConfig];
        break;

      default:
        break;
    }
    if (type == "create-issue-batch" || type == "create-issue-batch-detail") {
      setColumnDefs(ColOpt);
    }
  }, [type, stockDetail]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      resizable: true,
      enableRowGroup: type === "approve-stock" ? true : false,
      minWidth: 150,
    }),
    []
  );
  const gridRef: any = useRef(null);

  const onGridReady = useCallback((params) => {
    gridRef.current = params.api;
    if (window.innerWidth < 800) {
      sizeToFit();
    }
    setGridApi(params.api);
  }, []);

  const sizeToFit = () => {
    if (gridRef.current) {
      gridRef.current.sizeColumnsToFit({
        defaultMinWidth: 150,
      });
    }
  };

  const onRowDataUpdated = useCallback((params) => {
    selectRowsWithCheckboxes(params.api);
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    if (
      type == "close-unprocessed-stock" ||
      type == "create-issue-batch" ||
      type == "approve-stock"
    ) {
      const nodesToSelect: any[] = [];
      params.api.forEachNode((node: any) => {
        if (
          node.data &&
          node.data.checkedStatus &&
          node?.data?.checkedUser === userId
        ) {
          nodesToSelect.push(node);
        }
      });
      params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
    }
    selectRowsWithCheckboxes(params.api);
  }, []);


  const selectRowsWithCheckboxes = (api) => {
    if (gridRef?.current) {
      const nodesToSelect: any = [];
      api.forEachNode((node) => {
        if (node?.data && node.data.checkedStatus) {
          nodesToSelect?.push(node);
        }
      });

      api?.setNodesSelected({ nodes: nodesToSelect, newValue: true });
    }
  };

  const onSelectionChanged = (params) => {
    if (type == "create-issue-batch-detail") {
      return;
    }
    const allSelectedData = gridRef?.current?.getSelectedRows();
    const storedData: any = localStorage.getItem("createIssueBatch");
    const requestNos = allSelectedData?.map((obj) => obj.requestNo);
    setCheckedRows(requestNos);
    const parsedData = JSON.parse(storedData) || [];
    const mergeAndFilter = (arr1, arr2, prop) => {
      const merged = [...arr2, ...arr1];
      const uniqueMap = new Map(merged.map((item) => [item[prop], item]));
      return Array.from(uniqueMap.values());
    };
    params?.source == "rowClicked" && selectRowsWithCheckboxes(params.api);

    const filteredParsedData = parsedData?.filter((item) =>
      allSelectedData?.some(
        (selectedItem) => selectedItem.requestNo === item.requestNo
      )
    );
    const combinedData = mergeAndFilter(
      filteredParsedData,
      allSelectedData,
      "requestNo"
    );
    localStorage.setItem("createIssueBatch", JSON.stringify(combinedData));
  };

  const onShortButtonClick = (event: any) => {
    event.preventDefault();
    setIsadvanceShort(!isadvanceShort);
  };
  const onFilterButtonClick = () => {
    setShowFilter(!showFilter);
  };

  const onBtnExport = 
    async (typeAll) => {
      function filterUserFields(users) {
        return users.map((user) => {
          const truncatedAuthHistory =
            user?.authHistory?.length > 200
              ? `${user?.authHistory.slice(0, 50)}...`
              : user?.authHistory;

          const commonFields = {
            "Request No": user?.requestNo,
            "Request Date": dayjs(user?.requestDate).format("DD/MM/YYYY") || "",
            Priority: user?.priority,
            Status: user?.status,
            Instruction: user?.instructions,
            Amount: `${user?.amount}`,
            "Request By": user?.requestedBy,
            "Auth Group": user?.authGroup,
            "Last Auth by": user?.lastAuthorizeBy,
            "Authorized By": user?.authorizeBy,
            "Contact Person": user?.contactPerson,
            Notes: user?.notes || "",
            "Req.Notes": user?.reqNotes || "",
            "Auth.History": user?.authHistory ? truncatedAuthHistory : "",
          };

          if (type === "approve-stock") {
            return {
              ...commonFields,
              "Request Date":
                dayjs(user?.requestDate).format("DD-MM-YYYY") || "",
              Amount: `${user?.amount?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            };
          } else if (type === "create-issue-batch") {
            return {
              ...commonFields,
              "Request Date":
                dayjs(user?.requestDate).format("DD/MM/YYYY") || "",
              "Req.Notes": user?.reqNotes || "",
            };
          } else {
            return commonFields;
          }
        });
      }

      let data;
      if (typeAll === "all") {
        data = filterUserFields(exportAllData);
      } else {
        let filterData = getFilteredData();
        data = filterUserFields(filterData);
      }
      exportDataToExcel(data, `${fileName}.xlsx`);
    };

  const getFilteredData = () => {
    if (gridApi) {
      const filteredRows: any = [];
      gridApi.forEachNodeAfterFilter((node: any) => {
        filteredRows.push(node.data);
      });
      return filteredRows;
    }
  };

  const onBtnExportPdf =
    async (typeall) => {
      const pdf: any = new jsPDF({
        orientation: "landscape",
      });

      const image = new Image();
      image.src = imgData;

      let data;
      if (typeall === "all") {
        data = exportAllData;
        //console.debug(data, "himansu kk");
      } else {
        data = getFilteredData();
      }

      let formattedData = data?.map((user) => {
        const truncatedAuthHistory =
          user?.authHistory?.length > 200
            ? `${user?.authHistory.slice(0, 50)}...`
            : user?.authHistory;
        const commonFields = [
          user?.requestNo,
          dayjs(user?.requestDate).format("DD/MM/YYYY") || " ",
          user?.notes,
          `${user?.amount?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          user?.priority,
          user?.status,
          user?.requestedBy,
          user?.authorizeBy,
          user?.lastAuthorizeBy,
          user?.instructions,
          user?.contactPerson,
           user?.authGroup,  
        ];
        return type === "approve-stock"
          ? commonFields
          : type === "create-issue-batch"
          ? [...commonFields,  truncatedAuthHistory]
          : [...commonFields, truncatedAuthHistory];
      });

      autoTable(pdf, {
        didDrawCell: (data) => {
                
    if (data.section === 'head') {
      const lineWidth = 0.1; 
      const startX = data.cell.x;
      const startY = data.cell.y;
      const endX = startX + data.cell.width;
      const endY = startY;
      pdf.setLineWidth(lineWidth);
      pdf.line(startX, startY, endX, endY);
    }

    if (data.section === 'head' && data.column.index === 0) {
      pdf.addImage(image, 'PNG', 13, 2, 50, 30);
      let currdate = new Date();
      pdf.setFontSize(10);
      pdf.text(15, 5, 'Printed At: ' + formatDateToDDMMYYY(currdate.toLocaleString()));
      pdf.setFontSize(10);
      pdf.text(120, 15, userName);
      pdf.text(120, 10, userEmail);
      pdf.text(120, 20, companyName + ' - ' + company);
    }

    if (data.row.index !== undefined && data.row.index !== data.table.body.length - 1) {
      
      const lineWidth = 0.1;
      const startX = data.cell.x;
      const startY = data.cell.y + data.cell.height;
      const endX = startX + data.cell.width;
      const endY = startY;
      pdf.setLineWidth(lineWidth);
      pdf.line(startX, startY, endX, endY);
    }
        },
        didDrawPage: (HookData) => {
          pdf.setFontSize(13);
          pdf.setTextColor(214, 214, 214);
          pdf.text(100, 205, "For internal use only.");
          // Get current page number
          const currentPageNumber = pdf.internal.getCurrentPageInfo().pageNumber;
          pdf.setTextColor(0, 0, 0);
          pdf.text(260, 5, `Page ${currentPageNumber}`);
        },
        styles: { fontSize: 8 },
        margin: { top: 35 },
        
        columnStyles: {
          1: {cellWidth: 25},
          5: {cellWidth: 30},
           7: {cellWidth: 25},
           8: {cellWidth: 25},
          // 7: {cellWidth: 25},
          // 8: {cellWidth: 25},
          3: { halign: 'right', } ,
          },
        showHead: "everyPage",
        theme: "plain",
        head: [
          [
            "Request No",
            "Request Date",
            "Notes",
            {content: "Amount", styles: {halign: 'right'}},
            "Priority",
            "Status",
            "Requested By",
            "Next Approver",
            "Last Approver",
            "Instruction",
            "Contact Person",
            "Auth Group",
            "Auth.History",
           
          ],
        ],
        body: formattedData,
      });

      pdf.save(`${fileName}.pdf`);
    };

  const handleApply = (data) => {
    setFilterData(data);
    setInitialLoad(false);
  };

  const handleApplysort = (data) => {
    const sortPayload = data.map(({...newObject }) => ({
      column: newObject.column,
      ascDesc: newObject.ascDesc
    }));
    setSortData(sortPayload);
    setInitialLoad(false);
  };

  const closeExportModal = () => {
    setShowExportModal(false);
    setShowExportModalExcel(false);
  };

  const closeModal = () => {
    setActionToBeCalled("");
  };

  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const closeDropdownOnClickOutside: any = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdownOnClickOutside);

    return () => {
      document.removeEventListener("click", closeDropdownOnClickOutside);
    };
  }, []);

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 150, 200];
  }, []);

  const isRowSelectable = useMemo<any>(() => {
    return (params: any) => {
      return (
        !!params.data &&
        (params?.data?.checkedUser === userId || !params?.data?.checkedStatus)
      );
    };
  }, []);

  const renderExportIcons = () => {
    return (
      <div
        className="icon d-flex gap-2"
        style={{ justifyContent: "flex-end" }}
      >
        <img
          src={pdf}
          className="cursor-pointer"
          style={{ height: "35px", width: "35px" }}
          onClick={() => setShowExportModal(true)}
        />
        <img
          src={excel}
          className="cursor-pointer"
          style={{ height: "35px", width: "35px" }}
          onClick={() => setShowExportModalExcel(true)}
        />
      </div>
    );
  };


  return (
    <>
      {loader && <CustomLoaders />}
      <div
        ref={dropdownRef}
        onClick={(e) => {
          handleDropdownToggle("");
        }}
      >
        <div
          className=" d-flex"
          style={{
            marginBottom: 15,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      </div>
      <KTCard className=" px-10">
        <div className="mb-5 w-100">
          <div
            className="mt-5 row row-cols-1 row-cols-md-2 gy-3 w-100"
            style={{ alignItems: "center" }}
          >
            <SearchInput gridRef={gridRef} type={type} className={"col-md-4"} screen={"LookUpScreenForApproveStock"} />
            <div className="col-md-3"></div>
            <div className="col-md-2 d-flex align-items-end justify-content-end">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100"
                onClick={onShortButtonClick}
                title={t("Sort")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                {t("Sort")}
              </button>
            </div>
            <div className="col-md-2 d-flex align-items-end justify-content-end">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100"
                title={t("Filter")}
                onClick={onFilterButtonClick}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                {t("Filter")}
              </button>
            </div>
          
            <div className="col-md-1 d-flex align-items-end justify-content-end">
              {(!isNotDetailScreen || type === "close-unprocessed-stock") &&
                renderExportIcons()}
            </div>
            {showFilter && (
              <UserEditModal
              content="advancefilter"
              section={section}
              modalText="Filter"
              onCloseEditModal={() => {
                  setShowFilter(false);
                }}
                columnOptions={columnOptions}
                onApply={handleApply}
              />
            )}
            {isadvanceShort && (
              <UserEditModal
                content="advancesort"
                section={section}
                modalText={t("Sort")}
                onCloseEditModal={closeChangePasswordModal}
                user={
                  type === "create-issue-batch-detail"
                    ? createIssueDetailColumnsConfig
                    : type === "close-unprocessed-stock-detail"
                    ? closeUnprocessedDetailColumnConfig
                    : type === "approve-stock-detail"
                    ? approveDetailColumnsConfig
                    : type === "create-issue-batch" 
                    ? createIssueColumnsConfig
                    :  type === "close-unprocessed-stock"
                    ? closeUnprocessedColumnConfig
                    : approveColumnsConfig
                }
                onApply={handleApplysort}
              />
            )}
          </div>
        </div>
        <div className="ag-theme-alpine" style={{ height: 310, width: "100%" }}>
          {trackLoadingState ? <CustomLoadingCellRender />:(
          <AgGridReact
            ref={gridRef}
            rowSelection={"multiple"}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            suppressRowClickSelection={true}
            onRowSelected={cellClickedListener}
            rowData={
              type === "approve-stock-detail" ||
              type == "create-issue-batch-detail" ||
              type == "close-unprocessed-stock-detail"
                ? stockDetail
                : rowData
            }
            rowGroupPanelShow={type === "approve-stock" ? "never" : "never"}
            getRowStyle={getRowStyle}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowDragManaged={true}
            animateRows={true}
            pagination={true}
            paginationPageSize={50}
            paginationPageSizeSelector={paginationPageSizeSelector}
            onFirstDataRendered={onFirstDataRendered}
            isRowSelectable={isRowSelectable}
            onRowDataUpdated={onRowDataUpdated}
            //to prevent the issue with sort popup order.
            suppressMovableColumns={true}
            suppressDragLeaveHidesColumns={true}
          />
          )}
        </div>
      </KTCard>

      {(showExportModalExcel || showExportModal) && (
        <UserEditModal
          content="export"
          modalText={t("Export Data")}
          onCloseEditModal={closeExportModal}
          state={""}
          onSave={showExportModal ? onBtnExportPdf : onBtnExport}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
          isLoading={trackLoadingState}
        />
      )}

      {actionToBeCalled == "reject" && (
        <UserEditModal
          content="reject_modal"
          modalText={t("Comments/Reject Notes")}
          onCloseEditModal={closeModal}
          onSave={callrejectStockApi}
          setComments={setComments}
          comments={comments}
          rejectModal={actionToBeCalled}
          data={dataArray}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
    </>
  );
};

export default LookUpScreenForApproveStock;
