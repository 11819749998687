import { createSlice } from "@reduxjs/toolkit";

const POAndStockSlice = createSlice({
  name: "POAndStock",
  initialState: {
    pOHeader: [],
  },
  reducers: {
    updatePoHeader: (state, action) => {
      state.pOHeader = action.payload;
    },
  },
});

export const {
  updatePoHeader,
  
} = POAndStockSlice.actions;
export default POAndStockSlice.reducer;
