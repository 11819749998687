import * as CryptoJS from "crypto-js";

const s1 = 'NOnUSd/2NPHKCllRbM52xjmBLEMNZKajBMUTB3+y0L8=';

export const EncUtil = {
	encrypt: (plainString: string) => {
		const key = CryptoJS.enc.Base64.parse(s1);
		const encryptedData = CryptoJS.AES.encrypt(plainString, key, {
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7
		});
		return encryptedData.toString();
	},
	
};