import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";

export function exportDataToExcelNew(data, fileName,columnStyles = {}) {
    const worksheet = XLSX.utils.json_to_sheet(data);
  
    // Apply styles to the worksheet headers
    Object.keys(columnStyles).forEach(index => {
      const colIndex = XLSX.utils.encode_col(parseInt(index));
      worksheet[`${colIndex}1`].s = {
        alignment: { horizontal: columnStyles[index].halign },
        font: { bold: true }
      };
    });
  
    // Apply styles to body rows if needed
    if(worksheet['!ref']) {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (!worksheet[cell_ref]) continue;
        worksheet[cell_ref].s = { alignment: { horizontal: columnStyles[C]?.halign } };
      }
    }
  }
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    // Generate Excel file buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
  
    // Create a Blob from the buffer and save the file
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
  
    saveAs(blob, `${fileName}.xlsx`);
}
