import { createSlice } from "@reduxjs/toolkit";

const LayoutSlice = createSlice({
  name: "layout",
  initialState: {
    menuList: [],
    bread:"",
    isMultipleCompanyAssigned:true,
  },
  reducers: {
    saveMenuList: (state, action) => {
      state.menuList = action.payload;
    },
    saveBread: (state, action) => {
      state.bread = action.payload;
    },
    saveIsMultipleCompanyAssigned: (state, action) => {
      state.isMultipleCompanyAssigned = action.payload;
    },
  },
});

export const {
  saveMenuList,
  saveBread,
  saveIsMultipleCompanyAssigned
} = LayoutSlice.actions;
export default LayoutSlice.reducer;
