import { createSlice } from '@reduxjs/toolkit';

const getFormattedTimestamp = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = '0'; // fixed as .0

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};

const updateMenuData = (menuList, mniName, type) => {
    menuList.forEach(application => {
        application.items.forEach(item => {
            // Update the top-level item if mniName matches
            if (item.mniName === mniName) {
                if (type === "Fav") {
                    item.favourited = !item.favourited;
                } else if (type === "Pin") {
                    item.pinned = !item.pinned;
                    if (item.pinned) {
                        item.pinnedTimeStamp = getFormattedTimestamp();
                    } else {
                        item.pinnedTimeStamp = null;
                    }
                }
            }
            // Check for nested items
            item.items.forEach(subItem => {
                if (subItem.mniName === mniName) {
                    if (type === "Fav") {
                        subItem.favourited = !subItem.favourited;
                    } else if (type === "Pin") {
                        subItem.pinned = !subItem.pinned;
                        if (subItem.pinned) {
                            subItem.pinnedTimeStamp = getFormattedTimestamp();
                        } else {
                            subItem.pinnedTimeStamp = null;
                        }
                    }
                }
            });
        });
    });

    return menuList;
};

const initialState = {
    menuData: [],
    mniList: [],
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenuData: (state, action) => {
            state.menuData = action.payload;
        },
        updateMenuItem: (state, action) => {
            state.menuData = updateMenuData(state.menuData, action.payload.mniName, action.payload.type);
        },
        setMniName: (state,action) => {
            state.mniList = action.payload;

        }
    },
});

export const { setMenuData, updateMenuItem, setMniName } = menuSlice.actions;
export default menuSlice.reducer;
