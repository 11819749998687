import { useEffect, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { KTSVG } from "../../../../theme/helpers";

const CustomDropdown = ({
  defaultValue = "",
  data = [],
  onChange,
  languages,
  message,
  isSearch = true,
  height = "",
  isEditable = false,
  name = "",
  value={}
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<any>();
  console.debug(selected)
  const [searchText, setSearchText] = useState<any>("");
  const [filteredData, setFilteredData] = useState<any>(languages);
  console.debug(languages,"lllllllllllllllllll")
  const filtered = languages.filter(item => item.value === message);
  console.debug(filtered[0]?.label,"vvvvvvvvvvvvvvvv")
  const handleClick = (item) => {
    onChange(item.value);
    setSelected(item);
    setSearchText("");
    setIsOpen(false);
  };
  const handleChange = (e) => {
    const searchTerm = e.target.value;
    setSearchText(searchTerm);
  };

  useEffect(() => {
    const temp:any = languages?.filter((item) =>
      item?.label?.toLowerCase().trim().includes(searchText.toLowerCase().trim())
    );
    setFilteredData(temp);
  }, [searchText, languages]);
  

  return (
    <>
      <div
        className="w-100 rounded h-40px"
        style={{ position: "relative", border: "1px solid #d6d6d6" }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div style={{ position: "absolute", right: 10, top: 10 }}>
          <RiArrowDownSLine size={20} />
        </div>
        <div
          className="text-start ml-3 fs-5"
          style={{
            marginTop: "8px",
            color: isEditable ? "black" : "grey",
            fontSize: "10px",
          }}
          
        >
          {selected?.label || filtered[0]?.label || value}
        </div>
      </div>

      {isOpen && (
        <div
          className={` bg-white shadow ${
            height ? `h-${height} py-2` : "h-150px py-5"
          }  d-flex  px-2 mt-2 flex-column justify-content-start align-items-start`}
          style={{
            zIndex: 99999999,
            overflow: "hidden",
            overflowY: "scroll",
            position: "absolute",
            width:"94%"
          }}
        >
          {isSearch && (
            <div className="w-100 mb-2" style={{ position: "relative" }}>
              <input
                type="text"
                className="h-30px form-control fs-6 pl-5"
                placeholder="Search"
                value={searchText}
                onChange={(e) => handleChange(e)}
                name={name}
              />
              <div style={{ position: "absolute", top: 3, left: 2 }}>
                <KTSVG
                  path="/media/icons/duotune/general/gen021.svg"
                  className="svg-icon-1 position-absolute ms-3"
                />
              </div>
            </div>
          )}
          {filteredData?.map((item, index) => (
            <div
              className="p-2 w-100 text-start text-hover-primary bg-hover-secondary"
              onClick={() => {
                handleClick(item);
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CustomDropdown;
