/** @format */

import { KTSVG } from "../../../../theme/helpers";


const DeleteModal = (props: any) => {

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-500px">
          <div className="modal-content">
            <div style={{ height: "0px" }}>
              <h3 className="text-center mt-5">{"Confirm Delete"}</h3>
              <div
                className="btn btn-icon btn-sm btn-active-icon-primary float-end"
                data-kt-users-modal-action="close"
                onClick={props.onCancel}
                style={{
                  cursor: "pointer",
                  paddingTop: "0px",
                  marginTop: "-30px",
                  paddingRight: "35px",
                }}
                title="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon-1"
                />
              </div>
            </div>

            <div className="modal-body  mx-5 mx-xl-15 mt-7  d-flex align-items-center text-center justify-content-center">
              <h5>
                {props?.title
                  ? props?.title
                  : "Are you sure you want to delete ?"}
              </h5>
            </div>
            <div className="modal-footer m-auto py-8">
              <button
                type="button"
                className="btn btn-light me-10"
                data-bs-dismiss="modal"
                onClick={props.onCancel}
                title="Close"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-delete"
                onClick={props.onDelete}
                style={{ backgroundColor: "#ff0000", color: "#fff" }}
                title="Delete"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show w-100 h-100"></div>
    </>
  );
};

export { DeleteModal };
