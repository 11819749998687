export function formatDate(dateString:any) {
  const parts = dateString?.split("-");
  if (parts?.length === 3) {
    const [year, month, day] = parts;
    return `${day}/${month}/${year}`;
  }
  return dateString;
}

export function formatDateToDDMMYYY(dateString:any) {
  const parts = dateString?.split(",");
  const datePart=parts[0];
  const timePart=parts[1];
  const dateParts = datePart?.split("/");

  if (dateParts?.length === 3) {
    const [month, day, year] = dateParts;
    return `${day}/${month}/${year}`+','+timePart;
  }

  return dateString;
}

export const formatDatesinArray = (data) => {
  if (!data) return [];
  return data.map((item) => {
    const dateKeys = Object.keys(item).filter((key) =>
      key.toLowerCase().includes("date")
    );
    if (dateKeys.length === 0) return item;

    const formattedDates = {};
    dateKeys.forEach((dateKey) => {
      formattedDates[dateKey] = formatDateIfExist(item[dateKey]);
    });

    const rest = Object.keys(item)
      .filter((key) => !dateKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = item[key];
        return obj;
      }, {});

    return {
      ...rest,
      ...formattedDates,
    };
  });
};

const formatDateIfExist = (dateString) => {
  if (!dateString) return "";
  const [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year}`;
};
