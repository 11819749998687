
const ErrorPage = ({ message, details }) => {
  return (
    <div className='card h-100' style={{ textAlign: 'center', marginTop: '', padding: '200px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <div style={{ fontSize: '7em', color: 'red' }}>❌</div>
      <div style={{ fontSize: '5em', fontWeight: 'bold', marginTop: '20px' }}>Uh oh.</div>
      <div style={{ fontSize: '2em', marginTop: '20px', color: '#555' }}>Something went wrong.</div>
    </div>
  );
};

export default ErrorPage;
