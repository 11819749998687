import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  ExportParams,
  GetContextMenuItemsParams,
  MenuItemDef,
  ProcessHeaderForExportParams,
  ProcessRowGroupForExportParams,
} from "ag-grid-community";
import "./UsersTable.css";
import {
  getUser,
  unLockUserAcc,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { KTCard, KTSVG } from "../../../theme/helpers";
import ActionPopup from "./ActionPopup";
import pdf from "../../../assets/pdf.png";
import excel from "../../../assets/excel.png";
import AdvancedFilterPopup from "../../modules/widgets/components/Filterpopup";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useAuth } from "../../modules/auth";
import imgData from "../../../assets/logo.png";
import CustomLoadingCellRender from "./CustomLoadingCellRender";
import { HandleApiCall } from "../../modules/auth/functions/HandleApiCall";
import {
  formatDateToDDMMYYY,
  formatDate,
  formatDatesinArray,
} from "../../../theme/assets/ts/_utils/FormatData";
import { userTableConfig } from "./UserTableConfig";
import { exportDataToExcel } from "../../../theme/assets/ts/_utils/ExportXLSX";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import SearchInput from "../../utils/SearchInput";

const UsersTable = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const company = useSelector((state: any) => state?.userConfig?.company);
  const companyName = useSelector((state: any) => state?.userConfig?.companyName);
  const gridRef: any = useRef(null);
  const fileName = "users_list";
  const [trackLoadingState, setTrackLoadingState] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [sortData, setSortData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isadvanceShort, setIsadvanceShort] = useState(false);
  const [gridApi, setGridApi] = useState<any>(null);
  const [showExportModal, setShowExportModal] = useState<any>(null);
  const [showExportModalExcel, setShowExportModalExcel] = useState<any>(null);
  const { setEditable,currentUser } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  let [userEmail, userId]: any = currentUser?.sub?.split("#");
  const currentPath = window.location.pathname;
  const localStorageKeyFilter = `filters_${currentPath}`;
  const localStorageKeySort=`sorting${currentPath}`
  const location=useLocation();
  const roles=currentUser?.roles;

  useEffect(() => {
      // Clear local storage when navigating away from the component
      return () => {
        localStorage.removeItem(localStorageKeyFilter);
        localStorage.removeItem(localStorageKeySort);
      };
    }, [localStorageKeyFilter,localStorageKeySort, location]);

  const columnOptions = [
    { value: "USERID", label: "User ID", type: "text" },
    { value: "USERNAME", label: "Name", type: "text" },
    { value: "USER_EMAIL", label: "Email", type: "text" },
    { value: "EQUV_USER", label: "Equiv. User ID", type: "text" },
    { value: "START_DATE", label: "Start Date", type: "date" },
    { value: "END_DATE", label: "End Date", type: "date" },
  ];

  const [exportAllData,setExportAllData]=useState([]);
  const fetchUserAPI = async()=>{
    setTrackLoadingState(true);
    try {
      const response=await getUser(
            "",
            { advancedFilters: filterData, sortedList: sortData },
            "",
            ""
          );
        if(response.data){
          setRowData(formatDatesinArray(response?.data));
          if(filterData.length===0){
            setExportAllData(response?.data);
          }
        }

      
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }

  };

  const closeChangePasswordModal = (event) => {
    setIsadvanceShort(false);
  };

  useEffect(() => {
    fetchUserAPI();
  }, [filterData,sortData]);

  const CustomCheckbox = (props) => {
    const [isLocked,setIsLocked]=useState(props?.data?.accountLocked);
    const handleLockClick = async () => {
      const responseofUnLockUserAcc = await HandleApiCall(
        unLockUserAcc,
        props?.data?.userId
      );
      if (responseofUnLockUserAcc?.errorCode === 0) {
        setIsLocked(false);
      }
    };

    return (
      <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
      <label>{props?.data?.userId}</label>
      {roles==="A"&&isLocked && (
          <div
            onClick={() => handleLockClick()}
            style={{
              width: "100%",
              height: "auto",
              marginBottom: "12px",
            }}
          >
            <KTSVG
              path="/media/icons/duotune/general/lock.svg"
              className="svg-icon-3 position-relative ms-3 mt-5"
            />
          </div>
        )}
      </div>
    );
  };


  const columnDefs = [
    {
      headerName: "User ID",
      field: "userId",
      filter: "agTextColumnFilter",
      rowDrag: true,
      cellRenderer: CustomCheckbox,             
    },
    {
      headerName: "Name",
      field: "userName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Email",
      field: "userEmail",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Equiv. User ID",
      field: "equivalentUser",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Start Date",
      field: "startDate",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "End Date",
      field: "endDate",
      filter: "agDateColumnFilter",
    },
    {
      field: "actions",
      cellRenderer: ActionPopup,
      resizable: false,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      headerClass: "text-center",
      sortable: false,
      filter: false,
    },
  ];

  const exportParams: ExportParams<any> = {
    processRowGroupCallback: rowGroupCallback,
    processHeaderCallback: headerCallback,
  };

  function headerCallback(params: ProcessHeaderForExportParams): string {
    return params.column.getColDef().headerName?.toUpperCase() || "";
  }

  function rowGroupCallback(params: ProcessRowGroupForExportParams): string {
    const node = params.node;
    return node.key?.toUpperCase() || "";
  }

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      var result: (string | MenuItemDef)[] = [
        "copy",
        {
          name: "export",
          subMenu: [
            {
              name: "export to csv",
              action: () => {
                gridRef.current.exportDataAsCsv(exportParams);
              },
            },
            {
              name: "export to excel",
              action: () => {
                gridRef.current.exportDataAsExcel(exportParams);
              },
            },
          ],
        },
      ];
      return result;
    },
    []
  );

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      resizable: true,
      minWidth: 150,
    }),
    []
  );

  const onGridReady = (params) => {
    gridRef.current = params.api;
    if (window.innerWidth < 800) {
      sizeToFit();
    }
    setGridApi(params.api);
  };

  const sizeToFit = () => {
    if (gridRef.current) {
      gridRef.current.sizeColumnsToFit({
        defaultMinWidth: 150,
      });
    }
  };

  const onShortButtonClick = () => {
    setIsadvanceShort(!isadvanceShort);
  };
  const onFilterButtonClick = () => {
    setShowFilter(!showFilter);
  };
  

  const onBtnExport = 
    (type) => {
      function filterUserFields(users) {
        const filteredUsers = users.map((user) => {
          return {
            "User Id": user.userId,
            Name: user.userName,
            Email: user.userEmail,
            "Equiv. User ID": user.equivalentUser,
            "Start Date": user.startDate ? formatDate(user.startDate) : "",
            "End Date": user.endDate ? formatDate(user.endDate) : "",
          };
        });

        return filteredUsers;
      }
      let data;
      if (type === "all") {
        data = filterUserFields(exportAllData);
      } else {
        let filterData = getFilteredData();
        if (filterData?.length > 0) {
          data = filterUserFields(filterData);
        } else {
          data = {
            "User Id": "",
            Name: "",
            Email: "",
            "Equiv. User ID": "",
            "Start Date": "",
            "End Date": "",
          };
        }
      }
      exportDataToExcel(data, `${fileName}.xlsx`);
    };

  const getFilteredData = () => {
    if (gridApi) {
      const filteredRows: any = [];
      gridApi.forEachNodeAfterFilter((node: any) => {
        filteredRows.push(node.data);
      });
      return filteredRows;
    }
  };

  const onBtnExportPdf = 
    (type) => {
      const pdf: any = new jsPDF();

      var image = new Image();
      image.src = imgData;
      let data;
      if (type === "all") {
        data = exportAllData;
      } else {
        data = getFilteredData();
      }
      let formattedData = data?.map((user) => [
        user?.userId,
        user?.userName,
        user?.userEmail,
        user?.equivalentUser,
        user?.startDate ? formatDate(user?.startDate) : "",
        user?.endDate ? formatDate(user?.endDate) : "",
      ]);
      autoTable(pdf, {
        didDrawCell: (data) => {
           // Draw a line at the top of the table (for the header)
    if (data.section === 'head') {
      const lineWidth = 0.1; // Adjust line width as needed
      const startX = data.cell.x;
      const startY = data.cell.y;
      const endX = startX + data.cell.width;
      const endY = startY;
      pdf.setLineWidth(lineWidth);
      pdf.line(startX, startY, endX, endY);
    }
          if (data.section === "head" && data.column.index === 0) {
            pdf.addImage(image, 'PNG', 13, 2, 50, 30);
            let currdate = new Date();
            pdf.setFontSize(10);
            pdf.text(15, 5, 'Printed At: ' + formatDateToDDMMYYY(currdate.toLocaleString()));
            pdf.setFontSize(10);
            pdf.text(80, 15, userId);
      pdf.text(80, 10, userEmail);
      pdf.text(80, 20, companyName + ' - ' + company);
          }
          if (data.row.index !== undefined && data.row.index !== data.table.body.length - 1) {
            // Draw horizontal line between rows
            const lineWidth = 0.1; // Adjust the line width as needed
            const startX = data.cell.x;
            const startY = data.cell.y + data.cell.height;
            const endX = startX + data.cell.width;
            const endY = startY;
            pdf.setLineWidth(lineWidth);
            pdf.line(startX, startY, endX, endY);
          }
        },
        didDrawPage: (HookData: any) => {
          pdf.setFontSize(10);
          pdf.setTextColor(214, 214, 214);
          pdf.text(85, 290, "For internal use only.");
          // Get current page number
          const currentPageNumber = pdf.internal.getCurrentPageInfo().pageNumber;
          pdf.setTextColor(0, 0, 0);
          pdf.text(180, 5, `Page ${currentPageNumber}`);

          // }
        },
        styles: { fontSize: 8 },
        margin: { top: 30 },
        showHead: "everyPage",
        theme: "plain",
        head: [
          [
            "User Id",
            "Name",
            "Email",
            "Equiv. User ID",
            "Start Date",
            "End Date",
          ],
        ],
        body: formattedData,
      });

      pdf.save("user_data.pdf");
    };

  const handlecreateuser = () => {
    setEditable(false);
    navigate("/createuser");
  };

  const handleApply = (data) => {
    setFilterData(data);
  };

  const handleApplysort = (data) => {
    const sortPayload = data.map(({...newObject }) => ({
      column: newObject.column,
      ascDesc: newObject.ascDesc
    }));
    setSortData(sortPayload);
  };

  const closeExportModal = () => {
    setShowExportModal(false);
    setShowExportModalExcel(false);
  };
  
  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 150, 200];
  }, []);

  return (
    <>
      <div>
        <div
          className=" d-flex"
          style={{
            marginBottom: 15,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      </div>
      <KTCard className=" px-10">
        <div className="mb-5 w-100">
          <div
            className="mt-5 row row-cols-1 row-cols-md-4 gy-3 w-100"
            style={{ alignItems: "center" }}
          >
             <SearchInput gridRef={gridRef} type={"userList"} className={"col-md-6"} screen={"UserTable"}/>
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                onClick={handlecreateuser}
                title={t('Create User')}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                <FaPlus style={{ marginRight: "5px" }} size={15} />
                {t('Create User')}
              </button>
            </div>
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                onClick={onShortButtonClick}
                title={t('Sort')}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                {t('Sort')}
              </button>
            </div>
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                onClick={onFilterButtonClick}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
                title={t("Filter")}
              >
                {t("Filter")}
              </button>
            </div>
            {showFilter && (
              <AdvancedFilterPopup
                onClose={() => {
                  setShowFilter(false);
                }}
                columnOptions={columnOptions}
                onApply={handleApply}
              />
            )}
            {isadvanceShort && (
              <UserEditModal
                content="advancesort"
                modalText={t("Sort")}
                onCloseEditModal={closeChangePasswordModal}
                user={userTableConfig}
                onApply={handleApplysort}
                style={{
                  position: "relative",
                  zIndex: 1000,
                }}
              />
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-end mb-5">
          <div></div>

          <div className="icon"></div>

          <div className="icon me-5 d-flex gap-5">
            <img
              src={pdf}
              className="cursor-pointer"
              style={{ height: "35px", width: "35px" }}
              onClick={() => setShowExportModal(true)}
            />
            <img
              src={excel}
              className="cursor-pointer"
              style={{ height: "35px", width: "35px" }}
              onClick={() => setShowExportModalExcel(true)}
            />
          </div>
        </div>

        <div className="ag-theme-alpine" style={{ height: 520, width: "100%" }}>
        {trackLoadingState?(<CustomLoadingCellRender/>):(
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
            rowDragManaged={true}
            animateRows={true}
            onGridReady={onGridReady}
            enableAdvancedFilter={false}
            getContextMenuItems={getContextMenuItems}
            pagination={true}
            // noRowsOverlayComponent={loadingCellRenderer}
            paginationPageSize={50}
            paginationPageSizeSelector={paginationPageSizeSelector}
            suppressDragLeaveHidesColumns={true}
          />
       )}
        </div>
      </KTCard>

      {showExportModal && (
        <UserEditModal
          content="export"
          modalText={t("Export Data")}
          onCloseEditModal={closeExportModal}
          state={""}
          onSave={onBtnExportPdf}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
          isLoading={trackLoadingState}
        />
      )}
      {showExportModalExcel && (
        <UserEditModal
          content="export"
          modalText={t("Export Data")}
          onCloseEditModal={closeExportModal}
          state={""}
          onSave={onBtnExport}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
          isLoading={trackLoadingState}
        />
      )}
    </>
  );
};

export default UsersTable;
