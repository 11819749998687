import { useEffect, useRef, useState } from "react";
import CustomInput from "../../../modules/custom components/CustomInput/CustomInput";
import {
  getNotification,
  saveOrUpdateNotifications,
} from "../../../modules/apps/user-management/users-list/core/_requests";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../modules/auth";
import { handleNotificatonSearchChange } from "../../../../theme/assets/ts/_utils/SearchFunction";
import { formatDatesinArray } from "../../../../theme/assets/ts/_utils/FormatData";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Notification = () => {
  const { currentUser, setIsAuthNeeded } = useAuth();
  console.debug(currentUser, "user");
  const [rowData,setRowData]=useState<any>()
  const {t}=useTranslation();

  const maintainanceFor: any = currentUser?.sub?.split("#")[4];

  console.debug(maintainanceFor, "t3"); // Output: 'T3'
  console.debug("OUUUUUAAA", currentUser);
  const extractValueBetweenHash = () => {
    const regex = /#(.*?)#/;
    const match = currentUser?.sub?.match(regex);
    if (match) {
      return match[1];
    }
    return null; // Handle the case if no match is found
  };
  const currentUserName = extractValueBetweenHash() || "";
  const [searchTerm, setSearchTerm] = useState("");
  const [responseForSearch,setResponseForSearch]=useState<any>();
  
  const company = useSelector((state: any) => state?.userConfig?.company);
  const navigate = useNavigate();
  const fetchNotificationAPI = async () => {
    try {
      const response = await getNotification(company, "", "");
      if(response){
        setRowData(formatDatesinArray(response?.data));
        setResponseForSearch(response);
      }
    } catch (error) {
      throw new Error("Error fetching notification data");
    }
  };
  
  useEffect(() => {
   fetchNotificationAPI();
  }, []);
  
  const handleNavigate = async (item) => {
    console.debug("url", item);
    setIsAuthNeeded(true);
    await saveOrUpdateNotifications({
      isEditCase: true,
      assignTo: currentUserName,
      companyCode: company,
      mniName: `${item?.mniName}`,
      requestedBy: "",
    });
    navigate(`${item.url}`);
  };
  
  const debounceTimeout = useRef<any>(null);
  const debouncedSearching = (value) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      handleNotificatonSearchChange(
        value,
        responseForSearch,
        setRowData
      );
    }, 500);
  };


  
  return (
    <div>
      <div className="col-md-5">
        <CustomInput
          label={t("Search...")}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            debouncedSearching(e.target.value);
          }}
          onCancel={() => {
            setSearchTerm("");
            debouncedSearching("");
          }}
          id={"Search"}
        />
      </div>
      <div className="d-flex justify-content-center align-items-center mt-10">
        <table
          style={{
            width: "60%",
            borderCollapse: "collapse",
            marginBottom: "20px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: "25%",
                  padding: "8px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                {t("Date")}
              </th>
              <th
                style={{
                  width: "35%",
                  padding: "8px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                {t("Requested by")}
              </th>
              <th
                style={{
                  width: "25%",
                  padding: "8px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                {t("Description")}
              </th>
              <th style={{ padding: "8px", backgroundColor: "#f2f2f2" }}>
                {t("Link")}
              </th>
            </tr>
          </thead>
          <tbody>
            {rowData?.map((item, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: item.status === "Read" ? "#e6e6e6" : "white",
                  opacity: item.status === "Read" ? 0.8 : 1,
                  color: item.status === "Read" ? "black" : "black",
                }}
              >
                <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                  {item.createDate}
                </td>
                <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                  {item.createBy}
                </td>
                <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                  {item.description}
                </td>
                <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                  <a
                    onClick={() => handleNavigate(item)}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#007bff",
                      textDecoration: "none",
                      cursor: "pointer",
                      opacity: item.status === "Read" ? 1 : 1,
                    }}
                  >
                    {item.url}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Notification;
