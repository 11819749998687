import { languageChangeHandler } from "../../../../theme/assets/ts/_utils/LanguageUtils";
import { store } from "../../../../reduxStore/store";
import { resetUserConfig } from "../../../../reducers/UserConfigReducer";

function ClearStorage(props) {
  const { saveAuth, setCurrentUser, setCurrentUsers, setCurrent } = props;
  
    localStorage.removeItem("company");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("headerDataOfStock");
    localStorage.removeItem("companyName");
    localStorage.removeItem("sorting/apps/details");
    localStorage.removeItem("sorting-index/apps/details");
    localStorage.removeItem("filters_/apps/details");
    localStorage.removeItem("sorting/apps/system/users");
    localStorage.removeItem("filters_/apps/system/users");
    localStorage.removeItem("sorting-index/apps/system/users");
    localStorage.removeItem("requestNo");
    localStorage.setItem("language", "en");
    store.dispatch(resetUserConfig({}));
    // localStorage.removeItem("persist:root");
    languageChangeHandler("en");
    saveAuth(undefined);
    setCurrentUser(undefined);
    setCurrentUsers(undefined);
    setCurrent(undefined);
    window.dispatchEvent(new Event("logout"));
    window.dispatchEvent(new Event("languageChange"));
  
}

export default ClearStorage;
