import { useEffect, useMemo, useRef, useState } from "react";
import "./UsersTable.css";
import {
  getAllCompany,
  getCompanyWithUserIdFrom,
  getMenus,
  saveUserMenu,
  saveUserMenudet,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { FaChevronDown } from "react-icons/fa";
import { KTCard, KTSVG } from "../../../theme/helpers";
import clsx from "clsx";
import { colors, titleCss } from "../../../constants/colors";
import "ag-grid-enterprise";
import sortalphadown from "../../../../src/assets/sortalphadown.png";
import Tippy from "@tippyjs/react";
import "./ActionPopup.css";
import AdvancedFilterPopup from "../../modules/widgets/components/Filterpopup";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import { useAuth } from "../../modules/auth";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import { HandleApiCall } from "../../modules/auth/functions/HandleApiCall";
import CustomLoadingCellRender from "./CustomLoadingCellRender";
import { columnOptionsConfig } from "./UserTableConfig";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import SearchInputDev from "../../utils/SearchInputDev";
import DataGridWrapper from "../../utils/DataGrid/DataGridWrapper";
import DevExport from "../../modules/custom components/Export/DevExport";

const UserAccessTableDev = (props) => {
  const {t} = useTranslation();
  console.debug("UserAccessTable props", props);
  const userId = props?.data?.data;
  const isEditAccess = props?.data?.isEditAccess;
  const { setEditable, editable } = useAuth();
  
  const [accessData, setAccessData] = useState<any>({
    userId: userId?.userId || "",
    companyCode: "",
    access: [],
  });
  const [showFilter, setShowFilter] = useState(false);
  const [showCopyAccessModal, setShowCopyAccessModal] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [sortData, setSortData] = useState<any>([]);
  const [fromCompanyDropdown, setFromCompanyDropdown] = useState<string[]>([]);
  const [toCompanyDropdown, setToCompanyDropdown] = useState<string[]>([]);
  const [isadvanceShort, setIsadvanceShort] = useState(false);
  const [gridApi, setGridApi] = useState<any>(null);
  const [trackLoadingState, setTrackLoadingState] = useState(false);
  const currentPath = window.location.pathname;
  const localStorageKeyFilter = `filters_${currentPath}`;
  const localStorageKeySort=`sorting${currentPath}`
  const location=useLocation();
  const [isLoading,setIsLoading]=useState(false);

  useEffect(() => {
      // Clear local storage when navigating away from the component
      return () => {
        localStorage.removeItem(localStorageKeyFilter);
        localStorage.removeItem(localStorageKeySort);
      };
    }, [localStorageKeyFilter,localStorageKeySort, location]);

  const fileName = "user_access_data";

  const user = [
    {
      id: "1",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Company Code",
      column: "t.COMP_CODE",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "2",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Company Name",
      column: "c.NAME",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "3",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Module Name",
      column: "mo.NAME",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "4",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Menu Name",
      column: "t.menuItem",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "5",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Menu Group",
      column: "t.menuGroup",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "6",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Access",
      column: "t.access",
      col4: sortalphadown,
      iconIndex: 0,
    },
  ];

  const onFilterButtonClick = () => {
    setShowFilter(!showFilter);
  };


  const [exportAllData,setExportAllData]=useState([]);

  const fetchGetMenuAPI=async()=>{
    setTrackLoadingState(true);
    try {
      const response=await getMenus(
        { advancedFilters: filterData, sortedList: sortData },
        userId?.userId
      );
      if(response.data){
        setRowData(response?.data);
        if(filterData.length===0){
          setExportAllData(response?.data);
        }
      }
      
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }


  }


  useEffect(() => {
    fetchGetMenuAPI();
  }, [filterData, sortData]);

  const closeChangePasswordModal = (event) => {
    setIsadvanceShort(false);
  };

  const getCompanyFromApi = async () => {
    const companyGetResponse = await getCompanyWithUserIdFrom(
      userId?.userId ? userId?.userId : ""
    );
    const companyGetResponseAll = await getAllCompany();

    setFromCompanyDropdown(companyGetResponse);
    setToCompanyDropdown(companyGetResponseAll);
  };

  useEffect(() => {
    getCompanyFromApi();
  }, []);

  const AccessPopup = (props) => {
    const { editable } = useAuth();
    const tippyRef = useRef<any>();
    const [visible, setVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState<any>(null);

    const dropDownContent = (
      <div className="menu-container shadow-sm">
        <div
          onClick={() => handleOptionClick(true)}
          className="menu-items text-hover-primary"
        >
          true
        </div>
        <div
          onClick={() => handleOptionClick(false)}
          className="menu-items text-hover-primary"
        >
          false
        </div>
      </div>
    );

    const onCellValueChanged = (selectedOption) => {
      const changedData: any = {
        ...props?.data,
        access: selectedOption ? "true" : "false",
      };

      const updateRowData = (rowData, changedData, keyToUpdate) => {
        const { compCode, moduleName, menuName, menuGroup } = changedData;

        if (changedData.access == "false") {
          rowData.forEach((item) => {
            if (item[keyToUpdate] === changedData[keyToUpdate]) {
              item.access = changedData.access;
            }
          });
        } else
          rowData.forEach((item) => {
            if (
              changedData.access == "true" &&
              item.compCode === compCode &&
              item.moduleName === moduleName &&
              item.menuName === menuName &&
              item.menuGroup === menuGroup
            ) {
              item.access = changedData.access;
            }
          });
        return rowData;
      };

      const updateBottomFlowRowData = (rowData, changedData) => {
        const { compCode, moduleName, menuName, menuGroup } = changedData;

        const sameCompanyEntries = rowData.filter(
          (item) => item.compCode === compCode
        );
        const sameCompanyEntriesWithSameModules = rowData.filter(
          (item) => item.compCode === compCode && item.moduleName === moduleName
        );

        const trueEntriesCount = sameCompanyEntriesWithSameModules.reduce(
          (count, item) => {
            if (
              item.moduleName === moduleName &&
              item.access === "true" &&
              item.menuName !== "" &&
              item.menuGroup !== ""
            ) {
              return count + 1;
            }
            return count;
          },
          0
        );

        const trueEntriesCountWithSameCompanies = sameCompanyEntries.reduce(
          (count, item) => {
            if (
              item.access === "true" &&
              item.menuName !== "" &&
              item.menuGroup !== ""
            ) {
              return count + 1;
            }
            return count;
          },
          0
        );
        rowData.forEach((item) => {
          if (
            item.compCode === compCode &&
            (item.moduleName === moduleName ||
              item.moduleName == "" ||
              item.moduleName == null) &&
            (item.menuName === "" ||
              item.menuGroup === "" ||
              item.menuName === null ||
              item.menuGroup === null) &&
            trueEntriesCount === 0 &&
            trueEntriesCountWithSameCompanies === 0 &&
            changedData.access === "false"
          ) {
            item.access = "false";
          } else if (
            item.compCode === compCode &&
            item.moduleName === moduleName &&
            (item.menuName === "" ||
              item.menuGroup === "" ||
              item.menuName === null ||
              item.menuGroup === null) &&
            trueEntriesCount === 0 &&
            changedData.access === "false"
          ) {
            item.access = "false";
          } else if (
            item.compCode === compCode &&
            (item.moduleName === moduleName ||
              item.moduleName == "" ||
              item.moduleName == null) &&
            (item.menuName === "" || item.menuGroup === "") &&
            changedData.access === "true"
          ) {
            item.access = "true";
          } else if (
            item.compCode === compCode &&
            item.moduleName === moduleName &&
            item.menuName === menuName &&
            item.menuGroup === menuGroup &&
            changedData.access === "false"
          ) {
            item.access = "false";
          } else if (
            item.compCode === compCode &&
            item.moduleName === moduleName &&
            item.menuName === menuName &&
            item.menuGroup === menuGroup &&
            changedData.access === "true"
          ) {
            item.access = "true";
          }
        });

        return rowData;
      };

      const updatedData =
        (changedData?.moduleName === "" || changedData?.moduleName === null) &&
        (changedData?.moduleCode === null || changedData?.moduleCode === "") &&
        (changedData?.menuName === null || changedData?.menuName === "") &&
        (changedData?.menuGroup === "" || changedData?.menuGroup === null)
          ? updateRowData(rowData, changedData, "compCode")
          : (changedData?.menuName === null || changedData?.menuName == "") &&
            (changedData?.menuGroup === "" || changedData?.menuGroup === null)
          ? (updateBottomFlowRowData(rowData, changedData),
            updateRowData(rowData, changedData, "moduleCode"))
          :
          (changedData?.moduleName !== "" ||
              changedData?.moduleName !== null) &&
            (changedData?.moduleCode !== null ||
              changedData?.moduleCode !== "") &&
            (changedData?.menuName !== null || changedData?.menuName !== "") &&
            (changedData?.menuGroup !== "" || changedData?.menuGroup !== null)
          ? updateBottomFlowRowData(rowData, changedData)
          : rowData;

      setRowData(updatedData);
      setTimeout(() => {
        if (gridRef.current) {
          gridRef.current.instance().refresh(); // Reloads data from the data source
          // gridRef.current.instance().repaint(); // Redraws the grid
        }
      }, 1000);
      // setTimeout(() => {
      //   gridApi.refreshCells();
      //   gridApi.redrawRows();
      // }, 1000);

      const existingIndex = accessData.access.findIndex((item) =>
        isEqualObjects(item, changedData)
      );

      if (existingIndex !== -1) {
        const updatedAccess = [...accessData.access];
        updatedAccess.splice(existingIndex, 1);
        setAccessData({ ...accessData, access: updatedAccess });
      } else {
        setAccessData({
          ...accessData,
          access: [...accessData.access, changedData],
        });
      }
    };

    function isEqualObjects(objA: any, objB: any): boolean {
      return JSON.stringify(objA) === JSON.stringify(objB);
    }
    const handleOptionClick = (selectedOption) => {
      hide();
      setSelectedOption(selectedOption);
      onCellValueChanged(selectedOption);
    };

    const show = () => setVisible(true);
    const hide = () => setVisible(false);

    const onClick = (event) => {
      event.preventDefault();
      if (visible) {
        hide();
      } else {
        show();
      }
    };

    return (
      <Tippy
        ref={tippyRef}
        content={dropDownContent}
        visible={visible}
        onClickOutside={hide}
        allowHTML={true}
        arrow={false}
        appendTo={document.body}
        interactive={true}
        placement="right"
        disabled={!editable}
      >
        <a
          href="#!"
          className="btn btn-light btn-active-light-primary btn-sm"
          onClick={onClick}
        >
          {selectedOption !== null
            ? selectedOption.toString()
            : props?.data?.access}
          <KTSVG
            path="/media/icons/duotune/arrows/arr072.svg"
            className="svg-icon-5 m-0"
          />
        </a>
      </Tippy>
    );
  };

  const tempColumnDefs = [
    {
      headerName: "Company Code",
      caption: "Company Code",
      columnName: "Company Code",
      field: "compCode",
      minWidth: 50,
    },
    {
      headerName: "Company Name",
      caption: "Company Name",
      columnName: "Company Name",
      field: "compName",
      minWidth: 100,
    },
    {
      headerName: "Module Name",
      caption: "Module Name",
      columnName: "Module Name",
      field: "moduleName",
      minWidth: 100,
    },
    {
      headerName: "Menu Name",
      caption: "Menu Name",
      columnName: "Menu Name",
      field: "menuName",
      minWidth: 100,
    },
    {
      headerName: "Menu Group",
      caption: "Menu Group",
      columnName: "Menu Group",
      field: "menuGroup",
      minWidth: 50,
    },
    {
      headerName: "Access",
      caption: "Access",
      columnName: "Access",
      field: "access",
      minWidth: 100,
      halign: "center",
      cellRenderer: AccessPopup,
      sortable: false,
    },
  ];
   const [columnDefs,setColumnDefs]=useState<any>(tempColumnDefs);

  useEffect(() => {
    if (rowData && accessData && gridApi) {
      setColumnDefs(tempColumnDefs);
    }
  }, [rowData, accessData, gridApi]);

  useEffect(() => {
    if (gridApi && columnDefs) {
      columnDefs.map((item) => {
        if (item.headerName === "Access") {
          item.editable = editable ? true : false;
        }
      });
      setColumnDefs(columnDefs);
      // gridApi.setColumnDefs(columnDefs);
    }
  }, [editable]);


  const gridRef: any = useRef(null);

  const onGridReady = (params) => {
    setGridApi(params.component);
  };

  const headerRef: any = useRef(null);

  const handleMouseEnter = () => {
    if (headerRef.current) {
      headerRef.current.setAttribute("data-bs-toggle", "");
    }
  };

  const onShortButtonClick = () => {
    setIsadvanceShort(!isadvanceShort);
  };

  const handleMouseLeave = () => {
    if (headerRef.current) {
      headerRef.current.setAttribute("data-bs-toggle", "collapse");
    }
  };


  const handleApply = (data) => {
    setFilterData(data);
  };
  const handleApplysort = (data) => {
    const sortPayload = data.map(({...newObject }) => ({
      column: newObject.column,
      ascDesc: newObject.ascDesc
    }));
    setSortData(sortPayload);
  };

  const closeCopyModal = () => {
    setShowCopyAccessModal(false);
  };

  const onCopyAccess = async (data) => {
    await saveUserMenudet(
      {},
      userId?.userId,
      data?.selectedCompanyCodeFrom,
      data?.selectedCompanyCodeTo
    );
    toastMessage(t("Access copied successfully."), "success");
    //refetch();
    fetchGetMenuAPI(); // need to check the usecase of this api
  };

  const saveUserAccess = async () => {
    setIsLoading(true);
    !isEditAccess && setEditable(false);
    await HandleApiCall(saveUserMenu, accessData);
    setAccessData({
      userId: userId?.userId,
      companyCode: "",
      access: [],
    });
    setIsLoading(false);
    //refetch();
    fetchGetMenuAPI() // need to check the usecase of this api
    getCompanyFromApi();
  };

  const handleedit = () => {
    setEditable(true);
  };

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 150, 200];
  }, []);

  return (
    <>
      <div className="accordion mt-10" id="accordionExample">
        <div className="accordion-item">
          <KTCard className="px-10">
            <div
              className=" bg-white cursor-pointer w-100 px-0"
              ref={headerRef}
            >
              <div className="d-flex justify-content-between align-items-center w-100 row row-cols-1 row-cols-md-3 gy-3 mr-3 py-5">
                <div
                  className={clsx("col-md-2", titleCss)}
                  style={{ color: colors.title }}
                >
                  {t('User Access')}
                </div>
                <SearchInputDev gridRef={gridRef} type="accessTable" className="col-md-4" screen={"UserAccessTable"} />
                <button
                  className="btn  rounded-pill text-white  text-wrap  fs-7 primary-button col-md-1 "
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={onShortButtonClick}
                  title={t('Sort')}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#0a7eac",
                  }}
                >
                  {t('Sort')}
                </button>

                <button
                  className="btn  rounded-pill text-white  text-wrap  fs-7 primary-button col-md-1"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={onFilterButtonClick}
                  title={t('Filter')}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#0a7eac",
                  }}
                >
                  {t('Filter')}
                </button>

                <button
                  className={`btn rounded-pill text-white text-wrap fs-7 ${
                    fromCompanyDropdown?.length == 0
                      ? "btn-secondary"
                      : "btn-primary"
                  } col-md-1`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  title={t('Copy')}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() =>
                    fromCompanyDropdown?.length == 0
                      ? console.debug()
                      : setShowCopyAccessModal(true)
                  }
                >
                  {t('Copy')}
                </button>
                <button
                     className={`btn rounded-pill text-white text-wrap fs-7 btn-primary col-md-2`}
                      style={{
                        backgroundColor: "#0a7eac",
                        display: editable ? "none" : "unset",
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={handleedit}
                      title={t('Edit Access')}
                    >
                      {t('Edit Access')}
                    </button>

                <div className="col-md-1 d-flex align-items-center justify-content-end">
                  <div
                    className="btn"
                    data-toggle="collapse"
                    data-target="#accessTable"
                    aria-expanded="false"
                    aria-controls="accessTable"
                  >
                    <FaChevronDown size={15} />
                  </div>
                </div>
              </div>
            </div>
            {showFilter && (
              <AdvancedFilterPopup
                onClose={() => {
                  setShowFilter(false);
                }}
                columnOptions={columnOptionsConfig}
                onApply={handleApply}
              />
            )}
            {isadvanceShort && (
              <UserEditModal
                content="advancesort"
                modalText={t("Sort")}
                onCloseEditModal={closeChangePasswordModal}
                user={user}
                onApply={handleApplysort}
                style={{
                  position: "relative",
                  zIndex: 1000,
                }}
              />
            )}
            <div
              id="accessTable"
              className={`accordion-collapse collapse show`}
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                {/* <div className="d-flex justify-content-between align-items-end mb-5">
                  <div></div>

                  <div className="icon"></div>

                
                  <DevExport 
                   gridRef={gridRef}
                   apiData={exportAllData}
                   file={fileName}
                   columnDefs={tempColumnDefs}
                   />
                </div> */}
                {trackLoadingState?(<CustomLoadingCellRender/>):(
                     <DataGridWrapper
                     gridRef={gridRef}
                     rowData={rowData}
                     columnDefs={tempColumnDefs}
                     rowDragManaged={true}
                     animateRows={true}
                     onGridReady={onGridReady}
                     enableAdvancedFilter={false}
                     pagination={true}
                     paginationPageSize={50}
                     paginationPageSizeSelector={paginationPageSizeSelector}
                     rowGroupPanelShow={true}
                     isFilterRow={true}
                     height={620}
                     exportEnabled={true}
                     fontSizeForPdfExport={12}
                     fileName={fileName}
                     />
                )}
              </div>
            </div>
            {editable && (
              <div className="text-center mt-5 mb-5">
                <button
                  type="button"
                  title={t('Cancel')}
                  className="btn btn-secondary rounded-pill me-5"
                  style={{ lineHeight: "1" }}
                  onClick={() => setEditable(false)}
                >
                  {t('Cancel')}
                </button>
                <button
                  type="submit"
                  title={t('Save')}
                  onClick={() => saveUserAccess()}
                  disabled={isLoading || trackLoadingState }
                  className="btn text-white rounded-pill"
                  style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
                >
                  {isLoading?t('Saving'):t('Save')}
                </button>
              </div>
            )}
          </KTCard>
        </div>
      </div>
      {(showCopyAccessModal) && (
        <UserEditModal
          content={"copy_access" }
          modalText={"Copy Access"}
          onCloseEditModal={closeCopyModal }
          user={showCopyAccessModal ? "reset" : ""}
          userId={userId}
          data={showCopyAccessModal ? fromCompanyDropdown : null}
          toData={showCopyAccessModal ? toCompanyDropdown : null}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
          isLoading={trackLoadingState}
          onCopyAccess={onCopyAccess}
        />
      )}
    </>
  );
};

export default UserAccessTableDev;
