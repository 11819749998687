import UserDetail from "./UserDetailpage";
import { useLocation } from "react-router-dom";
import UserAccessTableDev from "./UserAccessTableDev";

const UserAccessDetailDev = () => {
  const { state } = useLocation();
  return (
    <div>
      <div>
        <UserDetail data={state} />
      </div>
      <div>
        <UserAccessTableDev data={state} />
      </div>
    </div>
  );
};

export default UserAccessDetailDev;
