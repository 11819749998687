import { createSlice } from "@reduxjs/toolkit";

const counterSlice = createSlice({
  name: "refreshApiState",
  initialState: {
    refreshApiState: true,
  },
  reducers: {
    updateRefreshState: (state, action) => {
      state.refreshApiState = action.payload;
    },
  },
});

export const { updateRefreshState } = counterSlice.actions;
export default counterSlice.reducer;
