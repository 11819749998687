import { Route, Routes, Navigate, Outlet, useLocation } from "react-router-dom";
import { MasterLayout } from "../../theme/layout/MasterLayout";
import { useAuth } from "../modules/auth";
import { PageLink, PageTitle } from "../../theme/layout/core";
import Dashboard from "../pages/dashboard/test-dashboard/Dashboard";
import { SelectCompanyPage } from "../modules/auth/components/SelectCompanyList"; 
import UsersTable from "../pages/user/UsersTable";
import CreateUser from "../pages/user/CreateUser";
import UserAccessDetail from "../pages/user/UserAccessDetail";
import StockTable from "../pages/stock/StockTable";
import RequestStock from "../pages/stock/RequestStock";
import StockDetail from "../pages/stock/StockDetail";
import ApproveStockRequest from "../pages/dashboard/capaign_components/ApproveStockRequest";
import Notification from "../pages/user/pdfExport/Notification";
import POAndStockEnquiry from "../pages/PO-Stock-Enquiry/POAndStockEnquiry";
import UsersTableDev from "../pages/user/UserTableDev";
import DevPOAndStockEnquiry from "../pages/PO-Stock-Enquiry/DevPOAndStockEnquiry";
import UserAccessTableDev from "../pages/user/UserAccessTableDev";
import StockTableDev from "../pages/stock/StockTableDev";
import StockListDev from "../pages/stock/StocklistDev";
import StockDetailDev from "../pages/stock/StockDetailDev";
import UserAccessDetailDev from "../pages/user/UserAccessDetailDev";
import ApproveStockRequestDev from "../pages/ApproveIssueBatch/ApproveStockRequestDev";
import RequestStockDev from "../pages/stock/RequestStockDev";
import InvoiceListing from "../pages/invoice/InvoiceListing";
import CreateInvoice from "../pages/invoice/CreateInvoice";
import InvoiceCostAllocation from "../pages/invoice/InvoiceCostAllocation";
import BankApprovalListing from "../pages/bankApproval/BankApprovalListing";
import EditBankDetails from "../pages/bankApproval/EditBankDetails";

const PrivateRoutes = () => {

  const { editable, pageStatus } = useAuth();

  const location:any = useLocation();

  const { state } = location;
  const containsenquary:any= state && state?.breadcrumb =="enquary";
  
  const currentUrl = window.location.href;

  const includesUsers = currentUrl?.includes("/users");
  const lastWord = currentUrl?.split("/").filter(Boolean).pop();

  const userList: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "System Module",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "User Listing",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];
  const details = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "System Module",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "User Listing",
      path: "/system/users",
      isSeparator: false,
      isActive: true,
    },
    // Conditionally include the "Create User" object based on the URL
    ...(includesUsers
      ? [
          {
            editdata: state,
            title: "Create User",
            path: "/createuser",
            isSeparator: false,
            isActive: false,
          },
        ]
      : []),
    {
      title: "User Details",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];
  const Createstock: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Inventory",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Stock Request",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Create Stock Request",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];
  const Stock: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Inventory",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Stock Request",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Create Stock Request",
      path: "/stock",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Request Stock",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];

  const POEnquiryLink: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "PO Enquiry",
      path: "/po-enquiry",
      isSeparator: false,
      isActive: true,
    },
  ];

  const PlantEnquiryLink: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Plant Enquiry",
      path: "/plantEnquiry",
      isSeparator: false,
      isActive: true,
    },
  ];

  const StockEnquiryLink: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Stock Enquiry",
      path: "/stock-enquiry",
      isSeparator: false,
      isActive: true,
    },
  ];

  const ExpenseDetails: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Inventory",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Stock Request",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Create Stock Request",
      path: "/stock",
      isSeparator: false,
      isActive: false,
    },
  ];

  const invoiceList: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Accounts Payable",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Invoice Listing",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];

  if (pageStatus !== "View" && pageStatus !== "Edit") {
    ExpenseDetails.push({
      title: "Request Stock",
      path: "/stockdetail",
      isSeparator: false,
      isActive: false,
    });
  }else if (pageStatus === "View"){
    ExpenseDetails.push({
      title: "View Stock Request",
      path: "",
      isSeparator: false,
      isActive: false,
    });

  }else if (pageStatus === "Edit"){
    ExpenseDetails.push({
      title: "Edit Stock Request",
      path: "",
      isSeparator: false,
      isActive: false,
    });

  }



 const ExpenseDetailsEnquary: Array<PageLink> = [
  {
    title: "Home",
    path: "/dashboard",
    isSeparator: false,
    isActive: true,
  },
  {
    title: "Stock Enquiry",
    path: "/stock-enquiry",
    isSeparator: false,
    isActive: true,
  },
  {
    title: "Request Stock",
    path: "",
    isSeparator: false,
    isActive: false,
  }

 ]

  const StockLookup: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Inventory",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Stock Request",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Create Stock Request",
      path: "/stock",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Request Stock",
      path: "/stockdetail",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Expense Details",
      path: "/requeststock",
      isSeparator: false,
      isActive: false,
    },
    {
      title: lastWord,
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];
  const JobCode: Array<any> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Inventory",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Stock Request",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
  ];
  const editabledetails: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "System Module",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "User List",
      path: "/system/users",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "User Details",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];

  const createuser: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "System Module",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "User List",
      path: "/system/users",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Create User",
      path: "",
      isSeparator: false,
      isActive: false,
    },
    
  ];
  const edituser: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "System Module",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "User List",
      path: "/system/users",
      isSeparator: false,
      isActive: true,
    },
    {
      editdata: state,
      title: "Edit User",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];
  const notification: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Notifications",
      path: "",
      isSeparator: false,
      isActive: true,
    },
  ];

  const createInvoice: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Accounts Payable",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Invoice Listing",
      path: "/invoice",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Create Invoice",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];

  const editInvoice: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Accounts Payable",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Approve Invoice",
      path: "/approveInvoice",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Edit Invoice",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];

  const editBatchInvoice: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Accounts Payable",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Create Invoice Batch",
      path: "/createInvoiceBatch",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Edit Invoice",
      path: "",
      isSeparator: false,
      isActive: false,
    }
  ]

  const editUnprocessedInvoice: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Accounts Payable",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Close Unprocessed Invoice",
      path: "/unprocessedInvoice",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Edit Invoice",
      path: "",
      isSeparator: false,
      isActive: false,
    }
  ]

  const approveInvoice: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Accounts Payable",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Approve Invoice",
      path: "/approveInvoice",
      isSeparator: false,
      isActive: true,
    }
  ];

  const createInvoiceBatch: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Accounts Payable",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Create Invoice Batch",
      path: "/createInvoiceBatch",
      isSeparator: false,
      isActive: true,
    }
  ];

  const UnprocessedInvoice: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Accounts Payable",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Close Unprocessed Invoice",
      path: "/unprocessedInvoice",
      isSeparator: false,
      isActive: true,
    }
  ];

  const bankDetails: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Vendor Bank Approval",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Update Bank Details",
      path: "",
      isSeparator: false,
      isActive: true,
    }
  ];

  const createBankDetails: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    // {
    //   title: "Vendor Bank Approval",
    //   path: "/dashboard",
    //   isSeparator: false,
    //   isActive: true,
    // },
    {
      title: "Update Bank Details",
      path: "/bankDetailList",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Edit Bank Details",
      path: "",
      isSeparator: false,
      isActive: true,
    }
  ];

  const createBankApprovalDetails: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    // {
    //   title: "Vendor Bank Approval",
    //   path: "/dashboard",
    //   isSeparator: false,
    //   isActive: true,
    // },
    {
      title: "Approve Bank Details",
      path: "/bankApprovalList",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Edit Bank Details",
      path: "",
      isSeparator: false,
      isActive: true,
    }
  ];

  const bankApproval: Array<PageLink> = [
    {
      title: "Home",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Vendor Bank Approval",
      path: "/dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Approve Bank Details",
      path: "",
      isSeparator: false,
      isActive: true,
    }
  ];


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="*" element={<Navigate to="/dashboard" />} />
        <Route path="selectcompany" element={<SelectCompanyPage />} />
        <Route element={<Outlet />}>
          <Route
            path="createuser"
            element={
              <>
                <PageTitle
                  breadcrumbs={editable ? edituser : createuser}
                ></PageTitle>
                <CreateUser data={state} />
              </>
            }
          ></Route>
        </Route>
        <Route element={<Outlet />}>
          <Route
            path="details"
            element={
              <>
                <PageTitle
                  breadcrumbs={editable ? editabledetails : details}
                ></PageTitle>
                <UserAccessDetail />
              </>
            }
          ></Route>
           <Route
            path="detailsdev"
            element={
              <>
                <PageTitle
                  breadcrumbs={editable ? editabledetails : details}
                ></PageTitle>
                <UserAccessDetailDev />
              </>
            }
          ></Route>
        </Route>
        <Route element={<Outlet />}>
          <Route
            path="requeststock"
            element={
              <>
                <PageTitle breadcrumbs={containsenquary?
                  ExpenseDetailsEnquary:
                  ExpenseDetails}></PageTitle>
                <RequestStock />
              </>
            }
          ></Route>
        </Route>
        <Route element={<Outlet />}>
          <Route
            path="requeststockdev"
            element={
              <>
                <PageTitle breadcrumbs={containsenquary?
                  ExpenseDetailsEnquary:
                  ExpenseDetails}></PageTitle>
                <RequestStockDev />
              </>
            }
          ></Route>
        </Route>
        <Route element={<Outlet />}>
          <Route
            path="stockdetail"
            element={
              <>
                <PageTitle breadcrumbs={Stock}></PageTitle>
                <StockDetail />
              </>
            }
          ></Route>
           <Route
            path="stockdetaildev"
            element={
              <>
                <PageTitle breadcrumbs={Stock}></PageTitle>
                <StockDetailDev />
              </>
            }
          ></Route>
        </Route>
        <Route element={<Outlet />}>
          <Route
            path="/system/users"
            element={
              <>
                <PageTitle breadcrumbs={userList}></PageTitle>
                <UsersTable />
              </>
            }
          ></Route>
        </Route>
        <Route element={<Outlet />}>
          <Route
            path="/system/usersdev"
            element={
              <>
                <PageTitle breadcrumbs={userList}></PageTitle>
                <UsersTableDev />
              </>
            }
          ></Route>
        </Route>
        <Route element={<Outlet />}>
          <Route
            path="/stock"
            element={
              <>
                <PageTitle breadcrumbs={Createstock}></PageTitle>
                <StockTable />
              </>
            }
          ></Route>
           <Route
            path="/stockdev"
            element={
              <>
                <PageTitle breadcrumbs={Createstock}></PageTitle>
                <StockTableDev />
              </>
            }
          ></Route>
      
          <Route
            path="/notification"
            element={
              <>
                <PageTitle breadcrumbs={notification}></PageTitle>
                <Notification />
              </>
            }
          ></Route>
        </Route>
        <Route
          path="dashboard"
          element={
            <>
              <Dashboard />
            </>
          }
        ></Route>
        <Route
          path="po-enquiry"
          element={
            <>
              <PageTitle breadcrumbs={POEnquiryLink}></PageTitle>
              <POAndStockEnquiry type={"PO_ENQUIRY"} key={location.pathname} />
            </>
          }
        ></Route>

        <Route
          path="stock-enquiry"
          element={
            <>
              <PageTitle breadcrumbs={StockEnquiryLink}></PageTitle>
              <POAndStockEnquiry type={"STOCK_ENQUIRY"} key={location.pathname} />
            </>
          }
        ></Route>
         <Route
          path="po-enquirydev"
          element={
            <>
              <PageTitle breadcrumbs={POEnquiryLink}></PageTitle>
              <DevPOAndStockEnquiry type={"PO_ENQUIRY"} key={location.pathname} />
            </>
          }
        ></Route>

        <Route
          path="stock-enquirydev"
          element={
            <>
              <PageTitle breadcrumbs={StockEnquiryLink}></PageTitle>
              <DevPOAndStockEnquiry type={"STOCK_ENQUIRY"} key={location.pathname} />
            </>
          }
        ></Route>

        <Route
          path="approve-stock-request"
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  ...JobCode,
                  {
                    title: "Approve Stock Request",
                    path: "",
                    isSeparator: false,
                    isActive: false,
                  },
                ]}
              ></PageTitle>
              <ApproveStockRequest type={"approve-stock-request"} key={location.pathname} />
            </>
          }
        ></Route>
        <Route
          path="approve-stock-requestdev"
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  ...JobCode,
                  {
                    title: "Approve Stock Request",
                    path: "",
                    isSeparator: false,
                    isActive: false,
                  },
                ]}
              ></PageTitle>
              <ApproveStockRequestDev type={"approve-stock-request"} key={location.pathname} />
            </>
          }
        ></Route>
        <Route
          path="create-issue-batch"
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  ...JobCode,
                  {
                    title: "Convert Stock Request into Issue batch",
                    path: "",
                    isSeparator: false,
                    isActive: false,
                  },
                ]}
              ></PageTitle>
              <ApproveStockRequest type={"create-issue-batch"} key={location.pathname} />
            </>
          }
        ></Route>
        <Route
          path="create-issue-batchdev"
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  ...JobCode,
                  {
                    title: "Convert Stock Request into Issue batch",
                    path: "",
                    isSeparator: false,
                    isActive: false,
                  },
                ]}
              ></PageTitle>
              <ApproveStockRequestDev type={"create-issue-batch"} key={location.pathname} />
            </>
          }
        ></Route>
        <Route
          path="close-unprocessed-stock"
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  ...JobCode,
                  {
                    title: "Close Unprocessed Stock Request",
                    path: "",
                    isSeparator: false,
                    isActive: false,
                  },
                ]}
              ></PageTitle>
              <ApproveStockRequest type={"close-unprocessed-stock"} key={location.pathname} />
            </>
          }
        ></Route>
        <Route
          path="close-unprocessed-stockdev"
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  ...JobCode,
                  {
                    title: "Close Unprocessed Stock Request",
                    path: "",
                    isSeparator: false,
                    isActive: false,
                  },
                ]}
              ></PageTitle>
              <ApproveStockRequestDev type={"close-unprocessed-stock"} key={location.pathname} />
            </>
          }
        ></Route>
        <Route
            path="/invoice"
            element={
              <>
                <PageTitle breadcrumbs={invoiceList}></PageTitle>
                <InvoiceListing type={'create'}/>
              </>
            }
          ></Route>
          <Route
            path="/createInvoice"
            element={
              <>
                <PageTitle
                  breadcrumbs={state && state.previousPage === 'create' ? createInvoice : (state && state.previousPage === 'approve' ? editInvoice : (state && state.previousPage === 'issue' ? editBatchInvoice : editUnprocessedInvoice))}
                ></PageTitle>
                <CreateInvoice />
              </>
            }
          ></Route>
           <Route
            path="/approveInvoice"
            element={
              <>
                <PageTitle
                  breadcrumbs={approveInvoice}
                ></PageTitle>
                <InvoiceListing type={'approve'}/>
              </>
            }
          ></Route>
          <Route
            path="/costAllocation"
            element={
              <>
                <InvoiceCostAllocation />
              </>
            }
          ></Route>
           <Route
            path="/createInvoiceBatch"
            element={
              <>
              <PageTitle breadcrumbs={createInvoiceBatch}></PageTitle>
              <InvoiceListing type={'issue'}/>
              </>
            }
          ></Route>
          <Route
            path="/unprocessedInvoice"
            element={
              <>
              <PageTitle breadcrumbs={UnprocessedInvoice}></PageTitle>
              <InvoiceListing type={'close'}/>
              </>
            }
          ></Route>
          <Route
            path="/bankDetailList"
            element={
              <>
              <PageTitle breadcrumbs={bankDetails}></PageTitle>
              <BankApprovalListing type={'create'}/>
              </>
            }
          ></Route>
          <Route
            path="/bankApprovalList"
            element={
              <>
              <PageTitle breadcrumbs={bankApproval}></PageTitle>
              <BankApprovalListing type={'approve'}/>
              </>
            }
          ></Route>
          <Route
            path="/editBankDetails"
            element={
              <>
              <PageTitle breadcrumbs={state && state.previousPage === 'create' ? createBankDetails : createBankApprovalDetails}></PageTitle>
              <EditBankDetails/>
              </>
            }
          ></Route>
          <Route
          path="/plantEnquiry"
          element={
            <>
              <PageTitle breadcrumbs={PlantEnquiryLink}></PageTitle>
              <DevPOAndStockEnquiry type={"PLANT_ENQUIRY"} key={location.pathname} />
            </>
          }
        ></Route>
      </Route>
    </Routes>
  );
};


export { PrivateRoutes };
