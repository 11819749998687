/* eslint-disable react/jsx-no-target-blank */

const SidebarFooter = () => {
  return (
    <div className='app-sidebar-footer bg-primary flex-column-auto pt-2 pb-6 px-6 text-center' id='kt_app_sidebar_footer'>
        <span className='c-white'>Version 1.3.19</span>
    </div>
  )
}

export { SidebarFooter }
