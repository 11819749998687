import { Suspense } from "react";
import { Provider } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { persistor, store } from "../reduxStore/store";
import { MasterInit } from "../theme/layout/MasterInit";
import { LayoutProvider, LayoutSplashScreen } from "../theme/layout/core";
import { AuthInit } from "./modules/auth";
import ErrorBoundary from "./Errorboundry";
import { PersistGate } from "redux-persist/integration/react";


const App = () => {
  console.debug("App Rendering !!")
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<LayoutSplashScreen />}>
              <LayoutProvider>
                <AuthInit>
                  <Outlet />
                  <ToastContainer />
                  <MasterInit />
                </AuthInit>
              </LayoutProvider>
          </Suspense>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
