

const ComingSoon = () => {
  return (
    <div
      className="fs-xxl-1 fw-bold d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div>Coming Soon!</div>
    </div>
  );
};

export default ComingSoon;
