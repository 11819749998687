import { useState, useEffect, useRef } from "react";
import "./ActionPopup.css";
import { useDispatch, useSelector } from "react-redux";
import { updateLookupData, updateStockCode } from "../../../reducers/lookupReducer";

interface SelectDropDown {
  label: string | undefined;
  description: string | undefined;
  details?: string | undefined;
  oemId?: string | undefined;
}

interface DropDownDataSet {
  code?: string;
  description?: string;
  workOrderNo?: string;
  details?: string;
  location?: string;
  jobCode?: string;
  phaseCode?: string;
  reqCode?: string;
  oemId?: string;
}

const ActionPopupWithSearchDev = (props) => {
  const isViewOnly = props?.isViewOnly;
  const selectedRowIndex = useSelector((state: any) => state?.currentIndex?.value);
  const allExpendeDropdownData = useSelector((state: any) => state?.lookupData?.allExpenseGroup);
  const allStockDropdownData = useSelector((state: any) => state?.lookupData?.stockDataOfExpenseGroup);
  const locationDropdownData = useSelector((state: any) => state?.lookupData?.locationDataOfExpenseGroup);
  const stockExpenseDetailsData = useSelector((state: any) => state?.lookupData?.lookupData);
  const [filteredOptions, setFilteredOptions] = useState<any>([]);
  const dispatch = useDispatch();
  const mergedCodeWithReqCode = allStockDropdownData?.filter((obj) => obj.reqCode !== "").map((obj) => obj.code);

  let headerName = props?.data?.column?.caption;
  const [isActive, setIsActive] = useState(false);

  const diableDropdown = props?.data?.data?.workOrderNo
    ? (headerName == "Job Code" ||
        headerName == "Phase Code" ||
        headerName == "Req Code" ||
        headerName == "GL Account") &&
      props?.data?.data?.workOrderNo
    : props?.data?.data?.reqCode
    ? headerName == "Req Code" &&
      mergedCodeWithReqCode.includes(props?.data?.data?.code)
    : "";

  let disableGl = props?.data?.data?.glAccount
    ? headerName == "GL Account" &&
      props?.data?.data?.reqCode &&
      props?.data?.data?.phaseCode &&
      props?.data?.data?.jobCode
    : "";

  const codedata: DropDownDataSet[] = allStockDropdownData || [];
  const [searchTerm, setSearchTerm] = useState("");

  const getPlaceHolderTitle = () => {
    let title = "";
    switch (headerName) {
      case "Stock": {
        title = props?.data?.data?.code;
        break;
      }
      case "From Location": {
        title = props?.data?.data?.location;
        break;
      }
      case "Work Order Number": {
        title = props?.data?.data?.workOrderNo;
        break;
      }
      case "Job Code": {
        title = props?.data?.data?.jobCode;
        break;
      }
      case "Phase Code": {
        title = props?.data?.data?.phaseCode;
        break;
      }
      case "GL Account": {
        title = props?.data?.data?.glAccount;
        break;
      }
      case "Req Code": {
        title = props?.data?.data?.reqCode;
        break;
      }
      default: {
        break;
      }
    }
    return title;
  };

  const placeHolderTitle: JSX.Element | string = getPlaceHolderTitle() || "";


  useEffect(() => {

    const data = getFilterData();
    if (!data) {
      setFilteredOptions([]);
      return;
    }

    const filteredData = data?.filter(
      (option: any) =>
        option?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        (option.description &&
          option?.description
            ?.toLowerCase()
            ?.includes(searchTerm?.toLowerCase()))
    );


    setFilteredOptions(filteredData);
  }, [searchTerm,locationDropdownData]);       

  const getFilterData = () => {
    let tempData: DropDownDataSet[] = [];
    
    switch (headerName) {
      case "Stock": {
        tempData = codedata;
        break;
      }
      case "Work Order Number": {
        tempData = allExpendeDropdownData?.workOrders;
        break;
      }
      case "Job Code": {
        tempData = allExpendeDropdownData?.jobCodes;
        break;
      }
      case "Phase Code": {
        if(props?.data?.data?.jobCode){
          tempData = allExpendeDropdownData?.jobCodes?.find((e) => e.jobCode.includes(props?.data?.data?.jobCode))?.phaseCode;
        }
        break;
      }
      case "GL Account": {
        tempData = allExpendeDropdownData?.glAccounts;
        break;
      }
      case "Req Code": {
        if(stockExpenseDetailsData[selectedRowIndex]?.jobCode && stockExpenseDetailsData[selectedRowIndex]?.phaseCode && stockExpenseDetailsData[selectedRowIndex]?.jobCode !=="" && stockExpenseDetailsData[selectedRowIndex]?.phaseCode !==""){        
        let jobCode = allExpendeDropdownData?.jobCodes
          ?.find((e) =>
            e.jobCode.includes(
              stockExpenseDetailsData[selectedRowIndex]?.jobCode
            )
          )
          tempData = jobCode?.phaseCode.find((e) =>
            e.phaseCode.includes(
              stockExpenseDetailsData[selectedRowIndex]?.phaseCode
            )
          )?.reqCodes;
            }
        break;
      }
      case "From Location": {
        // fix for location to be shown only when the StockCode is selected.
        if(props?.data?.data?.code){
          tempData = props?.data?.data?.locationDropdown || locationDropdownData[props?.data?.data?.code];
        }
        break;
      }
      default: {
        break;
      }
    }

    let data: SelectDropDown[] = [];
    if (tempData && tempData.length > 0) {
      data = tempData?.map((item : any) => ({
        label:
          headerName === "From Location"
            ? ( `${item.location} - ${item?.availableQty?.toFixed(4)}`)
            : item?.code
              || item?.workOrderNo
              || item?.jobCode
              || item?.phaseCode
              || item?.reqCode,

        description: item?.description || item?.details,
        details: item?.details,
        oemId: item?.oemId,
      }));
    }
    return data;
  };

  const handleChangeData = (val, headerName, index) => {
    let newvalue = val.split(" - ");
    let value = newvalue[0];
    const newData = [...stockExpenseDetailsData];
    let newIndex 
    if(props?.data?.data?.lineNo===0){
      newIndex  = newData.findIndex(item => item.tempLineNo == props?.data?.data?.tempLineNo); 
    }else {
      newIndex = newData.findIndex(item => item.lineNo == props?.data?.data?.lineNo); 
    }
    let matchedStock = allStockDropdownData?.find((item) => item.code == stockExpenseDetailsData[newIndex]?.code);
    console.debug("matched Stock !!",matchedStock);
    if (headerName == "Stock") {
      matchedStock = allStockDropdownData?.find((item) => item.code == value);
      //console.log("matched Stock : matchedStock : ",matchedStock)

      let defaultLocation = matchedStock?.location;
      if(locationDropdownData[value] && locationDropdownData[value].length>0){
        defaultLocation = locationDropdownData[value][0].location;
      }else if(value && props?.data?.data?.locationDropdown && props?.data?.data?.locationDropdown.length>0){
        defaultLocation = props?.data?.data?.locationDropdown[0].location;
      }
      
      newData[newIndex] = {
        ...newData[newIndex],
        code: value,
        description: `${
          matchedStock?.description ? matchedStock?.description : ""
        } 
        ${matchedStock?.oemId ? matchedStock?.oemId : ""}
        `,
        availableQty: matchedStock?.availableQty?matchedStock?.availableQty:stockExpenseDetailsData[newIndex]?.availableQty,
        location: value?defaultLocation:"",
        reqCode: matchedStock?.reqCode?matchedStock?.reqCode:stockExpenseDetailsData[newIndex]?.reqCode,
        glAccount: matchedStock?.glAccount?matchedStock?.glAccount:stockExpenseDetailsData[newIndex]?.glAccount,
        unitCost: matchedStock?.unitCost?matchedStock?.unitCost:stockExpenseDetailsData[newIndex]?.unitCost,
      };
      console.debug("updated Data : ",newData[newIndex]);
      if(!matchedStock || !value){
        // console.debug("StockCode : empty",value);
        dispatch(updateStockCode(""))
      }else{
        // console.debug("StockCode : ",value);
        dispatch(updateStockCode(value));
      }

    } else if (headerName == "Work Order Number") {
      const matchedWorOrderNo = allExpendeDropdownData?.workOrders?.find(
        (e) => e.workOrderNo === val
      );
      newData[newIndex] = {
        ...newData[newIndex],
        workOrderNo: value,
        jobCode: matchedWorOrderNo?.jobCode,
        phaseCode: matchedWorOrderNo?.phaseCode,
        reqCode: matchedStock?.reqCode || matchedWorOrderNo?.reqCode,
        glAccount: matchedWorOrderNo?.glAccount,
      };
      if (value) {
        
        stockExpenseDetailsData.slice(index + 1).forEach((item, i) => {
          console.debug("Item : ",item,i,index,newIndex);
          const emptyValues =
            !item.workOrderNo &&
            !item.jobCode &&
            !item.phaseCode &&
            !item.glAccount;

          if (emptyValues) {
            newData[newIndex + 1 + i] = {
              ...newData[newIndex + 1 + i],
              workOrderNo: value,
              jobCode: matchedWorOrderNo?.jobCode,
              phaseCode: matchedWorOrderNo?.phaseCode,
              glAccount: matchedWorOrderNo?.glAccount,
              reqCode:
                stockExpenseDetailsData[i + 1]?.reqCode ||
                matchedWorOrderNo?.reqCode,
            };
          }
        });
      }
    } else if (headerName == "Job Code") {
      // console.debug("JOb Code changed : ",matchedStock)
      newData[newIndex] = {
        ...newData[newIndex],
        jobCode: value,
        phaseCode: "",
        reqCode: matchedStock?.reqCode ? matchedStock?.reqCode : "",
        glAccount: "",
      };
      if (value) {
        for (let i = newIndex + 1; i < newData.length; i++) {
          if (!newData[i].jobCode && !newData[i].workOrderNo) {
            newData[i] = {
              ...newData[i],
              jobCode: value,
            };
          }
        }
      }else{
        // console.debug("JOb Code changed Value has been removed")
      }
    } else if (headerName == "Phase Code") {
      
      let glAccountVal = value;
      if(value !==""){
        glAccountVal = stockExpenseDetailsData[newIndex]?.reqCode && stockExpenseDetailsData[newIndex]?.reqCode !==""
        ? allExpendeDropdownData?.jobCodes?.find((e) => e.jobCode.includes(stockExpenseDetailsData[newIndex]?.jobCode))
            ?.phaseCode.find((e) => e.phaseCode.includes(val))
            ?.reqCodes?.find((item) => item.reqCode.includes(stockExpenseDetailsData[newIndex]?.reqCode))?.glAccount
        : ""
      }else{
        glAccountVal = ""
      }
      newData[newIndex] = {
        ...newData[newIndex],
        phaseCode: value,
        reqCode: matchedStock?.reqCode ? matchedStock?.reqCode : "",
        glAccount: glAccountVal,
      };
      if (value) {
        for (let i = newIndex + 1; i < newData.length; i++) {
          if (!newData[i].phaseCode && !newData[i].workOrderNo) {
            newData[i] = {
              ...newData[i],
              phaseCode: value,
              glAccount: stockExpenseDetailsData[i]?.reqCode && stockExpenseDetailsData[newIndex]?.reqCode !==""
                ? allExpendeDropdownData?.jobCodes?.find((e) => e.jobCode.includes(stockExpenseDetailsData[i]?.jobCode))
                    ?.phaseCode.find((e) => e.phaseCode.includes(val))
                    ?.reqCodes?.find((item) => item.reqCode.includes(stockExpenseDetailsData[i]?.reqCode)
                    )?.glAccount
                : "" || "",
            };
          }
        }
      }
    } else if (headerName == "Req Code") {
      const reqCodeGlAccount = value
        ? allExpendeDropdownData?.jobCodes
            ?.find((e) =>
              e.jobCode.includes(
                stockExpenseDetailsData[selectedRowIndex]?.jobCode
              )
            )
            ?.phaseCode.find((e) =>
              e.phaseCode.includes(
                stockExpenseDetailsData[selectedRowIndex]?.phaseCode
              )
            )
            ?.reqCodes?.find((e) => e.reqCode == val)?.glAccount
        : "";

      newData[newIndex] = {
        ...newData[newIndex],
        reqCode: value,
        glAccount: reqCodeGlAccount,
      };

      if (value) {
        for (let i = newIndex + 1; i < newData.length; i++) {
          if (
            (!newData[i].reqCode && !newData[i].workOrderNo) ||
            (newData[i].reqCode == value && !newData[i].workOrderNo)
          ) {
            newData[i] = {
              ...newData[i],
              reqCode: value,
              glAccount: reqCodeGlAccount,
            };
          }
        }
      }
    } else if (headerName == "GL Account") {
      newData[newIndex] = { ...newData[newIndex], glAccount: value };

      if (value) {
        for (let i = newIndex + 1; i < newData.length; i++) {
          if (!newData[i].glAccount && !newData[i].workOrderNo) {
            newData[i] = { ...newData[i], glAccount: value };
          }
        }
      }
    } else if (headerName == "From Location") {
      let code = newData[newIndex]?.code
      let availableQty = newData[newIndex]?.availableQty;
      let onHandQty = newData[newIndex]?.onHandQty;
      let unitCost = newData[newIndex]?.unitCost;
      let requiredQty= Number(newData[newIndex]?.requiredQty)?.toFixed(4);
      let amount= newData[newIndex]?.amount?newData[newIndex]?.amount:"0.00";
      let selectedLocation;
      if(locationDropdownData[code] && locationDropdownData[code].length>0){
        selectedLocation = locationDropdownData[code].find((item) => item.location === value);
      }else if(props?.data?.data?.locationDropdown && props?.data?.data?.locationDropdown.length>0){
        selectedLocation = props?.data?.data?.locationDropdown.find((item) => item.location === value);
      }
      if(selectedLocation){
        availableQty = selectedLocation?.availableQty;
        onHandQty = selectedLocation?.onHandQty;
        unitCost = selectedLocation?.unitCost;
        amount = (Number(unitCost) * Number(requiredQty)).toFixed(2);
      }else{
        availableQty = "0.00";
        onHandQty = "0.00";
        unitCost = 0.00;
        amount = "0.00";
      }
      // console.debug("Location selected : ",value,availableQty,onHandQty,unitCost,amount);
      newData[newIndex] = { ...newData[newIndex], location: value,availableQty,onHandQty,unitCost,amount };
    }
    dispatch(updateLookupData(newData));
  };

  const setComponentValue = (value: string, isReset: boolean) => {
    let lineNo ;
    if(props?.data?.data?.lineNo ===0){
      lineNo = props?.data?.data?.tempLineNo - 1;  
    }else{
      lineNo = props?.data?.data?.lineNo - 1;
    }
    const newValue = isReset ? "" : value;
    handleChangeData(newValue, headerName, lineNo);
  };

  const onCancel = () => {
    setSearchTerm("");
    setIsActive(true);
    setComponentValue("", true);

  };

  const onDropDownOptionSelect = (option) => {

    if (!diableDropdown) {
      console.debug("ONdropdown select!! : ",option,selectedRowIndex,stockExpenseDetailsData[selectedRowIndex]);
      setIsActive(!isActive);
      setComponentValue(option.label, false);
    }
  };
  const inputRef = useRef<any>(null);
  const handleDropdownClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const renderReactSelect = () => {
    return (
      <div className="dropdown-search">
        <button
          className="btn btn-secondary btn-table-dropdown btn-sm"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          disabled={
            isViewOnly
              ? true
              : diableDropdown || disableGl
              ? true
              : false
          }
          style={{ width: "100%" }}
          onClick={handleDropdownClick}
        >
          {placeHolderTitle}
          <span
            className={placeHolderTitle ? "fas fa-times dropdown-cancel" : ""}
            style={{ zIndex: 10000 }}
            onClick={onCancel}
          />
          <span
            className={
              isActive
                ? "fas fa-caret-up dropdown-caret"
                : "fas fa-caret-down dropdown-caret"
            }
            style={{ zIndex: 10000, right: 0 }}
          />
        </button>
        <ul
          className="dropdown-menu overflow-auto mh-200px"
          style={{ width: "380px" }}
        >
          <li>
            <input
              ref={inputRef}
              className="form-control"
              placeholder="Search"
              type="text"
              name="searchTerm"
              autoFocus={true}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </li>
          {filteredOptions?.map((option, index) => {
            return (
              <li key={index}>
                <div
                  className="dropdown-item"
                  onClick={() => onDropDownOptionSelect(option)}
                >
                  {option.label}
                  {headerName !== "From Location" && option.description && (
                    <span className="description">
                      - {option.description} - {option.oemId}
                    </span>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return <>{renderReactSelect()}</>;
};

export default ActionPopupWithSearchDev;
