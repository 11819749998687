import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "../user/UsersTable.css";
import {
  getStockItem,
  getVendor,
  selectStockCodes,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { useNavigate } from "react-router-dom";
import { KTCard } from "../../../theme/helpers";
import clsx from "clsx";
import { colors, titleCss } from "../../../constants/colors";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import sortalphadown from "../../../../src/assets/sortalphadown.png";
import onenine from "../../../../src/assets//sortnumericdown.png";
import { useAuth } from "../../modules/auth";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import CustomLoadingCellRender from "../user/CustomLoadingCellRender";
import { updateLookupData, updateStockCode } from "../../../reducers/lookupReducer";
import { useDispatch, useSelector } from "react-redux";
import { updateIndex, updateIsCreateRequest, updateIsValidRouteForRequestStock } from "../../../reducers/indexReducer";
import { formatDatesinArray } from "../../../theme/assets/ts/_utils/FormatData";

import { useTranslation } from "react-i18next";
import SearchInputDev from "../../utils/SearchInputDev";
import DevExport from "../../modules/custom components/Export/DevExport";
import ValueFormatter from "../../utils/ValueFormatter";
import DataGridWrapper from "../../utils/DataGrid/DataGridWrapper";

const StockListDev = ({ triggerAddFunction, error }) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, setGroup, setOrder, setReqCode, setPhase, setJob } =
    useAuth();
  const [rowData, setRowData] = useState<any>([]);
  const [sortData, setSortData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isadvanceShort, setIsadvanceShort] = useState(false);
  const [gridApi, setGridApi] = useState<any>(null);
  const [selectedValues, setSelectedValues] = useState<any>(new Set());
  const [selectedLocalStorageData, setSelectedLocalStorageData] = useState<any>(
    []
  );
  console.debug(selectedLocalStorageData, "selectedLocl");
  const [trackLoadingState, setTrackLoadingState] = useState(false);


  const columnOptions = [
    { value: "t.stockCode", label: "Code", type: "text" },
    { value: "t.description", label: "Description", type: "text" },
    {
      value: "inv.QTY",
      label: "On Hand Qty",
      type: "number",
    },
    { value: "t.ordQty", label: "Order Qty", type: "number" },
    { value: "t.oemId", label: "OEM ID", type: "text" },
    { value: "imv.VE_FLAG", label: "Type", type: "text" },
    { value: "imv.VENDOR", label: "Vendor", type: "text" },
  ];

  const [vendors, setVendors] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const company = useSelector((state: any) => state?.userConfig?.company);
  const [exportAllData,setExportAllData]=useState<any>();
  const [columnDefs, setColumnDefs] = useState<any>([]);

  const getvendors=async()=>{
    try {
      const response=await getVendor(company);
      if(response){
        const formattedArray = response?.data?.map((value) => ({
          label: value,
          value,
        }));
        setVendors(formattedArray);
        console.debug("ffff", formattedArray);
      }
    } catch (error) {
    }

  }

  useEffect(()=>{
   getvendors();
  },[])


  const fetchApi=async()=>{
    setTrackLoadingState(true);
    try {
      const response=await getStockItem(company, searchTerm, {
        advancedFilters: filterData,
        sortedList: sortData,
      });
      if (response.data) {
        setRowData(formatDatesinArray(response.data));
        if(filterData.length===0){
          setExportAllData(response);
        }
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  }

  const closeChangePasswordModal = (event) => {
    setIsadvanceShort(false);
  };

  useEffect(() => {
    fetchApi();
  }, [filterData,sortData]);

  const coulmnListForSort = [
    {
      id: "1",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Code",
      column: "CONVERT(t.stockCode, SQL_CHAR)",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "2",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Description",
      column: "CONVERT(t.description, SQL_CHAR)",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "3",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "On Hand Qty",
      column: "CONVERT(t.qtyHand, SQL_CHAR)",
      col4: onenine,
      iconIndex: 2,
    },
    {
      id: "4",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Order Qty",
      column: "CONVERT(t.ordQty, SQL_CHAR)",
      col4: onenine,
      iconIndex: 2,
    },
    // {                        // Removed as it is not required while sorting. It results invalid payload
    //   id: "7",
    //   col1: "⋮⋮⋮",
    //   col2: false,
    //   col3: "OEM ID",
    //   column: "CONVERT(t.oemId, SQL_CHAR)",
    //   col4: sortalphadown,
    //   iconIndex: 0,
    // },
  ];

  const columnDefinition = [
    {
      caption: "Code",
      columnName:"Code",
      field: "code",
      width: 100,
      alignment:"left",
      columnType: "Char",
    },
    {
      caption: "Description",
      columnName: "Description",
      field: "description",
      width: 400,
      alignment:"left",
      columnType: "Char",
    },
    {
      caption: "On Hand Qty",
      columnName: "On Hand Qty",
      field: "onHandQty",
      width: 100,
      alignment:"right",
      numericFormat:null,
      columnType: "Numeric",
      
    },
    {
      caption: "Order Qty",
      columnName: "Order Qty",
      field: "onOrderQty",
      width: 100,
      alignment:"right",
      numericFormat:null,
      columnType: "Numeric"
    },
    {
      caption: "OEM ID",
      columnName: "OEM ID",
      field: "oemId",
      width: 100,
      alignment:"left",
      columnType: "Char",
    },
  ];


  useEffect(() => {
    const col = columnDefinition.map((item) => {
      let valueFormatter;
          if (
            item?.columnType==='Numeric'
          ) {
              valueFormatter = (params) => { return (params.value || (params.value == 0)) ? ValueFormatter(params?.value,item?.numericFormat,item?.columnName) : "" };

          }
      return {
        headerName: item?.caption,
        columnType: item?.columnType,
        field: item?.field,
        width: item?.width,
        valueFormatter:valueFormatter,
        halign: item?.alignment,
        hide: false,
      };
    });
    setColumnDefs(col);

  },[])


  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 150, 200];
  }, []);

  const gridRef: any = useRef(null);
  

  const onGridReady = (params) => {
    // if (window.innerWidth < 800) {
    //   sizeToFit();
    // }
    setGridApi(params.component);
  };

  const sizeToFit = () => {
    if (gridRef.current) {
      gridRef.current.sizeColumnsToFit({
        defaultMinWidth: 150,
      });
    }
  };

  const onSelectionChanged =(e)=> {
      try {
          const selectedRowsData=e?.selectedRowsData;
          const selectedRowKeys=e?.selectedRowKeys;
          setSelectedValues(selectedRowKeys);
          setSelectedLocalStorageData(selectedRowsData);
        
      } catch (error) {
      }
    };

  const onShortButtonClick = () => {
    setIsadvanceShort(!isadvanceShort);
  };
  const onFilterButtonClick = () => {
    setShowFilter(!showFilter);
  };

  const handleApply = (data) => {
    setFilterData(data);
  };

  const handleApplysort = (data) => {
    const sortPayload = data.map(({...newObject }) => ({
      column: newObject.column,
      ascDesc: newObject.ascDesc
    }));
    setSortData(sortPayload);
  };

  const addStockList = async () => {
    if (error) {
      return toastMessage(t("Please fill all required fields."), "error");
    }
    if (selectedLocalStorageData?.length > 0) {
      console.debug("selectedLocalStorageData 1: ",selectedLocalStorageData)

      const stockCodes = selectedLocalStorageData.map((item) => item.code);
      const apiPromises = stockCodes.map((stockCode) =>selectStockCodes(company, stockCode));

      const selectedStockDataResponses = await Promise.all(apiPromises);
      const selectedStockDataToBeSendInStockRequest = selectedStockDataResponses.map((response) => response?.data);
      console.debug("selectedLocalStorageData 2: ",selectedStockDataToBeSendInStockRequest)

      let updatedSelectedLocalStorageData:any[] = [];

      selectedLocalStorageData.forEach((item, index) => {
        let selectedStockDataToBeSendInStockRequestResponse = selectedStockDataToBeSendInStockRequest.filter(data=>data.code===item.code);
        let newItem = {...item}
        console.debug("selectedLocalStorageData 3: ",selectedStockDataToBeSendInStockRequestResponse);
        if(selectedStockDataToBeSendInStockRequestResponse.length>0){
          newItem.locationDropdown = selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown;
          if(selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown && selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown.length>0){
            //setting location specific values.
            let defaultLocation = selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown[0]
            newItem.location = defaultLocation.location;
            newItem.availableQty = defaultLocation.availableQty;
            newItem.onHandQty = defaultLocation.onHandQty;
            newItem.unitCost = defaultLocation.unitCost;
          }
        }
        newItem.lineNo = 0;
        newItem.tempLineNo = Number(index) + 1; // fix for the stock request should not have lineNo.
        updatedSelectedLocalStorageData.push(newItem);
      });
      await dispatch(updateLookupData(updatedSelectedLocalStorageData));
      console.debug("selectedLocalStorageData 4: ",updatedSelectedLocalStorageData)

      triggerAddFunction();
      const selectedValuesArray = Array.from(selectedValues);
      setGroup(selectedLocalStorageData[0]?.code);
      console.debug("selectedValuesArray", selectedValuesArray);
      dispatch(updateIndex(0));

      setOrder(null);
      setReqCode(null);
      setPhase(null);
      setJob(null);
      setTimeout(() => {
        dispatch(updateStockCode(""));   // It will avoid an addition api call for the stock code that was last added.
        dispatch(updateIsCreateRequest(true));
        dispatch(updateIsValidRouteForRequestStock(true));
        navigate("/requeststockdev", {
          state: { selectedValues: selectedValuesArray},
        });
      }, 0);
    } else {
      toastMessage(t("Please select a stock to proceed."), "error");
    }
  };

  let columns = [
    "Code",
    "Description",
    {content: "On Hand Qty", styles: {halign: 'right'}},
    {content: "Order Qty", styles: {halign: 'right'}},
    "OEM ID",
  ];
  let dataKey = [
    "code",
    "description",
    "onHandQty",
    "onOrderQty",
    "oemId",
  ];
  let [userEmail, userId, userName]: any = currentUser?.sub?.split("#");


  const onDeselectAllClick = () => {
    if (gridRef?.current) {
      gridRef.current?.instance()?.clearSelection();
    }
  };
  const fileName="stock-item-list"

  return (
    <>
      <div>
        <div
          className="d-flex"
          style={{
            marginBottom: 15,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      </div>
      <KTCard className=" px-10">
        <div className={clsx("mt-5", titleCss)} style={{ color: colors.title }}>
        {t("Stock Listing")}
        </div>

        <div className="mb-5 w-100">
          <div
            className="mt-5 row row-cols-1 row-cols-md-4 gy-3 w-100"
            style={{ alignItems: "center" }}
          >
            <SearchInputDev gridRef={gridRef} type="stockList" className="col-md-6" screen={"Stocklist"} />
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill text-wrap fs-6 primary-button w-100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "45px",
                  flexDirection: "row",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
                onClick={onDeselectAllClick}
              >
                <span
                  className="d-flex align-item-center justify-content-center"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {t("Reset")}
                </span>
              </button>
            </div>
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100"
                onClick={onShortButtonClick}
                title="Sort"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                Sort
              </button>
            </div>
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100"
                onClick={onFilterButtonClick}
                title={t("Filter")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                {t("Filter")}
              </button>
            </div>
            {isadvanceShort && (
              <UserEditModal
                content="advancesort"
                modalText={t("Sort")}
                onCloseEditModal={closeChangePasswordModal}
                user={coulmnListForSort}
                onApply={handleApplysort}
                style={{
                  position: "relative",
                  zIndex: 1000,
                }}
              />
            )}
            {showFilter && (
              <UserEditModal
                content="advancefilter"
                modalText={t("Filter")}
                onClose={() => {
                  console.debug("calls sort");

                  setShowFilter(false);
                }}
                onCloseEditModal={() => setShowFilter(false)}
                columnOptions={columnOptions}
                vendor={vendors}
                onApply={handleApply}
                style={{
                  position: "relative",
                  zIndex: 1000,
                }}
              />
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center mr-4 mb-5">
        {/* <DevExport
          gridRef={gridRef}
          apiData={exportAllData?.data}
          columnDefs={columnDefs}
          backendDef={columnDefinition}
          file={fileName}
        /> */}
        </div>

        {trackLoadingState ? (<CustomLoadingCellRender /> ):(
          <DataGridWrapper
           gridRef={gridRef}
           rowData={rowData}
           columnDefs={columnDefs}
           rowSelection="multiple"
           onGridReady={onGridReady}
           enableAdvancedFilter={false}
          //  getContextMenuItems={getContextMenuItems}
           pagination={true}
           paginationPageSize={50}
           paginationPageSizeSelector={paginationPageSizeSelector}
           rowGroupPanelShow={false}
           isFilterRow={false}
           height={500}
           onSelectionChanged={onSelectionChanged}
           RowSelection={true}
           keyExpr={"code"}
           fileName={fileName}
           exportEnabled={true}
           fontSizeForPdfExport={12}
           />)}
        <div className="text-center mt-5 mb-5 ">
          <button
            type="button"
            title={t("Cancel")}
            className="btn btn-secondary rounded-pill me-5"
            style={{ lineHeight: "1" }}
            onClick={() => navigate(-1)}
          >
            {t("Cancel")}
          </button>
          <button
            type="submit"
            onClick={() => addStockList()}
            className="btn text-white rounded-pill me-4"
            style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
            disabled={selectedLocalStorageData?.length <= 0}
            title={t("Add")}
          >
            {t("Add")}
          </button>
        </div>
      </KTCard>
    </>
  );
};

export default StockListDev;
