import  { useState, useEffect, useCallback } from "react";
import security from "../../../../assets/security.png";
import {  useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { twoFactorAuth } from "../core/_requests";
import { useAuth } from "../core/Auth";
import jwt_decode from "jwt-decode";
import {
  resendTfa,
} from "../../apps/user-management/users-list/core/_requests";
import { toastMessage } from "../functions/toastMessage";
import { Language } from "../../../utils/Config";
import { languageChangeHandler } from "../../../../theme/assets/ts/_utils/LanguageUtils";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { saveLanguage } from "../../../../reducers/UserConfigReducer";

const TwoFactorAuth = () => {
  const dispatch = useDispatch();
  const [doNotAskAgain, setDoNotAskAgain] = useState(false);
  const [code, setCode] = useState("");
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const { saveAuth, setCurrentUser, logout, currentUser } = useAuth();
  const { t, i18n } = useTranslation();
  const languages=Language.LANGUAGE;

  // Function to handle the checkbox change
  const handleStaySignedInChange = (e) => {
    setDoNotAskAgain(e.target.checked);
  };
  const [timer, setTimer] = useState(10);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const resetTimer = async function () {
    if (!timer) {
      setTimer(10);
    }
    await resendTfa(state.userId);
  };

  const handleAuth = async () => {
    try {
      let data = await twoFactorAuth(state.userId, code, doNotAskAgain);

      if (data?.data?.statusCodeValue === 404) {
      }
       else {
        let token = data.data.data.accessToken;
        let refreshToken = data.data.data.refreshToken;

        if (token) {
          let decryptData: any = jwt_decode(token);

          let currentUser = {
            sub: decryptData?.sub,
            roles: decryptData?.roles,
            token,
            refreshToken,
          };
          let language = currentUser!.sub.split("#")[3];
          let obj: any = languages.find((lang) => lang?.label === language);
          localStorage.setItem("language", obj?.value);
          i18n.changeLanguage(obj.value);//changing language
          dispatch(saveLanguage(obj.value));
          languageChangeHandler(obj.value);
          setCurrentUser(currentUser);
          saveAuth(currentUser);
          toastMessage("Verified Successfully", "success");
          navigate("/selectcompany");          // navigate to select company page.
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        return toastMessage(`${error.response.data.data}`, "error");
      } else if (error.response.status === 404) {
        return toastMessage(
          `${error.response.data.data}` || "User does not exists!",
          "error"
        );
      } else if (error.response.status === 401) {
        return navigate("/auth");
      }
      else if(error.response.status === 400){
        return toastMessage(`${error.response.data.data}`, "error");
      }
    }
  };
  return (
    <div
      className=" p-10 pt-5 d-flex gap-3 justify-content-center align-items-center fs-5 flex-column"
      style={{ borderRadius: 0 }}
    >
      <div className="">
        <h1 className="text-white fw-bold mb-1">Two Factor Authentication</h1>
      </div>
      <div className="">
        <img src={security} alt="" style={{ height: "100px" }} />
      </div>
      <div className="d-flex text-white fs-6 col-12 mx-0 align-self-center">
        Please enter your security code sent to{" "}
        <span className="fw-bold  text-white ms-2">{state.email}</span>
      </div>
      <div
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          // width: "100%",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <div className="fv-row col-12" style={{ position: "relative" }}>
          <input
            className="border-secondary  text-white fs-5 whiteph"
            placeholder="Please type your security code here"
            type={"text"}
            autoComplete="off"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleAuth();
              }
            }}
          />
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2 col-12  mx-0 align-self-center">
          <span className="fs-5 text-white">Didn't receive the Code?</span>
          <div
            className="d-flex text-end"
            style={{ marginLeft: timer ? "40px" : "100px" }}
          >
            <a
              onClick={resetTimer}
              className={clsx(
                "fs-5 text-primary text-end",
                timer && "text-muted nav-link disabled"
              )}
              style={{ cursor: "pointer" }}
              title="Resend"
            >
              Resend Code
            </a>
            <span className={clsx("fs-5 ms-3 text-white", !timer && "d-none")}>
              {timer} secs
            </span>
          </div>
        </div>
        <div className="d-grid d-flex fs-5 gap-1 mb-2 col-12  mx-0 align-self-center">
          <div className="d-flex gap-1 align-items-start">
            <input
              type="checkbox"
              checked={doNotAskAgain}
              onChange={handleStaySignedInChange}
              style={{ transform: "scale(1.2)" }} // Make the checkbox smaller
            />
            <label
              className="fs-8 text-white"
              style={{
                marginLeft: "5px",
                marginTop: "13px",
                whiteSpace: "nowrap",
              }}
            >
              Do not ask for Two Factor Authentication for the next 10 days
            </label>
          </div>
        </div>

        <div className="d-grid mb-2 fs-4 col-12 text-black mx-0 align-self-center">
          <button
            title="Authenticate"
            type="submit"
            id="kt_sign_in_submit"
            className="btn text-white rounded-pill"
            style={{ backgroundColor: "#54CA98" }}
            onClick={handleAuth}
            disabled={code.length !== 6}
          >
            Authenticate
          </button>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuth;
