import {Formik, Form, Field} from 'formik';
const Reject = ({
  onCancel,
  setComments,
  comments,
  onSave,
  rejectModal,
  data,
}) => {

  const handleCanceClick = async()=>{
    await onCancel();
    setComments("");
  }

  const handleSaveClick = async()=>{
    await onSave();
    setComments("");
  }

  return (
    <Formik
      initialValues={{ }}
      onSubmit={handleSaveClick}
    >
      <Form className="d-flex flex-column gap-5">
        <Field
          className="form-control"
          placeholder="Comments"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-secondary rounded-pill mx-2"
            onClick={handleCanceClick}
            title="Cancel"
          >
            Cancel
          </button>
          <button
             type="submit"
             className="btn text-white rounded-pill mx-2"
             title="Save"
             style={{ backgroundColor: "#0a7eac" }}
             data-kt-users-modal-action="submit"
             //   disabled={rejectModal}
           >
             Save
           </button>
         </div>
       </Form>
     </Formik>
  );
};

export default Reject;
