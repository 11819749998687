/** @format */

import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { LayoutSplashScreen } from "../../../../theme/layout/core";
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import { getUserByToken } from "./_requests";
import { WithChildren } from "../../../../theme/helpers";
import ClearStorage from "../functions/ClearStorage";
import { saveCompanyAndName, saveLanguage } from "../../../../reducers/UserConfigReducer";
import { store } from "../../../../reduxStore/store";
import {logoutUsers} from "../../../modules/apps/user-management/users-list/core/_requests"
import { toastMessage } from "../functions/toastMessage";
import { setMenuData, setMniName } from "../../../../reducers/menuReducer";
import { updateIsValidRouteForRequestStock } from "../../../../reducers/indexReducer";

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  currentUsers: UserModel | undefined;
  current: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  setCurrentUsers: Dispatch<SetStateAction<UserModel | undefined>>;
  setCurrent: Dispatch<SetStateAction<UserModel | undefined>>;
  selectedList: any;
  setSelectedList: React.Dispatch<React.SetStateAction<[] | any>>;
  selectedRowIndex: any;
  setSelectedRowIndex: React.Dispatch<React.SetStateAction<[] | any>>;
  menuList: any[];
  setMenuList: React.Dispatch<React.SetStateAction<any[]>>;
  order: any;
  setOrder: React.Dispatch<React.SetStateAction<any>>;
  group: any;
  setGroup: React.Dispatch<React.SetStateAction<any>>;
  locations: any;
  setLocation: React.Dispatch<React.SetStateAction<any>>;
  job: any;
  setJob: React.Dispatch<React.SetStateAction<any>>;
  phase: any;
  setPhase: React.Dispatch<React.SetStateAction<any>>;
  glAcc: any;
  setGlAcc: React.Dispatch<React.SetStateAction<any>>;
  reqCode: any;
  setReqCode: React.Dispatch<React.SetStateAction<any>>;
  pinnedData: any[];
  setPinnedData: React.Dispatch<React.SetStateAction<any[]>>;
  editable: any;
  setEditable: React.Dispatch<React.SetStateAction<any>>;
  pageStatus: string | null;
  setPageStatus: React.Dispatch<React.SetStateAction<string | null>>;
  logout: () => void;
  indexing: number | undefined;
  setIndexes: Dispatch<SetStateAction<number | undefined>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  createIssueButtonDisabled: boolean;
  setCreateIssueButtonDisabled: Dispatch<SetStateAction<boolean>>;
  calls: boolean;
  setCalls: Dispatch<SetStateAction<boolean>>;
  isAuthNeeded: boolean;
  setIsAuthNeeded: Dispatch<SetStateAction<boolean>>;
  callsForLookup: boolean;
  setCallsForLookup: Dispatch<SetStateAction<boolean>>;
  callRefreshToken: boolean;
  setCallRefreshToken: Dispatch<SetStateAction<boolean>>;
  deleteid: any;
  setDeleteid: React.Dispatch<React.SetStateAction<any>>;
  currentLanguage: any;
  setCurrentLanguage: React.Dispatch<React.SetStateAction<any>>;
  clearLocalStorage:()=>void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  currentUsers: undefined,
  setCurrentUsers: () => {},
  current: undefined,
  setCurrent: () => {},
  logout: () => {},
  indexing: 0,
  setIndexes: () => {},
  group: "",
  setGroup: () => {},
  job: "",
  setJob: () => {},
  phase: "",
  setPhase: () => {},
  glAcc: "",
  setGlAcc: () => {},
  reqCode: "",
  setReqCode: () => {},
  order: "",
  setOrder: () => {},
  menuList: [],
  setMenuList: () => {},
  selectedList: [],
  setSelectedList: () => {},
  selectedRowIndex: "",
  setSelectedRowIndex: () => {},
  pinnedData: [],
  setPinnedData: () => {},
  editable: false,
  setEditable: () => {},
  loading: false,
  setLoading: () => {},
  createIssueButtonDisabled: false,
  setCreateIssueButtonDisabled: () => {},
  calls: false,
  setCalls: () => {},
  callsForLookup: false,
  setCallsForLookup: () => {},
  callRefreshToken: false,
  setCallRefreshToken: () => {},
  deleteid: false,
  setDeleteid: () => {},
  setCurrentLanguage: () => {},
  currentLanguage: () => {},
  locations: "",
  setLocation: () => {},
  setPageStatus: () => {},
  pageStatus: "Edit",
  isAuthNeeded: false,
  setIsAuthNeeded: () => {},
  clearLocalStorage: () => {},
};

export const AuthContext = createContext<AuthContextProps>(
  initAuthContextPropsState
);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  const [currentUsers, setCurrentUsers] = useState<UserModel | undefined>();
  const [current, setCurrent] = useState<UserModel | undefined>();

  const [menuList, setMenuList] = useState<any[]>([]);
  const [pinnedData, setPinnedData] = useState<any[]>([]);
  const [editable, setEditable] = useState<any>(false);
  const [deleteid, setDeleteid] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<any>([]);
  const [pageStatus, setPageStatus] = useState<String | any>();
  const [group, setGroup] = useState<any>();
  const [order, setOrder] = useState<any>();
  const [job, setJob] = useState<any>();
  const [locations, setLocation] = useState<any>();
  const [phase, setPhase] = useState<any>();
  const [glAcc, setGlAcc] = useState<any>();
  const [reqCode, setReqCode] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [createIssueButtonDisabled, setCreateIssueButtonDisabled] = useState<boolean>(false);
  const [calls, setCalls] = useState<boolean>(false);
  const [callsForLookup, setCallsForLookup] = useState<boolean>(false);
  const [callRefreshToken, setCallRefreshToken] = useState<boolean>(false);
  const [selectedList, setSelectedList] = useState<any>(new Set());
  const [selectedRowIndex, setSelectedRowIndex] = useState<any>(new Set());

  const [indexing, setIndexes] = useState<number | undefined>();
  const [isAuthNeeded, setIsAuthNeeded] = useState<boolean>(false);

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = async () => {
    try {
      const responseCode=await logoutUsers(auth?.token);
    if(responseCode===200||responseCode===401){
      store?.dispatch(saveCompanyAndName({code:"",name:""}))
      store?.dispatch(saveLanguage("en"));
      store?.dispatch(setMenuData([]));
      store?.dispatch(setMniName([]));
      store?.dispatch(updateIsValidRouteForRequestStock(false));
      clearLocalStorage();
    }
    else{
      toastMessage("Logout Failed.Please try again later","error");
    }
      
    } catch (error) {
      toastMessage("Logout Failed.Please contact support Team ","error");
    }
    
  };

  const clearLocalStorage = async () => {
      ClearStorage({ saveAuth, setCurrentUser, setCurrentUsers, setCurrent });
      localStorage.removeItem(authHelper.AUTH_LOCAL_STORAGE_KEY);
  };


  return (
    <AuthContext.Provider
      value={{
        selectedList,
        setSelectedList,
        selectedRowIndex,
        setSelectedRowIndex,
        phase,
        setPhase,
        glAcc,
        setGlAcc,
        reqCode,
        setReqCode,
        job,
        setJob,
        order,
        setOrder,
        group,
        setGroup,
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        currentUsers,
        setCurrentUsers,
        current,
        setCurrent,
        logout,
        deleteid,
        setDeleteid,
        currentLanguage,
        setCurrentLanguage,
        indexing,
        setIndexes,
        menuList,
        setMenuList,
        pinnedData,
        setPinnedData,
        editable,
        setEditable,
        loading,
        setLoading,
        createIssueButtonDisabled,
        setCreateIssueButtonDisabled,
        calls,
        setCalls,
        callsForLookup,
        setCallsForLookup,
        callRefreshToken,
        setCallRefreshToken,
        setPageStatus,
        pageStatus,
        isAuthNeeded,
        setIsAuthNeeded,
        locations,
        setLocation,
        clearLocalStorage
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, currentUser,clearLocalStorage } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await getUserByToken(apiToken);

          if (data) {
            setCurrentUser(data);
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (auth && auth.token) {
      requestUser(auth.token);
    } else {
      clearLocalStorage();
      setShowSplashScreen(false);
    }
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
