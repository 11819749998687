/** @format */

import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLayout } from "../../../core";
import { usePageData } from "../../../core/PageData";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa"; // Import the home icon from a library like react-icons
import { useTranslation } from "react-i18next";

const PageTitle = () => {
  const { pageTitle, pageDescription, pageBreadcrumbs } = usePageData();
  const { config, classes } = useLayout();
  const appPageTitleDirection = config.app?.pageTitle?.direction;
  const nav = useNavigate();
  const {t} = useTranslation();

  return (
    <div
      id="kt_page_title"
      data-kt-swapper="true"
      data-kt-swapper-mode="prepend"
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx(
        "page-title d-flex flex-wrap me-3 mb-1 mt-0",
        classes.pageTitle.join(" "),
        config.app?.pageTitle?.class,
        {
          "flex-column justify-content-center":
            appPageTitleDirection === "column",
          "align-items-center": appPageTitleDirection !== "column",
        }
      )}
    >
      {/* begin::Title */}
      {config.app?.pageTitle?.display && pageTitle && (
        <h1
          className={clsx(
            "page-heading d-flex text-dark fw-bold fs-3 my-0 mx-10",
            {
              "flex-column justify-content-center": appPageTitleDirection,
              "align-items-center": !appPageTitleDirection,
            }
          )}
        >
          {pageTitle}
          {pageDescription &&
            config.app?.pageTitle &&
            config.app?.pageTitle?.description && (
              <span
                className={clsx("page-desc text-muted fs-7 fw-semibold", {
                  "pt-2": appPageTitleDirection === "column",
                })}
              >
                {config.app?.pageTitle?.direction === "row" && (
                  <span className="h-20px border-1 border-gray-300 border-start ms-3 mx-2"></span>
                )}
                {pageDescription}{" "}
              </span>
            )}
        </h1>
      )}
      {/* end::Title */}

      {pageBreadcrumbs &&
        pageBreadcrumbs?.length > 0 &&
        config.app?.pageTitle &&
        config.app?.pageTitle?.breadCrumb && (
          <>
            {config.app?.pageTitle?.direction === "row" && (
              <span className="h-20px border-gray-300 border-start mx-4"></span>
            )}
            <ul className="breadcrumb breadcrumb-item fw-semibold fs-20 my-0 mx-10 ">
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  className={clsx("breadcrumb-item text-primary", {
                    "text-hover-primary": !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  {!item.isSeparator ? (
                    item.path === "goBack" ? (
                      <span
                        onClick={() => nav(-1)}
                        className="text-primary text-hover-primary"
                        style={{ cursor: "pointer", fontSize: "18px" }}
                      >
                        {item.title === "Home" ? (
                          <FaHome /> // Replace "Home" with the home icon
                        ) : (
                          t(item.title)
                        )}
                      </span>
                    ) : item.path === "" ? (
                      <span className="text-muted" style={{ fontSize: "18px" }}>
                        {item.title === "Home" ? (
                          <FaHome /> // Replace "Home" with the home icon
                        ) : (
                          t(item.title)
                        )}
                      </span>
                    ) : (
                      <Link
                        className="text-primary text-hover-primary"
                        style={{ fontSize: "18px" }}
                        to={item.path}
                        // state={{ filter: item.title }}
                        state={{ data: item?.editdata, filter: item?.title }}
                      >
                        {item.title === "Home" ? <FaHome /> : t(item.title)}
                      </Link>
                    )
                  ) : (
                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
    </div>
  );
};

export { PageTitle };
