import DataGridWrapper from "../../utils/DataGrid/DataGridWrapper";
import React,{ useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  getModifyTable,
  getResetColumn,
  getRestoreCustomizeLayout,
  saveSummaryFooter,
  selectStockCodes,
  getStockEnquiry,
  getPOEnquiry
} from "../../modules/apps/user-management/users-list/core/_requests";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import { HandleApiCall } from "../../modules/auth/functions/HandleApiCall";
import { useDispatch, useSelector } from "react-redux";
import { updateLookupData, updateSelectedRecords, updateStockCode } from "../../../reducers/lookupReducer";
import CustomLoadingCellRender from "../user/CustomLoadingCellRender";
import "ag-grid-charts-enterprise";
import { updatePoHeader } from "../../../reducers/PoAndStockReducer";
import CustomDatePicker from "../../modules/custom components/CustomDatePicker/CustomDatePicker";
import Tippy from "@tippyjs/react";
import { KTSVG } from "../../../theme/helpers";
import { functionsData } from "./functionDataDev";

import ValueFormatter from "../../utils/ValueFormatter"; 
import HyperlinkRenderer from "../../utils/HyperlinkRendererProps";
import { useTranslation } from "react-i18next";

import SearchInputDev from "../../utils/SearchInputDev";
import { updateIsCreateRequest, updateIsValidRouteForRequestStock } from "../../../reducers/indexReducer";
import DevExport from "../../modules/custom components/Export/DevExport";
import { UserEditModalDev } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModalDev";


interface ActionData {
  id: number;
  label: string;
}

const DevPOAndStockTablesOpti = ({
  type,
  fetchApi,
  isFirstTab,
  tabTitle,
  tabSqlData,
  tabStorageData,
  tabStorage,
  tabSqlName,
  tableConfiguration,
  handleStartDateChange,
  handleEndDateChange,
  startDate,
  endDate,
  tabId,
  customizeGridEnabled,
  updatePONumber,
  selectedPoNumberRef,
  currentPoNumberRef,
  poNumberRef
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [newTabStorageData,setNewTabStorageData]=useState(tabStorageData);

  const lookupData = useSelector((state: any) => state?.lookupData?.lookupData);
  const navigate = useNavigate();
  // const isCustomizedColumn = newTabStorageData?.filter((item) => item?.columnType == "Numeric");
  const company = useSelector((state: any) => state?.userConfig?.company);


  const [columnDefs, setColumnDefs] = useState<any>([]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [showModal, setShowModal] = useState<any>("");
  const [showCustomizeModal, setShowCustomizeModal] = useState<any>(false);
  const [applySummaryFooter, setApplySummaryFooter] = useState<any>("");
  const [applyGroupSummaryFooter, setApplyGroupSummaryFooter] = useState<any>("");

  const [multiLineSelection, setMultiLineSelection] = useState<any>(tableConfiguration?.multiLineSelection ? "multiple" : "single");
  const [showFiltering, setShowFiltering] = useState(tableConfiguration?.filterVisible);
  const [hideAndShowSearchPanel, setHideAndShowSearchPanel] = useState<any>(tableConfiguration?.searchPanelVisible);
  const [showGroupingPanel, setShowGroupingPanel] = useState<any>(tableConfiguration?.groupingPanelVisible ? "always" : "never");
  const[summaryFooterVisible,setSummaryFooterVisible]=useState(tableConfiguration?.summaryFooterVisible);
  const [groupFooterVisible,setGroupFooterVisible]=useState(tableConfiguration?.groupFooterVisible);
  const [isGroupingExpandable,setIsGroupingExpandable]=useState(tableConfiguration?.isGroupingExpandable || false);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const gridRef: any = useRef(null);

  const tippyRef: any = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const actionPopUpDataRef = useRef<ActionData[]>();
  const [defaultTabRowData,setDefaultTabRowData]=useState(tabSqlData);
  const fileName = `${type}-item`;


  const updatedActionPopupData = functionsData.map((item) => {
    switch (item.label) {
      case "Show Grouping Panel":
        return {
          ...item,
          label: showGroupingPanel === "always"
            ? "Hide Grouping Panel"
            : "Show Grouping Panel",
        };
      case "Show Group Footer":
        return {
          ...item,
          label: groupFooterVisible
            ? "Hide Group Footer"
            : "Show Group Footer",
        };
      case "Show All":
          return {
            ...item,
            label: isGroupingExpandable
              ? "Collapse All"   
              : "Show All",
          };
      case "Show Search Panel":
        return {
          ...item,
          label: hideAndShowSearchPanel
            ? "Hide Search Panel"
            : "Show Search Panel",
        };
      case "Show Summary Footer":
        return {
          ...item,
          label: summaryFooterVisible
            ? "Hide Summary Footer"
            : "Show Summary Footer",
        };
      case "Show Filtering":
        return {
          ...item,
          label: showFiltering
            ? "Hide Filtering"
            : "Show Filtering",
        };
      case "Enable Multi-Line Selection":
        return {
          ...item,
          label: multiLineSelection
            ? "Disable Multi-Line Selection"
            : "Enable Multi-Line Selection",
        };

      default:
        return item;
    }
  });
  actionPopUpDataRef.current = updatedActionPopupData;

  const dropDownContent = (
    <div className="menu-container shadow-sm">
      {actionPopUpDataRef?.current?.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            hide();
            onEditColumnButtonClick(item.id);
          }}
          className="menu-items text-hover-primary"
        >
          {t(item.label)}
        </div>
      ))}
    </div>
  );

  const onTippyClick = (event) => {
    event.preventDefault();
    if (visible) {
      hide();
    } else {
      show();
    }
  };
  const compareByColumnIndex = (a, b) => {
    return a.columnIndex - b.columnIndex;
  }
  const onNewProcessButtonClick = async () => {
    let updatedData=getUpdatedColumnsWithIndex();
    setColumnDefs(updatedData);
    setIsLoadingData(true);
    try {
      const poEnquiryData = await getStockEnquiry(
        company,
        type === "STOCK_ENQUIRY" ? "IN" : type === "PLANT_ENQUIRY" ? "MK" : "PO",
        startDate,
        endDate
      );
      const { tabList } = poEnquiryData?.data || {};
      if (!tabList || tabList.length === 0) return;
      const firstTab = tabList[0];
      setDefaultTabRowData(firstTab.tabSqlData);
    } catch (error) {}
    finally{
      setTimeout(()=>{
        setIsLoadingData(false);
      },0);
    }
  };

  useEffect(() => {
    setIsLoadingData(true);
    setTimeout(() => {
      setIsLoadingData(false);
    }, 100);
  }, [tabTitle, defaultTabRowData]);

  useEffect(() => {
    if (newTabStorageData) {
      newTabStorageData.sort(compareByColumnIndex);
      let columnType = newTabStorageData.filter((item) => item.infoType === "column");
      let cols = columnType.map((item) => {
        let valueFormatter;
          if (
            item?.columnType==='Numeric'
          ) {
              valueFormatter = (params) => { return (params.value || (params.value == 0)) ? ValueFormatter(params?.value,item?.numericFormat,item?.columnName) : "" };

          }
        return {
          headerName: item?.caption,
          field: item?.columnName,
          cellRenderer: item?.columnName.toLowerCase()==="path"?HyperlinkRenderer:null,
          visible: item?.columnVisible,
          rowGroupIndex: item?.rowGroupIndex,
          valueFormatter:valueFormatter,
          halign:item?.alignment,
          width: item?.width,
          columnType: item?.columnType,
          numericFormat: item?.numericFormat,
          sortOrder: item?.sort,
          summaryType: (item?.aggFunc),
          fixed:item?.pinned,
          fixedPosition:item?.pinnedPosition
          
        };
      });
      // below is workaround for the case when no aggegrate function is applied for group and total summary and still we need to show the grouping bar
      cols.push({
        headerName:"Summary",
        field:"show_summary",
        visible:false
      })
      setColumnDefs(cols);
    }
  }, [newTabStorageData]);

  // on reset to default and customized, we need the new column definition to render
  useLayoutEffect(()=>{        
    setNewTabStorageData(tabStorageData);
  },[tabStorageData]);

  useEffect(() => {
    // console.debug("Table configuration changed");
    // This is needed when you restore to default. Since page is not loaded, initialization wont happen again.
    // You need to reinitialize it here
    if (tableConfiguration) {
      setMultiLineSelection( tableConfiguration?.multiLineSelection ? "multiple" : "single");
      setShowFiltering(tableConfiguration?.filterVisible);
      setHideAndShowSearchPanel(tableConfiguration?.searchPanelVisible);
      setShowGroupingPanel(tableConfiguration?.groupingPanelVisible ? "always" : "never");
      setSummaryFooterVisible(tableConfiguration?.summaryFooterVisible);
      setGroupFooterVisible(tableConfiguration?.groupFooterVisible);
      setIsGroupingExpandable(tableConfiguration?.isGroupingExpandable || false);
    }
  }, [tableConfiguration, tabTitle]);


  const onGridReady=useCallback((params)=>{
    console.debug("onGridReady",params.component);
    const dataGridInstance = params.component;
    setGridApi(dataGridInstance);


    if (selectedPoNumberRef.current && selectedPoNumberRef.current?.length == 0) {
      console.debug("first",selectedPoNumberRef)
      if (defaultTabRowData?.length === 0) {
        console.debug("Empty PO Header");
        dispatch(updatePoHeader({}));
      }
      const firstRow = defaultTabRowData[0];
      if(currentPoNumberRef.current==getStockCodeOrPONumberNew(firstRow)){
        dataGridInstance.selectRows(getStockCodeOrPONumberNew(firstRow), true);
      }
    }
    else if (selectedPoNumberRef.current && selectedPoNumberRef.current?.length>0){
      console.debug("second",selectedPoNumberRef);
      dataGridInstance.selectRows(selectedPoNumberRef.current, true);
    }

  },[newTabStorageData])


  const getStockCodeOrPONumberNew = (node)=> {
    return (type == "STOCK_ENQUIRY") ? node?.stock_code : (type == "PLANT_ENQUIRY") ? node?.plantno : node?.ponumber; 
  }

  const getUpdatedColumnsWithIndex = () => {
    let columnState =  gridApi.getController('columns').getColumns();
    console.debug("coulumnState11",columnState);
      const updatedData = newTabStorageData.map((data) => {
        const matchingColumn = columnState.find(column => column.dataField === data.colId);
        if (matchingColumn) {
          return {
            ...data,
            width: matchingColumn.width,
            columnIndex: (matchingColumn.visibleIndex+1),
            columnVisible:matchingColumn.visible,
            pinned:matchingColumn.fixed,
            pinnedPosition:matchingColumn.fixedPosition,
            rowGroupIndex:matchingColumn.groupIndex,
            sort:matchingColumn.sortOrder,
            sortIndex:matchingColumn.sortIndex
          };
        }
        return { ...data }; // Create a new object with a new reference
      });
     console.debug("updatedData",updatedData);
    setNewTabStorageData([...updatedData]); // Create a new array with a new reference
    return updatedData;
  }
  

  const handleCustomizeLayout = async () => {
    // console.debug("handleCustomizeLayout : ", searchVisible.current, filterVisible.current);
    let updatedTableConfiguration = {
      ...tableConfiguration,
      searchPanelVisible: hideAndShowSearchPanel,
      filterVisible: showFiltering,
      groupingPanelVisible: showGroupingPanel == "never" ? false : true,
      multiLineSelection: multiLineSelection == "single" ? false : true,
      summaryFooterVisible:summaryFooterVisible,
      groupFooterVisible: groupFooterVisible,
      isGroupingExpandable: isGroupingExpandable,
    };
    
  
    const payload = {
      tabStorage: tabStorage,
      tabSqlName: tabSqlName,
      modifiedTableConfiguration: updatedTableConfiguration,
      modifiedColumnConfiguration: getUpdatedColumnsWithIndex(),
      companyCode: company,
    };
    console.debug("customizedColumnData while saving", payload);
    try {
      setIsLoadingData(true);
      console.debug("payload before save customized",payload);
      const response = await HandleApiCall(getModifyTable, payload);
      console.debug("Response : ", response);
      if (response.status === "OK") {
        console.debug("Response : OK Response so saving state");
        toastMessage(t("Saved Customized Layout Successfully"), "success");
      } else {
        console.debug("Response : NOT OK Response");
        toastMessage(t("Error Saving Customized Layout"), "error");
      }
    } catch (error) {}
    finally{
      setTimeout(()=>{
        setIsLoadingData(false);
      },0)
    }
  };

  const onRestoreDefaultLayout = async () => {
    setIsLoadingData(true);
    try {
      const payload = {
        payload: {},
        storageTab: tabStorage,
        tabSqlName: tabSqlName,
        companyCode: company,
      };
      const response = await HandleApiCall(getResetColumn, payload);
      await fetchApi();
    } catch (error) { }
    finally{
      setTimeout(()=>{
        setIsLoadingData(false);
      },0);
    }
  };

  const onRestoreCustomizeLayout = async () => {
    setIsLoadingData(true);
    try {
      const payload = {
        payload: {},
        storageTab: tabStorage,
        tabSqlName: tabSqlName,
        companyCode: company,
      };
      const response = await HandleApiCall(getRestoreCustomizeLayout, payload);

      await fetchApi();
    } catch (error) { }
    finally{
      setTimeout(()=>{
        setIsLoadingData(false);
      },0);
    }
  };

  const closeEditModal = () => {
    setShowModal(false);
  };

  const closeEditCustomizeModal = () => {
    setShowCustomizeModal(false);
  };

  const closeSummaryFunctionModal = () => {
    setApplySummaryFooter("");
  };

  const closeGroupSummaryFunctionModal = () => {
    setApplyGroupSummaryFooter("");
  };

  const handleEdit = async (data) => {
    setIsLoadingData(true);
    try {
      setNewTabStorageData(data.modifiedColumnNames);
      setShowModal(false);
    } catch (error) { }
    finally{
      setTimeout(()=>{
        setIsLoadingData(false);
      },0);
    }
  };

  const handleEditCustomizeColumn = async (data) => {
    setIsLoadingData(true);
    try {
      const modifiedFields = data.modifiedColumnNames.map((col) => col.columnName);

      const newTabStorageDataUpdated = [
        ...newTabStorageData.filter(
          (item) => !modifiedFields.includes(item.columnName)
        ),
        ...data.modifiedColumnNames,
      ];
      newTabStorageDataUpdated.sort(compareByColumnIndex)
      setNewTabStorageData(newTabStorageDataUpdated);
      setShowCustomizeModal(false);
    } catch (error) {}
     finally{
      setTimeout(()=>{
        setIsLoadingData(false);
      },0);
     }
  };

  const handleApplySummaryFunction = async (data) => {
    try {
      await HandleApiCall(saveSummaryFooter, data);
      fetchApi();
      setApplySummaryFooter("");
    } catch (error) { }
    finally{
      setTimeout(()=>{
        setIsLoadingData(false);
      },0);
    }
  };

  const handleApplyGroupSummaryFunction = async (data) => {
    console.debug(
      "handleApplyGroupSummaryFunction",
      data?.summarizedColumn?.summaryFooterFunction,
      data?.summarizedColumn?.columnName
    );
    let newArray = columnDefs.map((obj: any, index) => {
      if (obj?.headerName === data?.summarizedColumn?.columnName) {
        return {
          ...obj,
          aggFunc:
            data?.summarizedColumn?.summaryFooterFunction == "average"
              ? "avg"
              : data?.summarizedColumn?.summaryFooterFunction == "none"
                ? null
                : data?.summarizedColumn?.summaryFooterFunction,
        };
      } else {
        return obj;
      }
    });
    setColumnDefs(newArray);
  };

  const onSelectionChanged = useCallback((e) => {
    const selectedRowKeys=e?.selectedRowKeys;
    const selectedRowsData=e?.selectedRowsData;
    console.debug("keys",selectedRowKeys);
    console.debug("data",selectedRowsData);
    if (selectedRowKeys.length>0 && selectedRowsData.length>0) {
      dispatch(updatePoHeader(selectedRowsData[selectedRowsData.length-1]));
      updatePONumber(selectedRowKeys[selectedRowKeys.length-1]);
      selectedPoNumberRef.current=selectedRowKeys;
      console.debug("selected",selectedPoNumberRef.current)
    }
    else{
      selectedPoNumberRef.current=[];
    }
   
  },[]);

  const onReqStockButtonClick = async () => {
    if (selectedPoNumberRef?.current?.length == 0) {
      toastMessage(t("Please select a stock to proceed."), "error");
    } else {
      console.debug("gridApi",gridApi);
      const selectedData = gridApi.getSelectedRowsData();
      console.debug("selectedData",selectedData);
      if (type == "STOCK_ENQUIRY" && isFirstTab) {
        setIsLoadingData(true);
        const stockCodes = selectedData.map((item) => item.stock_code);
        console.debug("SelectedStock Codes : ",stockCodes);
        const apiPromises = stockCodes.map((stockCode) => selectStockCodes(company, stockCode));
        const selectedStockDataResponses = await Promise.all(apiPromises);
        console.debug("SelectedStock Codes : ",selectedStockDataResponses);
        const selectedStockDataToBeSendInStockRequest = selectedStockDataResponses.map((response) => response?.data);
        let updatedStockData:any[] = [];

        selectedData.forEach((item, index) => {
          let selectedStockDataToBeSendInStockRequestResponse = selectedStockDataToBeSendInStockRequest.filter(data=>data.code===item.stock_code);
          let newItem;
          console.debug("selectedLocalStorageData 3: ",selectedStockDataToBeSendInStockRequestResponse);
          if(selectedStockDataToBeSendInStockRequestResponse.length>0){
            newItem = {...selectedStockDataToBeSendInStockRequestResponse[0]}
            newItem.locationDropdown = selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown;
            if(selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown && selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown.length>0){
              let defaultLocation = selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown[0]
              console.debug("Default Location : ",defaultLocation);
            newItem.location = defaultLocation.location;
            newItem.availableQty = defaultLocation.availableQty;
            newItem.onHandQty = defaultLocation.onHandQty;
            newItem.onOrderQty = defaultLocation.onOrderQty;
            newItem.unitCost = defaultLocation.unitCost;
            newItem.amount="0.00";

          }
        }
        newItem.lineNo = 0;
        newItem.tempLineNo = Number(index) + 1; // fix for the stock request should not have lineNo.
        console.debug("newItem : ",newItem);
        updatedStockData.push(newItem);
      });

        await dispatch(updateLookupData(updatedStockData));
        setIsLoadingData(false);
      }
      
      const arrayOfCode = lookupData?.map((obj) => obj.code);
      dispatch(updateSelectedRecords([]));
      dispatch(updateStockCode(""));     //It will avoid an addition api call for the stock code that was last added.
      dispatch(updateIsCreateRequest(true));
      dispatch(updateIsValidRouteForRequestStock(true));
      navigate("/requeststockdev", {
        state: { selectedValues: arrayOfCode, breadcrumb: "enquary" },
      });
    }
  };

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 150, 200];
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      cellRendererParams: {
        footerValueGetter: (params) => {
          return `Total (${params.node.field})`;
        },
      },
    };
  }, []);


  function renderModal(
    showModal,
    modalContent,
    modalText,
    onCloseModal,
    columnHeaders,
    tabStorage,
    tabSqlName,
    onApply,
    applySummaryFooter
  ) {
    if (showModal) {
      return (
        <UserEditModalDev
          content={modalContent}
          modalText={modalText}
          onCloseEditModal={onCloseModal}
          columnHeaders={(typeof columnHeaders==='function')?columnHeaders():columnHeaders}
          tabStorage={tabStorage}
          tabSqlName={tabSqlName}
          onApply={onApply}
          applySummaryFooter={applySummaryFooter}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      );
    } else {
      return null;
    }
  }

  const getCurrentColumnData = () => {
    let tobeColumnState = JSON.parse(JSON.stringify(newTabStorageData));
    const allColumns = gridApi?.getController('columns').getColumns();
    // console.log("allCOlumn",allColumns);
    const currentColumnState = allColumns?.map((column) => ({
      colId: column.dataField,
      columnIndex: (column.visibleIndex+1),
      columnVisible:column.visible,
      width: column.width,
      rowGroupIndex:column.groupIndex,
      pinned:column.fixed,
      pinnedPosition:column.fixedPosition, 
      sort:column.sortOrder,
      sortIndex:column.sortIndex

    }));
    
    if (currentColumnState) {
      tobeColumnState = tobeColumnState.map((columnObj) => {
        const matchingColumn = currentColumnState.find(
          (column) => column.colId === columnObj.colId
        );
        if (matchingColumn) {
          return {
            ...columnObj,
            columnIndex: matchingColumn.columnIndex,
            columnVisible: matchingColumn.columnVisible,
            width: matchingColumn.width,
            rowGroupIndex:matchingColumn.rowGroupIndex,
            pinned:matchingColumn.pinned,
            pinnedPosition:matchingColumn.pinnedPosition, 
            sort:matchingColumn.sort,
            sortIndex:matchingColumn.sortIndex

          };
        }
        return columnObj;
      });
      // console.debug("Updated TableData  : ", tobeColumnState);
    }
    
    tobeColumnState.sort(compareByColumnIndex);
    return tobeColumnState;
  }

  const toggleAction = useCallback((id) => {
    let newActionPopUpData = actionPopUpDataRef.current?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          label: toggleLabel(id, item.label),
        };
      }
      return item;
    })
    actionPopUpDataRef.current = newActionPopUpData;
  }, []);

  const onEditColumnButtonClick =
    (id) => {
      let toggleRequired = false;
      switch (id) {
        case 1:
          setShowModal(!showModal);
          break;
        case 2:
          setShowGroupingPanel((previousData) =>
            previousData === "never" ? "always" : "never"
          );
          toggleRequired = true;
          break;
        case 3:
          // searchVisible.current = !hideAndShowSearchPanel
          setHideAndShowSearchPanel((previousData) => !previousData);
          toggleRequired = true;
          break;
        case 4:
            // filterVisible.current = !showFiltering;
            setShowFiltering((previousData) => !previousData);
            toggleRequired = true;
            break;
        case 5:
          setGroupFooterVisible((previousData) => !previousData);
          toggleRequired = true;
          break;
        case 6:
            setSummaryFooterVisible((previousData) => !previousData);
            toggleRequired = true;
            break;
        case 7:
          setIsGroupingExpandable((previousData) => !previousData);
          toggleRequired = true;
          break;
        case 8:
          setMultiLineSelection((previousData) =>
            previousData === "single" ? "multiple" : "single"
          );
          break;
        case 9:
          setShowCustomizeModal((previousData) => !previousData);
          break;
        case 10:
          handleCustomizeLayout();
          break;
        case 11:
          onRestoreDefaultLayout();
          break;
        case 12:
          onRestoreCustomizeLayout();
          break;
        default:
          break;
      }
      if (toggleRequired) {
        setTimeout(() => {
          toggleAction(id);
        }, 1000);
      }
    };

  const toggleLabel = useCallback((id, label) => {
    const isShowLabel = label.includes("Show");
    const isDisableLabel = label.includes("Enable");

    if (isShowLabel || isDisableLabel) {
      return label.replace("Show", "Hide").replace("Enable", "Disable");
    } else {
      return label.replace("Hide", "Show").replace("Disable", "Enable");
    }
  }, []);

  return (
    <>
     <div>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-end mb-5">
          {hideAndShowSearchPanel ?(
            <SearchInputDev
              gridRef={gridRef}
              type={tabId}
              className={"col-md-5 mb-md-0 mb-3 pl-0"} // Add margin-top on small screens
              screen={"PoAndStock"}
            />
          ):( <div className={"col-md-5 mb-md-0 mb-3 pl-0"} style={{ position: "relative", display: "inline-block" }}></div>)}
          <div className="icon me-5 d-flex gap-2 pr-5">
            {isFirstTab && type === "STOCK_ENQUIRY" && (
              <div className="col-md-7">
                <button
                  className="btn text-white rounded-pill fs-6 primary-button w-100"
                  onClick={onReqStockButtonClick}
                  title="Process"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#0a7eac",
                  }}
                >
                  {t("Request Stock")}
                </button>
              </div>
            )}
              {customizeGridEnabled ? (
              <Tippy
                ref={tippyRef}
                content={dropDownContent}
                visible={visible}
                onClickOutside={hide}
                allowHTML={true}
                arrow={false}
                appendTo={document.body}
                interactive={true}
                placement="bottom"
              >
                <a
                  href="#!"
                  className="btn btn-light btn-active-light-primary btn-sm d-flex align-items-center justify-content-center"
                  onClick={onTippyClick}
                >
                  {t("Options")}
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr072.svg"
                    className="svg-icon-5 m-0"
                  />
                </a>
              </Tippy>
            ) : (
              ""
            )}
          {/* <DevExport
          gridRef={gridRef}
          apiData={defaultTabRowData}
          columnDefs={columnDefs}
          backendDef={newTabStorageData}
          file={fileName}
        /> */}
          </div>
      </div>
      {
        type != "PLANT_ENQUIRY" && (
          <div className="row">
        {isFirstTab && type !== "STOCK_ENQUIRY" && (
          <div className="col-md-11 my-5">
            <div className="d-flex w-100 gy-5 align-items-center row row-cols-md-5 justify-content-between">
              <div className="col-md-3">{t("Order Date From")}</div>
              <div className="col-md-3">
                <CustomDatePicker
                  onChange={handleStartDateChange}
                  value={startDate}
                />
              </div>
              <div className="col-md-1 text-center">{t("to")}</div>
              <div className="col-md-3">
                <CustomDatePicker
                  onChange={handleEndDateChange}
                  value={endDate}
                />
              </div>
              <div className="col-md-2">
                <button
                  className="btn text-white rounded-pill fs-6 primary-button w-100"
                  onClick={onNewProcessButtonClick}
                  title={t("Process")}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#0a7eac",
                  }}
                  disabled={!startDate || !endDate || startDate > endDate}
                >
                  {t("Process")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
        )
      }
      
          {isLoadingData ? (
            <CustomLoadingCellRender />
          ) : (
              <DataGridWrapper
                gridRef={gridRef}
                rowClass={"notranslate"}
                rowSelection={isFirstTab ? "multiple" : multiLineSelection}
                onGridReady={onGridReady}
                onSelectionChanged={onSelectionChanged}
                rowData={defaultTabRowData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={50}
                paginationPageSizeSelector={paginationPageSizeSelector}
                rowGroupPanelShow={customizeGridEnabled?showGroupingPanel==="always":null}
                groupFooterVisible={groupFooterVisible}
                isGroupingExpandable={isGroupingExpandable}
                summaryFooterVisible={summaryFooterVisible}
                height="520"
                // isFirstTab={isFirstTab}
                // multiLineSelection={multiLineSelection}
                isFilterRow={showFiltering}
                enableAdvancedFilter={true}
                keyExpr={(type == "STOCK_ENQUIRY")?'stock_code': (type == "PLANT_ENQUIRY") ? 'plantno' : 'ponumber'}
                isColumnChooserEnabled={true}
                isColumnFixingEnabled={true}
                fileName={fileName}
                exportEnabled={true}
                fontSizeForPdfExport={7}
              />
          )}

        {renderModal(
          showModal,
          "editColumns",
          t("Rename Columns"),
          closeEditModal,
          getCurrentColumnData,
          tabStorage,
          tabSqlName,
          handleEdit,
          ""
        )}
        {renderModal(
          showCustomizeModal,
          "customizeColumns",
          t("Customized Numeric Formatting"),
          closeEditCustomizeModal,
          getCurrentColumnData()?.filter((item) => item?.columnType == "Numeric"),
          tabStorage,
          tabSqlName,
          handleEditCustomizeColumn,
          ""
        )}

        {renderModal(
          applySummaryFooter && applySummaryFooter?.length > 0,
          "customizeSummaryFooter",
          t("Apply Function") + " (" + applySummaryFooter + ")",
          closeSummaryFunctionModal,
          newTabStorageData,
          tabStorage,
          tabSqlName,
          handleApplySummaryFunction,
          applySummaryFooter
        )}

        {renderModal(
          applyGroupSummaryFooter && applyGroupSummaryFooter?.length > 0,
          "customizeSummaryFooter",
          t("Apply Group Function") + " (" + applyGroupSummaryFooter + ")",
          closeGroupSummaryFunctionModal,
          newTabStorageData,
          tabStorage,
          tabSqlName,
          handleApplyGroupSummaryFunction,
          applyGroupSummaryFooter
        )}
      </div>
    </>
  );
};

export default DevPOAndStockTablesOpti;
