/** @format */

import { createRoot } from "react-dom/client";
// Axios
import axios from "axios";
import { Chart, registerables } from "chart.js";
import { QueryClient, QueryClientProvider } from "react-query";
// Apps
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './theme/assets/css/style.rtl.css'
 **/
import "bootstrap-icons/font/bootstrap-icons.css";
import "./theme/assets/sass/plugins.scss";
import "./theme/assets/sass/style.react.scss";
import "./theme/assets/sass/style.scss";

import { StyledEngineProvider } from "@mui/material/styles";
import { AuthProvider, setupAxios } from "./app/modules/auth";
import { AppRoutes } from "./app/routing/AppRoutes";

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios);
Chart.register(...registerables);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});
if (process.env.REACT_APP_ENV === "production") {
  console.debug = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const container = document.getElementById("root");
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <StyledEngineProvider injectFirst>
            <AppRoutes />
          </StyledEngineProvider>
        </AuthProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}
