import { useEffect, useState } from "react";

const CustomSimpleSelect = ({
  onChange,
  options,
  message,
  value=""
}) => {
  const [selected, setSelected] = useState<any>(value);
  const [filteredData, setFilteredData] = useState<any>(options);

  const onOptionChangeHandler = (event) => {
    console.debug(
      "User Selected Value - ",
      event.target.value,
      event
  );
    onChange(event.target.value)
    setSelected(event.target.value);
    
};


  useEffect(() => {
    setFilteredData(options);
  }, [options]);
  

  return (
    <>
    <select onChange={onOptionChangeHandler} className="custom-select form-control" id="inputGroupSelect03" >
    <option disabled selected>{value}</option>
    {filteredData?.map((item, index) => (
      <option 
      key={`optionsearch${index}`}
      selected={(item.label===selected)} 
      value={item.value}>{item.label}</option>
      ))}
  </select>
    </>
  );
};

export default CustomSimpleSelect;
