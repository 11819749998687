import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAuth } from "../../../auth";
import { toastMessage } from "../../../auth/functions/toastMessage";
import { setPreference } from "./core/_requests";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import ReactSelectStyle from "../../../../utils/ReactSelectStyle"; 

const SetPreferencePage = ({ onCancel, state, email, call }) => {
  
  const {t} = useTranslation();
  console.debug(state, "vvvvvvvvvvv");
  const { currentUser } = useAuth();
  const setPreferenceSchema = Yup.object().shape({
    doNotAskAgain: Yup.boolean(),
    platform: Yup.string().when('doNotAskAgain', {
      is: (doNotAskAgain, platform_email) => !doNotAskAgain && platform_email !== "",
      then: Yup.string().required("Please associate the account linked to email"),
      otherwise: Yup.string().notRequired(), // If doNotAskAgain is true, no validation
    }),
    platform_email: Yup.string().when(['doNotAskAgain', 'platform'], {
      is: (doNotAskAgain, platform) => !doNotAskAgain && platform !== "",
      then: Yup.string().email("Invalid email address").required("Email is required"),
      otherwise: Yup.string().notRequired(), // No validation if doNotAskAgain is true or platform is empty
    }),
  });
  

  const [emailOption, setEmailOption] = useState("Select Account");
  const navigate = useNavigate();
  console.debug(currentUser, "bbbbbbbbbbb");
  const handleSubmit = async (values) => {
    try {
      if (state !== "hide") {
        await setPreference(values, state);
        // navigate("/twoFactorAuth");
        toastMessage(t("M.PREFERENCE.SET.SUCCESS"), "success");
        call();
      } else {
        const [splitEmail, userName] = currentUser!.sub.split("#");

        try {
          await setPreference(values, userName);
          toastMessage(t("M.PREFERENCE.CHANGE.SUCCESS"), "success");
        } catch (error) {
          if (error.response && error.response.status === 403) {
            // Handle 403 Forbidden (email already in use) scenario
            toastMessage(t("M.USER.ALREADY.EXIST"),
              "error"
            );
          } else {
            // Handle other errors
            toastMessage(t("M.SOMETHING.WENT.WRONG"), "error");
          }
        }
      }

      onCancel();
    } catch (error) {
      // Handle other errors outside of the preference setting logic
      toastMessage(t("M.USER.ALREADY.EXIST"),
        "error"
      );
    }
  };

  const options = [
    { value: "google.com", label: "Google" },
    { value: "microsoft.com", label: "Microsoft" },
  ];

  const [selectedAccount, setSelectedAccount] = useState<any>(null);

  return (
    <Formik
      initialValues={{
        platform_email: "",
        platform: "",
        doNotAskAgain: false,
      }}
      validationSchema={setPreferenceSchema}
      onSubmit={(values) => {
        // Add your save functionality here
        handleSubmit(values);
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <div className="mb-7" style={{ marginTop: "-15px" }}>
            <label htmlFor="genCat" className="required fw-bold fs-6 mb-2">
              Select Account
            </label>
           <Select
              options={options}
              onChange={(e) => {
                setFieldValue(
                  "platform",
                  e?.value
                );
                setSelectedAccount(e?.label);
              }}
              value={options.find((option) => option.label === selectedAccount)}
              placeholder="Select Account"
              styles={ReactSelectStyle}
            />
            {touched?.platform && errors?.platform && (
              <div
                style={{
                  color: "#f35a7f",
                  marginTop: "5px",
                  fontSize: "11px",
                  fontWeight: "500",
                }}
              >
                {errors?.platform}
              </div>
            )}
          </div>

          <div className="fv-row mb-2">
            <label className="required fw-bold fs-6 mb-2">Enter Email</label>
            <Field
              type="email"
              name="platform_email"
              className={clsx("form-control form-control-light", {
                "is-invalid": touched.platform_email && errors.platform_email,
              })}
            />
            {touched.platform_email && errors.platform_email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{errors.platform_email}</span>
                </div>
              </div>
            )}
          </div>

          {state !== "hide" && (
            <div className="d-flex fs-5  mb-5 ">
              <div className="d-flex align-items-center justify-content-center">
                <input
                  type="checkbox"
                  checked={values.doNotAskAgain}
                  onChange={(e) =>
                    setFieldValue("doNotAskAgain", e.target.checked)
                  }
                  style={{ transform: "scale(1.2)" }}
                />
                <span
                  className="fs-5 text-black ms-4"
                  style={{
                    marginLeft: "30px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Do not ask again
                </span>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-center mt-5">
            <button
              type="button"
              className="btn btn-secondary rounded-pill mx-2"
              onClick={onCancel}
              title="Cancel"
            >
              Skip
            </button>
            <button
              type="submit"
              className="btn text-white  rounded-pill mx-2"
              style={{ backgroundColor: "#0a7eac" }}
              title="Save"
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SetPreferencePage;
