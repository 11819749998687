import { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef } from "ag-grid-community";
import "./UsersTable.css";
import {
  getAllCompany,
  getCompanyWithUserIdFrom,
  getMenus,
  saveUserMenu,
  saveUserMenudet,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { FaChevronDown } from "react-icons/fa";
import { KTCard, KTSVG } from "../../../theme/helpers";
import clsx from "clsx";
import { colors, titleCss } from "../../../constants/colors";
import "ag-grid-enterprise";
import sortalphadown from "../../../../src/assets/sortalphadown.png";
import Tippy from "@tippyjs/react";
import "./ActionPopup.css";
import AdvancedFilterPopup from "../../modules/widgets/components/Filterpopup";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import pdf from "../../../assets/pdf.png";
import excel from "../../../assets/excel.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useAuth } from "../../modules/auth";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import imgData from "../../../assets/logo.png";
import { HandleApiCall } from "../../modules/auth/functions/HandleApiCall";
import CustomLoadingCellRender from "./CustomLoadingCellRender";
import { columnOptionsConfig } from "./UserTableConfig";
import { exportDataToExcel } from "../../../theme/assets/ts/_utils/ExportXLSX";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SearchInput from "../../utils/SearchInput";
import { useLocation } from "react-router-dom";

const UserAccessTable = (props) => {
  const {t} = useTranslation();
  console.debug("UserAccessTable props", props);
  const userId = props?.data?.data;
  const isEditAccess = props?.data?.isEditAccess;
  const { setEditable, editable } = useAuth();
  
  const [accessData, setAccessData] = useState<any>({
    userId: userId?.userId || "",
    companyCode: "",
    access: [],
  });
  const company = useSelector((state: any) => state?.userConfig?.company);
  const companyName = useSelector((state: any) => state?.userConfig?.companyName);
  const [showFilter, setShowFilter] = useState(false);
  const [showCopyAccessModal, setShowCopyAccessModal] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [sortData, setSortData] = useState<any>([]);
  const [fromCompanyDropdown, setFromCompanyDropdown] = useState<string[]>([]);
  const [toCompanyDropdown, setToCompanyDropdown] = useState<string[]>([]);
  const [isadvanceShort, setIsadvanceShort] = useState(false);
  const [showExportModal, setShowExportModal] = useState<any>(null);
  const [showExportModalExcel, setShowExportModalExcel] = useState<any>(null);
  const [gridApi, setGridApi] = useState<any>(null);
  const [trackLoadingState, setTrackLoadingState] = useState(false);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>();
  const currentPath = window.location.pathname;
  const localStorageKeyFilter = `filters_${currentPath}`;
  const localStorageKeySort=`sorting${currentPath}`
  const location=useLocation();
  const [isLoading,setIsLoading]=useState(false);

  useEffect(() => {
      // Clear local storage when navigating away from the component
      return () => {
        localStorage.removeItem(localStorageKeyFilter);
        localStorage.removeItem(localStorageKeySort);
      };
    }, [localStorageKeyFilter,localStorageKeySort, location]);

  const fileName = "user_access_data";

  const user = [
    {
      id: "1",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Company Code",
      column: "t.COMP_CODE",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "2",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Company Name",
      column: "c.NAME",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "3",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Module Name",
      column: "mo.NAME",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "4",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Menu Name",
      column: "t.menuItem",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "5",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Menu Group",
      column: "t.menuGroup",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "6",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Access",
      column: "t.access",
      col4: sortalphadown,
      iconIndex: 0,
    },
  ];

  const onFilterButtonClick = () => {
    setShowFilter(!showFilter);
  };

  const closeExportModal = () => {
    setShowExportModal(false);
    setShowExportModalExcel(false);
  };

  const [exportAllData,setExportAllData]=useState([]);

  const fetchGetMenuAPI=async()=>{
    setTrackLoadingState(true);
    try {
      const response=await getMenus(
        { advancedFilters: filterData, sortedList: sortData },
        userId?.userId
      );
      if(response.data){
        setRowData(response?.data);
        if(filterData.length===0){
          setExportAllData(response?.data);
        }
      }
      
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }


  }


  useEffect(() => {
    fetchGetMenuAPI();
  }, [filterData, sortData]);

  const closeChangePasswordModal = (event) => {
    setIsadvanceShort(false);
  };

  const getCompanyFromApi = async () => {
    const companyGetResponse = await getCompanyWithUserIdFrom(
      userId?.userId ? userId?.userId : ""
    );
    const companyGetResponseAll = await getAllCompany();

    setFromCompanyDropdown(companyGetResponse);
    setToCompanyDropdown(companyGetResponseAll);
  };

  useEffect(() => {
    getCompanyFromApi();
  }, []);

  const AccessPopup = (props) => {
    const { editable } = useAuth();
    const tippyRef = useRef<any>();
    const [visible, setVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState<any>(null);

    const dropDownContent = (
      <div className="menu-container shadow-sm">
        <div
          onClick={() => handleOptionClick(true)}
          className="menu-items text-hover-primary"
        >
          true
        </div>
        <div
          onClick={() => handleOptionClick(false)}
          className="menu-items text-hover-primary"
        >
          false
        </div>
      </div>
    );

    const onCellValueChanged = (selectedOption) => {
      const changedData: any = {
        ...props?.data,
        access: selectedOption ? "true" : "false",
      };

      const updateRowData = (rowData, changedData, keyToUpdate) => {
        const { compCode, moduleName, menuName, menuGroup } = changedData;

        if (changedData.access == "false") {
          rowData.forEach((item) => {
            if (item[keyToUpdate] === changedData[keyToUpdate]) {
              item.access = changedData.access;
            }
          });
        } else
          rowData.forEach((item) => {
            if (
              changedData.access == "true" &&
              item.compCode === compCode &&
              item.moduleName === moduleName &&
              item.menuName === menuName &&
              item.menuGroup === menuGroup
            ) {
              item.access = changedData.access;
            }
          });
        return rowData;
      };

      const updateBottomFlowRowData = (rowData, changedData) => {
        const { compCode, moduleName, menuName, menuGroup } = changedData;

        const sameCompanyEntries = rowData.filter(
          (item) => item.compCode === compCode
        );
        const sameCompanyEntriesWithSameModules = rowData.filter(
          (item) => item.compCode === compCode && item.moduleName === moduleName
        );

        const trueEntriesCount = sameCompanyEntriesWithSameModules.reduce(
          (count, item) => {
            if (
              item.moduleName === moduleName &&
              item.access === "true" &&
              item.menuName !== "" &&
              item.menuGroup !== ""
            ) {
              return count + 1;
            }
            return count;
          },
          0
        );

        const trueEntriesCountWithSameCompanies = sameCompanyEntries.reduce(
          (count, item) => {
            if (
              item.access === "true" &&
              item.menuName !== "" &&
              item.menuGroup !== ""
            ) {
              return count + 1;
            }
            return count;
          },
          0
        );
        rowData.forEach((item) => {
          if (
            item.compCode === compCode &&
            (item.moduleName === moduleName ||
              item.moduleName == "" ||
              item.moduleName == null) &&
            (item.menuName === "" ||
              item.menuGroup === "" ||
              item.menuName === null ||
              item.menuGroup === null) &&
            trueEntriesCount === 0 &&
            trueEntriesCountWithSameCompanies === 0 &&
            changedData.access === "false"
          ) {
            item.access = "false";
          } else if (
            item.compCode === compCode &&
            item.moduleName === moduleName &&
            (item.menuName === "" ||
              item.menuGroup === "" ||
              item.menuName === null ||
              item.menuGroup === null) &&
            trueEntriesCount === 0 &&
            changedData.access === "false"
          ) {
            item.access = "false";
          } else if (
            item.compCode === compCode &&
            (item.moduleName === moduleName ||
              item.moduleName == "" ||
              item.moduleName == null) &&
            (item.menuName === "" || item.menuGroup === "") &&
            changedData.access === "true"
          ) {
            item.access = "true";
          } else if (
            item.compCode === compCode &&
            item.moduleName === moduleName &&
            item.menuName === menuName &&
            item.menuGroup === menuGroup &&
            changedData.access === "false"
          ) {
            item.access = "false";
          } else if (
            item.compCode === compCode &&
            item.moduleName === moduleName &&
            item.menuName === menuName &&
            item.menuGroup === menuGroup &&
            changedData.access === "true"
          ) {
            item.access = "true";
          }
        });

        return rowData;
      };

      const updatedData =
        (changedData?.moduleName === "" || changedData?.moduleName === null) &&
        (changedData?.moduleCode === null || changedData?.moduleCode === "") &&
        (changedData?.menuName === null || changedData?.menuName === "") &&
        (changedData?.menuGroup === "" || changedData?.menuGroup === null)
          ? updateRowData(rowData, changedData, "compCode")
          : (changedData?.menuName === null || changedData?.menuName == "") &&
            (changedData?.menuGroup === "" || changedData?.menuGroup === null)
          ? (updateBottomFlowRowData(rowData, changedData),
            updateRowData(rowData, changedData, "moduleCode"))
          :
          (changedData?.moduleName !== "" ||
              changedData?.moduleName !== null) &&
            (changedData?.moduleCode !== null ||
              changedData?.moduleCode !== "") &&
            (changedData?.menuName !== null || changedData?.menuName !== "") &&
            (changedData?.menuGroup !== "" || changedData?.menuGroup !== null)
          ? updateBottomFlowRowData(rowData, changedData)
          : rowData;

      setRowData(updatedData);
      setTimeout(() => {
        gridApi.refreshCells();
        gridApi.redrawRows();
      }, 1000);

      const existingIndex = accessData.access.findIndex((item) =>
        isEqualObjects(item, changedData)
      );

      if (existingIndex !== -1) {
        const updatedAccess = [...accessData.access];
        updatedAccess.splice(existingIndex, 1);
        setAccessData({ ...accessData, access: updatedAccess });
      } else {
        setAccessData({
          ...accessData,
          access: [...accessData.access, changedData],
        });
      }
    };

    function isEqualObjects(objA: any, objB: any): boolean {
      return JSON.stringify(objA) === JSON.stringify(objB);
    }
    const handleOptionClick = (selectedOption) => {
      hide();
      setSelectedOption(selectedOption);
      onCellValueChanged(selectedOption);
    };

    const show = () => setVisible(true);
    const hide = () => setVisible(false);

    const onClick = (event) => {
      event.preventDefault();
      if (visible) {
        hide();
      } else {
        show();
      }
    };

    return (
      <Tippy
        ref={tippyRef}
        content={dropDownContent}
        visible={visible}
        onClickOutside={hide}
        allowHTML={true}
        arrow={false}
        appendTo={document.body}
        interactive={true}
        placement="right"
        disabled={!editable}
      >
        <a
          href="#!"
          className="btn btn-light btn-active-light-primary btn-sm"
          onClick={onClick}
        >
          {selectedOption !== null
            ? selectedOption.toString()
            : props?.data?.access}
          <KTSVG
            path="/media/icons/duotune/arrows/arr072.svg"
            className="svg-icon-5 m-0"
          />
        </a>
      </Tippy>
    );
  };

  const onBtnExport = 
    (type) => {
      function filterUserFields(users) {
        const filteredUsers = users.map((user) => {
          return {
            "Company Code": user.compCode,
            "Company Name": user.compName,
            "Module Name": user.moduleName,
            "Menu Name": user.menuName,
            "Menu Group": user.menuGroup,
            Access: user.access,
          };
        });

        return filteredUsers;
      }
      let data;

      if (type === "all") {
        data = filterUserFields(exportAllData);
      } else {
        let filterData = getFilteredData();
        if (filterData?.length > 0) {
          data = filterUserFields(filterData);
        } else {
          data = [
            {
              "Company Code": "",
              "Company Name": "",
              "Module Name": "",
              "Menu Name": "",
              "Menu Group": "",
              Access: "",
            },
          ];
        }
      }
      exportDataToExcel(data, `${fileName}.xlsx`);
    };

  const getFilteredData = () => {
    if (gridApi) {
      const filteredRows: any = [];
      gridApi.forEachNodeAfterFilter((node: any) => {
        filteredRows.push(node.data);
      });
      return filteredRows;
    }
  };

  const onBtnExportPdf = (type) => {
    const pdf: any = new jsPDF();

    let slicedName =
      userId?.userName.length > 25
        ? `${userId?.userName}`.slice(0, 20) + "..."
        : userId?.userName;
    let slicedEmail =
      userId?.userEmail.length > 25
        ? `${userId?.userEmail}`.slice(0, 20) + "..."
        : userId?.userEmail;

    var image = new Image();
    image.src = imgData;

    let data;
    if (type === "all") {
      data = exportAllData;
    } else {
      data = getFilteredData();
    }
    let formattedData = data?.map((user) => [
      user?.compCode,
      user?.compName,
      user?.moduleName,
      user?.menuName,
      user?.menuGroup,
      user?.access,
    ]);
    autoTable(pdf, {
      didDrawCell: (data) => {
           // Draw a line at the top of the table (for the header)
    if (data.section === 'head') {
      const lineWidth = 0.1; // Adjust line width as needed
      const startX = data.cell.x;
      const startY = data.cell.y;
      const endX = startX + data.cell.width;
      const endY = startY;
      pdf.setLineWidth(lineWidth);
      pdf.line(startX, startY, endX, endY);
    }
        if (data.section === "body" && data.column.index === 0) {
          pdf.addImage(image, 'PNG', 13, 2, 50, 30);
          let currdate = new Date();
          pdf.setFontSize(10);
          pdf.text(15, 5, 'Printed At: ' + currdate.toLocaleString());
          pdf.setFontSize(10);
          pdf.text(120, 15, slicedEmail);
          pdf.text(120, 10, slicedName);
          pdf.text(120, 20, companyName + ' - ' + company);
        }
        if (data.row.index !== undefined && data.row.index !== data.table.body.length - 1) {
          // Draw horizontal line between rows
          const lineWidth = 0.1; // Adjust the line width as needed
          const startX = data.cell.x;
          const startY = data.cell.y + data.cell.height;
          const endX = startX + data.cell.width;
          const endY = startY;
          pdf.setLineWidth(lineWidth);
          pdf.line(startX, startY, endX, endY);
        }
        
      },
      didDrawPage: (HookData: any) => {
        pdf.setFontSize(10);
          pdf.setTextColor(214, 214, 214);
          pdf.text(85, 290, "For internal use only.");
        // Get current page number
        const currentPageNumber = pdf.internal.getCurrentPageInfo().pageNumber;
        pdf.setTextColor(0, 0, 0);
        pdf.text(260, 5, `Page ${currentPageNumber}`);
      },
      styles: { fontSize: 8 },
      margin: { top: 30 },
      theme: "plain",
      head: [
        [
          "Company Code",
          "Company Name",
          "Module Name",
          "Menu Name",
          "Menu Group",
          "Access",
        ],
      ],
      body: formattedData,
    });

    pdf.save("user_access_data.pdf");
  };

  const tempColumnDefs = [
    {
      headerName: "Company Code",
      field: "compCode",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Company Name",
      field: "compName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Module Name",
      field: "moduleName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Menu Name",
      field: "menuName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Menu Group",
      field: "menuGroup",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Access",
      field: "access",
      filter: "agTextColumnFilter",
      cellRenderer: AccessPopup,
      cellRendererParams: {
        rowData: rowData,
        setRowData: setRowData,
        gridApi: gridApi,
        accessData: accessData,
        setAccessData: setAccessData,
      },
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      sortable: false,
    },
  ];

  useEffect(() => {
    if (rowData && accessData && gridApi) {
      setColumnDefs(tempColumnDefs);
    }
  }, [rowData, accessData, gridApi]);

  useEffect(() => {
    if (gridApi && columnDefs) {
      columnDefs.map((item) => {
        if (item.headerName === "Access") {
          item.editable = editable ? true : false;
        }
      });
      setColumnDefs(columnDefs);
      gridApi.setColumnDefs(columnDefs);
    }
  }, [editable]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      enableRowGroup: true,
      minWidth: 150 ,
    }),
    []
  );

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      minWidth: 200,
    };
  }, []);

  const gridRef: any = useRef(null);

  const onGridReady = (params) => {
    gridRef.current = params.api;
    if (window.innerWidth < 800) {
      sizeToFit();
    }
    setGridApi(params.api);
  };
  const sizeToFit = () => {
    if (gridRef.current) {
      gridRef.current.sizeColumnsToFit({
        defaultMinWidth: 150,
      });
    }
  };

  const headerRef: any = useRef(null);

  const handleMouseEnter = () => {
    if (headerRef.current) {
      headerRef.current.setAttribute("data-bs-toggle", "");
    }
  };

  const onShortButtonClick = () => {
    setIsadvanceShort(!isadvanceShort);
  };

  const handleMouseLeave = () => {
    if (headerRef.current) {
      headerRef.current.setAttribute("data-bs-toggle", "collapse");
    }
  };


  const handleApply = (data) => {
    setFilterData(data);
  };
  const handleApplysort = (data) => {
    const sortPayload = data.map(({...newObject }) => ({
      column: newObject.column,
      ascDesc: newObject.ascDesc
    }));
    setSortData(sortPayload);
  };

  const closeCopyModal = () => {
    setShowCopyAccessModal(false);
  };

  const onCopyAccess = async (data) => {
    await saveUserMenudet(
      {},
      userId?.userId,
      data?.selectedCompanyCodeFrom,
      data?.selectedCompanyCodeTo
    );
    toastMessage(t("Access copied successfully."), "success");
    //refetch();
    fetchGetMenuAPI(); // need to check the usecase of this api
  };

  const saveUserAccess = async () => {
    setIsLoading(true);
    !isEditAccess && setEditable(false);
    await HandleApiCall(saveUserMenu, accessData);
    setAccessData({
      userId: userId?.userId,
      companyCode: "",
      access: [],
    });
    setIsLoading(false);
    //refetch();
    fetchGetMenuAPI() // need to check the usecase of this api
    getCompanyFromApi();
  };

  const handleedit = () => {
    setEditable(true);
  };

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 150, 200];
  }, []);

  return (
    <>
      <div className="accordion mt-10" id="accordionExample">
        <div className="accordion-item">
          <KTCard className="px-10">
            <div
              className=" bg-white cursor-pointer w-100 px-0"
              ref={headerRef}
            >
              <div className="d-flex justify-content-between align-items-center w-100 row row-cols-1 row-cols-md-3 gy-3 mr-3 py-5">
                <div
                  className={clsx("col-md-2", titleCss)}
                  style={{ color: colors.title }}
                >
                  {t('User Access')}
                </div>
                <SearchInput gridRef={gridRef} type="accessTable" className="col-md-5" screen={"UserAccessTable"} />
                <button
                  className="btn  rounded-pill text-white  text-wrap  fs-7 primary-button col-md-1 "
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={onShortButtonClick}
                  title={t('Sort')}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#0a7eac",
                  }}
                >
                  {t('Sort')}
                </button>

                <button
                  className="btn  rounded-pill text-white  text-wrap  fs-7 primary-button col-md-1"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={onFilterButtonClick}
                  title={t('Filter')}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#0a7eac",
                  }}
                >
                  {t('Filter')}
                </button>

                <button
                  className={`btn rounded-pill text-white text-wrap fs-7 ${
                    fromCompanyDropdown?.length == 0
                      ? "btn-secondary"
                      : "btn-primary"
                  } col-md-1`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  title={t('Copy')}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() =>
                    fromCompanyDropdown?.length == 0
                      ? console.debug()
                      : setShowCopyAccessModal(true)
                  }
                >
                  {t('Copy')}
                </button>

                <div className="col-md-1 d-flex align-items-center justify-content-end">
                  <div
                    className="btn"
                    data-toggle="collapse"
                    data-target="#accessTable"
                    aria-expanded="false"
                    aria-controls="accessTable"
                  >
                    <FaChevronDown size={15} />
                  </div>
                </div>
              </div>
            </div>
            {showFilter && (
              <AdvancedFilterPopup
                onClose={() => {
                  setShowFilter(false);
                }}
                columnOptions={columnOptionsConfig}
                onApply={handleApply}
              />
            )}
            {isadvanceShort && (
              <UserEditModal
                content="advancesort"
                modalText={t("Sort")}
                onCloseEditModal={closeChangePasswordModal}
                user={user}
                onApply={handleApplysort}
                style={{
                  position: "relative",
                  zIndex: 1000,
                }}
              />
            )}
            <div
              id="accessTable"
              className={`accordion-collapse collapse show`}
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="d-flex justify-content-between align-items-end mb-5">
                  <div></div>

                  <div className="icon"></div>

                  <div className="icon me-5 d-flex gap-5">
                    <button
                      className="btn  rounded-pill text-white  text-wrap p-2 fs-7 primary-button col-md-6 w-120px"
                      style={{
                        backgroundColor: "#0a7eac",
                        visibility: editable ? "hidden" : "unset",
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={handleedit}
                      title={t('Edit Access')}
                    >
                      {t('Edit Access')}
                    </button>
                    <img
                      src={pdf}
                      className="cursor-pointer"
                      style={{ height: "35px", width: "35px" }}
                      onClick={() => setShowExportModal(true)}
                    />
                    <img
                      src={excel}
                      className="cursor-pointer"
                      style={{ height: "35px", width: "35px" }}
                      onClick={() => setShowExportModalExcel(true)}
                    />
                  </div>
                </div>
                <div
                  className="ag-theme-alpine"
                  style={{ height: 620, width: "100%" }}
                >
                  {trackLoadingState?(<CustomLoadingCellRender/>):(
                  <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    rowDragManaged={true}
                    animateRows={true}
                    onGridReady={onGridReady}
                    rowGroupPanelShow={"always"}
                    autoGroupColumnDef={autoGroupColumnDef}
                    pagination={true}
                    paginationPageSize={50}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    suppressDragLeaveHidesColumns={true}
                  />)}
                </div>
              </div>
            </div>
            {editable && (
              <div className="text-center mt-5 mb-5">
                <button
                  type="button"
                  title={t('Cancel')}
                  className="btn btn-secondary rounded-pill me-5"
                  style={{ lineHeight: "1" }}
                  onClick={() => setEditable(false)}
                >
                  {t('Cancel')}
                </button>
                <button
                  type="submit"
                  title={t('Save')}
                  onClick={() => saveUserAccess()}
                  disabled={isLoading || trackLoadingState }
                  className="btn text-white rounded-pill"
                  style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
                >
                  {isLoading?t('Saving'):t('Save')}
                </button>
              </div>
            )}
          </KTCard>
        </div>
      </div>
      {(showCopyAccessModal || showExportModal || showExportModalExcel) && (
        <UserEditModal
          content={showCopyAccessModal ? "copy_access" : "export"}
          modalText={showCopyAccessModal ? "Copy Access" : "Export Data"}
          onCloseEditModal={
            showCopyAccessModal ? closeCopyModal : closeExportModal
          }
          user={showCopyAccessModal ? "reset" : ""}
          userId={userId}
          onSave={showExportModal ? onBtnExportPdf : onBtnExport}
          data={showCopyAccessModal ? fromCompanyDropdown : null}
          toData={showCopyAccessModal ? toCompanyDropdown : null}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
          isLoading={trackLoadingState}
          onCopyAccess={onCopyAccess}
        />
      )}
    </>
  );
};

export default UserAccessTable;
