import clsx from "clsx";
import { useRef, useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { colors, titleCss } from "../../../constants/colors";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import {
  getAuthGroup,
  getAuthGroupby,
  getPriority,
  getStockNumber,
  getStockStatus,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { useAuth } from "../../modules/auth";
import Select from "react-select";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import { formatDate } from "../../../theme/assets/ts/_utils/FormatData";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactSelectStyle from "../../utils/ReactSelectStyle";

const StockHeader = ({ data, triggerAdd, setError,currentPage }) => {
  const {t} = useTranslation();
  const routeData = data;
  const { currentUser } = useAuth();
  const extractValueBetweenHash = () => {
    const regex = /#(.*?)#/;
    const match = currentUser?.sub?.match(regex);
    if (match) {
      console.debug("Matched user : ",match)
      return match[1];
    }
    return null;
  };

  const extractUserIdBetweenHash = () => {
    const match = currentUser?.sub?.split("#");
    if (match) {
      return match[1];
    }
    return null;
  };

  const requestedBy = routeData?.requestedBy?routeData?.requestedBy:extractValueBetweenHash() || "";

  const headerDataOfStockString = localStorage.getItem("headerDataOfStock");
  const reqNoExist = localStorage?.getItem("requestNo");
  const [headerData, setHeaderData] = useState<any>();
  console.debug("route data in stock heder", routeData);
  

  let headerDataOfStock: any = {};
  const path = window.location.href.includes("/stockdetail");

  const getStock = async () => {
    if(routeData?.requestNo){
      setStockNumber(routeData?.requestNo);  
    }else{
      let response = await getStockNumber(company);
      localStorage.setItem("requestNo", response?.data);
      setStockNumber(response?.data);
    }
  };
  useEffect(() => {                           
    if(currentPage === "stockDetail" || !reqNoExist) {           
    getStock();
    }
  }, []);

  useEffect(() => {
    if (headerDataOfStockString) {
      try {
        headerDataOfStock = JSON.parse(headerDataOfStockString);
        setHeaderData(headerDataOfStock);
      } catch (error) {}
    }
    else if(reqNoExist){
      setStockNumber(reqNoExist)
    }
  }, [headerDataOfStockString]);

  const [selectedValue, setSelectedValue] = useState(null);

  const [instruction, setInstruction] = useState(routeData?.instructions);
  const [contact, setContact] = useState(routeData?.contactPerson);
  const [notes, setNotes] = useState(routeData?.notes);
  const [priority, setPriority] = useState<any>([]);
  const [selectedPriorityValue, setSelectedPriorityValue] = useState(priority?.find((option) => option?.value === routeData?.priority)?.value || "P2");
  const [selectedPriorityLabel, setSelectedPriorityLabel] = useState("Normal");
  const [selectedValueOfAuthBy, setSelectedValueOfAuthBy] = useState(
    routeData?.authorizeBy
  );
  const [selectedValueOfAuthByObj, setSelectedValueOfAuthByObj] =
    useState<any>();
  const [selectedValueAuthGroup, setSelectedValueAuthGroup] = useState(
    routeData?.authGroup
  );
  
  const [stockNumber, setStockNumber] = useState(routeData?.requestNo);
  const [stockStatus, setStockStatus] = useState(data?.status);
  const [authgroup, setAuthgroup] = useState<any>([]);
  const [authgroupby, setAuthgroupby] = useState<any>([]);
  
  

  const company = useSelector((state: any) => state?.userConfig?.company);
  const headerRef: any = useRef(null);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLoadingAuthBy, setIsLoadingAuthBy] = useState(false);

  useEffect(() => {
    console.debug("HeaderData changed || ",headerData,routeData);
    if (headerData || routeData) {
      
      if(headerData?.selectedPriorityValue){
        setSelectedPriorityValue(headerData?.selectedPriorityValue);
      }else{
        let priorityValue = priority?.find((option) => option?.value === routeData?.priority)?.value
        if(priorityValue){
          console.debug("Priority Label 1: ",priorityValue); 
          setSelectedPriorityValue(priorityValue);
        }
      }
      
      let headerDataPriorityLabel = headerData?.priority 
      let routeDataPriorityLabel = routeData?.priority;
      let priorityLabel = "Normal";
      if(headerDataPriorityLabel){
        priorityLabel = priority?.find((option) => option?.value === headerDataPriorityLabel)?.label
      }
      if(routeDataPriorityLabel){
        priorityLabel = priority?.find((option) => option?.label === routeDataPriorityLabel)?.label
      }
      console.debug("Priority Label : ",priorityLabel,headerData?.priority,routeData?.priority,priority);
      setSelectedPriorityLabel(priorityLabel);
      setSelectedValueOfAuthBy(headerData?.authorizeBy);
      setSelectedValueAuthGroup(headerData?.authGroup || routeData?.authGroup);
      setSelectedValue(headerData?.authGroup || routeData?.authGroup);
      setInstruction(headerData?.instructions || routeData?.instructions);
      setNotes(headerData?.notes || routeData?.notes);
      setContact(headerData?.contactPerson || routeData?.contactPerson);
      const reqNoFromLocalStorage = localStorage?.getItem("requestNo");
      setStockNumber(
        headerData?.requestNo
          ? headerData?.requestNo
          : routeData?.requestNo
          ? routeData?.requestNo
          : reqNoFromLocalStorage
      );
    }
  }, [headerData, routeData]);
 useEffect(() => {
    setSelectedValueOfAuthBy(routeData?.authorizeBy)
  }, [routeData]);

  useEffect(() => {
    console.debug("priority changed || ", priority, routeData?.priority);
    if (priority) {
      console.debug("priority Label 2 ");
      let selectedPriority = priority?.find((option) => option?.value === routeData?.priority);
      if(!selectedPriority){
        selectedPriority = priority?.find((option) => option?.label === routeData?.priority);
      }
      console.debug("priority Label 3 ", selectedPriority);
      if (selectedPriority) {
        setSelectedPriorityValue(selectedPriority.value);
        setSelectedPriorityLabel(selectedPriority.label);
      }
    }
  }, [priority, routeData?.priority]);

  useEffect(() => {
    if (priority) {
      let selectedPriority = priority?.find((option) => option?.value === headerData?.priority);
      if (selectedPriority) {
        setSelectedPriorityValue(selectedPriority.value);
        setSelectedPriorityLabel(selectedPriority.label);
      }
    }
  }, [priority, headerData?.priority]);

  useEffect(() => {
    if (
      !selectedValueAuthGroup ||
      selectedValueAuthGroup?.length == 0 ||
      !selectedValueOfAuthBy ||
      selectedValueOfAuthBy?.length == 0 ||
      !selectedPriorityValue ||
      selectedPriorityValue?.length == 0
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [selectedValueAuthGroup, selectedValueOfAuthBy, selectedPriorityValue]);

  const fetchData = async () => {
    try {
      const authgroupData = await getAuthGroup(company, requestedBy);

      if (Array.isArray(authgroupData.data)) {
        const transformedAuthGroup = authgroupData?.data.map((item) => ({
          value: item?.authGroup,
          label: `${item?.authGroup} - ${item?.authDetail}`,
          select: item?.authGroup === (selectedValueAuthGroup)
        }));

        setAuthgroup(transformedAuthGroup);
        console.debug("transformedAuthGroup", transformedAuthGroup);
      } else {
        setAuthgroup([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchAuthby = async () => {
    setIsLoadingAuthBy(true);
    try {
      const authgroupbyData = await getAuthGroupby(
        company,
        selectedValue || routeData?.authGroup
      );
      if (Array?.isArray(authgroupbyData.data)) {
        // Assuming authgroupData is an array like ['INREQ', 'INRE2']
        const transformedAuthGroup = authgroupbyData?.data.map((item) => ({
          value: item?.authorizeBy,
          label: `${item?.authorizeBy} - ${item?.authorizeByName}`,
          select: item?.selected,
        }));
        if(transformedAuthGroup.length !== 0){
          setAuthgroupby(transformedAuthGroup);
          transformedAuthGroup?.forEach((item, index) => {
            if (item.select) {
              if(!selectedValueOfAuthBy){
              setSelectedValueOfAuthBy(item.value);
              }
              setSelectedValueOfAuthByObj(item);
              
            }
          });
          }
          console.debug("dtfghjkl;", transformedAuthGroup);
      } else {
        setAuthgroupby([]);
      }
    } catch (error) {
      toastMessage(t("Failed to load authby due to technical error Please try again later."),"error");
      setAuthgroupby([]);     // set option to empty if api fails
    }
    finally{
      setIsLoadingAuthBy(false);
    }
  };

  useEffect(() => {
    if(selectedValue){                // added to prevent apicall when selectedvalue is undefined.
    fetchAuthby();
    }
  }, [selectedValue]);

  useEffect(() => {
    if (triggerAdd) {
      const formData = {
        instructions: instruction,
        contactPerson: contact,
        notes: notes,
        selectedPriorityValue,
        priority: selectedPriorityValue || "P2",
        authorizeBy: selectedValueOfAuthBy,
        authGroup: selectedValueAuthGroup,
        requestNo: stockNumber,
        requestDate: formattedDate.current,
        requestTime: formattedTime.current,
        requestedBy: requestedBy,
        status: stockStatus,
        totalAmount: 0,
      };
      console.debug("formData in triggerAdd", formData);

      localStorage.setItem("headerDataOfStock", JSON.stringify(formData));
    }
  }, [triggerAdd]);

  const fetchPriority=async()=>{
    try {
      const response=await getPriority(company);
      if (response) {
        response?.data?.forEach((item) => {
          item.label = item.description;
          item.value = item.code;
        });
        setPriority(response.data);
      }
      
    } catch (error) {
      toastMessage(t("Failed to fetch priority list Please try again later."),"error");
    }
  }

  useEffect(()=>{
    fetchPriority();
  },[])

  useEffect(() => {
    const getStatus = async () => {
      let response = await getStockStatus(company);
      setStockStatus(response?.data[0]?.description);
    };
    if (!data?.status) {
      getStatus();
    }
  }, []);

  const closeChangePasswordModal = (event) => {
    setChangePasswordModalOpen(false);
  };

  const closeDeleteModal = (event) => {
    setDeleteModalOpen(false);
  };

  const isViewOnly = routeData?.isViewOnly;


  const getCurrentFormattedDate=()=>{
    const currentDate = new Date(Date.now());
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");  
    return `${year}-${month}-${day}`;
  }

  
  const formattedDate: any = useRef(routeData?.requestDate?routeData?.requestDate:getCurrentFormattedDate());

  const getCurrentFormattedTime=()=>{
    const currentDate = new Date(Date.now());
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const seconds = currentDate.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`
  }
  
  const formattedTime: any = useRef(routeData?.requestTime?routeData?.requestTime:getCurrentFormattedTime());

  const onAuthGroupChange=(selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    if(selectedValue===value){
      return;
    }
    setSelectedValue(value);
    setSelectedValueAuthGroup(value);
    setSelectedValueOfAuthBy("");
    setSelectedValueOfAuthByObj("");
  }
  return (
    <>
      <div className="accordion mt-n2" id="accordionExample3">
        <div className="accordion-item">
          <div className="px-4">
            <div
              ref={headerRef}
              className="accordion-button bg-white cursor-pointer "
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                <div className="col-md-6 d-flex">
                  <div
                    className={clsx("", titleCss)}
                    style={{ color: colors.title }}
                  >
                    {t('Header')} - {stockNumber}
                  </div>
                </div>
              </div>
            </div>
            <div
              id="collapseThree"
              className={`accordion-collapse collapse ${
                path || data?.breadcrumb ? "show" : ""
              }`}
              aria-labelledby="headingTwo"
              aria-expanded="true"
              data-bs-parent="#accordionExample3"
            >
              <div className="accordion-body">
                <Card className="p-4">
                  <Row>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t('Request Number')}
                        </label>
                        <input
                          className="bg-secondary"
                          type="text"
                          id="requestNumber"
                          name="requestNumber"
                          value={stockNumber}
                          disabled={true}
                        />
                      </div>
                    </Col>

                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t('Request Date')}
                        </label>
                        <input
                          className="bg-secondary"
                          // type="date"
                          id="requestDate"
                          name="requestDate"
                          value={formatDate(formattedDate.current)}
                          disabled={true}
                        />
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t("Request Time")}
                        </label>
                        <input
                          className="bg-secondary"
                          type="time"
                          id="requestTime"
                          name="requestTime"
                          value={formattedTime.current}
                          disabled={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t("Requested BY")}
                        </label>
                        <input
                          className="bg-secondary"
                          type="name"
                          id="name"
                          name="name"
                          value={requestedBy}
                          disabled={true}
                        />
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label
                          htmlFor="genCat"
                          className="required fw-bold fs-6"
                        >
                          {t("Auth.Group")}
                        </label>
                        <Select
                          options={authgroup}
                          maxMenuHeight={120}
                          onChange={onAuthGroupChange}
                          value={
                            authgroup.find(
                              (option) => option.value === selectedValue
                            ) || routeData?.authGroup
                          }
                          styles={ReactSelectStyle}
                          defaultValue={
                            authgroup?.find(
                              (e) => e.value == routeData?.authGroup
                            )?.label
                          }
                          isDisabled={
                            routeData?.authGroup && isViewOnly && true
                          }
                        />
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label
                          htmlFor="genCat"
                          className=" required fw-bold fs-6 mb-2"
                        >
                          {t("Authorized By")}
                        </label>
                        <Select
                          options={authgroupby}
                          maxMenuHeight={120}
                          isLoading={isLoadingAuthBy}
                          onChange={(selectedOption) => {
                            const selectedValueOfAuthBys = selectedOption
                              ? selectedOption
                              : null;

                            if (
                              selectedValueOfAuthByObj?.select &&
                              !selectedValueOfAuthBys.select
                            ) {
                              toastMessage(
                                t("Authorization By code is different to Authorization Tree Structure"),
                                "error"
                              );
                            }
                            setSelectedValueOfAuthBy(
                              selectedValueOfAuthBys?.value
                            );
                          }}
                          value={
                            authgroupby.find(
                              (option) => option.value === selectedValueOfAuthBy
                            ) || selectedValueOfAuthBy
                          }
                          styles={ReactSelectStyle}
                          isDisabled={isViewOnly || isLoadingAuthBy} 
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label
                          htmlFor="genCat"
                          className=" required fw-bold fs-6 mb-2"
                        >
                          {t("Priority")}
                        </label>
                        <Select
                        options={priority}
                        onChange={(selectedOption) => {
                        const selectedPriorityValue = selectedOption ? selectedOption.value : null;
                       const selectedPriorityLabel = selectedOption ? selectedOption.label : null;
                       setSelectedPriorityLabel(selectedPriorityLabel || routeData?.priority.label);
                       setSelectedPriorityValue(selectedPriorityValue || routeData?.priority.value);
                       if(routeData!=null)
                        routeData.priority=selectedPriorityLabel
                       }}
                      value={{label:selectedPriorityLabel,value:selectedPriorityValue}}

                      styles={ReactSelectStyle}
                      isDisabled={routeData?.authGroup && isViewOnly && true}
                    />
                      </div>
                    </Col>

                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t("Instruction")}
                        </label>
                        <input
                          className={
                            routeData?.authGroup && isViewOnly
                              ? "bg-secondary"
                              : ""
                          }
                          type="text"
                          id="requestTime"
                          name="requestTime"
                          value={instruction}
                          onChange={(e) => {
                            const value = e.target.value;
                            const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;

                            const filteredValue = value.replace(
                              /[^a-zA-Z0-9\s]/g,
                              ""
                            );

                            if (alphanumericRegex.test(filteredValue)) {
                              setInstruction(filteredValue);
                            } else if (filteredValue === "") {
                              setInstruction(filteredValue);
                            }
                          }}
                          disabled={routeData?.authGroup && isViewOnly && true}
                        />
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t("Status")}
                        </label>
                        <input
                          className={"bg-secondary"}
                          type="name"
                          id="name"
                          name="name"
                          value={stockStatus}
                          disabled={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t("Contact")}
                        </label>
                        <input
                          className={
                            routeData?.authGroup && isViewOnly
                              ? "bg-secondary"
                              : ""
                          }
                          type="name"
                          id="requestTime"
                          name="requestTime"
                          value={contact}
                          onChange={(e) => {
                            const value = e.target.value;
                            const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;

                            const filteredValue = value.replace(
                              /[^a-zA-Z0-9\s]/g,
                              ""
                            );

                            if (alphanumericRegex.test(filteredValue)) {
                              setContact(filteredValue);
                            } else if (filteredValue === "") {
                              setContact(filteredValue);
                            }
                          }}
                          disabled={routeData?.authGroup && isViewOnly && true}
                        />
                      </div>
                    </Col>
                    <Col sm={8}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t("Notes")}
                        </label>
                        <input
                          className={
                            routeData?.authGroup && isViewOnly
                              ? "bg-secondary"
                              : ""
                          }
                          type="name"
                          id="requestTime"
                          name="requestTime"
                          value={notes}
                          onChange={(e) => {
                            const value = e.target.value;
                            const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;
                            const filteredValue = value.replace(
                              /[^a-zA-Z0-9\s]/g,
                              ""
                            );
                            if (alphanumericRegex.test(filteredValue)) {
                              setNotes(filteredValue);
                            } else if (filteredValue === "") {
                              setNotes(filteredValue);
                            }
                          }}
                          disabled={routeData?.authGroup && isViewOnly && true}
                        />
                      </div>
                    </Col>
                  </Row>
                  {(data?.isViewOnly || data?.status === "Not Submitted yet") &&(
                  <Row>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t("Req notes")}
                        </label>
                        <input
                          className={"bg-secondary"}
                          type="text"
                          id="reqNotes"
                          name="reqNotes"
                          value={headerData?.reqNotes || routeData?.reqNotes}
                          disabled={true}
                        />
                      </div>
                    </Col>
                    <Col sm={8}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t("Auth history")}
                        </label>
                        <input
                          className={"bg-secondary"}
                          type="text"
                          id="authHistory"
                          name="authHistory"
                          value={headerData?.authHistory || routeData?.authHistory}
                          disabled={true}
                        />
                      </div>
                    </Col>
                  </Row>)}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isChangePasswordModalOpen && (
        <UserEditModal
          content="reset_password"
          modalText={t("Reset Password")}
          onCloseEditModal={closeChangePasswordModal}
          user="reset"
          userId={data}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}

      {deleteModalOpen && (
        <UserEditModal
          content="delete_user"
          modalText={t("Delete")}
          onCloseEditModal={closeDeleteModal}
          user="reset"
          userId={data?.userId}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
    </>
  );
};

export default StockHeader;
