import clsx from "clsx";
import { useRef } from "react";
import { Card, Row } from "react-bootstrap";
import { colors, titleCss } from "../../../constants/colors";
import { formatDate } from "../../../theme/assets/ts/_utils/FormatData";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const POEnquiryHeader = () => {
  const {t} = useTranslation();
  const headerRef = useRef(null);

  const pOHeader = useSelector((state:any)=>state?.poAndStock?.pOHeader);

  console.debug(pOHeader,"poheader data")
  function renderInputField(label, valueKey, value = "", width = "20%",type) {
    const additionalClass = type === "Qty" ? "text-end" : "";
    return (
      <div className="pe-4" style={{ width: width }}>
        <div className="mb-5">
          <label
            htmlFor={label}
            className="fw-bold fs-6 mb-0"
            style={{ whiteSpace: "nowrap", overflow: "hidden" }}
          >
            {t(label)}
          </label>
          <input
            className={`bg-secondary ${additionalClass}`}
            type="text"
            id={label}
            name={label}
            value={valueKey ? pOHeader?.[valueKey] : value}
            disabled={true}
          />
        </div>
      </div>
    );
  }

  function formatAmount(value) {
    return value
      ? value.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : "0.00";
  }

  return (
    <div className="mt-n2">
      <div className="accordion" id="accordionExample3">
        <div className="accordion-item">
          <div className="px-4 mb-n5">
            <div
              ref={headerRef}
              className="accordion-button bg-white cursor-pointer"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                <div className="col-md-6 d-flex">
                  <div
                    className={clsx("", titleCss)}
                    style={{ color: colors.title }}
                  >
                    {t('Purchase Order Details')}
                  </div>
                </div>
              </div>
            </div>
            <div
              id="collapseThree"
              className={`accordion-collapse collapse show`}
              aria-labelledby="headingTwo"
              aria-expanded="true"
              data-bs-parent="#accordionExample3"
            >
              <div className="accordion-body">
                <Card className="p-4">
                  <Row>
                    {renderInputField("PO Number", "ponumber","","20%","")}
                    {renderInputField("Vendor Number", "vendor","","20%","")}
                    {renderInputField("Status", "OrderStatus","","20%","")}
                    {renderInputField("Currency", "POCurrency","","20%","")}
                    {renderInputField("Order Amount","",formatAmount(pOHeader?.OrderAmount),"20%","Qty")}
                  </Row>
                  <Row>
                    {renderInputField("Ordered Date","Orderdate",formatDate(pOHeader?.Orderdate),"20%","")}
                    {renderInputField("Expiry Date","ExpriryDate",formatDate(pOHeader?.ExpriryDate),"20%","")}
                    {renderInputField("Vendor", "VendorInfo","","20%","")}
                    {renderInputField("UIID", "uid","","20%","")}
                    {renderInputField("Invoice Status", "invoiceStatus","","20%","")}
                  </Row>
                  <Row>
                    {renderInputField("Recipient Amount","",formatAmount(pOHeader?.ReceivedAmt),"20%","Qty")}
                    {renderInputField("Contact", "ContactDetails","","20%","")}
                    {renderInputField("Invoice Amount","",formatAmount(pOHeader?.InvoicedAmt),"20%","Qty")}
                    {renderInputField("Description", "Description", "", "40%","")}
                  </Row>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default POEnquiryHeader;
