import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "../user/UsersTable.css";
import {
  approvebankdetail,
  changeVendorBank,
  deleteBankDetail,
  fetchBankList,
  getvendordropdown,
  rejectbankdetail,
  submitBankDetails,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { KTCard, KTSVG } from "../../../theme/helpers";
import ActionPopupDev from "../user/ActionPopupDev";
import { useAuth } from "../../modules/auth";
import CustomLoadingCellRender from "../user/CustomLoadingCellRender";
import { useTranslation } from "react-i18next";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import SearchInputDev from "../../utils/SearchInputDev";
import DevExport from "../../modules/custom components/Export/DevExport";
import DataGridWrapper from "../../utils/DataGrid/DataGridWrapper";
import { useSelector } from "react-redux";
import CompanyDetail from "../../modules/apps/user-management/users-list/CompanyDetail";
import Upload from "../../../assets/upload.png"
import Email from "../../../assets/email.png"
import AddFolder from "../../../assets/add-folder.png"
import File from "../../../assets/file.png"
import { Popup } from 'devextreme-react/popup';
import UploadFile from "../../modules/custom components/uploadFile";
import ValueFormatter from "../../utils/ValueFormatter";
import { Row} from "react-bootstrap";
import { Field, Form, Formik } from 'formik';
import { DeleteModal } from "../../modules/widgets/components/DeleteModal";
import { invoiceFilterConfig } from '../invoice/FilterConfig';
import Select from "react-select";
import ReactSelectStyle from "../../utils/ReactSelectStyle";
import CustomLoaders from "../user/CustomLoaders";
import DevDynamicGridTable from "../PO-Stock-Enquiry/DevDynamicGridTable";
import { FormControlLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";

export default function BankApprovalListing({type}) {

    const [trackLoadingState, setTrackLoadingState] = useState(false);
    const [rowData, setRowData] = useState<any>([]);
    const [sortData, setSortData] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>([]);
    const [showFilter, setShowFilter] = useState(false);
    const [isadvanceShort, setIsadvanceShort] = useState(false);
    const [gridApi, setGridApi] = useState<any>(null);
    const [columnDefs, setColumnDefs] = useState<any>([]);
    const [exportAllData, setExportAllData] = useState([]);
    const [selectedDataRow, setSelectedDataRow] = useState([]);
    const [commentVisibilityFlag, setCommentVisibilityFlag] = useState(false)
    const [commentValue, setCommentValue] = useState('');
    const [actionType, setActionType] = useState('');
    const [popupVisibilityFlag, setPopupVisibilityFlag] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState('');
    const [vendorOption, setVendorOption] = useState([]);
    const [loading, setLoading] = useState(false);

    const [tableConfiguration, setTableConfiguration] = useState<any>();
    const [tabStorage, setTableStorage] = useState();
    const [tabSqlName, setTabSqlName] = useState();
    
    const [option ,setOption]=useState([]);
    const [isToggled, setIsToggled] = useState(false)
    const [enableGridCustomization, setEnableGridCustomization] = useState<Boolean>(false)
    const { setDeleteid, deleteid ,currentUser } = useAuth();
    const company = useSelector((state: any) => state?.userConfig?.company);
    const {t} = useTranslation();
    const gridRef: any = useRef(null);
    const navigate = useNavigate();

    

    const defaultColDef = useMemo(
      () => ({
        flex: 1,
        sortable: true,
        resizable: true,
        minWidth: 100,
      }),
      []
    );

    useEffect(() => {
        fetchBankApprovalListAPI();
    }, [isToggled]);
  
    const fetchBankApprovalListAPI = async()=>{
        setTrackLoadingState(true);
        
        try {
          const response = await fetchBankList(company, type, isToggled ? "all" : "default")
          const array2: string[] = [];
            if(response && response.errorCode === 0 && response.data){
              setRowData(response.data.data);
              setTableConfiguration(response.data.tableDefinition);
              setEnableGridCustomization(response.data.customizeGridEnabled)
              setTableStorage(response.data.storageName)
              setTabSqlName(response.data.sqlName)
              setOption(response.data.menuName)
              setCommentValue('')
              const columnDef = [
                ...response.data.columnDefinitions,
                {
                  "infoType": "column",
                  "columnName": "actions",
                  "columnType": "Char",
                  "caption": "Actions",
                  "defaultCaption": "Actions",
                  "lastSavedCaption": "Actions",
                  "alignment": "left",
                  "defaultAlignment": "left",
                  "lastSavedAlignment": "left",
                  "columnIndex": response.data.columnDefinitions.length+1,
                  "defaultColumnIndex": response.data.columnDefinitions.length+1,
                  "lastSavedcolumnIndex": response.data.columnDefinitions.length+1,
                  "columnVisible": true,
                  "defaultColumnVisible": true,
                  "lastSavedColumnVisible": true,
                  "numericFormat": null,
                  "defaultNumericFormat": null,
                  "lastSavedNumericFormat": null,
                  "summaryFooterFunction": null,
                  "defaultSummaryFooterFunction": null,
                  "lastSavedSummaryFooterFunction": null,
                  "currency": null,
                  "defaultCurrency": null,
                  "lastSavedcurrency": null,
                  "colId": "action",
                  "width": 150,
                  "defaultWidth": 150,
                  "lastSavedWidth": 150,
                  "hide": false,
                  "defaultHide": false,
                  "lastSavedHide": false,
                  "pinned": true,
                  "defaultPinned": true,
                  "lastSavedPinned": true,
                  "pinnedPosition": "right",
                  "defaultPinnedPosition": null,
                  "lastSavedPinnedPosition": null,
                  "sort": null,
                  "defaultSort": null,
                  "lastSavedSort": null,
                  "defaultSortIndex": null,
                  "lastSavedSortIndex": null,
                  "aggFunc": null,
                  "defaultAggFunc": null,
                  "lastSavedAggFunc": null,
                  "rowGroup": false,
                  "defaultRowGroup": false,
                  "lastSavedRowGroup": false,
                  "rowGroupIndex": null,
                  "defaultRowGroupIndex": null,
                  "lastSavedRowGroupIndex": null,
                  "pivot": false,
                  "defaultPivot": false,
                  "lastSavedPivot": false,
                  "pivotIndex": null,
                  "defaultPivotIndex": null,
                  "lastSavedPivotIndex": null,
                  "flex": 1,
                  "defaultFlex": 1,
                  "lastSavedFlex": 1,
                  "amount": false,
                  "lastRestoreCustomLayout": false,
                }  
              ];
             
              setColumnDefs(columnDef);
              if(filterData.length===0){
                setExportAllData(response.data.data);
              }
            } else {
              toastMessage(response?.errorMsg,"error");
            }
        } catch (error) {
          toastMessage(t("An error occured Please try again later."),"error");
        }
        finally{
          setTrackLoadingState(false);
        }
      };

      const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
        return [10, 50, 100, 150, 200];
      }, []);

      const setSeletedRow = (array) => {
        const srArray = array.map((item) => (item.seq_no));
        setSelectedDataRow(srArray);
      }

      const openComment = (value) => {
        setCommentVisibilityFlag(true);
        setActionType(value);
      }

      const commentAdded = () => {
        setCommentVisibilityFlag(false);
        onSubmitAction();
      }

      const onSubmitAction = async () => {
        try {
          setLoading(true);
    
          const payload = {
            'notes': commentValue,
            'seqNos': selectedDataRow
          }

          let response: any = null
          if(actionType === 'submitDetails') {
            response = await submitBankDetails(company, payload);
          } else if (actionType === 'approveDetails') {
            response = await approvebankdetail(company, payload);
          } else if (actionType === 'deleteDetails') {
            response = await deleteBankDetail(company, payload);
          } else {
            response = await rejectbankdetail(company, payload);
          }
          if(response && response.errorCode === 0) {
            toastMessage(t(response.successMsg),"success");
            fetchBankApprovalListAPI();
          } else {
            if(response.errorMsg) {
              toastMessage(t(response.errorMsg),"error");
    
            } else {
              toastMessage(t("An error occured Please try again later."),"error");
            }
          }
        } catch (error) {
          toastMessage(t("An error occured Please try again later."),"error");
        }
        finally{
          setLoading(false);
        }
      }
      
      // const OnDeleteAction = async () => {
      //   try {
      //     setLoading(true);
    
      //     const payload = {
      //       'notes': commentValue,
      //       'seqNos': selectedDataRow
      //     }
    
      //     const response = await deleteBankDetail(company, payload);
      //     if(response && response.errorCode === 0) {
      //       toastMessage(t(response.successMsg),"success");
      //       fetchBankApprovalListAPI();
      //     } else {
      //       if(response.errorMsg) {
      //         toastMessage(t(response.errorMsg),"error");
    
      //       } else {
      //         toastMessage(t("An error occured Please try again later."),"error");
      //       }
      //     }
      //   } catch (error) {
      //     toastMessage(t("An error occured Please try again later."),"error");
      //   }
      //   finally{
      //     setLoading(false);
      //   }
      // }

      // const onApproveAction = async () => {
      //   try {
      //     setLoading(true);
    
      //     const payload = {
      //       'notes': commentValue,
      //       'seqNos': selectedDataRow
      //     }
    
      //     const response = await approvebankdetail(company, payload);
      //     if(response && response.errorCode === 0) {
      //       toastMessage(t(response.successMsg),"success");
      //       fetchBankApprovalListAPI();
      //     } else {
      //       if(response.errorMsg) {
      //         toastMessage(t(response.errorMsg),"error");
    
      //       } else {
      //         toastMessage(t("An error occured Please try again later."),"error");
      //       }
      //     }
      //   } catch (error) {
      //     toastMessage(t("An error occured Please try again later."),"error");
      //   }
      //   finally{
      //     setLoading(false);
      //   }
      // }



      const renderContent = () => {
        return (
        <Formik
        initialValues={{ commentValue: commentValue}}
        onSubmit={(values) => commentAdded()}
      >
        {({ handleChange, handleBlur }) => (
          <Form>
            <Field
              as="textarea"
              name="description"
              placeholder="Comment"
              className="form-control"
              style={{ minHeight: '100px' }}
              value={commentValue}
              onChange={(e) => {
                setCommentValue(e.target.value);
                handleChange(e); // Important to call Formik's handleChange
              }}
              onBlur={handleBlur} // Ensure Formik's blur handling
            />
            <div className="col py-3">
            <button
              className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
              title={t('Submit')}
              style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
              {t('Submit')}
            </button>
          </div>      
        </Form>
        )}
      </Formik>
        )
      }

      const rederSelect = () => {
        return(
          <Formik
          initialValues={{ selectedVendor: selectedVendor}}
          onSubmit={(values) => createBankDetails()}
        >
          {({ handleChange, handleBlur, errors, setFieldValue }) => (
            <Form>
                <div className="mb-5">
                <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Vendor Code')}</label>
                <div style={{ zIndex: 999999999 }}>
                  <Select
                    options={vendorOption}
                    required={true}
                    onChange={(selectedOption: any) => {
                      const selectedValue = selectedOption ? selectedOption.value : null;
                      setSelectedVendor(selectedValue);
                      setFieldValue("selectedVendor", selectedValue);
                    }}
                    classNamePrefix="custom-select"
                    value={vendorOption.find((option: any) => option.value === selectedVendor) || null}
                    styles={ReactSelectStyle}
                    placeholder={t("Select Vendor Code")} />
                </div>
                {/* {errors?.vendorCode && (
                  <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
                    {errors?.vendorCode}
                  </div>
                )} */}
              </div>
              <div className="col py-3">
            <button
              className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
              title={t('Proceed')}
              style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
              {t('Proceed')}
            </button>
          </div>      
        </Form>
        )}
      </Formik>
        )
      }

      const createBankDetails = async () => {
        try {
          setLoading(true);
          const response = await changeVendorBank(company, selectedVendor);
          if(response && response.errorCode === 0 && response.data && response.data.seqNo) {
            navigate('/editBankDetails', { state: { bankDetailId: response.data.seqNo, previousPage: 'create' } });
          } else {
            if (response.errorMsg) {
              toastMessage(t(response.errorMsg), "error");
            } else {
              toastMessage(t("An error occured Please try again later."), "error");
            }
          }
          setLoading(false);
          setPopupVisibilityFlag(false);
        } catch (error) {
          toastMessage(t("An error occurred. Please try again later."), "error");
          setLoading(false);
        }
      }

      const fetchVendorOption = async () => {
        try {
          setLoading(true);
          const response = await getvendordropdown(company);
          if (response) {
            const transformedGroup = response?.data.data.map((item) => ({
              value: item?.code,
              label: `${item?.code} - ( ${item?.name} )`,
            }));
            setVendorOption(transformedGroup);
          } else {
            setVendorOption([]);
          }
          setLoading(false);
          setPopupVisibilityFlag(true);
        } catch (error) {
          toastMessage(t("An error occurred. Please try again later."), "error");
          setLoading(false);
        }
      }
      
    const addRender = () => (
      <>
            <div className="d-flex flex-wrap gap-4 align-items-center mr-3">
            {type === 'create' && (
            <button
            className="btn text-white rounded-pill fs-6 primary-button"
            onClick={() => fetchVendorOption()}
            title={t('New')}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                  minWidth: "150px",
                  flexShrink: 0, // Ensures button maintains size in small screens
                }}
              >
                <FaPlus style={{ marginRight: "5px" }} size={15} />
                {t('New')}
              </button>
            )}
         
         <ToggleButtonGroup
            color="primary"
            value={isToggled}
            exclusive
            onChange={() => setIsToggled((prev) => !prev)}
            aria-label="Platform"
            className="d-flex flex-grow-1 flex-wrap gap-1"
          >
            <ToggleButton value={false} sx={{ minWidth: 100, textAlign: "center" }}>
              Default
            </ToggleButton>
            <ToggleButton value={true} sx={{ minWidth: 100, textAlign: "center" }}>
              All Data
            </ToggleButton>
          </ToggleButtonGroup>
      </div>
      </>
    )

  return (
    <>
     {
        loading && <CustomLoaders />
     }
    <div>
      <div
        className=" d-flex"
        style={{
          marginBottom: 15,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></div>
    </div>
    <KTCard className=" px-10">
      {trackLoadingState?(<CustomLoadingCellRender/>):(
        <DevDynamicGridTable
             type={option}
             tabTitle={'Bank Approval'}
             tabSqlData={rowData}
             tabStorageData={columnDefs}
             tabStorage={tabStorage}
             tabSqlName={tabSqlName}
             tableConfiguration={tableConfiguration}
             tabId={'0'}
             fetchOthersTabData={fetchBankApprovalListAPI}
             customizeGridEnabled={enableGridCustomization}
             onSelectionChanged={(e) => setSeletedRow(e.selectedRowKeys)}
             exportFlag={false}
             extraAction={addRender}
           />
     )}
      <Row className="mt-5 mb-5 pt-15" style={{ display: "flex", justifyContent: "center" }}>
      {
        selectedDataRow && selectedDataRow.length > 0 &&
          <>
          <div className="col">
              <Row className="mt-5 mb-5" style={{ display: "flex", justifyContent: "center" }}>
              {
              type === 'create' &&
              <>
              <button
                  className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-25 h-45px"
                  onClick={() => openComment('submitDetails')}
                  title={t('Submit')}
                  style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
                  {t('Submit')}
                </button>
                <button
                  className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-25 h-45px ml-5"
                  onClick={() => openComment('deleteDetails')}
                  title={t('Delete')}
                  style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
                  {t('Delete')}
                </button>
              </>
              }
              {
              type === 'approve' &&
              <>
              <button
                  className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-25 h-45px"
                  onClick={() => openComment('approveDetails')}
                  title={t('Approve')}
                  style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
                  {t('Approve')}
                </button>
                <button
                  className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-25 h-45px ml-5"
                  onClick={() => openComment('rejectDetails')}
                  title={t('Reject')}
                  style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
                  {t('Reject')}
                </button>
              </>
              }
              </Row>
        </div>
          </>
      }
      </Row>
      <Popup
        visible={commentVisibilityFlag}
        hideOnOutsideClick={true}
        onHiding={(e) => setCommentVisibilityFlag(false)}
        showCloseButton={true}
        resizeEnabled={true}
        title={'Comment'}
        width={335}
        height={280}
        contentRender={renderContent}/>

        <Popup
        visible={popupVisibilityFlag}
        hideOnOutsideClick={true}
        onHiding={(e) => setPopupVisibilityFlag(false)}
        showCloseButton={true}
        resizeEnabled={true}
        title={'Vendor Bank Details'}
        width={400}
        height={470}
        contentRender={rederSelect}/>
      </KTCard>
     
     </>
  )
}

