import { toastMessage } from "./toastMessage";

export const HandleApiCall = async (api, data) => {
  try {
    let res = await api(data);
    if (res?.errorCode === 1) {
      toastMessage(res?.errorMsg, "error");
    } else {
      toastMessage(res?.successMsg, "success");
    }
    return res;
  } catch (error) {
    throw error;
  }
};
