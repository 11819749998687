import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../../pages/user/UsersTable.css";

import {
  approveStockRequest,
  closerequest,
  getStockRequestList,
  issuebatch,
  lockRequest,
  rejectStockRequest,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { KTCard } from "../../../theme/helpers";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import { useAuth } from "../../modules/auth";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import CustomLoadingCellRender from "../user/CustomLoadingCellRender";
import { HandleApiCall } from "../../modules/auth/functions/HandleApiCall";
import CustomLoaders from "../user/CustomLoaders";
import { formatDate } from "../../../theme/assets/ts/_utils/FormatData";
import {
  approveStockColdefConfig,
  approvestockFilterConfig,
  closeUnprocesStockColdefConfig,
  closeUnprocessedColumnConfig,
  closeUnprocessedFilterColumnConfig,
  createIssueBatchColdefConfig,
  createIssueBatchFilterColumnsConfig,
  createIssueColumnsConfig,
  approveColumnsConfig,
} from "./LookUpFilterConfigDev";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DataGridWrapper from "../../utils/DataGrid/DataGridWrapper";
import SearchInputDev from "../../utils/SearchInputDev";
import ValueFormatter from "../../utils/ValueFormatter";
import DevExport from "../../modules/custom components/Export/DevExport";

interface LookUpScreenProps {
  type: string;
  section: string,
  setStockDetail?: any;
  stockDetail?: any;
  rejectStock?: any;
  setRejectStock?: any;
  reqNumber?: any;
  setreqNumber?: any;
  setLoader?: any;
  loader?: any;
  loaderReject?: any;
  setLoaderReject?: any;
  actionToBeCalled?: any;
  setActionToBeCalled?: any;
  setTotalSelectedReqNumber: any;
}

const LookUpScreenForApproveStockDev: React.FC<LookUpScreenProps> = ({
  type,
  section,
  loader,
  setreqNumber,
  setLoader = false,
  actionToBeCalled,
  setActionToBeCalled,
  setTotalSelectedReqNumber
}) => {
  const {t} = useTranslation();
  const company = useSelector((state: any) => state?.userConfig?.company);
  const { currentUser, setCreateIssueButtonDisabled } = useAuth();
  const [fileName, setFileName] = useState("");
  const [columnOptions, setColumnOptions] = useState<any>([]);
  const [comments, setComments] = useState("");
  const [rowData, setRowData] = useState<any>([]);
  const [sortData, setSortData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isadvanceShort, setIsadvanceShort] = useState(false);
  const [trackLoadingState, setTrackLoadingState] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [dataArray, setDataArray] = useState<any[]>([]);
  const dropdownRef: any = useRef();
  let [userEmail,userId]: any = currentUser?.sub?.split("#");
  const onFirstDataRendered=useRef(true);

  const columnDefsForTable=() => {
    let ColOpt;
    switch (type) {
      case "approve-stock":
        ColOpt = approveStockColdefConfig;
        break;
      case "close-unprocessed-stock":
        ColOpt = closeUnprocesStockColdefConfig;
        break;
      case "create-issue-batch":
        ColOpt = createIssueBatchColdefConfig;
        break;
      default:
        break;
    }
    return (ColOpt);
  };
  const columnDefinition = columnDefsForTable();
  const [columnDefs, setColumnDefs] = useState<any>([]);
  useEffect(() => {
    const col = columnDefinition.map((item) => {
      let valueFormatter;
          if (
            item?.columnType==='Numeric'
          ) {
              valueFormatter = (params) => { return (params.value || (params.value == 0)) ? ValueFormatter(params?.value,item?.numericFormat,item?.columnName) : "" };

          }
      return {
        headerName: item?.caption,
        columnType: item?.columnType,
        field: item?.field,
        width: item?.width,
        minWidth: item?.minWidth,
        valueFormatter:valueFormatter,
        halign: item?.alignment,
        hide: false,
      };
    });
    setColumnDefs(col);
  }, []);

  const extractValueBetweenHash = () => {
    const regex = /#(.*?)#/;
    const match = currentUser?.sub?.match(regex);
    if (match) {
      return match[1];
    }
    return null;
  };
  const currentUserName = extractValueBetweenHash() || "";

  useEffect(() => {
    const temp =
      type == "approve-stock"
        ? "stock_list_approve"
        : type == "create-issue-batch"
        ? "stock_list_issue_batch"
        : "stock_list_close-unprocessed-stock";
    setFileName(temp);
  }, [type]);

  useEffect(() => {
    const selectedColumns =
        type === "create-issue-batch" 
        ? createIssueBatchFilterColumnsConfig
        : type === "close-unprocessed-stock"
        ? closeUnprocessedFilterColumnConfig
        : approvestockFilterConfig
    setColumnOptions(selectedColumns);
  }, [type]);

  const closeChangePasswordModal = (event) => {
    setIsadvanceShort(false);
  };

  const [exportAllData,setExportAllData]=useState<any>();

  const fetchApiCall=async()=>{
    setTrackLoadingState(true);
    try {
      const sortDataResponse=await getStockRequestList(
        currentUserName,
        company,
        { advancedFilters: filterData, sortedList: sortData },
        "",
        "",
        type == "create-issue-batch"
          ? "issue"
          : type == "approve-stock"
          ? "approve"
          : "close",
        ""
      );
      if(sortDataResponse.data){
          const formattedRowData =
          sortDataResponse?.data?.stockRequestListingData?.map((item) => {
            return {
              ...item,
              date: item?.requestDate != null ? formatDate(item.requestDate) : "",
            };
          });
          setRowData(formattedRowData);

          if(filterData.length === 0 ){
          setExportAllData(formattedRowData);
          }
      }
      onFirstDataRendered.current=true;
      
      
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setLoader(false);
      setTrackLoadingState(false);
    }
  }

  useEffect(() => {
        fetchApiCall();
  }, [sortData, filterData]);

  
  const handleApiCall = async (
    apiFunction,
    data,
    successCallback,
    errorCallback,
    loaderSetter
  ) => {
    loaderSetter(true);
    setActionToBeCalled("");
    try {
      const response = await apiFunction(company, data);
      loaderSetter(false);
      console.debug("response handle api call", response);

      if (response?.data) {
        setCreateIssueButtonDisabled(true);
        successCallback(response.data);
        //refetchStock();
        fetchApiCall();
      } else {
        setCreateIssueButtonDisabled(false);
        // errorCallback("Server Error.");
      }
    } catch (error) {
      loaderSetter(false);
      errorCallback(error);
    }
  };
  const callrejectStockApi = async () => {
    let sample = [...dataArray];
    sample.forEach((item) => {
      item.notes = comments;
    });


    handleApiCall(
      rejectStockRequest,
      sample,
      (successMessage) => {
        console.debug(successMessage, "sucess");
        toastMessage(t(successMessage), "success");
      },
      (error) => {
        // setRejectStock(false);
      },
      setLoader
    );
  };

  const callAuthorizeApiOnSubmit = async () => {

    handleApiCall(
      approveStockRequest,
      dataArray.map((item) => ({ ...item, url: window.location.href })),
      (successMessage) => {
        toastMessage(t(successMessage), "success");
      },
      (error) => {},
      setLoader
    );
  };

  const callcloseStockApi = async () => {

    handleApiCall(
      closerequest,
      dataArray,
      (successMessage) => {
        toastMessage(t(successMessage), "success");
      },
      (error) => {},
      setLoader
    );
  };

  const callIssueBatchApi = async () => {
    const storedData: any = localStorage.getItem("createIssueBatch");
    const parsedData = JSON.parse(storedData) || [];
    const filteredData = parsedData;


    if (filteredData?.length > 0) {
      handleApiCall(
        issuebatch,
        filteredData,
        (successMessage) => {
          toastMessage(t(successMessage), "success");
          // callSortedLineApi();
        },
        (error) => {},
        setLoader
      );
    } else {
      toastMessage(t("Nothing to Process."), "error");
      setLoader(false);
    }
  };

  useEffect(() => {
    if (actionToBeCalled == "Authorize") {
      callAuthorizeApiOnSubmit();
    } else if (actionToBeCalled == "Close Unprocess") {
      callcloseStockApi();
    } else if (actionToBeCalled == "Issue Batch") {
      callIssueBatchApi();
    }
  }, [actionToBeCalled, dataArray]);

  const gridRef: any = useRef(null);

  const onGridReady = useCallback((params) => {
  }, []);

  function getLineItemsonFirstDataRendered(requestNo){
    if (requestNo) {
      setreqNumber(requestNo);
    }
  };
  const onContentReady = useCallback((params) => {
    if(onFirstDataRendered.current){
      const nodesToSelect: any[] = [];
      let allRows;
      gridRef?.current?.instance()?.getDataSource()
      .store()
      .load()
      .then((result) => {
        allRows=result;
      });
      allRows?.forEach((node: any) => {
        if (
          node &&
          node.checkedStatus &&
          node?.checkedUser === userId
        ) {
          nodesToSelect.push(node.requestNo);
        }
      });
      if(nodesToSelect.length===0){
        if(allRows.length>0){
          const firstRow=allRows[0]?.requestNo;
          getLineItemsonFirstDataRendered(firstRow);
        }
      }
      else{
        params?.component?.selectRows(nodesToSelect, true);
        const lastSelectedRow=nodesToSelect[nodesToSelect.length-1];
        getLineItemsonFirstDataRendered(lastSelectedRow);
      }
      onFirstDataRendered.current=false;
    }
  }, []);

  function isRowSelectable (props) {
    return (
      !!props &&
      (props?.checkedUser === userId || !props?.checkedStatus)
    );
};
  const onCellPrepared = useCallback((e) => {
    if (e.rowType === 'data' && e?.data && !isRowSelectable(e.data)) {
      e.cellElement.classList.add('disabled-row');
    }
  },[]);
  const handleEditorPreparing = useCallback((e) => {
    if (e.parentType === 'headerRow' && e.command === 'select') {
       e.editorElement.style.display = 'none';
       e.editorOptions.disabled=true;      // disable the select all option
    }
  },[]);
  const handleRowClick =useCallback((e)=>{
    console.debug("handleRowClick",e?.data?.requestNo);
    const requestNumber=e?.data?.requestNo;
    if(requestNumber){
      setreqNumber(requestNumber);
    }
  },[])

  const handleSelection =async(e)=>{
    const allSelectedData = e?.selectedRowsData;
    console.debug("allSelectedData",allSelectedData);

    const currentSelectedRowKeys=e?.currentSelectedRowKeys[0];
    const currentDeselectedRowKeys=e?.currentDeselectedRowKeys[0];


    async function handleLocking(requestNo,isSelected,){
      const payload = {
        company: company,
        requestNo: requestNo,
        selected: isSelected ? "true" : "false",
      };
       await HandleApiCall(lockRequest, payload);
    }
      if(currentSelectedRowKeys) { 
        // console.debug("handle selection")
        handleLocking(currentSelectedRowKeys,true);
        setreqNumber(currentSelectedRowKeys);
      }
      else if(currentDeselectedRowKeys){
        // console.debug("Handle unselection");
        handleLocking(currentDeselectedRowKeys,false);
        const lastSelectedData = allSelectedData[allSelectedData.length - 1];     
        if(lastSelectedData){
         setreqNumber(lastSelectedData?.requestNo); 
        }
        }
      }
  
  const updateLocalStorage = (allSelectedData) => {
    const storedData: any = localStorage.getItem("createIssueBatch");
    const parsedData = JSON.parse(storedData) || [];
    const mergeAndFilter = (arr1, arr2, prop) => {
      const merged = [...arr2, ...arr1];
      const uniqueMap = new Map(merged.map((item) => [item[prop], item]));
      return Array.from(uniqueMap.values());
    };

    const filteredParsedData = parsedData?.filter((item) =>
      allSelectedData?.some(
        (selectedItem) => selectedItem.requestNo === item.requestNo
      )
    );
    const combinedData = mergeAndFilter(
      filteredParsedData,
      allSelectedData,
      "requestNo"
    );
    localStorage.setItem("createIssueBatch", JSON.stringify(combinedData));
    const requestNos = combinedData?.map((obj) => obj.requestNo);
    setTotalSelectedReqNumber(requestNos);
  }
  const onSelectionChanged = useCallback(async(params) => {
    if(!onFirstDataRendered.current){
      await handleSelection(params);         //only call handleSelection on user interaction(not on first Data Render) 
    }
    const allSelectedData = params?.selectedRowsData;
    if (allSelectedData?.length > 0) {
        setCreateIssueButtonDisabled(false);
        setDataArray(allSelectedData);
      } else {
        setCreateIssueButtonDisabled(true);
        setDataArray([]);
    }
    if(type==="create-issue-batch"){
      updateLocalStorage(allSelectedData);
    }
  },[]);

  const onShortButtonClick = (event: any) => {
    event.preventDefault();
    setIsadvanceShort(!isadvanceShort);
  };
  const onFilterButtonClick = () => {
    setShowFilter(!showFilter);
  };

  const handleApplyFilter = (data) => {
    setFilterData(data);
  };

  const handleApplysort = (data) => {
    const sortPayload = data.map(({...newObject }) => ({
      column: newObject.column,
      ascDesc: newObject.ascDesc
    }));
    setSortData(sortPayload);
  };


  const closeModal = () => {
    setActionToBeCalled("");
  };

  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const closeDropdownOnClickOutside: any = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdownOnClickOutside);

    return () => {
      document.removeEventListener("click", closeDropdownOnClickOutside);
    };
  }, []);

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 200];
  }, []);


  return (
    <>
      {loader && <CustomLoaders />}
      <div
        ref={dropdownRef}
        onClick={(e) => {
          handleDropdownToggle("");
        }}
      >
        <div
          className=" d-flex"
          style={{
            marginBottom: 15,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      </div>
      <KTCard className=" px-10">
        <div className="mb-5 w-100">
          <div
            className="mt-5 row row-cols-1 row-cols-md-2 gy-3 w-100"
            style={{ alignItems: "center" }}
          >
            <SearchInputDev gridRef={gridRef} type={type} className={"col-md-4"} screen={"LookUpScreenForApproveStock"} />
            <div className="col-md-3"></div>
            <div className="col-md-2 d-flex align-items-end justify-content-end">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100"
                onClick={onShortButtonClick}
                title={t("Sort")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                {t("Sort")}
              </button>
            </div>
            <div className="col-md-2 d-flex align-items-end justify-content-end">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100"
                title={t("Filter")}
                onClick={onFilterButtonClick}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                {t("Filter")}
              </button>
            </div>
               {/* <div className="col-md-1 d-flex align-items-end justify-content-end">
               <DevExport
                 gridRef={gridRef}
                 apiData={exportAllData}
                 columnDefs={columnDefs}
                 backendDef={columnDefinition}
                 file={fileName}
               />
               </div> */}
            {showFilter && (
              <UserEditModal
              content="advancefilter"
              section={section}
              modalText="Filter"
              onCloseEditModal={() => {
                  setShowFilter(false);
                }}
                columnOptions={columnOptions}
                onApply={handleApplyFilter}
              />
            )}
            {isadvanceShort && (
              <UserEditModal
                content="advancesort"
                section={section}
                modalText={t("Sort")}
                onCloseEditModal={closeChangePasswordModal}
                user={
                     type === "create-issue-batch" 
                    ? createIssueColumnsConfig
                    :  type === "close-unprocessed-stock"
                    ? closeUnprocessedColumnConfig
                    : approveColumnsConfig
                }
                onApply={handleApplysort}
              />
            )}
          </div>
        </div>
          {trackLoadingState ? <CustomLoadingCellRender />:(
          <DataGridWrapper
            gridRef={gridRef}
            rowSelection={"multiple"}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={50}
            paginationPageSizeSelector={paginationPageSizeSelector}
            onContentReady={onContentReady}
            keyExpr={"requestNo"}
            height={350}
            onEditorPreparing={handleEditorPreparing}
            onCellPrepared={onCellPrepared}
            onRowClick={handleRowClick}
            fileName={fileName}
            exportEnabled={true}
            fontSizeForPdfExport={8}
          />
          )}
      </KTCard>

      {actionToBeCalled == "reject" && (
        <UserEditModal
          content="reject_modal"
          modalText={t("Comments/Reject Notes")}
          onCloseEditModal={closeModal}
          onSave={callrejectStockApi}
          setComments={setComments}
          comments={comments}
          rejectModal={actionToBeCalled}
          data={dataArray}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
    </>
  );
};

export default LookUpScreenForApproveStockDev;
