import { useEffect, useState } from "react";
import DashboardCard from "../DashboardCard";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../modules/auth";
import { sidebarMenuItem } from "../../../../constants/config";
import loader from "../../../../assets/erpAssets/loader.gif";

const Dashboard = () => {
  const { state }: any = useLocation();
  const { menuList, loading } = useAuth();
  const [call, setCall] = useState(false);
  let pinnedData: any = localStorage.getItem("pinnedData");
  pinnedData = JSON.parse(pinnedData);

  const [filteredData, setFilteredData] = useState([]);

  const getSubArrayByName = (response, name) => {
    const parentItem = response.find((item) => item.application === name);

    if (parentItem) {
      return parentItem.items || [];
    }

    for (const parent of response) {
      const subFunction = parent.items.find((sub) => sub?.menu_item === name);
      if (subFunction) {
        return subFunction.items || [];
      }
    }

    return [];
  };
  function sortMenuData(data) {
    let pinnedItems: any = [];
    let unpinnedItems: any = [];

    data?.length > 0 &&
      data?.forEach((element) => {
        element?.items?.forEach((item) => {
          if (item.pinned) {
            pinnedItems.unshift(item);
          } else {
            unpinnedItems.push(item);
          }
        });
      });

    pinnedItems.sort((a, b) => {
      const timestampA: any = new Date(a.pinnedTimeStamp);
      const timestampB: any = new Date(b.pinnedTimeStamp);

      return timestampB - timestampA;
    });
    unpinnedItems.sort((a, b) => {
      const itemA = a.menu_item.toUpperCase();
      const itemB = b.menu_item.toUpperCase();
      return itemA.localeCompare(itemB);
    });

    return [...pinnedItems, ...unpinnedItems];
  }
  function getAllSubFunctions(response) {
    const result: any = [];
    response!.forEach((menuItem) => {
      if (menuItem && menuItem.items) {
        result.push(...menuItem.items);
      }
    });

    let res = sortMenuData(menuList);
    return res;
  }

  const queryParams = new URLSearchParams(window.location.search);
  let search: any = queryParams.get("search");
  useEffect(() => {
    if (sidebarMenuItem && menuList) {
      let temp: any;
      if (state?.filter && state?.filter !== "Home") {
        temp = getSubArrayByName(menuList, state.filter);
      } else {
        temp = getAllSubFunctions(menuList);
      }

      setFilteredData(temp);
    }
  }, [state, menuList, call, search]);

  useEffect(() => {
    const handleSearch = (temp) => {
      if (search && search.length > 0) {
        temp = temp.filter((item) => {
          if (item.menu_item.toLowerCase().includes(search.toLowerCase())) {
            return item;
          }
        });
        return temp;
      }
    };
    if (sidebarMenuItem && menuList && search && search.length > 0) {
      let res = [...menuList];
      let filtered: any = [];
      let allData = getAllSubFunctions(menuList);

      let temp = res.filter((item) => {
        if (item.application.toLowerCase().includes(search.toLowerCase())) {
          return item;
        }
      });
      if (temp.length > 0) {
        temp = getAllSubFunctions(temp);
      }
      let temp2 = handleSearch(allData);
      let data: any = [];
      allData.forEach((item) => {
        if (item.items.length > 0) {
          item.items.forEach((res) => {
            data.push(res);
          });
        }
      });
      let temp3 = handleSearch(data);

      let allItems = [...temp, ...temp2, ...temp3];

      let uniqueMenuItems = Array.from(
        new Set(allItems.map((item) => item.menu_item))
      ).map((menu_item) => ({ menu_item }));

      let resultMap = {};
      uniqueMenuItems.forEach((item) => {
        resultMap[item.menu_item] = allItems.find(
          (i) => i.menu_item === item.menu_item
        );
      });

      let result: any = Object.values(resultMap);

      console.debug("RESSS", result);
      setFilteredData(result);
    }
  }, [search, menuList, call, state, sidebarMenuItem]);

  const handleChange = () => {
    setCall((prev) => !prev);
  };

  return (
    <div className="bg-white">
      {filteredData.length > 0 ? (
        <div className="py-20 p-10">
          <div className="row row-cols-1 row-cols-xxl-4 row-cols-xl-3 row-cols-md-2 row-cols-sm-1 gy-10 gx-10">
            {filteredData.map((item,index) => (
              <div key={`dashboarditem${index}`} className="col">
                <DashboardCard data={item} onChange={handleChange} />
              </div>
            ))}
          </div>
        </div>
      ) : loading ? (
        <div
          className="d-flex align-items-center justify-content-center w-100 text-dark fs-5"
          style={{ height: "72vh" }}
        >
          <img
            src={loader}
            alt="Loading..."
            style={{ height: "100px", width: "100px" }}
          />
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center w-100 text-dark fs-1"
          style={{ height: "72vh" }}
        >
          No sub function found.
        </div>
      )}
    </div>
  );
};

export default Dashboard;
