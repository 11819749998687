const FilteredRowOFDataGrid =  (gridRef) => {
    if(gridRef){
      const grid = gridRef?.current?.instance();
      const filterExpr = grid.getCombinedFilter(true);
      const dataSource = grid.getDataSource();
      const loadOptions = dataSource.loadOptions();
      let filteredRows;
      dataSource
          .store()
          .load({ filter: filterExpr, sort: loadOptions.sort, group: loadOptions.group })
          .then((result) => {
            filteredRows=result
          });
      return  filteredRows; 

    }
  };

  export default FilteredRowOFDataGrid;