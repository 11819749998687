/** @format */

import { useEffect, useState } from "react";
import ChangeLanguage from "../../../../../../theme/layout/components/modal/ChangeLanguage";
import ChangePassword from "../ChangePassword";
import CopyAccessMoadal from "../CopyAccessModal";
import DeleteUser from "../DeleteUser";
import SetPreferencePage from "../PrefrencePopup";

import { UserEditModalHeader } from "./UserEditModalHeader";
import ExportModal from "../../../../../../theme/layout/components/modal/ExportModal";
import NotificationApprove from "../../../../../../theme/layout/components/modal/NotificationApprove";
import Reject from "../../../../../../theme/layout/components/modal/Reject";
import AdvancedFilterPopup from "../../../../widgets/components/Filterpopup";
import AdvanceSort from "../AdvanceSort";
import CustomizeColumnsModal from "../CustomizeColumn";
import EditColumnsModal from "../EditColumn";
import ForgotPasswordModal from "../ForgotPasswordModal";
import CustomizeFunction from "../CustomizeFunction";
import EditColumnDev from "../EditColumnDev";
import CustomizeColumnsModalDev from "../CustomizeColumnDev";

const UserEditModalDev = (props: any) => {
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 991);
  };

  useEffect(() => {
    // Initial setup
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const modalStyle = {
    minWidth: props?.content === "editColumns" ? "600px" : "400px",
  };

  const modalStyleAdvanceFilter = {
    ...modalStyle,
    minWidth: "75%",
    marginLeft: "300px",
  };

  const mobileViewStyle = {
    minWidth: "78%",
    // minHeigth:"800px",
    marginLeft: "",
  };

  const modalStyleToApply = isMobileView
    ? { ...modalStyle, ...mobileViewStyle }
    : props.content === "advancefilter"
    ? modalStyleAdvanceFilter
    : modalStyle;
  return (
    <div className="modal-overlay">
      <div
        className="modal show w-100"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered "
          style={modalStyleToApply}
        >
          <div className="modal-content">
            <UserEditModalHeader
              titleText={props.modalText}
              onCloseModal={props.onCloseEditModal}
            />
            <div
              className={
                "modal-body" +
                (props?.content === "editColumns" ? " mh-400px" : "") +
                " scroll-y mx-xl-0 my-4"
              }
            >
             {props.content === "change_password" ? (
                <ChangePassword
                  onCancel={props.onCloseEditModal}
                  user={props.user}
                  userId={props.userId}
                  login={props.login}
                  call={props.call}
                />
              ) : props.content === "copy_access" ? (
                <CopyAccessMoadal
                  onCancel={props.onCloseEditModal}
                  user={props.userId}
                  data={props.data}
                  toData={props.toData}
                  onCopyAccess={props.onCopyAccess}
                />
              ) : props.content === "delete_user" ? (
                <DeleteUser
                  onCancel={props.onCloseEditModal}
                  user={props.user}
                  userId={props.userId}
                />
              ) : props.content === "reset_password" ? (
                <ChangePassword
                  onCancel={props.onCloseEditModal}
                  user={props.user}
                  userId={props.userId}
                />
              ) : props.content === "advancesort" ? (
                <AdvanceSort
                  onCancel={props.onCloseEditModal}
                  user={props.user}
                  onApply={props.onApply}
                  section={props?.section}
                  // userId={props.userId}
                />
              ) : props?.content === "editColumns" ? (
                <EditColumnDev
                  onCancel={props.onCloseEditModal}
                  user={props.user}
                  onApply={props.onApply}
                  columnHeaders={props.columnHeaders}
                  tabStorage={props.tabStorage}
                  tabSqlName={props.tabSqlName}
                />
              ) : props?.content === "customizeColumns" ? (
                <CustomizeColumnsModalDev
                  onCancel={props.onCloseEditModal}
                  user={props.user}
                  onApply={props.onApply}
                  columnHeaders={props.columnHeaders}
                  tabStorage={props.tabStorage}
                  tabSqlName={props.tabSqlName}
                />
              ) : props?.content === "customizeSummaryFooter" ? (
                <CustomizeFunction
                  onCancel={props.onCloseEditModal}
                  user={props.user}
                  onApply={props.onApply}
                  columnHeaders={props.columnHeaders}
                  tabStorage={props.tabStorage}
                  tabSqlName={props.tabSqlName}
                  applySummaryFooter={props.applySummaryFooter}
                />
              ) : props.content === "advancefilter" ? (
                <AdvancedFilterPopup
                  content={props.content}
                  section={props?.section}    // for screen having multiple filter sections
                  onClose={props.onCloseEditModal}
                  columnOptions={props.columnOptions}
                  onApply={props.onApply}
                  vendor={props?.vendor}
                />
              ) : props.content === "prefrence" ? (
                <SetPreferencePage
                  onCancel={props.onCloseEditModal}
                  state={props.state}
                  email={""}
                  call={props.call}
                />
              ) : props.content === "forgotpassword" ? (
                <ForgotPasswordModal
                  onCancel={props.onCloseEditModal}
                  state={props.state}
                  email={""}
                  call={props.call}
                />
              ) : props.content === "export" ? (
                <ExportModal
                  onCancel={props.onCloseEditModal}
                  state={props.state}
                  onSave={props.onSave}
                  isLoading={props?.isLoading}
                />
              ) : props.content === "change_language" ? (
                <ChangeLanguage onCancel={props.onCloseEditModal} />
                // <></>
              ) : props.content === "reject_modal" ? (
                <Reject
                  onCancel={props.onCloseEditModal}
                  comments={props.comments}
                  setComments={props.setComments}
                  onSave={props.onSave}
                  rejectModal={props.rejectModal}
                  data={props.data}
                />
              ) : props.content === "approve_notification_modal" ? (
                <NotificationApprove
                  onCancel={props.onCloseEditModal}
                  setUserName={props?.setUserName}
                  userName={props?.userName}
                  setPassword={props?.setPassword}
                  password={props?.password}
                  onSave={props.onSave}
                  isFormValid={isFormValid}
                  setIsFormValid={setIsFormValid}
                  rejectModal={props.rejectModal}
                  data={props.data}
                  reauthoriseLoader={props.reauthoriseLoader}
                />
              ) : (
                //<UserEditModalFormWrapper />
                <></>
              )}
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show mt-0 w-100 h-100"></div>
      {/* end::Modal Backdrop */}
    </div>
  );
};

export { UserEditModalDev };
