import { createSlice } from "@reduxjs/toolkit";

const counterSlice = createSlice({
  name: "index",
  initialState: {
    value: 0,
    isCreateRequest: true, 
    isValidRouteForRequestStock: false,
  },
  reducers: {
    updateIndex: (state, action) => {
      state.value = action.payload;
    },
    updateIsCreateRequest: (state, action) => {
      state.isCreateRequest = action.payload; // reducer to update isCreateRequest
    },
    updateIsValidRouteForRequestStock: (state, action) => {
      state.isValidRouteForRequestStock = action.payload;
    }
  },
});

export const { updateIndex , updateIsCreateRequest, updateIsValidRouteForRequestStock} = counterSlice.actions;
export default counterSlice.reducer;
