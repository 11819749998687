interface languageOptionDef {
  value: string;
  label: string;
}
declare global {
    interface Window {
      env: {
        REACT_APP_API_URL: string;
        REACT_APP_REQUISITION_URL: string;
        CONFIG: Config;
      };
    }
    interface Config {
      REAUTH:boolean;
      MESSAGE:string;
      TIMEOUT:number;
      LANGUAGE: languageOptionDef[];
    }
    
  }
  
  const { REACT_APP_API_URL, REACT_APP_REQUISITION_URL } = window.env;

export const URLS = {
	REACT_APP_API_URL: REACT_APP_API_URL,
    REACT_APP_REQUISITION_URL:REACT_APP_REQUISITION_URL
};