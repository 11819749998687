import { useState, useEffect, useCallback } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Formik, Form } from "formik";
import sortalphadown from "../../../../../../src/assets/sortalphadown.png";
import sortalphaup from "../../../../../../src/assets/sortalphaup.png";
import sortnumericdown from "../../../../../../src/assets/sortnumericdown.png";
import sortnumericup from "../../../../../../src/assets/sortnumericup.png";

const ItemTypes = {
  ROW: "row",
};

const icons = [sortalphadown, sortalphaup, sortnumericdown, sortnumericup];

interface RowData {
  id: number;
  column: string;
  ascDesc: string;
  col3: string;
  iconIndex: number;
  index?: number;
}

interface DraggedItem {
  index: number;
}

interface AdvanceSortProps {
  user: RowData[];
  onApply: (sortedData: { column: string; ascDesc: string }[]) => void;
  onCancel: () => void;
  section: string;
}

const AdvanceSort = (props: AdvanceSortProps) => {
  const currentPath = window.location.pathname;
  const localStorageKey = props.section?`sorting${currentPath}_${props.section}`:`sorting${currentPath}`;


  function getLocal() {
    const storedDataString = localStorage.getItem(localStorageKey);
    if (storedDataString) {
      const filterData: RowData[] = JSON.parse(storedDataString);
      return filterData;
    }

    return props.user;
  }
  
  const [data, setData] = useState<RowData[]>(localStorageKey ? getLocal() : props.user);

  const moveRow = useCallback((dragIndex: number, hoverIndex: number) => {
    setData((prevData) => {
      const newData = [...prevData];
      const [draggedRow] = newData.splice(dragIndex, 1);
      newData.splice(hoverIndex, 0, draggedRow);
      return newData;
    });
  }, []);

  const Row = ({ row, index, moveRow, handleDirectionChange }) => {
    const [, ref] = useDrag<DraggedItem>({
      type: ItemTypes.ROW,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop<DraggedItem>({
      accept: ItemTypes.ROW,
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveRow(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });

    return (
      <tr
        key={row.id}
        ref={(node) => drop(ref(node))}
        style={{ background: "inherit" }}
      >
        <td
          style={{
            border: "0",
            padding: "8px",
            paddingLeft: "40px",
            color: "black",
          }}
          className="fs-5"
        >
          {row.col3}
        </td>
        <td style={{ border: "0", padding: "8px" }}>
          <img
            src={icons[row.iconIndex]}
            style={{ height: "20px", width: "20px", cursor: "pointer" }}
            alt="sort icon"
            onClick={() => handleDirectionChange(row.id)}
          />
        </td>
      </tr>
    );
  };

  const handleDirectionChange = (id: number) => {
    setData((prevData) =>
      prevData.map((row) => {
        if (row.id === id) {
          let newIconIndex;
          // Toggle between sortalphadown and sortalphaup for alphabetic sorting
          if (row.iconIndex === 0 || row.iconIndex === 1) {
            newIconIndex = row.iconIndex === 0 ? 1 : 0;
          }
          // Toggle between sortnumericdown and sortnumericup for numeric sorting
          else if (row.iconIndex === 2 || row.iconIndex === 3) {
            newIconIndex = row.iconIndex === 2 ? 3 : 2;
          }
          return { ...row, iconIndex: newIconIndex };
        }
        return row;
      })
    );
  };
  

  const handleApply = () => {
    const sortedDataWithIndex = data.map((row) => ({
      column: row.column,
      ascDesc: row.iconIndex % 2 === 0 ? "asc" : "desc",
    }));

    const sortedDataToStore = data.map((row, index) => ({
      ...row,
      row: index + 1,
    }));

    localStorage.setItem(localStorageKey, JSON.stringify(sortedDataToStore));
    props.onApply(sortedDataWithIndex);
    props.onCancel(); // Close the card after applying
  };

  const handleReset = () => {
    localStorage.removeItem(localStorageKey);
    props.onApply([]);
    props.onCancel();
  };

  return (
    <Formik initialValues={{}} onSubmit={handleApply} onReset={handleReset}>
      {({ isSubmitting }) => (
        <DndProvider backend={HTML5Backend}>
          <Form>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "-28px",
              }}
            >
              <tbody>
                {data.map((row, index) => (
                  <Row
                    key={row.id}
                    row={row}
                    index={index}
                    moveRow={moveRow}
                    handleDirectionChange={handleDirectionChange}
                  />
                ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-center gap-15 mt-5">
              <button
                type="reset"
                className="btn btn-secondary mx-2"
                title="Reset"
                disabled={isSubmitting}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary mx-2"
                title="Apply"
                style={{ backgroundColor: "#0a7eac" }}
                disabled={isSubmitting}
              >
                Apply
              </button>
            </div>
          </Form>
        </DndProvider>
      )}
    </Formik>
  );
};

export default AdvanceSort;
