import { ColDef, SideBarDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../modules/auth";
import pdf from "../../../assets/pdf.png";
import excel from "../../../assets/excel.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import imgData from "../../../assets/logo.png";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import {
  getModifyTable,
  getResetColumn,
  getRestoreCustomizeLayout,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import { HandleApiCall } from "../../modules/auth/functions/HandleApiCall";
import { useSelector } from "react-redux";
import CustomLoadingCellRender from "../user/CustomLoadingCellRender";
import "ag-grid-charts-enterprise";
import { exportDataToExcel } from "../../../theme/assets/ts/_utils/ExportXLSX";
import Tippy from "@tippyjs/react";
import { functionsData } from "./functionData";
import { KTSVG } from "../../../theme/helpers";
import {
  formatDateToDDMMYYY,
} from "../../../theme/assets/ts/_utils/FormatData";

import ValueFormatter from "../../utils/ValueFormatter"; 
import HyperlinkRenderer from "../../utils/HyperlinkRendererProps";

import { useTranslation } from "react-i18next";
import SearchInput from "../../utils/SearchInput";
import { exportDataToExcelNew } from "../../../theme/assets/ts/_utils/ExportXLSXNew";


interface ActionData{
   id: number; 
   label: string;
}
interface MyColDef extends ColDef {
  halign?: string;
}

const DynamicGridTable = ({
  type,
  tabTitle,
  tabSqlData,
  tabStorageData,
  tabStorage,
  tabSqlName,
  tableConfiguration,
  tabId,
  fetchOthersTabData,
  customizeGridEnabled
}) => {
  const [newTabStorageData,setNewTabStorageData]=useState(tabStorageData);
  const {t} = useTranslation();
  const { currentUser, } = useAuth();
  const [hideAndShowSearchPanel, setHideAndShowSearchPanel] = useState<any>(tableConfiguration?.searchPanelVisible);
  const searchVisible = useRef(tableConfiguration?.searchPanelVisible)
  const [showFiltering, setShowFiltering] = useState(tableConfiguration?.filterVisible);
  const filterVisible = useRef(tableConfiguration?.filterVisible)
  const isCustomizedColumn = newTabStorageData?.filter((item) => item?.columnType == "Numeric");
  const company = useSelector((state: any) => state?.userConfig?.company);
  const companyName = useSelector((state: any) => state?.userConfig?.companyName);
  let [userEmail, userId]: any = currentUser?.sub?.split("#");

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [showModal, setShowModal] = useState<any>("");
  const [showCustomizeModal, setShowCustomizeModal] = useState<any>(false);
  const [showPdfExportModal, setShowPdfExportModal] = useState(false);
  const [showExcelExportModal, setShowExcelExportModal] = useState(false);
  
  const [multiLineSelection, setMultiLineSelection] = useState<any>(tableConfiguration?.multiLineSelection ? "multiple" : "single");
  const [showGroupingPanel, setShowGroupingPanel] = useState<any>(tableConfiguration?.groupingPanelVisible ? "always" : "never");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const gridRef: any = useRef(null);


  const tippyRef: any = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const actionPopUpDataRef = useRef<ActionData[]>();

  const updatedActionPopupData = functionsData.map((item) => {
    switch (item.label) {
      case "Show Grouping Panel":
        return {
          ...item,
          label: showGroupingPanel === "always"
            ? "Hide Grouping Panel"
            : "Show Grouping Panel",
        };
      case "Show Group Footer":
        return {
          ...item,
          label: tableConfiguration?.groupFooterVisible
            ? "Hide Group Footer"
            : "Show Group Footer",
        };
      case "Show Search Panel":
        return {
          ...item,
          label: hideAndShowSearchPanel
            ? "Hide Search Panel"
            : "Show Search Panel",
        };
      case "Show Summary Footer":
        return {
          ...item,
          label: tableConfiguration?.summaryFooterVisible
            ? "Hide Summary Footer"
            : "Show Summary Footer",
        };
      case "Show Filtering":
        return {
          ...item,
          label: showFiltering
            ? "Hide Filtering"
            : "Show Filtering",
        };
      case "Enable Multi-Line Selection":
        return {
          ...item,
          label: multiLineSelection
            ? "Disable Multi-Line Selection"
            : "Enable Multi-Line Selection",
        };

      default:
        return item;
    }
  });
  actionPopUpDataRef.current = updatedActionPopupData;
  
  const dropDownContent = (
    <div className="menu-container shadow-sm">
      {actionPopUpDataRef?.current?.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            hide();
            onEditColumnButtonClick(item.id);
          }}
          className="menu-items text-hover-primary"
        >
          {t(item.label)}
        </div>
      ))}
    </div>
  );

  const onClick = (event) => {
    event.preventDefault();
    if (visible) {
      hide();
    } else {
      show();
    }
  };
  const compareByColumnIndex = (a, b) => {
    return a.columnIndex - b.columnIndex;
  }


  useEffect(() => {
    setIsLoadingData(true);
    setTimeout(() => {
      setIsLoadingData(false);
    }, 100);
  }, [tabTitle, tabSqlData]);

  useEffect(() => {
    if (newTabStorageData) {
      newTabStorageData.sort(compareByColumnIndex);
      let columnType = newTabStorageData.filter((item) => item.infoType === "column");
      let cols = columnType.map((item) => {
        let valueFormatter;
          if (
            item?.columnType==='Numeric'
          ) {
            valueFormatter = (params) => { return (params.value || (params.value == 0)) ? ValueFormatter(params?.value,item?.numericFormat,item?.columnName) : "" };
          }
        return {
          headerName: item?.caption,
          field: item?.columnName,
          cellRenderer: item?.columnName.toLowerCase()==="path"?HyperlinkRenderer:null,
          filter: "agTextColumnFilter",
          rowDrag: false,
          hide: !item?.columnVisible,
          rowGroupIndex: item?.rowGroupIndex,
          valueFormatter: valueFormatter,
          headerClass: item?.alignment === "right" ? "ag-right-aligned-header ag-right-aligned-cell" : "",
          halign:item?.alignment,
          cellStyle: (params) => {
            const alignType = item?.alignment;

            return {
              textAlign:
                alignType == "right"
                  ? "right"
                  : alignType == "center"
                    ? "center"
                    : "left",
            };
          },
        };
      });
      setColumnDefs(cols);
    }
  }, [newTabStorageData]);

  useEffect(()=>{
    setNewTabStorageData(tabStorageData);

  },[tabStorageData])

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api);
      const customizedColumnData = newTabStorageData?.map((item) => ({
        colId: item?.colId,
        width: item?.width,
        hide: !item?.columnVisible,
        pinned: item?.pinned,
        sort: item?.sort,
        sortIndex: item?.sortIndex,
        aggFunc: item?.aggFunc,
        rowGroup: item?.rowGroup,
        rowGroupIndex: item?.rowGroupIndex,
        pivot: item?.pivot,
        pivotIndex: item?.pivotIndex,

      }));
      if (customizedColumnData?.length > 0 && params!.api) {
          params!.api.applyColumnState({
          state: customizedColumnData,
          applyOrder: true,
        });
      }
    },
    [newTabStorageData]
  );

  useEffect(() => {
    console.debug("SHow Filtering !!", showFiltering);
    if (gridApi) {
      const columnDefs = gridApi.getColumnDefs();
      if (columnDefs) {
        columnDefs?.forEach((colDef: any, index: number) => {
          colDef.floatingFilter = showFiltering;
        });
      }
      gridApi.setColumnDefs(columnDefs);
    }

  }, [showFiltering])

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      minWidth: 100,
      enableRowGroup: true,
      floatingFilter: showFiltering,
      lockVisible: true,
      enableValue: true,
      enablePivot: true,
      filter: true,
    }),
    [showFiltering]
  );

  const getFilteredData = () => {
    if (gridApi) {
      const filteredRows: any = [];
      gridApi.forEachNodeAfterFilter((node: any) => {
        filteredRows.push(node.data);
      });
      return filteredRows;
    }
  };

  const onBtnExportPdf = (types) => {
    const pdf: any = new jsPDF({
      orientation: "landscape",
    });

    var image = new Image();
    image.src = imgData;

    let columnStyles: any = {};
    const tableHeaders: any = (columnDefs as MyColDef[])?.map((column, index) => {
      columnStyles[index] = { halign: column?.halign };
      return {content: column?.headerName, styles: {halign: column?.halign}};
    });
     const numericFormKeys = newTabStorageData
  .filter(item => tableHeaders.some(header => header.content === item.caption))
  .map(item => ({ numericFormat: item?.numericFormat, columnName: item?.columnName,columnType:item?.columnType }));

    const tableBody: any = columnDefs?.map((column) => column.field);
    const body =
      types == "filtered"
        ? getFilteredData()?.map((item) => tableBody?.map((key) => item[key]))
        : tabSqlData?.map((item) => tableBody?.map((key) => item[key]));

    autoTable(pdf, {
      didDrawCell: (data) => {
        if (data.section === 'head') {
          const lineWidth = 0.1;
          const startX = data.cell.x;
          const startY = data.cell.y;
          const endX = startX + data.cell.width;
          const endY = startY;
          pdf.setLineWidth(lineWidth);
          pdf.line(startX, startY, endX, endY);
        }
        if (data.section === "head" && data.column.index === 0) {
          pdf.addImage(image, "PNG", 13, 2, 50, 30);
          let currdate = new Date();
          pdf.setFontSize(10);
          pdf.text(15, 5, 'Printed At: ' + formatDateToDDMMYYY(currdate.toLocaleString()));
          pdf.setFontSize(10);
          pdf.text(120, 15, userId);
          pdf.text(120, 10, userEmail);
          pdf.text(120, 20, companyName + ' - ' + company);
        }
        if (data.row.index !== undefined && data.row.index !== data.table.body.length - 1) {

          const lineWidth = 0.1;
          const startX = data.cell.x;
          const startY = data.cell.y + data.cell.height;
          const endX = startX + data.cell.width;
          const endY = startY;
          pdf.setLineWidth(lineWidth);
          pdf.line(startX, startY, endX, endY);
        }
      },
      didDrawPage: (HookData: any) => {
        pdf.setFontSize(10);
        pdf.setTextColor(214, 214, 214);
        pdf.text(85, 290, "For internal use only.");
        // Get current page number
        const currentPageNumber = pdf.internal.getCurrentPageInfo().pageNumber;
        pdf.setTextColor(0, 0, 0);
        pdf.text(260, 5, `Page ${currentPageNumber}`);
      },
      didParseCell: function(data) {
        // data.cell is the cell object
        // Format the value same as Aggrid:
        if (data.section === 'body') { // Only format body cells
          for (let i = 0; i < numericFormKeys.length; i++) {
            if (data.column.index === i && numericFormKeys[i].columnType === "Numeric") {
              data.cell.text[0] = ValueFormatter(data.cell.text[0],numericFormKeys[i].numericFormat,numericFormKeys[i].columnName);
            }
          }
        }
      },
      styles: { fontSize: 6 },
      margin: { top: 30 },
      theme: "plain",
      columnStyles: columnStyles,
      head: [tableHeaders],
      body: body,
      foot: [],
    });

    pdf.save(`${type}-item.pdf`);
  };

  const fileName = `${type}-item`;

  const onBtnExportExcel = (type: string) => {
    let columnStyles: any = {};
    const tableHeaders: any = (columnDefs as MyColDef[])?.map((column, index) => {
      columnStyles[index] = { halign: column?.halign };
      return { content: column?.headerName, styles: { halign: column?.halign } };
    });
  
    const numericFormKeys = newTabStorageData
    .filter(item => tableHeaders.some(header => header.content === item.caption))
    .map(item => ({ numericFormat: item?.numericFormat, columnName: item?.columnName,columnType:item?.columnType }));
  
    const dataToMap = type === "all" ? tabSqlData : getFilteredData();
    
    const sortedData = dataToMap.map((obj) => {
      let sortedObj: any = {}; // Adjust type definition as per your object structure
      tableHeaders.forEach((header, index) => {
        const key = header.content;
        let value = obj[key];
        if (numericFormKeys[index].columnType === "Numeric" && !isNaN(value)) {
          value = ValueFormatter(value, numericFormKeys[index].numericFormat,numericFormKeys[index].columnName); // Apply the formatter
        }
        sortedObj[key] = value;
      });
      return sortedObj;
    });
  
    exportDataToExcelNew(sortedData, `${fileName}`, columnStyles);
  };


  const getUpdatedColumnsWithIndex = () => {
    let columnState = gridRef.current!.api.getColumnState();
      columnState.map((columnObj, index) => {
        columnObj["columnIndex"] = index + 1;
      });
      const updatedData = newTabStorageData.map((data) => {
        const matchingColumn = columnState.find(column => column.colId === data.colId);
        if (matchingColumn) {
          return {
            ...data,
            width: matchingColumn.width,
            columnIndex: matchingColumn.columnIndex,
            aggFunc:matchingColumn.aggFunc,
            hide:matchingColumn.hide,
            columnVisible:!matchingColumn.hide,
            pinned:matchingColumn.pinned,
            pivot:matchingColumn.pivot,
            pivotIndex:matchingColumn.pivotIndex,
            rowGroup:matchingColumn.rowGroup,
            rowGroupIndex:matchingColumn.rowGroupIndex,
            sort:matchingColumn.sort,
            sortIndex:matchingColumn.sortIndex
          };
        }
        return { ...data }; // Create a new object with a new reference
      });
    
    setNewTabStorageData([...updatedData]); // Create a new array with a new reference
    return updatedData;
  }

  const handleCustomizeLayout = async () => {
    console.debug("handleCustomizeLayout : ", searchVisible.current,filterVisible.current);
    let updatedTableConfiguration = {
      ...tableConfiguration,
      searchPanelVisible: searchVisible.current,
      filterVisible: filterVisible.current,
      groupingPanelVisible: showGroupingPanel == "never" ? false : true,
      multiLineSelection: multiLineSelection == "single" ? false : true,
    };


    const payload = {
      tabStorage: tabStorage,
      tabSqlName: tabSqlName,
      modifiedTableConfiguration: updatedTableConfiguration,
      modifiedColumnConfiguration: getUpdatedColumnsWithIndex(),
      companyCode: company,
    };
    console.debug("customizedColumnData while saving", payload);

    try {
      setIsLoadingData(true);
      const response = await HandleApiCall(getModifyTable, payload);
      console.debug("Response : ", response);
      if (response.status === "OK") {
        console.debug("Response : OK Response so saving state");
        toastMessage(t("Saved Customized Layout Successfully"), "success");
      } else {
        console.debug("Response : NOT OK Response");
        toastMessage(t("Error Saving Customized Layout"), "error");
      }
       setIsLoadingData(false);
    } catch (error) {
       setIsLoadingData(false);
    }
  };

  const onRestoreDefaultLayout = async () => {
    setIsLoadingData(true);
    try {
      const payload = {
        payload: {},
        storageTab: tabStorage,
        tabSqlName: tabSqlName,
        companyCode: company,
      };
      const response = await HandleApiCall(getResetColumn, payload);
      await fetchOthersTabData(tabId,true);
      setIsLoadingData(false);
    } catch (error) {
      setIsLoadingData(false);
     }
  };

  const onRestoreCustomizeLayout = async () => {
    setIsLoadingData(true);
    try {
      const payload = {
        payload: {},
        storageTab: tabStorage,
        tabSqlName: tabSqlName,
        companyCode: company,
      };
      const response = await HandleApiCall(getRestoreCustomizeLayout, payload);

      await fetchOthersTabData(tabId,true);
      setIsLoadingData(false);
    } catch (error) { 
      setIsLoadingData(false)
    }
  };

  const closeEditModal = () => {
    setShowModal(false);
  };

  const closeEditCustomizeModal = () => {
    setShowCustomizeModal(false);
  };

  const handleEdit = async (data) => {
    setIsLoadingData(true);
    try {
      setNewTabStorageData(data.modifiedColumnNames);
      setIsLoadingData(false);
      setShowModal(false);
    } catch (error) {
      setIsLoadingData(false)
     }
  };

  const handleEditCustomizeColumn = async (data) => {
    setIsLoadingData(true);
    try {
      const newTabStorageDataUpdated = [
        ...newTabStorageData.filter((item) => !data.modifiedColumnNames.includes(item)),
        ...data.modifiedColumnNames,
      ];
      newTabStorageDataUpdated.sort(compareByColumnIndex)
      setNewTabStorageData(newTabStorageDataUpdated);
      setIsLoadingData(false);
      setShowCustomizeModal(false);
    } catch (error) {
      setIsLoadingData(false)
     }
  };

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 150, 200];
  }, []);

  const closePdfExportModal = () => {
    setShowPdfExportModal(false);
  };

  const closeExcelExportModal = () => {
    setShowExcelExportModal(false);
  };

  function renderModal(
    showModal,
    modalContent,
    modalText,
    onCloseModal,
    columnHeaders,
    tabStorage,
    tabSqlName,
    onApply,
    applySummaryFooter
  ) {
    if (showModal) {
      return (
        <UserEditModal
          content={modalContent}
          modalText={modalText}
          onCloseEditModal={onCloseModal}
          columnHeaders={columnHeaders}
          tabStorage={tabStorage}
          tabSqlName={tabSqlName}
          onApply={onApply}
          applySummaryFooter={applySummaryFooter}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      );
    } else {
      return null;
    }
  }

  const getCurrentColumnData = () => {
    let tobeColumnState = JSON.parse(JSON.stringify(newTabStorageData));
    let currentColumnState = gridRef?.current!?.api?.getColumnState();
    if (currentColumnState) {
      tobeColumnState?.map((columnObj, index) => {
        let currentColumnIndex = currentColumnState.findIndex(object => {
          return object.colId === columnObj.colId;
        });
        columnObj["columnIndex"] = currentColumnIndex + 1;
      })
    }
    tobeColumnState?.sort(compareByColumnIndex);
    return tobeColumnState;
  }

  const toggleAction = useCallback((id) => {
      let newActionPopUpData = actionPopUpDataRef.current?.map((item)=>{
        if (item.id === id) {
          return {
            ...item,
            label: toggleLabel(id, item.label),
          };
        }
        return item;
      })
      actionPopUpDataRef.current = newActionPopUpData;
  }, []);

  const onEditColumnButtonClick = 
    (id) => {
      let toggleRequired = false;
      switch (id) {
        case 1:
          setShowModal(!showModal);
          break;
        case 2:
          setShowGroupingPanel((previousData) =>
            previousData === "never" ? "always" : "never"
          );
          toggleRequired = true;
          break;
        case 3:
          searchVisible.current = !hideAndShowSearchPanel
          setHideAndShowSearchPanel((previousData) => !previousData);
          toggleRequired = true;
          break;
        case 4:
          // setShowSummaryFooter(!showSummaryFooter);
          // toggleRequired = true;
          break;
        case 5:
          // setShowGroupFooter(!showGroupFooter);
          // toggleRequired = true;
          break;
        case 6:
          filterVisible.current = !showFiltering;
          setShowFiltering((previousData) => !previousData);
          toggleRequired = true;
          break;
        case 7:
          //check what is this case and fix.
          // setRowData((previousData) => (previousData?.length > 0 ? [] : tableData?.tabData));
          break;
        case 8:
          setMultiLineSelection((previousData) =>
            previousData === "single" ? "multiple" : "single"
          );
          break;
        case 9:
          setShowCustomizeModal((previousData) => !previousData);
          break;
        case 10:
          handleCustomizeLayout();
          break;
        case 11:
          onRestoreDefaultLayout();
          break;
        case 12:
          onRestoreCustomizeLayout();
          break;
        default:
          break;
      }
      if (toggleRequired) {
        setTimeout(() => {
          toggleAction(id);
        }, 1000);
      }
    };

  const toggleLabel = useCallback((id, label) => {
    const isShowLabel = label.includes("Show");
    const isDisableLabel = label.includes("Enable");

    if (isShowLabel || isDisableLabel) {
      return label.replace("Show", "Hide").replace("Enable", "Disable");
    } else {
      return label.replace("Hide", "Show").replace("Disable", "Enable");
    }
  }, []);

  const sideBar = useMemo< SideBarDef | string | string[] | boolean | null >(() => { 
    return {
          toolPanels: [
              {
                  id: 'columns',
                  labelDefault: 'Grouping',
                  labelKey: 'columns',
                  iconKey: 'columns',
                  toolPanel: 'agColumnsToolPanel',
                  toolPanelParams: {
                    suppressPivotMode: false, // <-- suppress pivot!
                    suppressColumnSelectAll: false
                  },
              },
              {
                  id: 'filters',
                  labelDefault: 'Filters',
                  labelKey: 'filters',
                  iconKey: 'filter',
                  toolPanel: 'agFiltersToolPanel',
              }
          ],
      };
  }, []);

  return (
    <>
      <div>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-end mb-5">
          {hideAndShowSearchPanel ? (
            <SearchInput
              gridRef={gridRef}
              type={tabId}
              className="col-md-5 mb-md-0 mb-3 pl-0"
              screen={"PoAndStock"}
            />
          ):( <div className={"col-md-5 mb-md-0 mb-3 pl-0"} style={{ position: "relative", display: "inline-block" }}></div>)}

          <div className="icon me-5 d-flex gap-5">
            {customizeGridEnabled ? (
              <Tippy
                ref={tippyRef}
                content={dropDownContent}
                visible={visible}
                onClickOutside={hide}
                allowHTML={true}
                arrow={false}
                appendTo={document.body}
                interactive={true}
                placement="bottom"
              >
                <a
                  href="#!"
                  className="btn btn-light btn-active-light-primary btn-sm d-flex align-items-center justify-content-center"
                  onClick={onClick}
                >
                  {t("Options")}
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr072.svg"
                    className="svg-icon-5 m-0"
                  />
                </a>
              </Tippy>
            ) : (
              ""
            )}
            <img
              src={pdf}
              className="cursor-pointer"
              style={{ height: "35px", width: "35px" }}
              onClick={() => setShowPdfExportModal(true)}
            />
            <img
              src={excel}
              className="cursor-pointer"
              style={{ height: "35px", width: "35px" }}
              onClick={() => setShowExcelExportModal(true)}
            />
          </div>
        </div>
        {showPdfExportModal && (
          <UserEditModal
            content="export"
            modalText={t("Export Data")}
            onCloseEditModal={closePdfExportModal}
            state={""}
            onSave={onBtnExportPdf}
            style={{
              position: "relative",
              zIndex: 1000,
            }}
          />
        )}
        {showExcelExportModal && (
          <UserEditModal
            content="export"
            modalText={t("Export Data")}
            onCloseEditModal={closeExcelExportModal}
            state={""}
            onSave={onBtnExportExcel}
            style={{
              position: "relative",
              zIndex: 1000,
            }}
          />
        )}

        <div className="ag-theme-alpine" style={{ height: 520, width: "100%" }}>
          {isLoadingData ? (
            <CustomLoadingCellRender />
          ) : (
            <>
              <AgGridReact
                ref={gridRef}
                rowClass={"notranslate"}
                onGridReady={onGridReady}
                rowData={tabSqlData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowDragManaged={true}
                animateRows={true}
                pagination={true}
                paginationPageSize={50}
                paginationPageSizeSelector={paginationPageSizeSelector}
                suppressHorizontalScroll={false}
                suppressContextMenu={false}
                suppressRowClickSelection={true}
                suppressDragLeaveHidesColumns={true}
                rowGroupPanelShow={
                  customizeGridEnabled ? showGroupingPanel : null
                }
                sideBar={
                  customizeGridEnabled
                    ? showGroupingPanel !== "never"
                      ? sideBar
                      : null
                    : null
                }
              />
            </>
          )}
        </div>

        {renderModal(
          showModal,
          "editColumns",
          t("Rename Columns"),
          closeEditModal,
          getCurrentColumnData(),
          tabStorage,
          tabSqlName,
          handleEdit,
          ""
        )}
        {renderModal(
          showCustomizeModal,
          "customizeColumns",
          t("Customized Numeric Formatting"),
          closeEditCustomizeModal,
          isCustomizedColumn,
          tabStorage,
          tabSqlName,
          handleEditCustomizeColumn,
          ""
        )}
      </div>
    </>
  );
};

export default DynamicGridTable;




    
