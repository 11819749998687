import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

// Set up the PDF worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PdfViewerProps {
  fileUrl: string; // Ensure this is a string URL
}

export default function PdfViewer({ fileUrl }: PdfViewerProps) {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(1.0); // Scale state for zooming

  const zoomIn = () => {
    setScale(prevScale => Math.min(prevScale + 0.2, 1.8)); // Limit max zoom to 3x
  };

  const zoomOut = () => {
    setScale(prevScale => Math.max(prevScale - 0.2, 0.5)); // Limit min zoom to 0.5x
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <div style={{  height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
        <button onClick={zoomOut} style={{ marginRight: '10px' }}>
          Zoom Out
        </button>
        <button onClick={zoomIn}>
          Zoom In
        </button>
      </div>
      <div style={{ flexGrow: 1, overflow: 'auto', border: '1px solid black' }}>
        <Document
          file={fileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
        >
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
      </div>
      <div style={{ padding: '10px', textAlign: 'center' }}>
        Page {pageNumber} of {numPages}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
        <button
          disabled={pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}
          style={{ marginRight: '10px' }}
        >
          Previous
        </button>
        <button
          disabled={pageNumber >= numPages}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          Next
        </button>
      </div>
     
    </div>
  );
}
