import React from "react";

function AlertModelMoreAttempt({ onClose, days }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
        transition: "background 0.3s", // Add transition for the background color
      }}
    >
      <div
        style={{
          background: "#fff",
          padding: "30px",
          borderRadius: "5px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          textAlign: "center",
          position: "relative", // Position relative for positioning the close button
          opacity: 1, // Set initial opacity to fully visible
          transition: "opacity 0.3s", // Add transition for opacity
        }}
      >
        <button
          style={{
            position: "absolute",
            top: "-5px",
            right: "5px",
            background: "none",
            border: "none",
            fontSize: "30px",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          &times;
        </button>
        {/* <h2 className=" ">Password Reset?</h2> */}
        <div
          className="separator separator-solid my-2 "
          style={{ height: "2px" }}
        ></div>
        <div className="fs-5 mb-2 mt-4">{days}</div>
        <div>
          <button
            className="btn btn-secondary mx-2 rounded-pill"
            onClick={onClose}
          >
            Remind Me Later
          </button>
        </div>
      </div>
    </div>
  );
}

export default AlertModelMoreAttempt;
