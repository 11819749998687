import { Container, Card, Button } from "react-bootstrap";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate("/");
    resetErrorBoundary();
  };
  return (
    <Container className="mt-5">
      <Card className="text-center">
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>{t("Oops!")}</h1>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <p className="lead">
              {t("We're sorry, but something went wrong.")}
            </p>
            <p className="lead"> {t("Please try again later.")}</p>
            <p className="lead">
              {t("Please contact the Support Team at- ")}
              <a href="https://classicinfo.com.au/" target="_blank" className="text-info">
                Classic Info
              </a>
            </p>
          </Card.Text>
          <Button variant="primary" onClick={handleOnClick} className="m-2">
            {t("Go to Home Page")}
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

const ErrorBoundary = ({ children }) => {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
