import { KTSVG } from "../../../../../../theme/helpers";
// import { useListView } from "../core/ListViewProvider";

const UserEditModalHeader = (props: any) => {
  // const { setItemIdForUpdate } = useListView();

  return (
    <div
      className="modal-header "
      style={{
        backgroundColor:
          props.titleText == "Authorization" ? "#0b7eac" : "white",
      }}
    >
      {/* begin::Modal title */}
      <h2
        className="fw-bolder "
        style={{
          color: props.titleText == "Authorization" ? "white" : "black",
        }}
      >
        {props.titleText}
      </h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      {props.titleText !== "Authorization" && (
        <div
          className="btn btn-icon btn-sm btn-active-icon-primary"
          data-kt-users-modal-action="close"
          onClick={props.onCloseModal}
          style={{ cursor: "pointer" }}
          title="Close"
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-1"
          />
        </div>
      )}
      {/* end::Close */}
    </div>
  );
};

export { UserEditModalHeader };
