import { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../modules/custom components/CustomDatePicker/CustomDatePicker";
import {
  getRules,
  getUser,
  getUserById,
  getsaveuserdropdowns,
  isUserExist,
  saveUser,
} from "../../modules/apps/user-management/users-list/core/_requests";
import dayjs from "dayjs";
import Select from "react-select";
import { useAuth } from "../../modules/auth";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import { useDispatch, useSelector } from "react-redux";
import { updateRefreshState } from "../../../reducers/refreshApiState";
import CustomLoaders from "./CustomLoaders";
import { useTranslation } from "react-i18next";
import { Language } from "../../utils/Config";
import ReactSelectStyle from "../../utils/ReactSelectStyle";
type EquivData = {
  value: string;
  label: string;
};

interface UserData {
  userType?: string;
  userId?: string;
  userEmail?: string;
  password?: string;
  userName?: string;
  equivalentUser?: string;
  menuType?: string;
  notifyAllApprovals?: string;
  maintainenceFor?: string;
  startDate?: string;
  endDate?: string;
  language?: string;
  action?: string;
  platform?: string;
  platformEmail?: string;
  tfaCode?: string;
  tfaExpiry?: string;
  noTfaExpiry?: string;
  token?: string;
  tokenExpiry?: string;
  role?: string;
  accountLocked?:boolean;
}
const defaultPasswordRules = {
  "defaultExpiry": 5,
  "skipDays": 10,
  "minLength": 8,
  "maxLength": 20,
  "expiryDays": 90,
  "warningDays": 7,
  "capitalLetter": "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  "lowercaseLetter": "abcdefghijklmnopqrstuvwxyz",
  "number": "0123456789",
  "specialChar": "!@#$%^&*()[{}]",
  "lockingDuration": 30,
  "maxWrongAttempts": 5,
  "xexcludeChar": "~! _-+=`|\\(){}[]:;\"'<>,.?/"
};

const CreateUser = ({ data }) => {
  const {t} = useTranslation();
  const { currentUser } = useAuth();
  const [userData,setUserData]=useState<UserData>();
  const { setEditable } = useAuth();
  const [loading, setLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const language = Language.LANGUAGE;
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [equivData, setEquivData] = useState<EquivData[] | null | any>([]);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordRules, setPasswordRules] = useState<any>(defaultPasswordRules);
  let [userEmail, userId, userName]: any = currentUser?.sub?.split("#");
  const [dropdowndata, setDropdownData] = useState<any>(null);
  const refetchRefreshApiState = useSelector(
    (state: any) => state?.refreshState?.refreshApiState
  );
  const dispatch = useDispatch();
  const userIdForEdit=data;

  const userTypeOptions = dropdowndata?.userType?.map((item) => ({
    label: item.description,
    value: item.code,
  }))|| [];
  const menuTypeOptions = dropdowndata?.menuType?.map((item) => ({
    label: item.description,
    value: item.code,
  }))|| [];

  const notifyAllApprovalsOptions = dropdowndata?.notifyAll?.map((item) => ({
    label: item.description,
    value: item.code,
  }))|| [];
  const maintenanceForOptions = dropdowndata?.maintFor?.map((item) => ({
    label: item.description,
    value: item.code,
  }))|| [];

  const togglePasswordVisibility = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };
  const userIdValidatedRef = useRef(false);

  const handleInputChange = (event, setFieldValue) => {
    const inputValue = event.target.value
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, "");
    setFieldValue("userId", inputValue);
    userIdValidatedRef.current = true;
  };

  const initialValidationSchema = {
    userType: Yup.string().required(t("User Type is required")),
    userEmail: Yup.string().email(t("Invalid email address")).required(t("Email is required")),
    password: Yup.string().required(t("Password is required")),
    userName: Yup.string().required(t("Name is required")),
    userId: Yup.string().required(t("User id is required")).min(3, t("Minimum 3 characters required"))
      .test("is-unique", t("User ID must be unique"), async function (value) {
        if (value && value !== "") {
          if (userIdValidatedRef.current) {
            const isUnique = await isUserExist(value?.toUpperCase());
            userIdValidatedRef.current = false;
            return !isUnique?.data;
          }
        }
        return true;
      }),
      startDate: Yup.string()
      .nullable()
      .test(
        "start-date-validation",
        "Start Date should be greater than or equal to today",
        function (value) {
          if (!value || !dayjs(value).isBefore(dayjs(), 'day')){
            return true; // return true if validation succeeds
          }
          return false;
        }
      ),
    endDate: Yup.string()
      .nullable()
      .test("end-date-validation", "End Date should be equal or greater then Start Date", function (value) {
        if (!value || !dayjs(value).isBefore(dayjs(this.parent.startDate))) {
          return true; // return true if validation succeeds
        }
        return false;
      })

  };


  let modifiedValidationSchema = passwordRules
    ? {
        ...initialValidationSchema,
        password: initialValidationSchema.password

          .min(
            passwordRules.minLength,
            `${t('Password must be at least')} ${passwordRules.minLength} characters`
          )
          .max(
            passwordRules.maxLength,
            `${t('Password must be at most ')}${passwordRules.maxLength} characters`
          )
          .test(
            "uppercase",
            t("Password must contain at least one uppercase letter"),
            (value: any) =>
              passwordRules.capitalLetter
                ? new RegExp(`[${passwordRules.capitalLetter}]`).test(value)
                : /[A-Z]/.test(value)
          )
          .test(
            "lowercase",
            t("Password must contain at least one lowercase letter"),
            (value: any) =>
              passwordRules.lowercaseLetter
                ? new RegExp(`[${passwordRules.lowercaseLetter}]`).test(value)
                : /[a-z]/.test(value)
          )
          .test(
            "number",
            t("Password must contain at least one number"),
            (value: any) =>
              passwordRules.number
                ? new RegExp(`[${passwordRules.number}]`).test(value)
                : /\d/.test(value)
          )
          .test(
            "special",
            t("Password must contain at least one special character"),
            (value: any) => /[!@#$%^&*(),.?":{}|<>]/.test(value)
          )
          .notOneOf(
            Array.from(passwordRules.xexcludeChar),
            t("Password cannot contain certain special characters")
          ),
      }
    : initialValidationSchema;

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    if (!values.menuType) {
      values.menuType = "HM";
    }
    if (!values.userType) {
      values.userType = "U";
    }
    values.isEditCase = userData?.userId ? "TRUE" : "FALSE";
    let state = { data: values, isEditAccess: false };
    try {
      const response = await saveUser(values);
      if (response.errorCode != 1) {
        if (userId == values.userId) {
          dispatch(updateRefreshState(!refetchRefreshApiState));
        }
        setTimeout(() => {         
          toastMessage(response?.successMsg, "success");
          setShowSuccessMessage(true);
          setEditable(true);
          if(!userData?.userId){
            navigate("/details", { state });
          }
          setLoading(false);
        }, 1000);
      } else {
        toastMessage(response?.errorMsg, "error");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const company = useSelector((state: any) => state?.userConfig?.company);

  const fetchUserAPI =async()=>{
    setLoading(true);
    try {
      const response=await getUser("", {});
      if(response.data){
        const transformedData = response?.data?.map((item) => ({
          value: item?.userId,
          label: `${item?.userId} - ${item?.userName}`,
        }));
  
        setEquivData([
          { value: "", label: "Select Equiv. User ID" },
          ...transformedData,
        ]);
      }
      
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
      
    } finally{
      setLoading(false);
    }
  }

  const fetchUserById=async()=>{
    setLoading(true);
    try {
      const response=await getUserById(userIdForEdit);
      if(response.data){
        setUserData(response.data);
      }
      
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
      
    } finally{
      setLoading(false);
    }
  }
  
  const fetchUserDropDowns =async ()=>{
    setLoading(true);
    try {
      const response= await getsaveuserdropdowns(company);
      if(response.data){
        setDropdownData(response.data);
      }
      
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
    setLoading(false);
    }

  }
  const fetchPasswordRules = async () => {
    setLoading(true);
    try {
      const rules = await getRules();
      if (rules?.data) {
        const filteredRules = Object.keys(rules.data).reduce((acc, key) => {
          if (rules.data[key] !== null) {
            acc[key] = rules.data[key];
          } else {
            acc[key] = passwordRules[key];
          }
          return acc;
        }, {});
        setPasswordRules(filteredRules);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    if(userIdForEdit){
      setEditable(true);
      fetchUserById();
    }
    
    fetchUserAPI();
    fetchUserDropDowns();
    fetchPasswordRules();
  },[])      // fetch on mount
  

  const PasswordRulesTooltip = () => {
    const [tooltipContent, setTooltipContent] = useState<any>("");
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const showTooltip = () => setTooltipVisible(true);
    const hideTooltip = () => setTooltipVisible(false);

    useEffect(() => {
      const content = `
        <div>
          ${t('Password Rules')}
          <ul>
            <li>${t('Minimum length')}: ${passwordRules?.minLength}</li>
            <li>${t('Maximum length')}: ${passwordRules?.maxLength}</li>
            <li>${t('Include at least one uppercase letter')}: ${passwordRules?.capitalLetter}</li>
            <li>${t('Include at least one lowercase letter')}: ${passwordRules?.lowercaseLetter}</li>
            <li>${t('Include at least one number')}: ${passwordRules?.number}</li>
            <li>${t('Include at least one special character')}: ${passwordRules?.specialChar}</li>
           
          </ul>
        </div>
      `;

      setTooltipContent(content);
    }, []);

    return (
      <span
        className="ms-2"
        style={{ cursor: "pointer" }}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        <i className="bi bi-info-circle"></i>
        {tooltipVisible && (
          <div
            style={{
              position: "absolute",
              zIndex: 1000,
              background: "#fff",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: tooltipContent }} />
          </div>
        )}
      </span>
    );
  };

  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown((prevDropdown) =>
      prevDropdown === dropdownName ? null : dropdownName
    );
  };

  function formatDateToNumeric(inputDate: string | undefined): string {
    if (inputDate) {
      var parts = inputDate.split("/");
      var formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
      return formattedDate;
    } else {
      return "";
    }
  }

  return (
    <>
      {loading && <CustomLoaders />}
      <div
        onClick={(e) => {
          handleDropdownToggle("");
        }}
      >
        <Container className="mt-14">
          <Card className="px-5 py-4">
            <h2 className="mb-4">
              {userData?.userId ? t("Edit User") : t("Create User")}{" "}
            </h2>

            <Formik
              enableReinitialize
              initialValues={{
                userType: userData?.userType || "U",
                userId: userData?.userId || "",
                userEmail: userData?.userEmail || "",
                password: userData?.userId ? "***********" : "",
                userName: userData?.userName || "",
                equivalentUser: userData?.equivalentUser || "",
                menuType: userData?.menuType || "HM",
                notifyAllApprovals: userData?.notifyAllApprovals || "",
                maintainenceFor: userData?.maintainenceFor || "",
                startDate: userData?.startDate || "",
                endDate: userData?.endDate || "",
                language: userData?.language || "English",
              }}
              validationSchema={Yup.object().shape(
                !userData?.userId
                  ? modifiedValidationSchema
                  : initialValidationSchema
              )}
              onSubmit={handleSubmit}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {({
                touched,
                errors,
                values,
                setFieldValue,
                isSubmitting,
                isValid,
              }) => (
                <Form className="p-4">
                  <Row>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label
                          htmlFor="genCat"
                          className="required fw-bold fs-6 mb-2"
                        >
                          {t('User ID')}
                        </label>
                        <Field
                          type="text"
                          name="userId"
                          placeholder={t('User ID')}
                          value={values.userId.toUpperCase()}
                          className={`${
                            userData?.userId ? " form-control disable" : ""
                          }`}
                          disabled={userData?.userId ? true : false}
                          autoComplete="false"
                          onChange={(e) => handleInputChange(e, setFieldValue)}
                          triggerFn={() => handleDropdownToggle("")}
                        />
                        {errors?.userId && (
                          <div
                            style={{
                              color: "#f35a7f",
                              marginTop: "5px",
                              fontSize: "11px",
                              fontWeight: "500",
                            }}
                          >
                            {errors?.userId}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label
                          htmlFor="genCat"
                          className="required fw-bold fs-6 mb-2"
                        >
                          {t('Password')}
                          <PasswordRulesTooltip />
                        </label>
                        <div style={{ display: "flex" }}>
                          <Field
                            name="password"
                            placeholder={t('Password')}
                            type={passwordType}
                            className={`${
                              userData?.userId ? " form-control disable" : ""
                            }`}
                            autoComplete="false"
                            disabled={userData?.userId ? true : false}
                          />
                          {!userData?.userId && (
                            <div
                              onClick={togglePasswordVisibility}
                              style={{
                                position: "absolute",
                                right: 20,
                                marginTop: "10px",
                              }}
                            >
                              <i
                                className={`bi ${
                                  passwordType === "password"
                                    ? "bi-eye"
                                    : "bi-eye-slash"
                                }`}
                              ></i>
                            </div>
                          )}
                        </div>
                        {touched?.password && errors?.password && (
                          <div
                            style={{
                              color: "#f35a7f",
                              marginTop: "5px",
                              fontSize: "11px",
                              fontWeight: "500",
                            }}
                          >
                            {errors?.password}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label
                          htmlFor="genCat"
                          className="required fw-bold fs-6 mb-2"
                        >
                          {t('Email Address')}
                        </label>
                        <Field
                          type="text"
                          name="userEmail"
                          placeholder={t('Email Address')}
                          autoComplete="false"
                        />
                        {touched?.userEmail && errors?.userEmail && (
                          <div
                            style={{
                              color: "#f35a7f",
                              marginTop: "5px",
                              fontSize: "11px",
                              fontWeight: "500",
                            }}
                          >
                            {errors?.userEmail}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label
                            htmlFor="genCat"
                            className="required fw-bold fs-6 mb-2"
                        >
                          {t('User Type')}
                        </label>
                        <div style={{zIndex: 999999999}}>
                          <Select
                              options={userTypeOptions}
                              onChange={(selectedOption) => {
                                const selectedValue = selectedOption
                                    ? selectedOption.value
                                    : null;
                                setFieldValue("userType", selectedValue);
                              }}
                              value={
                                userTypeOptions.find(
                                  (option) =>
                                    option.value === values.userType
                                ) || null
                              }
                              styles={ReactSelectStyle}
                              placeholder={t("Select User Type")}
                          />
                        </div>
                        {touched?.userType && errors?.userType && (
                            <div
                                style={{
                                  color: "#f35a7f",
                                  marginTop: "5px",
                                  fontSize: "11px",
                                  fontWeight: "500",
                                }}
                            >
                              {errors?.userType}
                            </div>
                        )}
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label
                            htmlFor="genCat"
                            className=" required fw-bold fs-6 mb-2"
                        >
                          {t('Name')}
                        </label>
                        <Field
                            type="text"
                            name="userName"
                            placeholder="Name"
                            className={`form-control h-40px${
                                touched.userName && errors.userName
                                    ? "is-invalid"
                                    : ""
                            }`}
                            autoComplete="false"
                        />
                        {touched?.userName && errors?.userName && (
                            <div
                            style={{
                              color: "#f35a7f",
                              marginTop: "5px",
                              fontSize: "11px",
                              fontWeight: "500",
                            }}
                          >
                            {errors?.userName}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t('Equiv. User ID')}
                        </label>
                        <div style={{ zIndex: 999999999 }}>
                          <Select
                            options={equivData}
                            maxMenuHeight={200}
                            onChange={(selectedOption) => {
                              const selectedValue = selectedOption
                                ? selectedOption.value
                                : null;
                              setFieldValue("equivalentUser", selectedValue);
                            }}
                            value={
                              equivData.find(
                                (option) =>
                                  option.value === values.equivalentUser
                              ) || null
                            }
                            styles={ReactSelectStyle}
                            placeholder={t('Equiv. User ID')}
                          />
                        </div>
                        <ErrorMessage
                          name="equivalentUser"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t('Menu Type')}
                        </label>
                        <Select 
                          options={menuTypeOptions}
                          onChange={(selectedOption) => {
                            const selectedValue = selectedOption
                              ? selectedOption.value
                              : null;
                            setFieldValue("menuType", selectedValue);
                          }}
                          value={
                            menuTypeOptions.find(
                              (option) =>
                                option.value === values.menuType
                            ) || null
                          }
                          styles={ReactSelectStyle}
                          placeholder={t('Select Menu Type')}
                        />
                        <ErrorMessage
                          name="menuType"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t('Maintenance For')}
                        </label>
                        <Select 
                          options={maintenanceForOptions}
                          onChange={(selectedOption) => {
                            const selectedValue = selectedOption
                              ? selectedOption.value
                              : null;
                            setFieldValue("maintainenceFor", selectedValue);
                          }}
                          value={
                            maintenanceForOptions.find(
                              (option) =>
                                option.value === values.maintainenceFor
                            ) || null
                          }
                          styles={ReactSelectStyle}
                          placeholder={t('Select Maintenance For')}
                          />
                        <ErrorMessage
                          name="maintainenceFor"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t('Notify All Approvals')}
                        </label>
                        <Select
                          options={notifyAllApprovalsOptions}
                          onChange={(selectedOption) => {
                            const selectedValue = selectedOption
                              ? selectedOption.value
                              : null;
                            setFieldValue("notifyAllApprovals", selectedValue);
                          }}
                          value={
                            notifyAllApprovalsOptions.find(
                              (option) =>
                                option.value === values.notifyAllApprovals
                            ) || null
                          }
                          styles={ReactSelectStyle}
                          placeholder={t('Select Notify All Approvals')}
                         />

                        <ErrorMessage
                          name="notifyAllApprovals"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
                        {t('Start Date')}
                        </label>
                        <div className="" style={{ position: "relative" }}>
                          <CustomDatePicker
                            onChange={(val) => {
                              if (val) {
                                const formattedDate = dayjs(val.$d).format(
                                    "YYYY-MM-DD"
                                  );
                                setFieldValue("startDate", formattedDate);
                              } else {
                                setFieldValue("startDate", "");
                              }
                            }}
                            value={values?.startDate}
                          />
                        </div>
                        {errors?.startDate && (
                          <div
                            style={{
                              color: "#f35a7f",
                              marginTop: "5px",
                              fontSize: "11px",
                              fontWeight: "500",
                            }}
                          >
                            {errors?.startDate}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className=" fw-bold fs-6 mb-2">
                        {t('End Date')}
                        </label>
                        <div className="" style={{ position: "relative" }}>
                          <CustomDatePicker
                            onChange={(val) => {
                              if (val) {
                                const formattedDate = dayjs(val.$d).format(
                                  "YYYY-MM-DD"
                                );
                                setFieldValue("endDate", formattedDate);
                              } else {
                                setFieldValue("endDate", "");
                              }
                            }}
                            value={values?.endDate}
                          />
                        </div>
                        {errors?.endDate && (
                          <div
                            style={{
                              color: "#f35a7f",
                              marginTop: "5px",
                              fontSize: "11px",
                              fontWeight: "500",
                            }}
                          >
                            {errors?.endDate}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-5" >
                        <label htmlFor="genCat" className="required fw-bold fs-6 mb-2">
                        {t('Language')}
                        </label>
                        <Select
                          options={language}
                          onChange={(selectedOption) => {
                            const selectedValue = selectedOption
                              ? selectedOption.label
                              : null;
                            setFieldValue("language", selectedValue);
                          }}
                          value={
                            language.find(
                              (option) =>
                                option.label === values.language
                            ) || null
                          }
                          styles={ReactSelectStyle}
                          placeholder={t('Select Language')} 
                          />
                        <ErrorMessage
                          name="language"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} className="text-center mt-5 mb-5">
                      <button
                        type="button"
                        title={t('Cancel')}
                        className="btn btn-secondary rounded-pill me-5"
                        style={{ lineHeight: "1" }}
                        onClick={() => navigate("/system/users")}
                      >
                        {t('Cancel')}
                      </button>

                      <button
                        type="submit"
                        title={t('Save')}
                        className="btn text-white rounded-pill"
                        style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
                        disabled={isSubmitting || !isValid || loading}
                      >
                        {!loading && (
                          <span className="indicator-label">{t('Save')}</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            {t('Please wait...')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2 "></span>
                          </span>
                        )}
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default CreateUser;
