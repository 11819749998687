/** @format */

import axios from "axios";
import { UserModel } from "./_models";
import { useStorage } from "../../../hooks/useStorage";
import {
	getAuth,
	signInWithPopup,
	OAuthProvider,
	GoogleAuthProvider,
} from "firebase/auth";
import {
	GProvider,
	micosoftProvider,
	auth as firebaseAuth,
} from "../../../../firebase";

import { EncUtil } from "../../../hooks/EncUtil";
import { URLS } from "../../../utils/URLS";

const API_URL = URLS.REACT_APP_API_URL;


const auth: UserModel = useStorage.get("kt-auth-erp");
const verifyData = {
	data: auth,
};

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;
export const ERP_LOGIN_URL = `${API_URL}/login`;
export const ERP_TWO_FACTOR = `${API_URL}/validatetfa`;
export const SSO_LOGIN = `${API_URL}/callback`;

interface ssoCred {
	providerId?: string | null;
	email?: string;
	idToken?: string;
}
export function login(username: string, userpwd: string) {
	try {
		var params = new URLSearchParams();
		params.append("userId", username);
		params.append("userpwd", EncUtil.encrypt(userpwd));
		return axios({
			method: "post",
			url: ERP_LOGIN_URL,
			data: params,
		});
	} catch (error) {
		throw error;
	}
}

export async function loginViaMicrosoft(platform) {
	try {
		let response = await loginViaProvider(micosoftProvider, platform);
		return response.data;
	} catch (error) {
		console.debug("ERRORRR", error);
		throw error;
	}
}
export async function loginViaGoogle(platform) {
	try {
		let response = await loginViaProvider(GProvider, platform);
		return response.data;
	} catch (error) {
		console.debug("ERROR", error);
		throw error;
	}
}

async function loginViaProvider(provider, platform) {
	const authProp = getAuth();
	let authProvider;
	let prompt;
	switch (platform) {
		case "microsoft":
			authProvider = OAuthProvider;
			prompt = "login";
			break;
		case "google":
			authProvider = GoogleAuthProvider;
			prompt = "select_account";
			break;

		default:
			break;
	}

	provider.setCustomParameters({ prompt: prompt });
	let result: any = await signInWithPopup(authProp, provider);
	// User is signed in.
	// IdP data available in result.additionalUserInfo.profile.
	let temp: ssoCred = {};

	// Get the OAuth access token and ID Token
	const credential = authProvider.credentialFromResult(result);
	const fidToken = await firebaseAuth.currentUser?.getIdToken();

	const accessToken = credential!.accessToken;
	const idToken = credential!.idToken;
	temp.providerId = result.providerId;
	temp.email = result?._tokenResponse.email;
	temp.idToken = fidToken;
	console.debug("FINALLL", temp);
	let response = await sooLogin(temp);
	// setCurrentUser
	console.debug("RESPONSEEEEE", response);
	return response;
}

const sooLogin = (userData) => {
	console.debug("SSSOOOOOO", userData);
	return axios
		.post(SSO_LOGIN, userData)
		.then((response: any) => response.data)
		.catch((e: any) => {
			console.debug(e, "api error");
			throw e;
		});
};

export function twoFactorAuth(userId: string, code: string, doNotAskAgain) {
	try {
		var params = new URLSearchParams();
		params.append("userId", userId);
		params.append("code", code);
		params.append("doNotAskAgain", doNotAskAgain);
		return axios({
			method: "post",
			url: ERP_TWO_FACTOR,
			data: params,
		});
	} catch (error) {
		throw error;
	}
}


// Server should return AuthModel
export function register(
	email: string,
	firstname: string,
	lastname: string,
	password: string,
	password_confirmation: string
) {
	return axios.post(REGISTER_URL, {
		email,
		first_name: firstname,
		last_name: lastname,
		password,
		password_confirmation,
	});
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
	return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
		email,
	});
}

export function getUserByToken(token: string) {
	return verifyData;
}
