import React from "react";
import { useTranslation } from "react-i18next";

const ConditionalButtons = ({
  type,
  onClickIssueBatch,
  generatePickupSlip,
  loader,
  selectedData,
  createIssueButtonDisabled,
  onClickReject,
  onClickAuthorize,
  loaderReject,
  company,
  formattedDate,
  formattedTime,
  onClickClose,
}) => {
  const {t} = useTranslation();
  return (
    <div className="text-center mt-5 mb-5">
      {type === "create-issue-batch" && (
        <>
          <button
            type="submit"
            title={t("Create Issue Batch")}
            onClick={onClickIssueBatch}
            className="btn text-white rounded-pill  me-5"
            style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
            disabled={createIssueButtonDisabled || loader}
          >
            {loader ? t("Processing...") : t("Create Issue Batch")}
          </button>
          <button
            type="submit"
            title="Picking Slip"
            onClick={() => {
              generatePickupSlip(
                {
                  companyCode: company,
                  generateDate: formattedDate,
                  generateTime: formattedTime,
                  reqNo: selectedData
                    ? selectedData[selectedData.length - 1].requestNo
                    : "",
                  status: selectedData
                    ? selectedData[selectedData.length - 1].status
                    : "",
                },
                selectedData ? selectedData : []
              );
            }}
            className="btn text-white rounded-pill"
            style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
          >
            {t('Picking Slip')}
          </button>
        </>
      )}

      {type === "close-unprocessed-stock" && (
        <button
          type="submit"
          title={t("Close stock request")}
          onClick={onClickClose}
          className="btn text-white rounded-pill"
          disabled={createIssueButtonDisabled || loader}
          style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
        >
          {loader ? t("Processing...") : t("Close stock request")}
        </button>
      )}

      {type !== "create-issue-batch" && type !== "close-unprocessed-stock" && (
        <>
          <button
            type="button"
            title="Reject"
            className="btn btn-secondary rounded-pill me-5"
            style={{ lineHeight: "1" }}
            onClick={onClickReject}
            disabled={createIssueButtonDisabled || loaderReject}
          >
            {loaderReject ? t("Processing...") : t("Reject")}
          </button>
          <button
            type="submit"
            title="Authorize"
            onClick={onClickAuthorize}
            className="btn text-white rounded-pill"
            style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
            disabled={createIssueButtonDisabled || loader}
          >
            {loader ? t("Processing...") : t("Authorize")}
          </button>
        </>
      )}
    </div>
  );
};

export default ConditionalButtons;
