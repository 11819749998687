import clsx from "clsx";
import  {useEffect, useRef, useState } from "react";
import {Row, Col, Card } from "react-bootstrap";
import { colors, titleCss } from "../../../constants/colors";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import { useAuth } from "../../modules/auth";
import dayjs from "dayjs";
import { FaChevronDown } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const UserDetail = ({ data }) => {
  const {t} = useTranslation();
  const routeData = data?.data;
  console.debug(
    "UserDetail data",
    routeData?.endDate,
    routeData?.startDate,
    dayjs(routeData?.endDate).format("DD-MM-YYYY").toString()
  );
  const { currentUser } = useAuth();
  const headerRef: any = useRef(null);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [maintainenceForValue, setMaintainenceForValue] = useState(
    routeData?.maintainenceFor
  );

  function formatDate(dateString) {
    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
    const parts = dateString.split("-");
    if(!datePattern.test(dateString)){
    const formattedDate = parts[2] + "/" + parts[1] + "/" + parts[0];
    return formattedDate;
    }
    return dateString;
  }
  useEffect(() => {
    console.debug(maintainenceForValue, "t1");
    if (maintainenceForValue !== undefined && maintainenceForValue !== null) {
      const stringValue = JSON.stringify(maintainenceForValue);
      localStorage.setItem("maintainenceFor", stringValue);
    }
  }, [maintainenceForValue, routeData, currentUser]);

  useEffect(() => {
    setMaintainenceForValue(routeData?.maintainenceFor);
  }, [routeData, currentUser]);

  const handleMouseEnter = () => {
    if (headerRef.current) {
      headerRef.current.setAttribute("data-bs-toggle", "");
    }
  };

  const handleMouseLeave = () => {
    if (headerRef.current) {
      headerRef.current.setAttribute("data-bs-toggle", "collapse");
    }
  };

  const closeChangePasswordModal = (event) => {
    setChangePasswordModalOpen(false);
  };

  const closeDeleteModal = (event) => {
    setDeleteModalOpen(false);
  };

  function renderInfoRow(label, value) {
    return (
      <Col sm={4}>
        <div className="mb-5">
          <label htmlFor="genCat" className="fw-bold fs-6 mb-2">
            {t(label)}
          </label>
          <div>{value}</div>
        </div>
      </Col>
    );
  }

  return (
    <>
      <div className="accordion mt-10" id="accordionExample">
        <div className="accordion-item">
          <div className="px-4">
            <div ref={headerRef} className=" bg-white cursor-pointer py-5">
              <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                <div className="col-md-5 d-flex">
                  <div
                    className={clsx("", titleCss)}
                    style={{ color: colors.title }}
                  >
                    {t('User Details')} -{" "}
                    <span className="fs-6 fw-normal text-dark">
                      {" "}
                      {routeData?.userName} | {routeData?.userEmail}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 d-flex ">
                  <div className="row row-cols-1 row-cols-md-3 gy-2 d-flex justify-content-between align-items-center w-100 ps-4">
                    <div className="col-md-3 "></div>
                    <button
                      className="btn  rounded-pill text-white text-wrap p-2 fs-7  primary-button col-md-4 w-120px h-45px"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      style={{ backgroundColor: "#0a7eac" }}
                      onClick={() => setChangePasswordModalOpen(true)}
                    >
                      {t('Reset Password')}
                    </button>
                    <button
                      className="btn rounded-pill text-white text-wrap  p-2 fs-7 primary-button  col-md-3 w-120px h-45px"
                      style={{ backgroundColor: "#0a7eac" }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => setDeleteModalOpen(true)}
                    >
                      {t('Delete User')}
                    </button>
                  </div>
                </div>
                <div className="col-md-1 d-flex align-items-center justify-content-end">
                  <div
                    className="btn"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <FaChevronDown size={15} />
                  </div>
                </div>
              </div>
            </div>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <Card>
                  <Card className="p-4">
                    <Row>
                      {renderInfoRow("User ID", routeData?.userId)}
                      {renderInfoRow("Password", "******")}
                      {renderInfoRow("Email Address", routeData?.userEmail)}
                    </Row>
                    <Row>
                      {renderInfoRow( "User Type", routeData?.userType === "U" ? "Normal User" : "Administrator" )}
                      {renderInfoRow("Name", routeData?.userName)}
                      {renderInfoRow("Equiv. User ID", routeData?.equivalentUser)}
                    </Row>
                    <Row>
                      {renderInfoRow("Menu Type", routeData?.menuType === "SM" ? "Show all menu option"
                          : routeData?.menuType === "HM"
                          ? "Show only accessible menu option"
                          : ""
                      )}
                      {renderInfoRow( "Maintenance For",
                        routeData?.maintainenceFor === "T2"
                          ? "All under the Hierarchy"
                          : routeData?.maintainenceFor === "T1"
                          ? "Originator only"
                          : routeData?.maintainenceFor === "T3"
                          ? "For all users"
                          : ""
                      )}
                      {renderInfoRow( "Notify All Approvals",
                        routeData?.notifyAllApprovals === "81"
                          ? "Yes"
                          : routeData?.notifyAllApprovals === "82"
                          ? "No"
                          : ""
                      )}
                    </Row>
                    <Row>
                      {renderInfoRow("Start Date", routeData?.startDate ? formatDate((routeData?.startDate)).toString() : "")}
                      {renderInfoRow("End Date", routeData?.endDate ? formatDate((routeData?.endDate)).toString() : "")}
                      {renderInfoRow("Language", routeData?.language)}
                    </Row>
                  </Card>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isChangePasswordModalOpen && (
        <UserEditModal
          content="reset_password"
          modalText={t("Reset Password")}
          onCloseEditModal={closeChangePasswordModal}
          user="reset"
          userId={routeData}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}

      {deleteModalOpen && (
        <UserEditModal
          content="delete_user"
          modalText={t("Delete")}
          // onDelete={handleDelete}
          onCloseEditModal={closeDeleteModal}
          user="reset"
          userId={routeData?.userId}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
    </>
  );
};

export default UserDetail;
