import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateLookupData } from "../../../reducers/lookupReducer";
import { getstockrequestbyno } from "../../modules/apps/user-management/users-list/core/_requests";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import CustomLoaders from "../user/CustomLoaders";
import { useTranslation } from "react-i18next";
import RequestStockdetailDev from "./RequestStockdetailDev";
import RequestStockTableDev from "./RequestStockTableDev";
interface State {
  requestNo: string;
  isViewOnly: boolean;
  currentPage: string;
  isValidRoute: string;
  // Add other properties as needed
}

const RequestStockDev = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const company = useSelector((state: any) => state?.userConfig?.company);
  const location = useLocation();
  const initialState = location.state as State;
  const [isLoading, setIsLoading] = useState(false);
  const [isUseEffectCalled, setIsUseEffectCalled] = useState(false);
  const [data, setData] = useState();
  const requestNo = initialState?.requestNo;
  const isViewOnly = initialState?.isViewOnly;
  const headerDataOfStockString: any =localStorage?.getItem("headerDataOfStock");
  const parsedData = JSON.parse(headerDataOfStockString);
  const isCreateRequest=useSelector((state:any)=>state?.currentIndex?.isCreateRequest);
  const isValidRouteForRequestStock=useSelector((state:any)=>state?.currentIndex?.isValidRouteForRequestStock);
  const navigate=useNavigate();

  

  useEffect(() => {
    if(!isValidRouteForRequestStock){
      navigate("/dashboard");
      return 
    }

    const callSortedApi = async () => {
      setIsLoading(true);
      try {
        const sortDataResponse = await getstockrequestbyno(
          company,
          requestNo || parsedData.requestNo
        );

        if (sortDataResponse.data) {
          if (isViewOnly) {
            setData({ ...sortDataResponse.data, isViewOnly: true });
          } else {
            setData(sortDataResponse.data);
          }
          dispatch(updateLookupData(sortDataResponse.data?.lineItems));
        }
        setIsLoading(false);
      } catch (error) {
        toastMessage(
          t(
            "Sorry some Technical error happenned. Please try again after sometime "
          ),
          "error"
        );
        setIsLoading(false);
      }
    };
    if (!isCreateRequest) {
        callSortedApi();
      }
    setIsUseEffectCalled(true);     // added to prevent calling of child component before useeffect is called
  }, []);

  const [triggerAdd, setTriggerAdd] = useState(false);
  const [error, setError] = useState(false);

  const triggerAddFunction = () => {
    setTriggerAdd(true);
    setTimeout(() => {
      setTriggerAdd(false);
    }, 3000);
  };

  useEffect(() => {
    console.debug("trigger func 2", triggerAdd);
    if (triggerAdd) {
      console.debug("trigger func", triggerAdd);
      setTriggerAdd(true);
      const timeoutId = setTimeout(() => {
        setTriggerAdd(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [triggerAdd]);
  return (
    <div>
      <div>
        {isUseEffectCalled ? (
          isLoading ? (
            <CustomLoaders />
          ) : (
            <RequestStockdetailDev
              data={data}
              triggerAdd={triggerAdd}
              setError={setError}
            />
          )
        ) : (
          <CustomLoaders />
        )}
      </div>
      <div>
        {isUseEffectCalled ? (
          isLoading ? (
            <CustomLoaders />
          ) : (
            <RequestStockTableDev
              data={data}
              triggerAddFunction={triggerAddFunction}
              error={error}
            />
          )
        ) : (
          <CustomLoaders />
        )}
      </div>
    </div>
  );
};

export default RequestStockDev;
