import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useCallback, useState } from "react";
import excel from "../../../../assets/excel.png";
import imgData from "../../../../assets/logo.png";
import pdf from "../../../../assets/pdf.png";
import { UserEditModal } from "../../apps/user-management/users-list/user-edit-modal/UserEditModal";
import { exportDataToExcel } from "../../../../theme/assets/ts/_utils/ExportXLSX";
import { useSelector } from "react-redux";
import { formatDateToDDMMYYY } from "../../../../theme/assets/ts/_utils/FormatData";

const Export = ({ gridApi, userId, apiData, column, dataKey, file }) => {
  const [showExportModal, setShowExportModal] = useState<any>(null);
  const [showExportModalExcel, setShowExportModalExcel] = useState<any>(null);
  const company = useSelector((state: any) => state?.userConfig?.company);
  const companyName = useSelector((state: any) => state?.userConfig?.companyName);

  const fileName = file;

  const closeExportModal = () => {
    setShowExportModal(false);
    setShowExportModalExcel(false);
  };

  const getFilteredData = () => {
    if (gridApi) {
      const filteredRows: any = [];
      gridApi.forEachNodeAfterFilter((node: any) => {
        filteredRows.push(node.data);
      });
      return filteredRows;
      // Now filteredRows contains the filtered data
    }
  };

  const onBtnExportPdf = (type) => {
    const pdf: any = new jsPDF();

    let slicedName =
      userId?.userName.length > 25
        ? `${userId?.userName}`.slice(0, 20) + "..."
        : userId?.userName;

     let userids= userId?.userId;   
        
    let slicedEmail =
      userId?.userEmail.length > 25
        ? `${userId?.userEmail}`.slice(0, 20) + "..."
        : userId?.userEmail;

    var image = new Image();
    image.src = imgData;

    let data;
    if (type === "all") {
      data = apiData?.data;
    } else {
      data = getFilteredData();
    }
    let formattedData = data?.map((user) => {
      let userData: any = [];
      dataKey.forEach((key) => {
        userData.push(user?.[key]);
      });
      return userData;
    });


    autoTable(pdf, {
      didDrawCell: (data) => {
        if (data.section === 'head') {
          const lineWidth = 0.1; // Adjust line width as needed
          const startX = data.cell.x;
          const startY = data.cell.y;
          const endX = startX + data.cell.width;
          const endY = startY;
          pdf.setLineWidth(lineWidth);
          pdf.line(startX, startY, endX, endY);
        }

        if (data.section === "body" && data.column.index === 0) {
          pdf.addImage(image, "PNG", 13, 2, 50, 30);
          let currdate = new Date();
          pdf.setFontSize(10);
          pdf.text(15, 5, 'Printed At: ' + formatDateToDDMMYYY(currdate.toLocaleString()));
          pdf.setFontSize(10);
          pdf.text(80, 10, slicedEmail);
          pdf.text(80, 15, userids);
          pdf.text(80, 20, companyName + ' - ' + company);
        }
        if (data.row.index !== undefined && data.row.index !== data.table.body.length - 1) {
          // Draw horizontal line between rows
          const lineWidth = 0.1; // Adjust the line width as needed
          const startX = data.cell.x;
          const startY = data.cell.y + data.cell.height;
          const endX = startX + data.cell.width;
          const endY = startY;
          pdf.setLineWidth(lineWidth);
          pdf.line(startX, startY, endX, endY);
        }
      },
      didDrawPage: (HookData: any) => {
        // if (HookData?.section === 'body' && HookData.column.index === 0) {
        pdf.setFontSize(10);
        pdf.setTextColor(214, 214, 214);
        pdf.text(85, 290, "For internal use only.");
        // Get current page number
        const currentPageNumber = pdf.internal.getCurrentPageInfo().pageNumber;
        pdf.setTextColor(0, 0, 0);
        pdf.text(180, 5, `Page ${currentPageNumber}`);
      },
      styles: { fontSize: 8 },
      margin: { top: 30 },
      theme: "plain",
      columnStyles: {
        2: { halign: 'right' } ,
        3: { halign: 'right' } ,
        },
      head: [column],
      body: formattedData,
    });

    pdf.save(`${file}.pdf`);
  };

  const onBtnExport = useCallback(
    (type) => {
      function filterUserFields(users) {
        const filteredUsers = users?.map((user) => {
          const filteredUser = {};
          column.forEach((col, index) => {
            filteredUser[col] = user[dataKey[index]];
          });
          return filteredUser;
        });
        return filteredUsers;
      }
      let data;

      if (type === "all" && apiData) {
        data = filterUserFields(apiData?.data);
      } else {
        let filterData = getFilteredData();
        data = filterUserFields(filterData);
      }

      exportDataToExcel(data, `${fileName}.xlsx`);
    },
    [apiData]
  );
  return (
    <>
      <div className="d-flex justify-content-between align-items-end mb-5">
        <div></div>

        <div className="icon"></div>

        <div className="icon me-5 d-flex gap-5">
          <img
            src={pdf}
            className="cursor-pointer"
            style={{ height: "35px", width: "35px" }}
            onClick={() => setShowExportModal(true)}
            alt=""
          />
          <img
            src={excel}
            className="cursor-pointer"
            style={{ height: "35px", width: "35px" }}
            onClick={() => setShowExportModalExcel(true)}
            alt=""
          />
        </div>
      </div>
      {showExportModal && (
        <UserEditModal
          content="export"
          modalText="Export Data"
          onCloseEditModal={closeExportModal}
          state={""}
          onSave={onBtnExportPdf}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
      {showExportModalExcel && (
        <UserEditModal
          content="export"
          modalText="Export Data"
          onCloseEditModal={closeExportModal}
          state={""}
          onSave={onBtnExport}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      )}
    </>
  );
};

export default Export;
