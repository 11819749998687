import { ColDef, SideBarDef} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../modules/auth";
import pdf from "../../../assets/pdf.png";
import excel from "../../../assets/excel.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import imgData from "../../../assets/logo.png";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import {
  getModifyTable,
  getResetColumn,
  getRestoreCustomizeLayout,
  saveSummaryFooter,
  selectStockCodes,
  getStockEnquiry,
  getPOEnquiry
} from "../../modules/apps/user-management/users-list/core/_requests";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import { HandleApiCall } from "../../modules/auth/functions/HandleApiCall";
import { useDispatch, useSelector } from "react-redux";
import { updateLookupData, updateSelectedRecords, updateStockCode } from "../../../reducers/lookupReducer";
import CustomLoadingCellRender from "../user/CustomLoadingCellRender";
import "ag-grid-charts-enterprise";
import { exportDataToExcel } from "../../../theme/assets/ts/_utils/ExportXLSX";
import { updatePoHeader } from "../../../reducers/PoAndStockReducer";
import CustomDatePicker from "../../modules/custom components/CustomDatePicker/CustomDatePicker";
import Tippy from "@tippyjs/react";
import { KTSVG } from "../../../theme/helpers";
import { functionsData } from "./functionData";
import {
  formatDateToDDMMYYY,
} from "../../../theme/assets/ts/_utils/FormatData";

import ValueFormatter from "../../utils/ValueFormatter"; 
import HyperlinkRenderer from "../../utils/HyperlinkRendererProps";
import { useTranslation } from "react-i18next";

import SearchInput from "../../utils/SearchInput";
import { updateIsCreateRequest, updateIsValidRouteForRequestStock } from "../../../reducers/indexReducer";
import { exportDataToExcelNew } from "../../../theme/assets/ts/_utils/ExportXLSXNew";

interface ActionData {
  id: number;
  label: string;
}
interface MyColDef extends ColDef {
  halign?: string;
}

const POAndStockTablesOpti = ({
  type,
  fetchApi,
  isFirstTab,
  tabTitle,
  tabSqlData,
  tabStorageData,
  tabStorage,
  tabSqlName,
  tableConfiguration,
  handleStartDateChange,
  handleEndDateChange,
  startDate,
  endDate,
  tabId,
  customizeGridEnabled,
  updatePONumber,
  updateSelectedPOList,
  selectedPoNumberRef,
  currentPoNumberRef,
  poNumberRef
}) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [newTabStorageData,setNewTabStorageData]=useState(tabStorageData);

  const lookupData = useSelector((state: any) => state?.lookupData?.lookupData);
  const { currentUser, } = useAuth();
  const [hideAndShowSearchPanel, setHideAndShowSearchPanel] = useState<any>(false);
  const searchVisible = useRef(tableConfiguration?.searchPanelVisible)
  const navigate = useNavigate();
  const isCustomizedColumn = newTabStorageData?.filter((item) => item?.columnType == "Numeric");
  const company = useSelector((state: any) => state?.userConfig?.company);
  const companyName = useSelector((state: any) => state?.userConfig?.companyName);
  let [userEmail, userId, userName]: any = currentUser?.sub?.split("#");

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [showModal, setShowModal] = useState<any>("");
  const [showCustomizeModal, setShowCustomizeModal] = useState<any>(false);
  const [applySummaryFooter, setApplySummaryFooter] = useState<any>("");
  const [applyGroupSummaryFooter, setApplyGroupSummaryFooter] = useState<any>("");
  const [showPdfExportModal, setShowPdfExportModal] = useState(false);
  const [showExcelExportModal, setShowExcelExportModal] = useState(false);
  const [showFiltering, setShowFiltering] = useState(false);
  const filterVisible = useRef(tableConfiguration?.filterVisible)
  const [multiLineSelection, setMultiLineSelection] = useState<any>("single");
  const [showGroupingPanel, setShowGroupingPanel] = useState<any>("never");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const gridRef: any = useRef(null);

  const tippyRef: any = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const actionPopUpDataRef = useRef<ActionData[]>();
  const [defaultTabRowData,setDefaultTabRowData]=useState(tabSqlData);

  const updatedActionPopupData = functionsData.map((item) => {
    switch (item.label) {
      case "Show Grouping Panel":
        return {
          ...item,
          label: showGroupingPanel === "always"
            ? "Hide Grouping Panel"
            : "Show Grouping Panel",
        };
      case "Show Group Footer":
        return {
          ...item,
          label: tableConfiguration?.groupFooterVisible
            ? "Hide Group Footer"
            : "Show Group Footer",
        };
      case "Show Search Panel":
        return {
          ...item,
          label: hideAndShowSearchPanel
            ? "Hide Search Panel"
            : "Show Search Panel",
        };
      case "Show Summary Footer":
        return {
          ...item,
          label: tableConfiguration?.summaryFooterVisible
            ? "Hide Summary Footer"
            : "Show Summary Footer",
        };
      case "Show Filtering":
        return {
          ...item,
          label: showFiltering
            ? "Hide Filtering"
            : "Show Filtering",
        };
      case "Enable Multi-Line Selection":
        return {
          ...item,
          label: multiLineSelection
            ? "Disable Multi-Line Selection"
            : "Enable Multi-Line Selection",
        };

      default:
        return item;
    }
  });
  actionPopUpDataRef.current = updatedActionPopupData;

  const dropDownContent = (
    <div className="menu-container shadow-sm">
      {actionPopUpDataRef?.current?.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            hide();
            onEditColumnButtonClick(item.id);
          }}
          className="menu-items text-hover-primary"
        >
          {t(item.label)}
        </div>
      ))}
    </div>
  );

  const onClick = (event) => {
    event.preventDefault();
    if (visible) {
      hide();
    } else {
      show();
    }
  };
  const compareByColumnIndex = (a, b) => {
    return a.columnIndex - b.columnIndex;
  }
  const onNewProcessButtonClick = async () => {
    let updatedData=getUpdatedColumnsWithIndex();
    setColumnDefs(updatedData);
    setIsLoadingData(true);
    try {
      const apiCall = type === "STOCK_ENQUIRY" ? getStockEnquiry : getPOEnquiry;
      const poEnquiryData = await apiCall(
        company,
        type === "STOCK_ENQUIRY" ? "IN" : "PO",
        startDate,
        endDate
      );
      const { tabList } = poEnquiryData?.data || {};
      if (!tabList || tabList.length === 0) return;
      const firstTab = tabList[0];
      setDefaultTabRowData(firstTab.tabSqlData);
      setIsLoadingData(false);
    } catch (error) {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    // console.debug("Table configuration changed");
    if (tableConfiguration) {
      // console.debug("Table configuration changed inside");
      setShowFiltering(tableConfiguration?.filterVisible);
      setShowGroupingPanel(
        tableConfiguration?.groupingPanelVisible ? "always" : "never"
      );
      setMultiLineSelection(
        tableConfiguration?.multiLineSelection ? "multiple" : "single"
      );
      setHideAndShowSearchPanel(tableConfiguration?.searchPanelVisible);
      // setShowSummaryFooter(tableConfiguration?.summaryFooterVisible);
      // setShowGroupFooter(tableConfiguration?.groupFooterVisible);
    }
  }, [tableConfiguration, tabTitle]);

  useEffect(() => {
    setIsLoadingData(true);
    setTimeout(() => {
      setIsLoadingData(false);
    }, 100);
  }, [tabTitle, defaultTabRowData]);

  useEffect(() => {
    if (newTabStorageData) {
      newTabStorageData.sort(compareByColumnIndex);
      let columnType = newTabStorageData.filter((item) => item.infoType === "column");
      let cols = columnType.map((item) => {
        let valueFormatter;
          if (
            item?.columnType==='Numeric'
          ) {
              valueFormatter = (params) => { return (params.value || (params.value == 0)) ? ValueFormatter(params?.value,item?.numericFormat,item?.columnName) : "" };

          }
        return {
          headerName: item?.caption,
          field: item?.columnName,
          filter: "agTextColumnFilter",
          cellRenderer: item?.columnName.toLowerCase()==="path"?HyperlinkRenderer:null,
          rowDrag: true,
          headerClass: item?.alignment === "right" ? "ag-right-aligned-header ag-right-aligned-cell" : "",
          hide: !item?.columnVisible,
          rowGroupIndex: item?.rowGroupIndex,
          valueFormatter:valueFormatter,
          halign:item?.alignment,
          cellStyle: (params) => {
            const alignType = item?.alignment;

            return {
              textAlign:
                alignType == "right"
                  ? "right"
                  : alignType == "center"
                    ? "center"
                    : "left",
            };
          },
        };
      });
      if (
        cols.length > 0 &&
        isFirstTab
      ) {
        cols[0] = {
          ...cols[0],
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
        };
      }
      setColumnDefs(cols);
    }
  }, [newTabStorageData]);

  useEffect(()=>{
    setNewTabStorageData(tabStorageData);

  },[tabStorageData])

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api);
      const customizedColumnData = newTabStorageData?.map((item) => ({
        colId: item?.colId,
        width: item?.width,
        hide: !item?.columnVisible,
        pinned: item?.pinned,
        sort: item?.sort,
        sortIndex: item?.sortIndex,
        aggFunc: item?.aggFunc,
        rowGroup: item?.rowGroup,
        rowGroupIndex: item?.rowGroupIndex,
        pivot: item?.pivot,
        pivotIndex: item?.pivotIndex,
      }));
      // // console.debug("customizedColumnData while coming", customizedColumnData);
      if (customizedColumnData?.length > 0 && params!.api) {
        // console.debug("customizedColumnData while coming inside block",customizedColumnData);

        params!.api.applyColumnState({
          state: customizedColumnData,
          applyOrder: true,
        });
      }

      if(selectedPoNumberRef.current && selectedPoNumberRef.current?.length==0){
        const nodesToSelect: any[] = [];
        if(defaultTabRowData?.length === 0){
          console.debug("Empty PO Header")
           dispatch(updatePoHeader({}));
        }
        params.api.forEachNode((node: any) => {
          if (node.rowIndex == 0) {
            if(getStockCodeOrPONumber(node) === currentPoNumberRef.current){
              nodesToSelect.push(node);
              updatePONumber(getStockCodeOrPONumber(node));
              updateSelectedPOList(getStockCodeOrPONumber(node),true);
              dispatch(updatePoHeader(node?.data));
            }
          }
        });
        
        params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
        gridRef.current!.api.setGridOption("quickFilterText", (document.getElementById(`table-search-box-${tabId}`) as HTMLInputElement).value,);
      }else if (selectedPoNumberRef.current && selectedPoNumberRef.current?.length>0){
              const nodesToSelect: any[] = [];
              params.api.forEachNode((node: any) => {
                let containsObject = selectedPoNumberRef.current?.some((obj) => obj === getStockCodeOrPONumber(node));
                if (containsObject) {
                  nodesToSelect.push(node);
                }
              });
              params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
      }
    },
    [newTabStorageData]
  );

  const getStockCodeOrPONumber = (node)=> {
    return (type == "STOCK_ENQUIRY") ? node?.data?.stock_code : node?.data?.ponumber; 
  }

  useEffect(() => {
    console.debug("SHow Filtering !!", showFiltering);
    if (gridApi) {
      const columnDefs = gridApi.getColumnDefs();
      if (columnDefs) {
        columnDefs?.forEach((colDef: any, index: number) => {
          colDef.floatingFilter = showFiltering;
        });
      }
      gridApi.setColumnDefs(columnDefs);
    }

  }, [showFiltering])

  const defaultColDef = useMemo(
    () => ({
      // flex: 1,
      sortable: true,
      resizable: true,
      minWidth: 100,
      enableRowGroup: true,
      floatingFilter: showFiltering,
      lockVisible: true,
      enableValue: true,
      enablePivot: true,
      filter: true,
    }),
    [showFiltering]
  );



  const getFilteredData = () => {
    if (gridApi) {
      const filteredRows: any = [];
      gridApi.forEachNodeAfterFilter((node: any) => {
        filteredRows.push(node.data);
      });
      return filteredRows;
    }
  };

  const onBtnExportPdf = (types) => {
    const pdf: any = new jsPDF({
      orientation: "landscape",
    });

    var image = new Image();
    image.src = imgData;

    let columnStyles: any = {};
    const tableHeaders: any = (columnDefs as MyColDef[])?.map((column, index) => {
      columnStyles[index] = { halign: column?.halign };
      return {content: column?.headerName, styles: {halign: column?.halign}};
    });
    const numericFormKeys = newTabStorageData
    .filter(item => tableHeaders.some(header => header.content === item.caption))
    .map(item => ({ numericFormat: item?.numericFormat, columnName: item?.columnName,columnType:item?.columnType }));
  
  
    const tableBody: any = columnDefs?.map((column) => column.field);
    const body =
      types == "filtered"
        ? getFilteredData()?.map((item) => tableBody?.map((key) => item[key]))
        : defaultTabRowData?.map((item) => tableBody?.map((key) => item[key]));

    autoTable(pdf, {
      didDrawCell: (data) => {
        if (data.section === 'head') {
          const lineWidth = 0.1;
          const startX = data.cell.x;
          const startY = data.cell.y;
          const endX = startX + data.cell.width;
          const endY = startY;
          pdf.setLineWidth(lineWidth);
          pdf.line(startX, startY, endX, endY);
        }
        if (data.section === "head" && data.column.index === 0) {
          pdf.addImage(image, "PNG", 13, 2, 50, 30);
          let currdate = new Date();
          pdf.setFontSize(10);
          pdf.text(15, 5, 'Printed At: ' + formatDateToDDMMYYY(currdate.toLocaleString()));
          pdf.setFontSize(10);
          pdf.text(120, 15, userId);
          pdf.text(120, 10, userEmail);
          pdf.text(120, 20, companyName + ' - ' + company);
        }
        if (data.row.index !== undefined && data.row.index !== data.table.body.length - 1) {

          const lineWidth = 0.1;
          const startX = data.cell.x;
          const startY = data.cell.y + data.cell.height;
          const endX = startX + data.cell.width;
          const endY = startY;
          pdf.setLineWidth(lineWidth);
          pdf.line(startX, startY, endX, endY);
        }
      },
      didDrawPage: (HookData: any) => {
        pdf.setFontSize(10);
        pdf.setTextColor(214, 214, 214);
        pdf.text(85, 290, "For internal use only.");
        // Get current page number
        const currentPageNumber = pdf.internal.getCurrentPageInfo().pageNumber;
        pdf.setTextColor(0, 0, 0);
        pdf.text(260, 5, `Page ${currentPageNumber}`);
      },
      didParseCell: function(data) {
        // data.cell is the cell object
        // Format the value same as Aggrid:
        if (data.section === 'body') { // Only format body cells
          for (let i = 0; i < numericFormKeys.length; i++) {
            if (data.column.index === i && numericFormKeys[i].columnType === "Numeric") {
              data.cell.text[0] = ValueFormatter(data.cell.text[0],numericFormKeys[i].numericFormat,numericFormKeys[i].columnName);
            }
          }
        }
      },
      styles: { fontSize: 6 },
      margin: { top: 30 },
      theme: "plain",
      columnStyles: columnStyles,
      head: [tableHeaders],
      body: body,
      foot: [],
    });

    pdf.save(`${type}-item.pdf`);
  };

  const fileName = `${type}-item`;


  const onBtnExportExcel = (type: string) => {
    let columnStyles: any = {};
    const tableHeaders: any = (columnDefs as MyColDef[])?.map((column, index) => {
      columnStyles[index] = { halign: column?.halign };
      return { content: column?.headerName, field: column?.field, styles: { halign: column?.halign } };
    });
  
    const numericFormKeys = newTabStorageData
    .filter(item => tableHeaders.some(header => header.content === item.caption))
    .map(item => ({ numericFormat: item?.numericFormat, columnName: item?.columnName,columnType:item?.columnType }));
  

    const dataToMap = type === "all" ? defaultTabRowData : getFilteredData();
    
    const sortedData = dataToMap.map((obj) => {
      let sortedObj: any = {}; // Adjust type definition as per your object structure
      tableHeaders.forEach((header, index) => {
        const key = header.content;
        let value = obj[header.field];
        if (numericFormKeys[index].columnType === "Numeric" && !isNaN(value)) {
          value = ValueFormatter(value, numericFormKeys[index].numericFormat,numericFormKeys[index].columnName); // Apply the formatter
        }
        sortedObj[key] = value;
      });
      return sortedObj;
    });
  
    exportDataToExcelNew(sortedData, `${fileName}`, columnStyles);
  };


  const getUpdatedColumnsWithIndex = () => {
    let columnState = gridRef.current!.api.getColumnState();
      columnState.map((columnObj, index) => {
        columnObj["columnIndex"] = index + 1;
      });
      const updatedData = newTabStorageData.map((data) => {
        const matchingColumn = columnState.find(column => column.colId === data.colId);
        if (matchingColumn) {
          return {
            ...data,
            width: matchingColumn.width,
            columnIndex: matchingColumn.columnIndex,
            aggFunc:matchingColumn.aggFunc,
            hide:matchingColumn.hide,
            columnVisible:!matchingColumn.hide,
            pinned:matchingColumn.pinned,
            pivot:matchingColumn.pivot,
            pivotIndex:matchingColumn.pivotIndex,
            rowGroup:matchingColumn.rowGroup,
            rowGroupIndex:matchingColumn.rowGroupIndex,
            sort:matchingColumn.sort,
            sortIndex:matchingColumn.sortIndex
          };
        }
        return { ...data }; // Create a new object with a new reference
      });
    
    setNewTabStorageData([...updatedData]); // Create a new array with a new reference
    return updatedData;
  }
  

  const handleCustomizeLayout = async () => {
    console.debug("handleCustomizeLayout : ", searchVisible.current, filterVisible.current);
    let updatedTableConfiguration = {
      ...tableConfiguration,
      searchPanelVisible: searchVisible.current,
      filterVisible: filterVisible.current,
      groupingPanelVisible: showGroupingPanel == "never" ? false : true,
      multiLineSelection: multiLineSelection == "single" ? false : true,
    };
    
  
    const payload = {
      tabStorage: tabStorage,
      tabSqlName: tabSqlName,
      modifiedTableConfiguration: updatedTableConfiguration,
      modifiedColumnConfiguration: getUpdatedColumnsWithIndex(),
      companyCode: company,
    };
    console.debug("customizedColumnData while saving", payload);

    try {
      setIsLoadingData(true);
      const response = await HandleApiCall(getModifyTable, payload);
      console.debug("Response : ", response);
      if (response.status === "OK") {
        console.debug("Response : OK Response so saving state");
        toastMessage(t("Saved Customized Layout Successfully"), "success");
      } else {
        console.debug("Response : NOT OK Response");
        toastMessage(t("Error Saving Customized Layout"), "error");
      }
      setIsLoadingData(false);
    } catch (error) {
      setIsLoadingData(false);
    }
  };

  const onRestoreDefaultLayout = async () => {
    setIsLoadingData(true);
    try {
      const payload = {
        payload: {},
        storageTab: tabStorage,
        tabSqlName: tabSqlName,
        companyCode: company,
      };
      const response = await HandleApiCall(getResetColumn, payload);
      await fetchApi();
      setIsLoadingData(false);
    } catch (error) { 
      setIsLoadingData(false);
    }
  };

  const onRestoreCustomizeLayout = async () => {
    setIsLoadingData(true);
    try {
      const payload = {
        payload: {},
        storageTab: tabStorage,
        tabSqlName: tabSqlName,
        companyCode: company,
      };
      const response = await HandleApiCall(getRestoreCustomizeLayout, payload);

      await fetchApi();
      setIsLoadingData(false);
    } catch (error) { 
      setIsLoadingData(false);
    }
  };

  const closeEditModal = () => {
    setShowModal(false);
  };

  const closeEditCustomizeModal = () => {
    setShowCustomizeModal(false);
  };

  const closeSummaryFunctionModal = () => {
    setApplySummaryFooter("");
  };

  const closeGroupSummaryFunctionModal = () => {
    setApplyGroupSummaryFooter("");
  };

  const handleEdit = async (data) => {
    setIsLoadingData(true);
    try {
      setNewTabStorageData(data.modifiedColumnNames);
      setIsLoadingData(false);
      setShowModal(false);
    } catch (error) { 
      setIsLoadingData(false);
    }
  };

  const handleEditCustomizeColumn = async (data) => {
    setIsLoadingData(true);
    try {
      const newTabStorageDataUpdated = [
        ...newTabStorageData.filter((item) => !data.modifiedColumnNames.includes(item)),
        ...data.modifiedColumnNames,
      ];
      newTabStorageDataUpdated.sort(compareByColumnIndex)
      setNewTabStorageData(newTabStorageDataUpdated);
      setIsLoadingData(false);
      setShowCustomizeModal(false);
    } catch (error) {
      setIsLoadingData(false);
     }
  };

  const handleApplySummaryFunction = async (data) => {
    setIsLoadingData(true);
    try {
      await HandleApiCall(saveSummaryFooter, data);
      fetchApi();
      setIsLoadingData(false);
      setApplySummaryFooter("");
    } catch (error) { }
  };

  const handleApplyGroupSummaryFunction = async (data) => {
    console.debug(
      "handleApplyGroupSummaryFunction",
      data?.summarizedColumn?.summaryFooterFunction,
      data?.summarizedColumn?.columnName
    );
    let newArray = columnDefs.map((obj: any, index) => {
      if (obj?.headerName === data?.summarizedColumn?.columnName) {
        return {
          ...obj,
          aggFunc:
            data?.summarizedColumn?.summaryFooterFunction == "average"
              ? "avg"
              : data?.summarizedColumn?.summaryFooterFunction == "none"
                ? null
                : data?.summarizedColumn?.summaryFooterFunction,
        };
      } else {
        return obj;
      }
    });
    setColumnDefs(newArray);
  };

  const handleRowUnselection=(e)=>{
    let poNumberUnSelected;
      if (type === "STOCK_ENQUIRY") {
        poNumberUnSelected = e?.data?.stock_code;    
      }else{
        poNumberUnSelected = e?.data?.ponumber;    
      }
      if(selectedPoNumberRef.current && selectedPoNumberRef.current.length>0){
        let indexOfPoUnselected = selectedPoNumberRef.current.indexOf(poNumberUnSelected);
        //check if the item is the last in the selection
        console.debug("poNumberUnSelected : ",poNumberUnSelected,indexOfPoUnselected);  
        let lastPoNumberIndex;
        if(indexOfPoUnselected===selectedPoNumberRef.current.length-1){
          lastPoNumberIndex = selectedPoNumberRef.current.length-2;
        }else{
          lastPoNumberIndex = selectedPoNumberRef.current.length-1;
        }
        console.debug("lastPoNumberIndex : ",lastPoNumberIndex,selectedPoNumberRef.current.length,selectedPoNumberRef.current);
        if(lastPoNumberIndex<0){
          lastPoNumberIndex=0;
          console.debug("lastPoNumberIndex 2: ",lastPoNumberIndex);
        }
        let lastPoNumber = selectedPoNumberRef.current[lastPoNumberIndex];
        updatePONumber(lastPoNumber);
        updateSelectedPOList(poNumberUnSelected,false);
        if(gridApi){
          gridApi.forEachNode((node: any) => {
            if (type === "STOCK_ENQUIRY") {
              if (node?.data?.stock_code == lastPoNumber) {
                dispatch(updatePoHeader(node?.data));    
              }
            }else{
              if (node?.data?.ponumber == lastPoNumber) {
                dispatch(updatePoHeader(node?.data));    
              }
          }
          });
        } 
      }
  }

  const handleRowSelection=(e)=>{
    if (isFirstTab) {
      let stockCodePoNumber;
      if (type === "STOCK_ENQUIRY") {

        stockCodePoNumber = e?.data?.stock_code;
      } else {
        stockCodePoNumber = e?.data?.ponumber;
      }
      updatePONumber(stockCodePoNumber);
      updateSelectedPOList(stockCodePoNumber,true);
      dispatch(updatePoHeader(e?.data));
    }
  }

  const onRowSelected = (e) => {
    if (!e?.node?.selected) {
        handleRowUnselection(e);
      return;
    }else if(e?.node?.selected && e?.source==="checkboxSelected"){
      handleRowSelection(e);
    }
  };

  const onReqStockButtonClick = async () => {
    if (selectedPoNumberRef?.current?.length == 0) {
      toastMessage(t("Please select a stock to proceed."), "error");
    } else {
      
      const selectedData = gridRef.current!.api.getSelectedRows();
      if (type == "STOCK_ENQUIRY" && isFirstTab) {
        setIsLoadingData(true);
        const stockCodes = selectedData.map((item) => item.stock_code);
        console.debug("SelectedStock Codes : ",stockCodes);
        const apiPromises = stockCodes.map((stockCode) => selectStockCodes(company, stockCode));
        const selectedStockDataResponses = await Promise.all(apiPromises);
        console.debug("SelectedStock Codes : ",selectedStockDataResponses);
        const selectedStockDataToBeSendInStockRequest = selectedStockDataResponses.map((response) => response?.data);
        let updatedStockData:any[] = [];

        selectedData.forEach((item, index) => {
          let selectedStockDataToBeSendInStockRequestResponse = selectedStockDataToBeSendInStockRequest.filter(data=>data.code===item.stock_code);
          let newItem;
          console.debug("selectedLocalStorageData 3: ",selectedStockDataToBeSendInStockRequestResponse);
          if(selectedStockDataToBeSendInStockRequestResponse.length>0){
            newItem = {...selectedStockDataToBeSendInStockRequestResponse[0]}
            newItem.locationDropdown = selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown;
            if(selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown && selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown.length>0){
              let defaultLocation = selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown[0]
              console.debug("Default Location : ",defaultLocation);
            newItem.location = defaultLocation.location;
            newItem.availableQty = defaultLocation.availableQty;
            newItem.onHandQty = defaultLocation.onHandQty;
            newItem.onOrderQty = defaultLocation.onOrderQty;
            newItem.unitCost = defaultLocation.unitCost;
            newItem.amount="0.00";

          }
        }
        newItem.lineNo = 0;
        newItem.tempLineNo = Number(index) + 1; // fix for the stock request should not have lineNo.
        console.debug("newItem : ",newItem);
        updatedStockData.push(newItem);
      });

        await dispatch(updateLookupData(updatedStockData));
        setIsLoadingData(false);
      }
      
      const arrayOfCode = lookupData?.map((obj) => obj.code);
      dispatch(updateSelectedRecords([]));
      dispatch(updateStockCode(""));     //It will avoid an addition api call for the stock code that was last added.
      dispatch(updateIsCreateRequest(true));
      dispatch(updateIsValidRouteForRequestStock(true));
      navigate("/requeststock", {
        state: { selectedValues: arrayOfCode, breadcrumb: "enquary" },
      });
    }
  };

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 150, 200];
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      cellRendererParams: {
        footerValueGetter: (params) => {
          return `Total (${params.node.field})`;
        },
      },
    };
  }, []);

  const closePdfExportModal = () => {
    setShowPdfExportModal(false);
  };

  const closeExcelExportModal = () => {
    setShowExcelExportModal(false);
  };

  function renderModal(
    showModal,
    modalContent,
    modalText,
    onCloseModal,
    columnHeaders,
    tabStorage,
    tabSqlName,
    onApply,
    applySummaryFooter
  ) {
    if (showModal) {
      return (
        <UserEditModal
          content={modalContent}
          modalText={modalText}
          onCloseEditModal={onCloseModal}
          columnHeaders={columnHeaders}
          tabStorage={tabStorage}
          tabSqlName={tabSqlName}
          onApply={onApply}
          applySummaryFooter={applySummaryFooter}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      );
    } else {
      return null;
    }
  }

  const getCurrentColumnData = () => {
    // console.debug("grid columnData : ",gridRef?.current!?.api?.getColumnState());
    let tobeColumnState = JSON.parse(JSON.stringify(newTabStorageData));
    let currentColumnState = gridRef?.current!?.api?.getColumnState();
    if (currentColumnState) {
      tobeColumnState.map((columnObj, index) => {
        let currentColumnIndex = currentColumnState.findIndex(object => {
          return object.colId === columnObj.colId;
        });
        columnObj["columnIndex"] = currentColumnIndex + 1;
      })
      // console.debug("Updated TableData  : ",tobeColumnState);
    }
    tobeColumnState.sort(compareByColumnIndex);
    return tobeColumnState;
  }

  const toggleAction = useCallback((id) => {
    let newActionPopUpData = actionPopUpDataRef.current?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          label: toggleLabel(id, item.label),
        };
      }
      return item;
    })
    actionPopUpDataRef.current = newActionPopUpData;
  }, []);

  const onEditColumnButtonClick =
    (id) => {
      let toggleRequired = false;
      switch (id) {
        case 1:
          setShowModal(!showModal);
          break;
        case 2:
          setShowGroupingPanel((previousData) =>
            previousData === "never" ? "always" : "never"
          );
          toggleRequired = true;
          break;
        case 3:
          searchVisible.current = !hideAndShowSearchPanel
          setHideAndShowSearchPanel((previousData) => !previousData);
          toggleRequired = true;
          break;
        case 4:
          // setShowSummaryFooter(!showSummaryFooter);
          // toggleRequired = true;
          break;
        case 5:
          // setShowGroupFooter(!showGroupFooter);
          // toggleRequired = true;
          break;
        case 6:
          filterVisible.current = !showFiltering;
          setShowFiltering((previousData) => !previousData);
          toggleRequired = true;
          break;
        case 7:
          //check what is this case and fix.
          // setRowData((previousData) => (previousData?.length > 0 ? [] : tableData?.tabData));
          break;
        case 8:
          setMultiLineSelection((previousData) =>
            previousData === "single" ? "multiple" : "single"
          );
          break;
        case 9:
          setShowCustomizeModal((previousData) => !previousData);
          break;
        case 10:
          handleCustomizeLayout();
          break;
        case 11:
          onRestoreDefaultLayout();
          break;
        case 12:
          onRestoreCustomizeLayout();
          break;
        default:
          break;
      }
      if (toggleRequired) {
        setTimeout(() => {
          toggleAction(id);
        }, 1000);
      }
    };

  const toggleLabel = useCallback((id, label) => {
    const isShowLabel = label.includes("Show");
    const isDisableLabel = label.includes("Enable");

    if (isShowLabel || isDisableLabel) {
      return label.replace("Show", "Hide").replace("Enable", "Disable");
    } else {
      return label.replace("Hide", "Show").replace("Disable", "Enable");
    }
  }, []);

  const sideBar = useMemo< SideBarDef | string | string[] | boolean | null >(() => { 
    return {
          toolPanels: [
              {
                  id: 'columns',
                  labelDefault: 'Grouping',
                  labelKey: 'columns',
                  iconKey: 'columns',
                  toolPanel: 'agColumnsToolPanel',
                  toolPanelParams: {
                    suppressPivotMode: false, // <-- suppress pivot!
                    suppressColumnSelectAll: false
                  },
              },
              {
                  id: 'filters',
                  labelDefault: 'Filters',
                  labelKey: 'filters',
                  iconKey: 'filter',
                  toolPanel: 'agFiltersToolPanel',
              }
          ],
          // defaultToolPanel: 'columns',
      };
  }, []);

  return (
    <>
      <div>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-end mb-5">
          {hideAndShowSearchPanel ?(
            <SearchInput
              gridRef={gridRef}
              type={tabId}
              className={"col-md-5 mb-md-0 mb-3 pl-0"} // Add margin-top on small screens
              screen={"PoAndStock"}
            />
          ):( <div className={"col-md-5 mb-md-0 mb-3 pl-0"} style={{ position: "relative", display: "inline-block" }}></div>)}
          <div className="icon me-5 d-flex gap-2">
            {isFirstTab && type === "STOCK_ENQUIRY" && (
              <div className="col-md-6">
                <button
                  className="btn text-white rounded-pill fs-6 primary-button w-100"
                  onClick={onReqStockButtonClick}
                  title="Process"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#0a7eac",
                  }}
                >
                  {t("Request Stock")}
                </button>
              </div>
            )}
              {customizeGridEnabled ? (
              <Tippy
                ref={tippyRef}
                content={dropDownContent}
                visible={visible}
                onClickOutside={hide}
                allowHTML={true}
                arrow={false}
                appendTo={document.body}
                interactive={true}
                placement="bottom"
              >
                <a
                  href="#!"
                  className="btn btn-light btn-active-light-primary btn-sm d-flex align-items-center justify-content-center"
                  onClick={onClick}
                >
                  {t("Options")}
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr072.svg"
                    className="svg-icon-5 m-0"
                  />
                </a>
              </Tippy>
            ) : (
              ""
            )}
            <img
              src={pdf}
              className="cursor-pointer"
              style={{ height: "35px", width: "35px" }}
              onClick={() => setShowPdfExportModal(true)}
            />
            <img
              src={excel}
              className="cursor-pointer"
              style={{ height: "35px", width: "35px" }}
              onClick={() => setShowExcelExportModal(true)}
            />
          </div>
      </div>
      <div className="row">
        {isFirstTab && type !== "STOCK_ENQUIRY" && (
          <div className="col-md-10 my-5">
            <div className="d-flex w-100 gy-5 align-items-center row row-cols-md-5 justify-content-between">
              <div className="col-md-3">{t("Order Date From")}</div>
              <div className="col-md-3">
                <CustomDatePicker
                  onChange={handleStartDateChange}
                  value={startDate}
                />
              </div>
              <div className="col-md-1 text-center">{t("to")}</div>
              <div className="col-md-3">
                <CustomDatePicker
                  onChange={handleEndDateChange}
                  value={endDate}
                />
              </div>
              <div className="col-md-2">
                <button
                  className="btn text-white rounded-pill fs-6 primary-button w-100"
                  onClick={onNewProcessButtonClick}
                  title={t("Process")}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#0a7eac",
                  }}
                  disabled={!startDate || !endDate || startDate > endDate}
                >
                  {t("Process")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
        {showPdfExportModal && (
          <UserEditModal
            content="export"
            modalText={t("Export Data")}
            onCloseEditModal={closePdfExportModal}
            state={""}
            onSave={onBtnExportPdf}
            style={{
              position: "relative",
              zIndex: 1000,
            }}
          />
        )}
        {showExcelExportModal && (
          <UserEditModal
            content="export"
            modalText={t("Export Data")}
            onCloseEditModal={closeExcelExportModal}
            state={""}
            onSave={onBtnExportExcel}
            style={{
              position: "relative",
              zIndex: 1000,
            }}
          />
        )}

        <div className="ag-theme-alpine" style={{ height: 520, width: "100%" }}>
          {isLoadingData ? (
            <CustomLoadingCellRender />
          ) : (
            <>
              <AgGridReact
                ref={gridRef}
                rowClass={"notranslate"}
                rowSelection={isFirstTab ? "multiple" : multiLineSelection}
                onGridReady={onGridReady}
                onRowSelected={onRowSelected}
                rowData={defaultTabRowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowDragManaged={true}
                animateRows={true}
                pagination={true}
                paginationPageSize={50}
                paginationPageSizeSelector={paginationPageSizeSelector}
                suppressHorizontalScroll={false}
                suppressContextMenu={false}
                suppressRowClickSelection={isFirstTab ? true : false}
                suppressDragLeaveHidesColumns={true}
                rowGroupPanelShow={
                  customizeGridEnabled ? showGroupingPanel : null
                }
                sideBar={
                  customizeGridEnabled
                    ? showGroupingPanel !== "never"
                      ? sideBar
                      : null
                    : null
                }
              />
            </>
          )}
        </div>

        {renderModal(
          showModal,
          "editColumns",
          t("Rename Columns"),
          closeEditModal,
          getCurrentColumnData(),
          tabStorage,
          tabSqlName,
          handleEdit,
          ""
        )}
        {renderModal(
          showCustomizeModal,
          "customizeColumns",
          t("Customized Numeric Formatting"),
          closeEditCustomizeModal,
          isCustomizedColumn,
          tabStorage,
          tabSqlName,
          handleEditCustomizeColumn,
          ""
        )}

        {renderModal(
          applySummaryFooter && applySummaryFooter?.length > 0,
          "customizeSummaryFooter",
          t("Apply Function") + " (" + applySummaryFooter + ")",
          closeSummaryFunctionModal,
          newTabStorageData,
          tabStorage,
          tabSqlName,
          handleApplySummaryFunction,
          applySummaryFooter
        )}

        {renderModal(
          applyGroupSummaryFooter && applyGroupSummaryFooter?.length > 0,
          "customizeSummaryFooter",
          t("Apply Group Function") + " (" + applyGroupSummaryFooter + ")",
          closeGroupSummaryFunctionModal,
          newTabStorageData,
          tabStorage,
          tabSqlName,
          handleApplyGroupSummaryFunction,
          applyGroupSummaryFooter
        )}
      </div>
    </>
  );
};

export default POAndStockTablesOpti;
