/** @format */

import clsx from "clsx";
import { BsFillBuildingFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import notification from "../../../../assets/notification.png";
import user from "../../../../assets/user.png";
import { colors } from "../../../../constants/colors";
import { HeaderUserMenu } from "../../../partials";
import CompanyModal from "../modal/CompanyModal";
import { useSelector } from "react-redux";

const itemClass = "ms-1 ms-lg-5";
const btnClass =
  "btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const Navbar = () => {
  const navigate = useNavigate();
  let pinnedData: any = localStorage.getItem("pinnedData");
  pinnedData = JSON.parse(pinnedData);
  const isMultipleCompanyAssigned = useSelector((state: any) => state?.layout?.isMultipleCompanyAssigned);
  return (
    <div className="app-navbar flex-shrink-0 gap-5">
      {isMultipleCompanyAssigned && (
        <div className={clsx("app-navbar-item", itemClass)}>
          <div
            className={clsx("bg-white", btnClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
          >
            <BsFillBuildingFill color={colors.navIcon} size={20} />
            <CompanyModal />
          </div>
        </div>
      )}

      <div className={clsx("app-navbar-item", itemClass)}>
        <div>
          <img
            src={notification}
            height="25px"
            width="25px"
            alt="logo"
            loading="lazy"
            onClick={() => {
              navigate("/notification");
            }}
          />
        </div>
      </div>
      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className="bg-white"
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <img
            src={user}
            alt=""
            style={{ height: "25px", width: "25px", background: "white" }}
          />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
};

export { Navbar };
