import { FC, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth } from "../modules/auth";
import TwoFactorAuth from "../modules/auth/components/TwoFactorAuth";
import { AuthLayout } from "../modules/auth/AuthLayout";
import { SelectCompanyPage } from "../modules/auth/components/SelectCompanyList"; 
import App from "../App";
import ErrorPage from "../modules/auth/components/Errorpage"; 
import ComingSoon from "../modules/auth/components/ComingSoon"; 
import Unauthorized from "../modules/auth/components/Unauthorized"; 
import { store } from "../../reduxStore/store";
import RouteInterceptor from "./RouteInterceptor";


const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  const state = store.getState();
  let expired: boolean | undefined;
  if (currentUser !== undefined) {
    expired = false;
  }

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          <Route element={<AuthLayout />}>
            <Route
              path="twoFactorAuth"
              element={
                  <TwoFactorAuth />
              }
            ></Route>
            <Route
              path="selectcompany"
              element={
                currentUser ? (
                  <SelectCompanyPage />
                ) : (
                  <Navigate to="/auth" replace />
                )
              }
            ></Route>
          </Route>
          <Route
            path="error"
            element={
              <>
                <ErrorPage message={undefined} details={undefined} />
              </>
            }
          ></Route>
          <Route
            path="coming-soon"
            element={
              <>
                <ComingSoon />
              </>
            }
          ></Route>
          <Route
            path="unauthorized"
            element={
              <>
                <Unauthorized />
              </>
            }
          ></Route>

          {currentUser && (state?.userConfig.company !=="") ?(
            <>
              <Route path="/*" element={
                <RouteInterceptor>
                <PrivateRoutes />
                </RouteInterceptor>
              } />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };