import  { useEffect, useRef, useState } from "react";
import { FaTimes, FaPlus } from "react-icons/fa";
import CustomDropdown from "../../custom components/DropdownWithSearch/Dropdowncustom";
import { useSelector } from "react-redux";
import CustomSimpleSelect from "../../custom components/DropdownWithSearch/CustomSimpleSelect";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { formatISO } from 'date-fns';
import {toastMessage} from "../../auth/functions/toastMessage";
import { useTranslation } from "react-i18next";

const AdvancedFilterPopup = (props) => {
  const {t} = useTranslation();
  const currentPath = window.location.pathname;
  const localStorageKey = props.section?`filters_${currentPath}_${props.section}`:`filters_${currentPath}`;

  console.debug(props, "ggg")
  const vendor = props?.vendor || []
  const [dateInputError, setDateInputError] = useState(false);
  const scrollableContainerRef = useRef<any>(null);             


  const storedFiltersString = localStorage.getItem(localStorageKey);
  const storedFilters = storedFiltersString
    ? JSON.parse(storedFiltersString)
    : [{ type: "text", column: "", operator: "", value: "" }];

  const [filters, setFilters] = useState(storedFilters);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(filters));
  }, [localStorageKey]);

  const textOperators = [
    { value: "like UPPER('%", label: "Contains" },
    { value: "=", label: "Is" },
    { value: "<>", label: "Is Not" },
    { value: "like UPPER('", label: "Starts with" },
    { value: "end", label: "Ends with" },
    { value: "empty", label: "Is Empty" },
    { value: "notEmpty", label: "Is Not Empty" },
  ];
  const dateOperators = [
    { value: "=", label: "Is" },
    { value: "<>", label: "Is Not" },
    { value: "<", label: "Is Before" },
    { value: ">", label: "Is After" },
  ];
  const numberOperators = [
    { value: "=", label: "Is" },
    { value: "<>", label: "Is Not" },
    { value: "<", label: "Less Than" },
    { value: ">", label: "Greater Than" },
    { value: "<=", label: "Less Than or Equal To" },
    { value: ">=", label: "Greater Than or Equal To" },
  ];
  const typeOperators = [
    { value: "=", label: "Is" },
    { value: "<>", label: "Is Not" },

  ];

  const company = useSelector((state: any) => state?.userConfig?.company);

  const handleAddFilter = () => {
    setFilters([...filters, { type: "", column: "", operator: "", value: "" }]);
    localStorage.setItem(
      localStorageKey,
      JSON.stringify([
        ...filters,
        { type: "", column: "", operator: "", value: "" },
      ])
    );

    // to scroll to the bottom when a new filter is added
    setTimeout(() => {
      if (scrollableContainerRef.current) {
        scrollableContainerRef.current.scrollTop = scrollableContainerRef.current.scrollHeight;
      }
    }, 0); 
  };

  const handleRemoveFilter = (index) => {
    const updatedFilters = filters.filter((_, i) => i !== index);
    setFilters(updatedFilters);
  };

  const handleApply = () => {
    if(filters.length > 0 && filters.every(filter => filter.type && filter.column && filter.operator && filter.value)) {
      props.onApply(filters);
      props.onClose();
      localStorage.setItem(localStorageKey, JSON.stringify(filters));
    }
    else{
      if(filters.length===0){
        props.onApply(filters);
        props.onClose();
        localStorage.setItem(localStorageKey, JSON.stringify(filters));
      }
      else {
        toastMessage(t("Please fill all the filters fields"), "error");
      }
    }
  };

  const handleFilterChange = (index, field, value) => {
    console.debug("handleFilterChange : ",index,field,value)
    const updatedFilters = [...filters];  
    if (field === "column" ) {
      setDateInputError(true);
      const selectedColumn = props?.columnOptions.find(
        (option) => option.value === value
        
      );
      updatedFilters[index] = {
        ...updatedFilters[index],
        [field]: value,
        type: selectedColumn?.type, // Set type based on column selection
        value: "", // Reset value when column changes
      };
    } else {
      updatedFilters[index] = {
        ...updatedFilters[index],
        [field]: value,
      };
    }
    setFilters(updatedFilters);
  };

  const renderFilterValueInput = (filter, index) => {
    if (filter.type === "text") {
      if (filter.column === "imv.VE_FLAG") {
        // Type column - use dropdown with options Vendor, Equipment, All
        return (
          <CustomSimpleSelect
            onChange={(code) => handleFilterChange(index, "value", code)}
            options={[
              { value: "VD", label: "Vendor" },
              { value: "EQ", label: "Equipment" },
              { value: "", label: "All" },
            ]}
            message={filter.value}
            value={"Choose Option"}
          />
        );
      } else if (filter.column === "imv.VENDOR") {
        // Vendor column - use search dropdown with list of vendors
        return (
          <CustomDropdown
            onChange={(code) => handleFilterChange(index, "value", code)}
            languages={vendor}
            message={filter.value}
            value={"Choose Option"}
            isSearch={true}
            height="85px"
          />
        );
      } else {
        return (
          <input
            className="form-control"
            placeholder="Value"
            type="text"
            value={filter.value}
            onChange={(e) => handleFilterChange(index, "value", e?.target?.value)}
            disabled={filter?.operator === "empty"}
          />
        );
      }
    }
    else if (filter.type === "date") {
      function isValidDate(dateString) {
        const date = new Date(dateString);
        return !isNaN(date.getTime());
      }
      return (
        <>
          <DatePicker
              className={`form-control  ${(filter.value && !isValidDate(filter.value)) ? 'is-invalid' : ''}`}
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              selected={filter.value ? new Date(filter.value) : null}
              onChange={(date) => {
                // Format the date to 'YYYY-MM-DD' before passing it to handleFilterChange
                const formattedDate = date ? formatISO(date, { representation: 'date' }) : '';
                handleFilterChange(index, "value", formattedDate);
              }}
              disabled={filter?.operator === "empty"}
          />
          {filter.value && !isValidDate(filter.value) && (
            <div className="invalid-feedback">
              {t("Please enter a valid date in the format dd-mm-yyyy.")}
            </div>
          )}
        </>
      );
    } else if (filter.type === "number") {
      // Number column - use input field with number type
      return (
        <input
          className="form-control "
          placeholder="Value"
          type="number"
          value={filter.value}
          onChange={(e) => handleFilterChange(index, "value", e?.target?.value)}
          disabled={filter?.operator === "empty"}
        />
      );
    }
  };

  const handleSavedValue = (field, value) => {
    if (field === "column") {
      const selectedColumn = props?.columnOptions.find(
        (option) => option.value === value
      );
      if(selectedColumn)
        return selectedColumn.label;
      else{
        return "Choose Column"
      }

  }
    else if (field === "operator") {
    if (value === "like UPPER('%") {
      return "Contains";
    } else if (value === "=") {
      return "Is";
    } else if (value === "<>") {
      return "Is Not";
    } else if (value === "like UPPER('") {
      return "Starts with";
    } else if (value === "end") {
      return "Ends with";
    } else if (value === "empty") {
      return "Is Empty";
    } else if (value === "notEmpty") {
      return "Is Not Empty";
    } else if (value === "<") {
      return "Is Before";
    } else if (value === ">") {
      return "Is After";
    } else if (value === "<=") {
      return "Less Than or Equal To";
    } else if (value === ">=") {
      return "Greater Than or Equal To";
    }
    else{
      return "Choose Operator"
    }
  }


  }

  return (
    <>
      {console.debug("filters data", filters)}
      <div
        className="h-100"
        style={{ zIndex: 99999999, marginTop: "-50px", width: "100%" }}
      >
        <div className="card w-100">
          {props.content !== "advancefilter" && (
            <div className="card-header d-flex justify-content-between align-items-center">
              <div className="h4 m-0">{t("Filter")}</div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setFilters([]);
                  props.onClose();
                }}
                title={t("Close")}
              >
                <FaTimes />
              </div>
            </div>
          )}
          <div
          ref={scrollableContainerRef}
            className="card-body w-150"
            style={{
              overflowY: "scroll",
              maxHeight: "250px",
              marginLeft: "15px",
              scrollBehavior: "smooth",
            }}
          >
            <div className="row row-cols-1 gy-2">
              {filters.map((filter, index) => (
                <div className="col-md-12 mb-3  row gy-2" key={index}>
                  <div className="col-md-4">
                  <CustomSimpleSelect
                      onChange={(code) => handleFilterChange(index, "column", code)}
                      options={props?.columnOptions}
                      message={filter?.column}
                      value={filter.column?handleSavedValue("column",filter.column):"Choose Column"}
                    />
                  </div>
                  <div className="col-md-4">
                  <CustomSimpleSelect
                      onChange={(code) => handleFilterChange(index, "operator", code)}
                      options={
                        (filter.type === "text" && filter.column !== "imv.VE_FLAG")
                          ? textOperators
                          : filter.type === "number"
                            ? numberOperators
                            : (filter.type === "text" &&
                              filter.column === "imv.VE_FLAG") ?
                              typeOperators :
                              dateOperators
                      }
                      message={filter.operator}
                      value={filter.operator?handleSavedValue("operator",filter.operator):"Choose Operator"}
                    />
                  </div>
                  <div className="col-md-3">
                    {renderFilterValueInput(filter, index)}

                  </div>
                  <div className="col-md-1">
                    <div
                      className="btn btn-secondary "
                      onClick={() => handleRemoveFilter(index)}
                    >
                      <FaTimes />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="row row-col-md-2 mt-3  w-100">
              <div className="col-md-6 mb-3 mb-md-0">
                <button
                  className="btn text-white w-100 btn-primary"
                  onClick={handleAddFilter}
                  title={t('Add A Filter')}
                >
                  <FaPlus /> {t('Add A Filter')}
                </button>
              </div>
              <div className="col-md-6 d-flex">
                <button
                  className="btn px-5 text-white w-100 btn-primary"
                  onClick={handleApply}
                  title={t('Apply')}
                >
                  {t('Apply')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.content !== "advancefilter" && (
        <div className="modal-backdrop fade show  mt-0 w-100 h-100"></div>
      )}
    </>
  );
};

export default AdvancedFilterPopup;
