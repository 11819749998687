import clsx from "clsx";
import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  savePinsFav,
} from "../../../../../app/modules/apps/user-management/users-list/core/_requests";
import { useAuth } from "../../../../../app/modules/auth";
import { KTSVG, WithChildren, checkIsActive } from "../../../../helpers";
import { useLayout } from "../../../core";
import { useSelector } from "react-redux";
import { URLS } from "../../../../../app/utils/URLS";
import {useTranslation} from "react-i18next";
import { useDispatch } from "react-redux";
import { updateMenuItem } from "../../../../../reducers/menuReducer";
import { toastMessage } from "../../../../../app/modules/auth/functions/toastMessage";
import { Spinner } from "react-bootstrap";
type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  fav?: boolean;
  data?: any;
  onChange?: any;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  fav = false,
  data = {},
  onChange = () => {},
}) => {
  const {t} = useTranslation();
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const {
    currentUser,
    setEditable,
  } = useAuth();
  let pinnedData: any = localStorage.getItem("pinnedData");
  const company = useSelector((state: any) => state?.userConfig?.company);
  pinnedData = JSON.parse(pinnedData);
  const [isLoading,setIsLoading]=useState(false);
  

  const handleFav = async (e) => {
    
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    try {
      const response=await savePinsFav({
        companyCode: company,
        userId: currentUser!.sub.split("#")[1],
        applicationCode: data.applicationCode,
        mniName: data.mniName,
        pinOrFav: "Fav",
        pinOrFavStatus: `${!fav}`,
      });
      if(response.errorCode===0){
        dispatch(updateMenuItem({mniName:data.mniName,type:"Fav"}));
      }
      else{
        toastMessage(t("Could not add the item to favourite Please contact support."),"error");
      }
     
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    } finally{
      setIsLoading(false);
    }

  };
  const handleClick = (to, data) => {
    if(data.menu_item==="Add New User"){
      setEditable(false);
    }

    if(data?.application==="Requisition") {
      window.open(`${URLS.REACT_APP_REQUISITION_URL}${data.webUrl}`, "_blank");
      return;
    }
    navigate(to);

  };
  return (
    <div className="menu-item px-6">
      <span
        className={clsx("menu-link without-sub", { active: isActive })}
        // to={to}
        onClick={() => handleClick(to, data)}
      >
        {hasBullet && (
          <span className="menu-bullet" style={{ verticalAlign: 'text-top'}}>
            <span className="bullet bullet-dot" style={{ verticalAlign: 'text-top'}} ></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
          <span className="menu-icon">
            {" "}
            <KTSVG path={icon} className="svg-icon-2" />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span className="menu-title" 
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          WebkitLineClamp: 1, // Adjust the number of lines as needed
          lineClamp: 1,
          verticalAlign: 'text-top', // For browsers that support line-clamp
          transition: 'WebkitLineClamp 0.5s', // Optional: add a smooth transition
        }}
        title={t(title)} 
        >{t(title)}</span>

        <span onClick={handleFav}>
          {isLoading?( <Spinner animation="border" variant="success" />):(fav ? (
            <KTSVG
              path={"/media/icons/duotune/general/star.svg"}
              className="svg-icon-2"
            />
          ) : (
            <KTSVG
              path={"/media/icons/duotune/general/star-empty.svg"}
              className="svg-icon-2"
            />
          ))}
        </span>
      </span>
      {children}
    </div>
  );
};

export { SidebarMenuItem };
