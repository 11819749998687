import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  ExportParams,
  GetContextMenuItemsParams,
  MenuItemDef,
  ProcessHeaderForExportParams,
  ProcessRowGroupForExportParams,
} from "ag-grid-community";
import "../user/UsersTable.css";
import {
  getStockItem,
  getVendor,
  selectStockCodes,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { useNavigate } from "react-router-dom";
import { KTCard } from "../../../theme/helpers";
import clsx from "clsx";
import { colors, titleCss } from "../../../constants/colors";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import sortalphadown from "../../../../src/assets/sortalphadown.png";
import onenine from "../../../../src/assets//sortnumericdown.png";
import { useAuth } from "../../modules/auth";
import Export from "../../modules/custom components/Export/Export";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import CustomLoadingCellRender from "../user/CustomLoadingCellRender";
import { updateLookupData, updateStockCode } from "../../../reducers/lookupReducer";
import { useDispatch, useSelector } from "react-redux";
import { updateIndex, updateIsCreateRequest, updateIsValidRouteForRequestStock } from "../../../reducers/indexReducer";
import { formatDatesinArray } from "../../../theme/assets/ts/_utils/FormatData";

import { useTranslation } from "react-i18next";
import SearchInput from "../../utils/SearchInput";

const StockList = ({ triggerAddFunction, error }) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, setGroup, setOrder, setReqCode, setPhase, setJob } =
    useAuth();
  const [rowData, setRowData] = useState<any>([]);
  const [sortData, setSortData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isadvanceShort, setIsadvanceShort] = useState(false);
  const [gridApi, setGridApi] = useState<any>(null);
  const [selectedValues, setSelectedValues] = useState<any>(new Set());
  const [selectedLocalStorageData, setSelectedLocalStorageData] = useState<any>(
    []
  );
  console.debug(selectedLocalStorageData, "selectedLocl");
  const [trackLoadingState, setTrackLoadingState] = useState(false);


  const columnOptions = [
    { value: "t.stockCode", label: "Code", type: "text" },
    { value: "t.description", label: "Description", type: "text" },
    {
      value: "t.qtyHand",
      label: "On Hand Qty",
      type: "number",
    },
    { value: "t.ordQty", label: "Order Qty", type: "number" },
    { value: "t.oemId", label: "OEM ID", type: "text" },
    { value: "imv.VE_FLAG", label: "Type", type: "text" },
    { value: "imv.VENDOR", label: "Vendor", type: "text" },
  ];

  const [vendors, setVendors] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const company = useSelector((state: any) => state?.userConfig?.company);
  const [exportAllData,setExportAllData]=useState<any>();

  const getvendors=async()=>{
    try {
      const response=await getVendor(company);
      if(response){
        const formattedArray = response?.data?.map((value) => ({
          label: value,
          value,
        }));
        setVendors(formattedArray);
        console.debug("ffff", formattedArray);
      }
    } catch (error) {
    }

  }

  useEffect(()=>{
   getvendors();
  },[])


  const fetchApi=async()=>{
    setTrackLoadingState(true);
    try {
      const response=await getStockItem(company, searchTerm, {
        advancedFilters: filterData,
        sortedList: sortData,
      });
      if (response.data) {
        setRowData(formatDatesinArray(response.data));
        if(filterData.length===0){
          setExportAllData(response);
        }
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  }

  const closeChangePasswordModal = (event) => {
    setIsadvanceShort(false);
  };

  useEffect(() => {
    fetchApi();
  }, [filterData,sortData]);

  const coulmnListForSort = [
    {
      id: "1",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Code",
      column: "CONVERT(t.stockCode, SQL_CHAR)",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "2",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Description",
      column: "CONVERT(t.description, SQL_CHAR)",
      col4: sortalphadown,
      iconIndex: 0,
    },
    {
      id: "3",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "On Hand Qty",
      column: "CONVERT(t.qtyHand, SQL_CHAR)",
      col4: onenine,
      iconIndex: 2,
    },
    {
      id: "4",
      col1: "⋮⋮⋮",
      col2: false,
      col3: "Order Qty",
      column: "CONVERT(t.ordQty, SQL_CHAR)",
      col4: onenine,
      iconIndex: 2,
    },
    // {                        // Removed as it is not required while sorting. It results invalid payload
    //   id: "7",
    //   col1: "⋮⋮⋮",
    //   col2: false,
    //   col3: "OEM ID",
    //   column: "CONVERT(t.oemId, SQL_CHAR)",
    //   col4: sortalphadown,
    //   iconIndex: 0,
    // },
  ];

  const columnDefs = [
    {
      headerName: "Code",
      field: "code",
      filter: "agTextColumnFilter",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      rowDrag: true,
    },
    {
      headerName: "Description",
      field: "description",
      minWidth: 300,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "On Hand Qty",
      field: "onHandQty",
      type:"rightAligned",
      valueGetter: (params) =>
      `${params?.data?.onHandQty?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }
      )}`,
      comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
      filter: "agTextColumnFilter",
      
    },
    {
      headerName: "Order Qty",
      field: "onOrderQty",
      type:"rightAligned",
      valueGetter: (params) =>
      `${params?.data?.onOrderQty?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }
      )}`,
      comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "OEM ID",
      field: "oemId",
      filter: "agTextColumnFilter",
    },
  ];

  const exportParams: ExportParams<any> = {
    processRowGroupCallback: rowGroupCallback,
    processHeaderCallback: headerCallback,
  };

  function headerCallback(params: ProcessHeaderForExportParams): string {
    return params.column.getColDef().headerName?.toUpperCase() || "";
  }

  function rowGroupCallback(params: ProcessRowGroupForExportParams): string {
    const node = params.node;
    return node.key?.toUpperCase() || "";
  }
  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      var result: (string | MenuItemDef)[] = [
        "copy",
        {
          name: "export",
          subMenu: [
            {
              name: "export to csv",
              action: () => {
                gridRef.current.exportDataAsCsv(exportParams);
              },
            },
            {
              name: "export to excel",
              action: () => {
                gridRef.current.exportDataAsExcel(exportParams);
              },
            },
          ],
        },
      ];
      return result;
    },
    []
  );

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,
      minWidth: 150,
    }),
    []
  );

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 150, 200];
  }, []);

  const gridRef: any = useRef(null);

  const onGridReady = (params) => {
    gridRef.current = params.api;
    if (window.innerWidth < 800) {
      sizeToFit();
    }
    setGridApi(params.api);
  };

  const sizeToFit = () => {
    if (gridRef.current) {
      gridRef.current.sizeColumnsToFit({
        defaultMinWidth: 150,
      });
    }
  };

  const cellClickedListener = useCallback(
    async (e) => {
      const codeValue = e.data.code;
      console.debug("cell clicked", codeValue);
      if (selectedValues.has(codeValue)) {
        const updatedSet = new Set(selectedValues);
        updatedSet.delete(codeValue);
        setSelectedValues(updatedSet);
        setSelectedLocalStorageData((prevData) =>
          prevData.filter((data) => data.code !== codeValue)
        );
      } else {
        setSelectedValues((prevSet) => new Set([...prevSet, codeValue]));
        setSelectedLocalStorageData((prevData) => [...prevData, e.data]);
      }
    },
    [selectedValues]
  );

  const onShortButtonClick = () => {
    setIsadvanceShort(!isadvanceShort);
  };
  const onFilterButtonClick = () => {
    setShowFilter(!showFilter);
  };

  const handleApply = (data) => {
    setFilterData(data);
  };

  const handleApplysort = (data) => {
    const sortPayload = data.map(({...newObject }) => ({
      column: newObject.column,
      ascDesc: newObject.ascDesc
    }));
    setSortData(sortPayload);
  };

  const addStockList = async () => {
    if (error) {
      return toastMessage(t("Please fill all required fields."), "error");
    }
    if (selectedLocalStorageData?.length > 0) {
      console.debug("selectedLocalStorageData 1: ",selectedLocalStorageData)

      const stockCodes = selectedLocalStorageData.map((item) => item.code);
      const apiPromises = stockCodes.map((stockCode) =>selectStockCodes(company, stockCode));

      const selectedStockDataResponses = await Promise.all(apiPromises);
      const selectedStockDataToBeSendInStockRequest = selectedStockDataResponses.map((response) => response?.data);
      console.debug("selectedLocalStorageData 2: ",selectedStockDataToBeSendInStockRequest)

      let updatedSelectedLocalStorageData:any[] = [];

      selectedLocalStorageData.forEach((item, index) => {
        let selectedStockDataToBeSendInStockRequestResponse = selectedStockDataToBeSendInStockRequest.filter(data=>data.code===item.code);
        let newItem = {...item}
        console.debug("selectedLocalStorageData 3: ",selectedStockDataToBeSendInStockRequestResponse);
        if(selectedStockDataToBeSendInStockRequestResponse.length>0){
          newItem.locationDropdown = selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown;
          if(selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown && selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown.length>0){
            //setting location specific values.
            let defaultLocation = selectedStockDataToBeSendInStockRequestResponse[0].locationDropdown[0]
            newItem.location = defaultLocation.location;
            newItem.availableQty = defaultLocation.availableQty;
            newItem.onHandQty = defaultLocation.onHandQty;
            newItem.unitCost = defaultLocation.unitCost;
          }
        }
        newItem.lineNo = 0;
        newItem.tempLineNo = Number(index) + 1; // fix for the stock request should not have lineNo.
        updatedSelectedLocalStorageData.push(newItem);
      });
      await dispatch(updateLookupData(updatedSelectedLocalStorageData));
      console.debug("selectedLocalStorageData 4: ",updatedSelectedLocalStorageData)

      triggerAddFunction();
      const selectedValuesArray = Array.from(selectedValues);
      setGroup(selectedLocalStorageData[0]?.code);
      console.debug("selectedValuesArray", selectedValuesArray);
      dispatch(updateIndex(0));

      setOrder(null);
      setReqCode(null);
      setPhase(null);
      setJob(null);
      setTimeout(() => {
        dispatch(updateStockCode(""));   // It will avoid an addition api call for the stock code that was last added.
        dispatch(updateIsCreateRequest(true));
        dispatch(updateIsValidRouteForRequestStock(true));
        navigate("/requeststock", {
          state: { selectedValues: selectedValuesArray},
        });
      }, 0);
    } else {
      toastMessage(t("Please select a stock to proceed."), "error");
    }
  };

  let columns = [
    "Code",
    "Description",
    {content: "On Hand Qty", styles: {halign: 'right'}},
    {content: "Order Qty", styles: {halign: 'right'}},
    "OEM ID",
  ];
  let dataKey = [
    "code",
    "description",
    "onHandQty",
    "onOrderQty",
    "oemId",
  ];
  let [userEmail, userId, userName]: any = currentUser?.sub?.split("#");


  const onDeselectAllClick = () => {
    if (gridRef.current) {
      gridRef.current.deselectAll();
    }
  };

  return (
    <>
      <div>
        <div
          className="d-flex"
          style={{
            marginBottom: 15,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      </div>
      <KTCard className=" px-10">
        <div className={clsx("mt-5", titleCss)} style={{ color: colors.title }}>
        {t("Stock Listing")}
        </div>

        <div className="mb-5 w-100">
          <div
            className="mt-5 row row-cols-1 row-cols-md-4 gy-3 w-100"
            style={{ alignItems: "center" }}
          >
            <SearchInput gridRef={gridRef} type="stockList" className="col-md-6" screen={"Stocklist"} />
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill text-wrap fs-6 primary-button w-100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "45px",
                  flexDirection: "row",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
                onClick={onDeselectAllClick}
              >
                <span
                  className="d-flex align-item-center justify-content-center"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {t("Reset")}
                </span>
              </button>
            </div>
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100"
                onClick={onShortButtonClick}
                title="Sort"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                Sort
              </button>
            </div>
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100"
                onClick={onFilterButtonClick}
                title={t("Filter")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                {t("Filter")}
              </button>
            </div>
            {isadvanceShort && (
              <UserEditModal
                content="advancesort"
                modalText={t("Sort")}
                onCloseEditModal={closeChangePasswordModal}
                user={coulmnListForSort}
                onApply={handleApplysort}
                style={{
                  position: "relative",
                  zIndex: 1000,
                }}
              />
            )}
            {showFilter && (
              <UserEditModal
                content="advancefilter"
                modalText={t("Filter")}
                onClose={() => {
                  console.debug("calls sort");

                  setShowFilter(false);
                }}
                onCloseEditModal={() => setShowFilter(false)}
                columnOptions={columnOptions}
                vendor={vendors}
                onApply={handleApply}
                style={{
                  position: "relative",
                  zIndex: 1000,
                }}
              />
            )}
          </div>
        </div>
        <Export
          gridApi={gridApi}
          userId={{ userName, userId, userEmail }}
          apiData={exportAllData}
          column={columns}
          dataKey={dataKey}
          file={"stock-item-list"}
        />

        <div className="ag-theme-alpine" style={{ height: 520, width: "100%" }}>
        {trackLoadingState ? (
      <CustomLoadingCellRender />
    ) : (
          <AgGridReact
            ref={gridRef}
            onRowSelected={cellClickedListener}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
            rowDragManaged={true}
            animateRows={true}
            onGridReady={onGridReady}
            enableAdvancedFilter={false}
            getContextMenuItems={getContextMenuItems}
            pagination={true}
            paginationPageSize={50}
            suppressRowClickSelection={true}
            paginationPageSizeSelector={paginationPageSizeSelector}
            suppressDragLeaveHidesColumns={true}
          />
          )}
        </div>
        <div className="text-center mt-5 mb-5 ">
          <button
            type="button"
            title={t("Cancel")}
            className="btn btn-secondary rounded-pill me-5"
            style={{ lineHeight: "1" }}
            onClick={() => navigate(-1)}
          >
            {t("Cancel")}
          </button>
          <button
            type="submit"
            onClick={() => addStockList()}
            className="btn text-white rounded-pill me-4"
            style={{ lineHeight: "1", backgroundColor: "#0a7eac" }}
            disabled={selectedLocalStorageData?.length <= 0}
            title={t("Add")}
          >
            {t("Add")}
          </button>
        </div>
      </KTCard>
    </>
  );
};

export default StockList;
