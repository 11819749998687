import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  lookupData: any[]; 
  allExpenseGroup: Record<string, any>;
  locationDataOfExpenseGroup: Record<string, any>;
  stockDataOfExpenseGroup: any[];
  stockCode: string;
  selectedRecords:[]
}

const initialState: InitialState = {
  lookupData: [],
  allExpenseGroup: {},
  locationDataOfExpenseGroup: {},
  stockDataOfExpenseGroup: [],
  stockCode: "",
  selectedRecords:[]
};

const counterSlice = createSlice({
  name: "lookupData",
  initialState: initialState,
  reducers: {
    updateLookupData: (state, action) => {
      state.lookupData = action.payload;
    },
    updateAllExpenseGroup: (state, action) => {
      state.allExpenseGroup = action.payload;
    },
    updateLocationDataExpenseGroup: (state, action) => {
      state.locationDataOfExpenseGroup = action.payload;
    },
    updateStockDataExpenseGroup: (state, action) => {
      state.stockDataOfExpenseGroup = action.payload;
    },
    updateStockCode: (state, action) => {
      state.stockCode = action.payload;
    },
    updateSelectedRecords: (state,action)=>{
      state.selectedRecords = action.payload;
    },
    reloadLookupData: (state, action) => { 
      const updatedData = state.lookupData.map((item, index) => {
        const matchingPayload = action.payload.filter(payloadItem => payloadItem.code === item.code);
        if (matchingPayload.length > 0) {
          const updatedItem = matchingPayload.reduce((acc, payloadItem) => {
            return {
              ...acc,
              reqCode : payloadItem.reqCode ? payloadItem.reqCode : item.reqCode, 
              location : payloadItem.location ? payloadItem.location : item.location,
              // lineNo :  item.lineNo?item.lineNo:Number(index) + 1,
            };
          }, item);
          return updatedItem;
        }
        return item;
      });
      state.lookupData = updatedData;
    },
  },
});

export const {
  updateLookupData,
  updateAllExpenseGroup,
  updateLocationDataExpenseGroup,
  updateStockDataExpenseGroup,
  updateStockCode,
  updateSelectedRecords,
  reloadLookupData,
} = counterSlice.actions;
export default counterSlice.reducer;