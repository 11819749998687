
function UserBlockModal({ onClose, onReset, time }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        background: "rgba(0, 0, 0, 0.5)",
        transition: "background 0.3s",
      }}
    >
      <div
        style={{
          background: "#fff",
          padding: "30px",
          borderRadius: "5px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          textAlign: "center",
          position: "relative",
          opacity: 1,
          transition: "opacity 0.3s",
        }}
      >
        <button
          style={{
            position: "absolute",
            top: "-5px",
            right: "5px",
            background: "none",
            border: "none",
            fontSize: "30px",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          &times;
        </button>
        <h2 >Your Account Has Been Locked</h2>
        <div className="separator separator-solid my-2" style={{ height: "2px" }}></div>
        <div className="fs-5 mb-2 mt-4 text-start" style={{ maxWidth: '600px', margin: '0 auto' }}>
          <div>Your account has been temporarily locked for security</div>
          <div>reasons. This is a precautionary measure due to</div>
          <div>multiple incorrect login attempts.</div>
        </div>
        <div className="fs-5 mb-5 mt-4 text-center">
          <h2 className="fs-5">Please Try to login after : {time}</h2>
        </div>
        <div className="fs-5 mb-2 mt-4 text-start" style={{ maxWidth: '600px', margin: '0 auto' }}>
          During this time, you won't be able to log in. Please wait<br />
          until the lockout period expires.
        </div>
        <div>
          <button
            className="btn text-white mx-2 rounded-pill bg-primary"
            onClick={onReset}
            style={{ minWidth: "80px" }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserBlockModal;
