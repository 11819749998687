import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../../pages/user/UsersTable.css";

import {
  getLineitemList,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { KTCard } from "../../../theme/helpers";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import { useAuth } from "../../modules/auth";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import CustomLoaders from "../user/CustomLoaders";
import {
  approveDetailColumnsConfig,
  approveStockDetailsColdefConfig,
  approvestockdetailConfig,
  closeUnprocesStockDetailsColdefConfig,
  closeUnprocessedDetailColumnConfig,
  closeUnprocessedDetailFilterColumnConfig,
  createIssueBatchColumnsConfig,
  createIssueBatchDetailsColdefConfig,
  createIssueDetailColumnsConfig,
} from "./LookUpFilterConfigDev";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DataGridWrapper from "../../utils/DataGrid/DataGridWrapper";
import SearchInputDev from "../../utils/SearchInputDev";
import ValueFormatter from "../../utils/ValueFormatter";

interface LookUpScreenProps {
  type: string;
  section: string,
  setStockDetail?: any;
  stockDetail?: any;
  rejectStock?: any;
  setRejectStock?: any;
  reqNumber?: any;
  setreqNumber?: any;
  setLoader?: any;
  loader?: any;
  loaderReject?: any;
  setLoaderReject?: any;
  actionToBeCalled?: any;
  setActionToBeCalled?: any;
  totalSelectedReqNumber:any;
}

const LookUpScreenForApproveStockDetailDev: React.FC<LookUpScreenProps> = ({
  type,
  section,
  setStockDetail,
  stockDetail = [],
  reqNumber,
  loader,
  totalSelectedReqNumber
}) => {
  const {t} = useTranslation();
  const company = useSelector((state: any) => state?.userConfig?.company);
  const { currentUser } = useAuth();
  const [columnOptions, setColumnOptions] = useState<any>([]);
  const [sortData, setSortData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isadvanceShort, setIsadvanceShort] = useState(false);
  const [trackLoadingState, setTrackLoadingState] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef: any = useRef();
  let [userEmail,userId]: any = currentUser?.sub?.split("#");
  const onFirstDataRendered = useRef(true);

  const checkIfRequestIsSelected=(requestNumber)=>{
    const storedData: any = localStorage.getItem("createIssueBatch");
    const parsedData = JSON.parse(storedData) || [];
    const index = parsedData?.findIndex(data => data?.requestNo === requestNumber);
    if (index !== -1) {
        return true;
    }
    return false;
  }

  const columnDefsForTable=() => {
    let ColOpt;
    const CustomCheckbox = (props) => {
      const handleCheckboxClick = () => {
        const storedData: any = localStorage.getItem("createIssueBatch");
        const parsedData = JSON.parse(storedData) || [];
        const updatedStockDetail = stockDetail.map((item) => {
          if (item?.lineNo === props?.data?.lineNo) {
            return { ...item, isChecked: item?.isChecked == true ? false : true };
          } else {
            return item;
          }
        });
        const index = parsedData?.findIndex(data => data?.requestNo === props?.data?.requestNo);
  
        if (index !== -1) {
            // Update the lineItems key of the found element with updatedStockDetail
            parsedData[index].lineItems = updatedStockDetail;
          }
        localStorage.setItem("createIssueBatch", JSON.stringify(parsedData));
        setStockDetail(updatedStockDetail);
      };
  
      return (
        <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
        <label>{props.data?.lineNo}</label>
        <input
          type="checkbox"
          onClick={handleCheckboxClick}
          checked={(props?.data?.disabled) ? "": props.data.isChecked}
          disabled={props?.data?.disabled} 
          style={{ width: "16px", height: "16px", marginLeft: "10px", marginBottom: "5px" }}
        />
      </div>
      );
    };

    switch (type) {
      case "approve-stock-detail":
        ColOpt = approveStockDetailsColdefConfig;
        break;
      case "close-unprocessed-stock-detail":
        ColOpt = closeUnprocesStockDetailsColdefConfig;
        break;
      case "create-issue-batch-detail":
        const extraObject = {
              columnName: "Line No",
              caption: "Line No",
              field: "lineNo",
              minWidth: 60,
              cellRenderer:checkIfRequestIsSelected(reqNumber)? CustomCheckbox:undefined
            };
            
        ColOpt = [extraObject, ...createIssueBatchDetailsColdefConfig];
        break;
      default:
        break;
    }
    return (ColOpt);
  };
  // need to call the colDef again when stockDetail changes
  const conditionForColDef=type==="create-issue-batch-detail"?[stockDetail,totalSelectedReqNumber]:[];
  const [columnDefs, setColumnDefs] = useState<any>([]);
  useEffect(() => {
    let columnDefinition = columnDefsForTable();
    const col = columnDefinition?.map((item) => {
      let valueFormatter;
          if (
            item?.columnType==='Numeric'
          ) {
              valueFormatter = (params) => { return (params.value || (params.value == 0)) ? ValueFormatter(params?.value,item?.numericFormat,item?.columnName) : "" };

          }
      return {
        headerName: item?.caption,
        columnType: item?.columnType,
        field: item?.field,
        width: item?.width,
        minWidth: item?.minWidth,
        valueFormatter:valueFormatter,
        cellRenderer: item?.cellRenderer,
        halign: item?.alignment,
        hide: false,
        valueGetter: item?.valueGetter,
      };
    });
    setColumnDefs(col);
  }, conditionForColDef);

  useEffect(() => {
    const selectedColumns =
      type === "create-issue-batch-detail" 
        ? createIssueBatchColumnsConfig
        : type === "close-unprocessed-stock-detail"
        ? closeUnprocessedDetailFilterColumnConfig
        : approvestockdetailConfig;
    setColumnOptions(selectedColumns);
  }, [type]);

  const closeChangePasswordModal = (event) => {
    setIsadvanceShort(false);
  };

  const callSortedLineApi = async () => {
     setTrackLoadingState(true);
    try {
      const sortDataLineResponse = await getLineitemList(company, reqNumber, {
        advancedFilters: filterData,
        sortedList: sortData,
      });
      
      if(sortDataLineResponse.data){
        const modifiedData = sortDataLineResponse?.data?.map((item) => {
          item.isChecked = true;
          item.disabled = (item?.availableQty <= (item?.requiredQty - item?.inQty)||  item?.status == "I1");
          return item;
        });
        console.debug("modifiedData",modifiedData);
        setStockDetail(modifiedData);
    }
      
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
   
  };

  const checkIfLineNumberExists=(requestNumber)=>{
    const storedData: any = localStorage.getItem("createIssueBatch");
    const parsedData = JSON.parse(storedData) || [];
    const index = parsedData?.findIndex(data => data?.requestNo === requestNumber);
  
    if (index !== -1 && parsedData[index]?.lineItems?.length>0) {
      setStockDetail(parsedData[index]?.lineItems);
      return true;
      }
    return false;

  }

  useEffect(() => {
    //need to handle case when sorted and filtered
    const shouldCallgetLineAPI=type==='create-issue-batch-detail'?(reqNumber&&!checkIfLineNumberExists(reqNumber)):reqNumber;
      if (shouldCallgetLineAPI) {
         callSortedLineApi();
      }

  }, [reqNumber]);

  useEffect(()=>{
    if(!onFirstDataRendered.current){
      callSortedLineApi();
    }
    onFirstDataRendered.current=false;
  },[sortData,filterData])


  
  const handleRowBackgroundChange = (e) => {
    if (e?.rowType === "data") {
      if (e?.data?.status === "I1" || e?.data?.status === "IX") {
        e.rowElement.style.backgroundColor = "#E3F2FD";
      }
    }
  }
  const handleCellBackgroundChange = (e) => {
    if (e?.rowType === 'data'&&e.column.dataField === 'availableQty'&&e?.data?.status !== "I1" && e?.data?.status !== "IX" && e?.data?.availableQty <= (e?.data?.requiredQty - e?.data?.inQty)) {
      e.cellElement.style.backgroundColor = '#FFC0C0';
    }
  };
  const gridRef: any = useRef(null);

  const onGridReady = useCallback((params) => {
  }, []);


  const onShortButtonClick = (event: any) => {
    event.preventDefault();
    setIsadvanceShort(!isadvanceShort);
  };
  const onFilterButtonClick = () => {
    setShowFilter(!showFilter);
  };

  const handleApplyFilter = (data) => {
    setFilterData(data);
  };

  const handleApplysort = (data) => {
    const sortPayload = data.map(({...newObject }) => ({
      column: newObject.column,
      ascDesc: newObject.ascDesc
    }));
    setSortData(sortPayload);
  };


  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const closeDropdownOnClickOutside: any = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdownOnClickOutside);

    return () => {
      document.removeEventListener("click", closeDropdownOnClickOutside);
    };
  }, []);

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 200];
  }, []);

  return (
    <>
      {loader && <CustomLoaders />}
      <div
        ref={dropdownRef}
        onClick={(e) => {
          handleDropdownToggle("");
        }}
      >
        <div
          className=" d-flex"
          style={{
            marginBottom: 15,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      </div>
      <KTCard className=" px-10">
        <div className="mb-5 w-100">
          <div
            className="mt-5 row row-cols-1 row-cols-md-2 gy-3 w-100"
            style={{ alignItems: "center" }}
          >
            <SearchInputDev gridRef={gridRef} type={type} className={"col-md-4"} screen={"LookUpScreenForApproveStock"} />
            <div className="col-md-3"></div>
            <div className="col-md-2 d-flex align-items-end justify-content-end">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100"
                onClick={onShortButtonClick}
                title={t("Sort")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                {t("Sort")}
              </button>
            </div>
            <div className="col-md-2 d-flex align-items-end justify-content-end">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100"
                title={t("Filter")}
                onClick={onFilterButtonClick}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                {t("Filter")}
              </button>
            </div>
            {showFilter && (
              <UserEditModal
              content="advancefilter"
              section={section}
              modalText="Filter"
              onCloseEditModal={() => {
                  setShowFilter(false);
                }}
                columnOptions={columnOptions}
                onApply={handleApplyFilter}
              />
            )}
            {isadvanceShort && (
              <UserEditModal
                content="advancesort"
                section={section}
                modalText={t("Sort")}
                onCloseEditModal={closeChangePasswordModal}
                user={
                  type === "create-issue-batch-detail"
                    ? createIssueDetailColumnsConfig
                    : type === "close-unprocessed-stock-detail"
                    ? closeUnprocessedDetailColumnConfig
                    : approveDetailColumnsConfig
                }
                onApply={handleApplysort}
              />
            )}
          </div>
        </div>
          <DataGridWrapper
            gridRef={gridRef}
            onGridReady={onGridReady}
            suppressRowClickSelection={true} 
            rowData={stockDetail}
            onRowPrepared={type==="create-issue-batch-detail" ?handleRowBackgroundChange:undefined}
            onCellPrepared={type==="create-issue-batch-detail" ?handleCellBackgroundChange:undefined}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={paginationPageSizeSelector}
            suppressMovableColumns={true}
            suppressDragLeaveHidesColumns={true}
            keyExpr={'code'}
            height={310}
            loadPanel={trackLoadingState }
            fileName={type}
            exportEnabled={false}
            fontSizeForPdfExport={10}
          />
      </KTCard>
    </>
  );
};

export default LookUpScreenForApproveStockDetailDev;
