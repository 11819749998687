/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import SelectCompanycard from "./SelectCompanycard";
 

export function SelectCompanyPage() {


  return (
    <>
      <div
        className="card  d-flex justify-content-center align-items-center "
        style={{
          borderRadius: 0,
          // height: "100vh",
          backgroundColor: "#F2F5F7",
          // margin: "auto",
          width: "350px",
          // minWidth:"auto"
        }}
      >
        <div>
          <SelectCompanycard />
        </div>
      </div>
    </>
  );
}
