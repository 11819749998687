import React, { useEffect, useState } from 'react'
import { Popup, Position } from 'devextreme-react/popup';
import FileUploader from 'devextreme-react/cjs/file-uploader'
import { FileUploadMode } from 'devextreme/ui/file_uploader';
import { toastMessage } from '../auth/functions/toastMessage';
import { useTranslation } from 'react-i18next';

type UploadFileProps = {
    visibilityFlag: boolean;
    title: string;
    height?: number;
    width?: number;
    onHideFunction: () => void;
    multipleFlag: boolean;
    fileExtensions: string;
    onSelectedFilesChanged: (e: any) => void;
    mode?: FileUploadMode;
    onUploadAction: () => void;
    errorFunction?: () => void;
}


export default function UploadFile({
    visibilityFlag = false,
    title = "Upload File",
    width = 335,
    height = 280,
    onHideFunction,
    multipleFlag = false,
    fileExtensions,
    onSelectedFilesChanged,
    mode = 'useButtons' as FileUploadMode,
    onUploadAction,
    errorFunction
}: UploadFileProps) {

    const { t } = useTranslation();
    const [fileData, setFileData] = useState<File[] | null>(null);

    useEffect(() => {
        if (visibilityFlag) {
            setFileData(null);
        }
    }, [visibilityFlag]);

    const handleFileChange = (e: any) => {
        setFileData(e.value);
        onSelectedFilesChanged(e);
    };

    const errorWhileUpload = () => {
        toastMessage(t("An error occurred. Please try again later."), "error");
    }

    const renderContent = () => (
        <>
            {
                multipleFlag && fileData && fileData?.length > 0 && <span>{fileData?.length} File's Selected</span>
            }
            <FileUploader
                multiple={multipleFlag}
                accept={fileExtensions}
                onValueChanged={handleFileChange}
                uploadMode={mode}
                uploadFile={onUploadAction}
                onUploadError={errorFunction ?? errorWhileUpload}
            />
        </>

    )


    return (

        visibilityFlag ? 
            <Popup
                visible={visibilityFlag}
                hideOnOutsideClick={true}
                onHiding={onHideFunction}
                showCloseButton={true}
                resizeEnabled={true}
                title={title}
                width={width}
                minWidth={335}
                height={height}
                minHeight={280}
                contentRender={renderContent}>
                <Position at="center" my="center" collision="fit" />
            </Popup>
        : <></>

    )
}
