import UserDetail from "./UserDetailpage";
import UserAccessTable from "./UserAccessTable";
import { useLocation } from "react-router-dom";

const UserAccessDetail = () => {
  const { state } = useLocation();
  return (
    <div>
      <div>
        <UserDetail data={state} />
      </div>
      <div>
        <UserAccessTable data={state} />
      </div>
    </div>
  );
};

export default UserAccessDetail;
