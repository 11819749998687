import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import StockHeader from "./StockHeader";
import StockList from "./Stocklist";

const StockDetail = () => {
  const { state } = useLocation();
  const [triggerAdd, setTriggerAdd] = useState(false);
  const [error, setError] = useState(false);
  const currentPath = window.location.pathname;
  const localStorageKeyFilter = `filters_${currentPath}`;
  const localStorageKeySort=`sorting${currentPath}`
  const location=useLocation();

  useEffect(() => {
      // Clear local storage when navigating away from the component
      return () => {
        localStorage.removeItem(localStorageKeyFilter);
        localStorage.removeItem(localStorageKeySort);
      };
    }, [localStorageKeyFilter,localStorageKeySort, location]);
	

  const triggerAddFunction = () => {
    setTriggerAdd(true);
  };

  useEffect(() => {
    if (triggerAdd) {
      setTriggerAdd(true);
    }
  }, [triggerAdd]);

  return (
    <div>
      <div>
        <StockHeader data={state} triggerAdd={triggerAdd} setError={setError} currentPage="stockDetail"  />
      </div>
      <div>
        <StockList triggerAddFunction={triggerAddFunction} error={error} />
      </div>
    </div>
  );
};

export default StockDetail;
