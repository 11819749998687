import { useTranslation } from "react-i18next";
import loader from "../../../assets/erpAssets/loader.gif";

function CustomLoaders() {
  const {t}=useTranslation();
  return (
    <div
      className="ag-custom-loading-cell d-flex align-items-center justify-content-center w-100 h-100 text-dark fs-5"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999,
      }}
    >
      <img
        src={loader}
        alt={`${t("Loading")}...`}
        style={{ height: "100px", width: "100px" }}
      />
    </div>
  );
}

export default CustomLoaders;
