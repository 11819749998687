console.debug("winows : ",window.env,window.env.CONFIG);
const { REAUTH, MESSAGE,TIMEOUT, LANGUAGE } = window.env.CONFIG;

export const ApproveAuth = {
  REAUTH:REAUTH,
};
export const Message = {
  MESSAGE:MESSAGE
};
export const Timeout = {
  TIMEOUT:TIMEOUT
}
export const Language ={
  LANGUAGE:LANGUAGE
}

export const formatPrice = (price) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,  // No trailing decimals if unnecessary
    maximumFractionDigits: 2,  // Up to two decimal places
    useGrouping: true          // Enables grouping with commas
  }).format(price);
}
