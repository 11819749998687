import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';
import {DataGridTypes} from 'devextreme-react/data-grid';
import { formatDateToDDMMYYY } from '../../../theme/assets/ts/_utils/FormatData';
import imgData from '../../../assets/logo.png';


export function ExportingToExcel(e: DataGridTypes.ExportingEvent, fileName: string) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGridToExcel({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
          if (gridCell && gridCell.column && gridCell.column.caption && gridCell.column.caption.toLowerCase() === 'actions') {
              excelCell.value = ''; // Optional: Empty the value if you just want to clear content instead of removing
          }
      },
  }).then(() => {
      // After export, remove the specific column (e.g., "Action" column) by its index or caption
      const actionColumnIndex = worksheet.columns.findIndex(column => column.header === 'Actions');
      if (actionColumnIndex !== -1) {
          worksheet.spliceColumns(actionColumnIndex + 1, 1); // +1 because columnIndex is 1-based
      }

      workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
      });
    });
  };

  export function ExportingToPdf(
    e: DataGridTypes.ExportingEvent,
    fileName: string,
    company: string,
    companyName: string,
    userEmail: string,
    userId: string,
    fontSizeForPdfExport: number = 10
) {
    const doc: any = new jsPDF({
        orientation: 'landscape',
    });

    const image = new Image();
    image.src = imgData;

    const visibleColumns = e.component.getVisibleColumns();
    const columnsWithoutAction = visibleColumns.filter((column) => column.caption !== 'Actions');

    exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
        margin: { top: 25, left: 5, right: 5, bottom: 5 },
        indent: 5,
        customizeCell: ({ pdfCell, gridCell }) => {
            if (gridCell?.column?.caption === 'Actions') {
                return; // Skip "Actions" column
            }
            if (pdfCell) {
                pdfCell.wordWrapEnabled = true;
                pdfCell.padding = { top: 1, right: 2, bottom: 1, left: 1 };

                if (gridCell?.rowType === 'header') {
                    pdfCell.backgroundColor = '#53B1F8';
                    pdfCell.textColor = '#030F18';
                    if (pdfCell.font) {
                        pdfCell.font.size = fontSizeForPdfExport + 2;
                    }
                } else {
                    pdfCell.textColor = '#333333';
                    if (pdfCell.font) {
                        pdfCell.font.size = fontSizeForPdfExport;
                    }
                }
            }
        },
    }).then(() => {
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);

            // Adding logo and additional information on each page
            doc.addImage(image, 'PNG', 4, 2, 50, 30);

            doc.setFont('helvetica', 'bold');
            const currdate = new Date();
            doc.setFontSize(12);
            doc.text(5, 5, 'Printed At: ' + formatDateToDDMMYYY(currdate.toLocaleString()));
            doc.text(80, 10, userEmail);
            doc.text(80, 15, userId);
            doc.text(80, 20, `${companyName} - ${company}`);
            doc.setFont('helvetica', 'normal');

            // Adding footer on each page
            doc.setFontSize(10);
            doc.setTextColor(214, 214, 214);
            doc.text(200, 5, 'For internal use only.');
            doc.setTextColor(0, 0, 0);
            doc.text(270, 5, `Page ${i}`);
        }
        doc.save(`${fileName}.pdf`);
    });
}


